import { publicImages } from 'utils/getPublicImages'

const getBtnImageUrl = (image: string) =>
  publicImages.getIllustration(`main-landing/build-info/${image}`)

const getImageUrl = (image: string) =>
  publicImages.getIllustration(`rose/prize/${image}`)

export const MAIN_LANDING_BUILD_INFO: Record<
  string,
  { name: string; description: string; btnImageUrl: string; imageUrl: string }
> = {
  Beginner: {
    name: 'Beginner',
    description:
      'Be one of the chosen few to enter the ring as a true fighter! Unlock the Beginner level with 2 Beginner stickers, found mostly in the most sought-after Bronze Mystery Boxes.',
    btnImageUrl: getBtnImageUrl('beginner-small.webp'),
    imageUrl: getImageUrl('beginner-prize.webp'),
  },
  Intermediate: {
    name: 'Intermediate',
    description:
      'Ready to level up toward the ultimate title? Show your grit and determination by collecting 3 Intermediate stickers, mostly found in the Silver Mystery Boxes.',
    btnImageUrl: getBtnImageUrl('intermediate-small.webp'),
    imageUrl: getImageUrl('intermediate-prize.webp'),
  },
  Advanced: {
    name: 'Advanced',
    description:
      "You're a force to be reckoned with, and it's time to prove it. Collect 4 Advanced stickers, hidden deep within the exclusive Gold Mystery Boxes, and unlock the next level of glory.",
    btnImageUrl: getBtnImageUrl('advanced-small.webp'),
    imageUrl: getImageUrl('advanced-prize.webp'),
  },
  Elite: {
    name: 'Elite',
    description:
      "You've made it to the big leagues! With 5 Elite stickers, found in the rarest Diamond Mystery Boxes, you'll be among the few who can claim the title of the Elite fighter.",
    btnImageUrl: getBtnImageUrl('elite-small.webp'),
    imageUrl: getImageUrl('elite-prize.webp'),
  },
  Legendary: {
    name: 'Legendary',
    description:
      'Breeding lower-level fighters will give you the chance to enter the ranks of Legendary fighters. Will you rise to the challenge?',
    btnImageUrl: getBtnImageUrl('legendary-small.webp'),
    imageUrl: getImageUrl('legendary-prize.webp'),
  },
  GOAT: {
    name: 'GOAT',
    description:
      "You've proven yourself as a Legendary champion, and now it's time to cement your legacy. Enter the special raffle for a chance to win the ultimate fighter - the GOAT.",
    btnImageUrl: getBtnImageUrl('goat-small.webp'),
    imageUrl: getImageUrl('elite-prize.webp'),
  },
}
