import { NftGroupRarity } from 'models/INfts'
import { publicImages } from 'utils/getPublicImages'
import { AthleteRarityId } from 'constants/rarity'

type RoseNftCollectionDetailsContentType = {
  title: string
  subtitle: string
  description: string
  allItems: {
    title: string
    description: string
    href: string
    btnCaption: string
  }
  prizeUrlImage: string
  rarityId: number
}
export const ROSE_NFT_COLLECTION_DETAILS_CONTENT: Record<
  string,
  RoseNftCollectionDetailsContentType
> = {
  [NftGroupRarity.Beginner]: {
    title: 'Beginner',
    subtitle: 'Fighter Avatar NFT',
    description:
      'Be one of the chosen few to enter the ring as a true fighter! Unlock the Beginner level with 2 Beginner stickers, found mostly in the most sought-after Bronze Mystery Boxes.',
    allItems: {
      title: 'How to Build an Avatar?',
      description:
        'Acquire Mystery Boxes with digital stickers inside. Collect enough stickers to build your avatar. Keep collecting to upgrade your Fighter to reach the ultimate Legendary title!',
      href: '',
      btnCaption: 'Start Building',
    },
    prizeUrlImage: publicImages.getIllustration(
      'rose/packs/pack-beginner-label.webp'
    ),
    rarityId: AthleteRarityId.Beginner,
  },
  [NftGroupRarity.Intermediate]: {
    title: 'Intermediate',
    subtitle: 'Fighter Avatar NFT',
    description:
      'Ready to level up toward the ultimate title? Show your grit and determination by collecting 3 Intermediate stickers, mostly found in the Silver Mystery Boxes.',
    allItems: {
      title: 'How to Build an Avatar?',
      description:
        'Acquire Mystery Boxes with digital stickers inside. Collect enough stickers to build your avatar. Keep collecting to upgrade your Fighter to reach the ultimate Legendary title!',
      href: '',
      btnCaption: 'Start Building',
    },
    prizeUrlImage: publicImages.getIllustration(
      'rose/packs/pack-intermediate-label.webp'
    ),
    rarityId: AthleteRarityId.Intermediate,
  },
  [NftGroupRarity.Advanced]: {
    title: 'Advanced',
    subtitle: 'Fighter Avatar NFT',
    description:
      "You're a force to be reckoned with, and it's time to prove it. Collect 4 Advanced stickers, hidden deep within the exclusive Gold Mystery Boxes, and unlock the next level of glory.",
    allItems: {
      title: 'How to Build an Avatar?',
      description:
        'Acquire Mystery Boxes with digital stickers inside. Collect enough stickers to build your avatar. Keep collecting to upgrade your Fighter to reach the ultimate Legendary title!',
      href: '',
      btnCaption: 'Start Building',
    },
    prizeUrlImage: publicImages.getIllustration(
      'rose/packs/pack-advanced-label.webp'
    ),
    rarityId: AthleteRarityId.Advanced,
  },
  [NftGroupRarity.Elite]: {
    title: 'Elite',
    subtitle: 'Fighter Avatar NFT',
    description:
      "You've made it to the big leagues! With 5 Elite stickers, found in the rarest Diamond Mystery Boxes, you'll be among the few who can claim the title of the Elite fighter.",
    allItems: {
      title: 'How to Build an Avatar?',
      description:
        'Acquire Mystery Boxes with digital stickers inside. Collect enough stickers to build your avatar. Keep collecting to upgrade your Fighter to reach the ultimate Legendary title!',
      href: '',
      btnCaption: 'Start Building',
    },
    prizeUrlImage: publicImages.getIllustration(
      'rose/packs/pack-elite-label.webp'
    ),
    rarityId: AthleteRarityId.Elite,
  },
}
