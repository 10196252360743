import { FC } from 'react'
import { makeStyles } from 'styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import { SocialsLinks } from './utils'
import Box from '@mui/material/Box'
import { TwitterIcon, DiscordIcon, InstagramIcon } from 'assets/socials'
import theme from 'styles/theme'
import { socialUrls } from 'constants/staticUrls'

const useStyles = makeStyles({ name: 'SocialSection' })(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const sxClasses = {
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    willChange: 'color',
    transition: 'color 0.3s ease',

    '&:hover': {
      color: 'rgba(255, 255, 255, 0.8)',
    },

    '&:not(:first-of-type)': {
      ml: {
        xs: 0,
        md: 1,
      },
    },

    '& svg': {
      height: 16,
      maxWidth: 20,
    },
  },
}

export const socialInfoList = [
  new SocialsLinks(<TwitterIcon />, socialUrls.digi.twitter, 'Twitter'),
  // new SocialsLinks(<TelegramIcon />, '', 'Telegram'),
  new SocialsLinks(<DiscordIcon />, socialUrls.digi.discord, 'Linkedin'),
  // new SocialsLinks(<MediumIcon />, '', 'Medium'),
  // new SocialsLinks(<RedditIcon />, '', 'Reddit'),
  // new SocialsLinks(<FacebookIcon />, '', 'Facebook'),
  new SocialsLinks(<InstagramIcon />, socialUrls.rose.instagram, 'Instagram'),
]

type Props = {
  className?: string
}

export const SocialSection: FC<Props> = ({ className }) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.root, className)}>
      {socialInfoList.map((social, idx) => (
        <IconButton
          key={idx}
          href={social.path}
          target={social.target}
          sx={sxClasses.icon}
        >
          <>{social.icon}</>
        </IconButton>
      ))}
    </Box>
  )
}
