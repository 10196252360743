import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import { fontSpaceMono } from 'styles/fonts'
import { FC } from 'react'

const useStyles = makeStyles({ name: 'MainLandingBuildInfoBtn' })((theme) => ({
  root: {
    width: 184,
    height: 184,
    borderRadius: theme.spacing(2),
    backdropFilter: 'blur(12px)',
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    position: 'relative',
    overflow: 'hidden',
    backgroundImage:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',

    '&::before': {
      content: "''",
      left: 0,
      bottom: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      backgroundImage: 'none',
      background: 'rgba(255, 255, 255, 0.2)',
      zIndex: -1,
      transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      opacity: 0,
      borderRadius: 'inherit',
      border: '1px solid rgba(255, 255, 255, 0.4)',
      willChange: 'opacity',
    },

    '&:hover::before': {
      opacity: 1,
    },

    '&:hover': {
      cursor: 'pointer',

      img: {
        opacity: 1,
        transform: 'rotate(0)',
      },
    },

    [theme.breakpoints.down('md')]: {
      minWidth: 156,
      width: 'auto',
      height: 'auto',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1.5, 2, 1.5, 5.5),
    },
  },
  active: {
    '&::before': {
      content: "''",
      left: 0,
      bottom: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      backgroundImage: 'none',
      background: 'rgba(255, 255, 255, 0.2)',
      zIndex: -1,
      transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      opacity: 1,
      borderRadius: 'inherit',
      border: '1px solid rgba(255, 255, 255, 0.4)',
      willChange: 'opacity',
    },

    img: {
      opacity: 1,
      transform: 'rotate(0)',
    },
  },
  title: {
    fontFamily: fontSpaceMono,
    fontSize: 18,
    position: 'relative',
    zIndex: 1,

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  image: {
    position: 'absolute',
    left: -30,
    bottom: -15,
    transform: 'rotate(-10deg)',
    opacity: 0.5,
    transition: 'opacity 0.3s ease-in, transform 0.3s ease-in',
    willChange: 'opacity',

    [theme.breakpoints.down('md')]: {
      width: 50,
      left: -20,
      bottom: -5,
    },
  },
}))

interface Props {
  name: string
  imageUrl: string
  isActive: boolean
  onClick: (name: string) => void
}

export const MainLandingBuildInfoBtn: FC<Props> = ({
  name,
  isActive,
  onClick,
  imageUrl,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, isActive && classes.active)}
      onClick={() => onClick(name)}
    >
      <Typography variant={'subtitle1'} className={classes.title}>
        {name}
      </Typography>
      <img src={imageUrl} className={classes.image} alt={name} />
    </div>
  )
}
