import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import {
  LogoAnimocaBrands,
  LogoLympoWhite,
  LogoRoseNamajunas,
} from 'assets/logo'

const useStyles = makeStyles({ name: 'MainLandingPartners' })((theme) => ({
  root: {
    padding: theme.spacing(15, 0, 10),
    width: '100%',
    overflow: 'hidden',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 0, 5),
    },
  },
  container: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    gap: theme.spacing(6),
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(3),
    },
  },
  subtitle1: {
    fontFamily: fontSpaceMono,
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    lineHeight: '40px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    paddingLeft: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    maxWidth: 384,
    alignItems: 'start',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  logo: {
    height: 48,
    color: theme.palette.text.primary,

    [theme.breakpoints.down('sm')]: {
      height: 40,
    },
  },
  logoSmall: {
    height: 30,

    [theme.breakpoints.down('sm')]: {
      height: 20,
    },
  },
  itemText: {
    color: theme.palette.text.secondary,
  },
}))

export const MainLandingPartners = () => {
  const { classes, cx } = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth={'lg'}>
        <div className={classes.container}>
          <div>
            <Typography variant={'subtitle1'} className={classes.subtitle1}>
              Our
            </Typography>
            <Typography variant={'h2'}>Partners</Typography>
          </div>
          <div className={classes.list}>
            <div className={classes.item}>
              <LogoLympoWhite className={classes.logo} />
              <Typography className={classes.itemText}>
                Sports NFT ecosystem with preserved IP rights of world-famous
                athletes, clubs, and sports celebrities aiming to revolutionize
                fan engagement and connection through digital ownership.
              </Typography>
            </div>
            <div className={classes.item}>
              <LogoAnimocaBrands className={classes.logo} />
              <Typography className={classes.itemText}>
                Leader in digital entertainment, blockchain, and gamification,
                working to advance digital property rights and contribute to
                building the open metaverse.
              </Typography>
            </div>
            <div className={classes.item}>
              <LogoRoseNamajunas
                className={cx(classes.logo, classes.logoSmall)}
              />
              <Typography className={classes.itemText}>
                Mixed martial artist and a two-time UFC Champion embodying the
                true spirit of a fighter — never giving up and showing that your
                goals are worth fighting for.
              </Typography>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
