import { makeStyles } from 'styles/makeStyles'
import { FC, memo } from 'react'
import { OpenSeaIcon } from 'assets/icons'
import { fontSpaceMono } from 'styles/fonts'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'

const useStyles = makeStyles({ name: 'InventoryFighterAvatarSticker' })(
  (theme) => ({
    root: {
      maxWidth: 308,
      width: '100%',
      padding: theme.spacing(2),
      background: 'rgba(38, 38, 38, 0.9)',
      backdropFilter: 'blur(12px)',
      border: '1px solid rgba(255, 255, 255, 0.4)',
      borderBottom: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      width: '100%',
    },
    imageContainer: {
      minWidth: 120,
      width: 120,
      height: 120,
      position: 'relative',

      '& img': {
        width: '100%',
        height: 'auto',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontWeight: 700,
    },
    subtitle: {
      color: theme.palette.secondary.light,
      fontWeight: 700,
      fontFamily: fontSpaceMono,
      fontSize: 12,
    },
    text: {
      fontWeight: 400,
      fontFamily: fontSpaceMono,
    },
    btn: {
      textTransform: 'none',

      svg: {
        minWidth: 24,
        width: 24,
        height: 24,
      },
    },
    dark: {
      '&::before': {
        content: "''",
        left: 0,
        position: 'absolute',
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 3,
        width: '100%',
        height: '100%',
      },
    },
  })
)

interface Props {
  className?: string
  imgUrl: string
  name: string
  title: string
  own: string | number
  openSeaUrl: string
  isShortVariant?: boolean
}

const InventoryFighterAvatarStickerComponent: FC<Props> = ({
  className,
  imgUrl,
  name,
  title,
  own,
  openSeaUrl,
  isShortVariant,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={classes.container}>
        <div className={cx(classes.imageContainer, own === 0 && classes.dark)}>
          <img src={imgUrl} alt={name} />
        </div>
        <div className={classes.content}>
          <Typography variant={'body1'} className={classes.title}>
            {name}
          </Typography>
          <Typography variant={'subtitle2'} className={classes.subtitle}>
            {title}
          </Typography>
          {!isShortVariant && (
            <div>
              <Typography variant={'subtitle2'} className={classes.text}>
                You own: <span>{own}</span>
              </Typography>
            </div>
          )}
        </div>
      </div>
      <Button
        variant={'text'}
        className={classes.btn}
        href={openSeaUrl}
        target={'_blank'}
      >
        <OpenSeaIcon />
        <span>View on Opensea</span>
      </Button>
    </div>
  )
}

export const InventoryFighterAvatarSticker = memo(
  InventoryFighterAvatarStickerComponent
)
