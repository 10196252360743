import { createTheme, Theme } from '@mui/material/styles'

import {
  primaryText,
  secondaryText,
  grey600,
  primaryMain,
  primaryContrastText,
  bgColorDefault,
  grey700,
  secondaryLight,
  primaryDark,
  infoMain,
  warningMain,
  successMain,
  errorMain,
  grey900,
  grey800,
  grey500,
  grey400,
  grey100,
  grey300,
  primaryLight,
  successLight,
  infoLight,
  infoDark,
  warningDark,
  greyA100,
  successDark,
  errorDark,
  grey200,
  errorLight,
  infoContrastText,
  successContrastText,
  warningLight,
  greyA200,
} from './colors'

import { getOverridesComponent } from './overridesComponent'
import { getTypography } from './typography'

export const createCustomTheme = (): Theme => {
  const baseTheme = createTheme({
    spacing: (x: number) => `${x * 8}px`,
    palette: {
      primary: {
        main: primaryMain,
        dark: primaryDark,
        light: primaryLight,
        contrastText: primaryContrastText,
      },
      secondary: {
        main: secondaryText,
        light: secondaryLight,
        contrastText: primaryText,
      },
      info: {
        main: infoMain,
        light: infoLight,
        dark: infoDark,
        contrastText: infoContrastText,
      },
      warning: {
        main: warningMain,
        light: warningLight,
        dark: warningDark,
      },
      success: {
        main: successMain,
        light: successLight,
        dark: successDark,
        contrastText: successContrastText,
      },
      error: {
        main: errorMain,
        dark: errorDark,
        light: errorLight,
      },
      grey: {
        100: grey100,
        200: grey200,
        300: grey300,
        400: grey400,
        500: grey500,
        600: grey600,
        700: grey700,
        800: grey800,
        900: grey900,
        A100: greyA100,
        A200: greyA200,
      },
      text: {
        primary: primaryText,
        secondary: secondaryText,
      },
      background: {
        default: bgColorDefault,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 680,
        md: 1024,
        lg: 1348,
        xl: 1536,
      },
    },
  })

  return createTheme({
    ...baseTheme,
    components: getOverridesComponent(baseTheme),
    typography: getTypography(baseTheme),
  })
}

export default createCustomTheme()
