export const grey100 = '#D2DAE8'
export const grey200 = '#B7B7B7'
export const grey300 = '#9E9E9E'
export const grey400 = '#868686'
export const grey500 = '#6E6E6E'
export const grey600 = '#6D6D6D'
export const grey700 = '#565656'
export const grey800 = '#3D3D3D'
export const grey900 = '#303030'
export const greyA100 = '#221E12'
export const greyA200 = '#151515'

export const primaryText = '#FFF'
export const secondaryText = '#CECFD8'

export const primaryMain = '#D34856'
export const primaryLight = '#B02A53'
export const primaryDark = '#5C252A'
export const primaryContrastText = '#D8CED7'

export const secondaryLight = '#D9B240'

export const bgColorDefault = '#000'

export const infoMain = '#2561ED'
export const infoLight = '#9EDEFA'
export const infoDark = '#172849'
export const infoContrastText = '#2551A3'

export const warningMain = '#FFAD0D'
export const warningLight = '#FFEAAB'

export const warningDark = '#726026'

export const successMain = '#4DAB75'
export const successLight = '#4cab75'
export const successDark = '#274C37'
export const successContrastText = '#203C2C'

export const errorMain = '#C0425E'
export const errorDark = '#34181B'
export const errorLight = '#FF3236'

export const activePrimaryBtn = '#F26659'
export const activePrimarySecondaryBtn = '#CFCFCF'

export const bgDarkGray = '#262626'

export const snowWhite = '#FFFBFA'
export const darkOlive = '#352E17'
export const siennaBrown = '#5F4F22'
