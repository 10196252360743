import { ComponentProps, FC, memo } from 'react'
import { makeStyles } from 'styles/makeStyles'
import Button from '@mui/material/Button'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  selectIsLoadingUserAuth,
  isLoggedInSelector,
} from 'store/reducers/user/selectors'
import { UserCirclePlusIcon } from 'assets/icons'
import Skeleton from '@mui/material/Skeleton'
import { setModal } from 'store/reducers/app/actions'
import { ModalName } from 'constants/modals'

const useStyles = makeStyles({ name: 'ConnectButton' })(() => ({
  root: {
    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
}))

type Props = ComponentProps<typeof Button> & {
  className?: string
  caption?: string
  variant?: 'text' | 'outlined' | 'contained'
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  position?: string
  withIcon?: boolean
}

const ConnectButtonComponent: FC<Props> = ({
  className,
  caption = 'Connect',
  variant = 'contained',
  size = 'medium',
  color = 'primary',
  position = 'header',
  withIcon,
  ...rest
}) => {
  const { classes, cx } = useStyles()

  const dispatch = useAppDispatch()

  const isLoggedIn = useAppSelector(isLoggedInSelector)
  const isLoadingUserAuth = useAppSelector(selectIsLoadingUserAuth)

  const onConnectButtonClick = () => {
    dispatch(setModal(ModalName.Auth, true))
  }

  if (isLoadingUserAuth) {
    return (
      <Skeleton
        sx={{
          transform: 'inherit',
          minWidth: 156,
          height: 40,
          borderRadius: 4,
        }}
        className={className}
      />
    )
  }

  if (isLoggedIn) {
    return null
  }

  return (
    <Button
      className={cx(cx(classes.root, className))}
      variant={variant}
      size={size}
      color={color}
      onClick={onConnectButtonClick}
      {...rest}
    >
      <span dangerouslySetInnerHTML={{ __html: caption }} />
      {withIcon && <UserCirclePlusIcon />}
    </Button>
  )
}

export const ConnectButton = memo(ConnectButtonComponent)
