import { makeStyles } from 'styles/makeStyles'
import { FC, ReactNode } from 'react'
import { publicImages } from 'utils/getPublicImages'
import { ScrollToTop } from 'components/common/ScrollToTop'
import { Footer } from 'components/Footer'
import { RoseHeader } from '../components/RoseHeader'

const useStyles = makeStyles({ name: 'RoseProfileLayout' })(() => ({
  root: {
    background: `
        url('${publicImages.getBackground('rose/profile-head-bg.webp')}') 
        left center 
        no-repeat
      `,
    position: 'relative',
  },
}))

interface Props {
  children: ReactNode
}

export const RoseProfileLayout: FC<Props> = ({ children }) => {
  const { classes, cx } = useStyles()

  return (
    <main className={cx(classes.root)}>
      <ScrollToTop />
      <RoseHeader />
      <>{children}</>
      <Footer />
    </main>
  )
}
