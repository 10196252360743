import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { fontSpaceMono } from 'styles/fonts'
import { FC, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { RoseInventoryHeadingLine } from '@pages/Rose/RoseInventory/RoseInventoryHeadingLine'
import {
  InventoryFighterAvatarSticker,
  InventoryItemSticker,
} from 'components/InventoryItems'
import Button from '@mui/material/Button'
import ConfettiAnimation from 'components/ConfettiAnimation/ConfettiAnimation'
import { urlGenerator } from 'services/urlGenerator'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'
import {
  athleteStickersImages,
  athleteStickersRarityById,
  athleteStickersSmallNameById,
  RoseCollectionById,
  itemStickersImages,
  itemStickersNameById,
} from 'constants/stickers'

const useStyles = makeStyles({ name: 'OpenMysteryBoxSuccessStep' })(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 0, 3),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 2),
      },
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    subtitle1: {
      fontFamily: fontSpaceMono,
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      lineHeight: '40px',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      willChange: 'opacity',
    },
    title: {
      fontSize: 64,
      fontWeight: 800,
      lineHeight: '72px',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      willChange: 'opacity',
    },
    description: {
      maxWidth: 632,
      opacity: 0,
      transition: 'opacity 0.5s ease',
      color: theme.palette.text.secondary,
      margin: theme.spacing(2, 0, 8),
      willChange: 'opacity',
    },
    opacityVisible: {
      opacity: 1,
    },
    titleLine: {
      margin: theme.spacing(0, 0, 2),
      opacity: 0,
      transition: 'opacity 0.5s ease',
      willChange: 'opacity',
    },
    list: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
      marginBottom: theme.spacing(5),
      opacity: 0,
      transition: 'opacity 0.5s ease',
      willChange: 'opacity',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    bottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    btn: {
      maxWidth: 416,
      width: '100%',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      marginBottom: theme.spacing(3),
      willChange: 'opacity',
    },
  })
)

const sxClasses = {
  container: {
    pt: 3,
  },
}

interface Props {
  className?: string
  onClose: () => void
  athleteStickersIds: number[]
  itemStickersIds: number[]
  balancesAthleteStickersById: Record<number, number>
  balancesItemStickersById: Record<number, number>
}

export const OpenMysteryBoxSuccessStep: FC<Props> = ({
  className,
  onClose,
  athleteStickersIds,
  itemStickersIds,
  balancesAthleteStickersById,
  balancesItemStickersById,
}) => {
  const { classes, cx } = useStyles()
  const [isTransitioning, setTransitioning] = useState({
    subtitle: false,
    title: false,
    description: false,
    athletesTitle: false,
    athletesList: false,
    itemsTitle: false,
    itemsList: false,
    btn: false,
  })

  const [recycle, setRecycle] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setRecycle(false)
    }, 3500)
    ;(async () => {
      for (const key in isTransitioning) {
        setTransitioning((prevState) => ({ ...prevState, [key]: true }))
        await new Promise((resolve) => setTimeout(resolve, 300))
      }
    })()
  }, [])

  return (
    <>
      <ConfettiAnimation recycle={recycle} />
      <Container maxWidth="lg" sx={sxClasses.container}>
        <div className={cx(classes.root, className && className)}>
          <div className={classes.textContainer}>
            <Typography
              variant={'subtitle1'}
              className={cx(
                classes.subtitle1,
                isTransitioning.subtitle && classes.opacityVisible
              )}
            >
              Success!
            </Typography>
            <Typography
              variant={'h2'}
              className={cx(
                classes.title,
                isTransitioning.title && classes.opacityVisible
              )}
            >
              Here are your rewards !
            </Typography>
            <Typography
              variant={'body2'}
              className={cx(
                classes.description,
                isTransitioning.description && classes.opacityVisible
              )}
            >
              Congratulations on receiving these stickers. You can now find them
              all in your “Inventory”. Place them in correct slots in order to
              receive available prizes.
            </Typography>
          </div>
          {!!athleteStickersIds.length && (
            <>
              <RoseInventoryHeadingLine
                title={'Athlete stickers'}
                className={cx(
                  classes.titleLine,
                  isTransitioning.athletesTitle && classes.opacityVisible
                )}
              />
              <div
                className={cx(
                  classes.list,
                  isTransitioning.athletesList && classes.opacityVisible
                )}
              >
                <>
                  {athleteStickersIds.map((id, idx) => (
                    <InventoryFighterAvatarSticker
                      key={`${id}-${idx}`}
                      // @ts-ignore
                      imgUrl={athleteStickersImages[id as RoseCollectionById]}
                      // @ts-ignore
                      name={athleteStickersRarityById[id as RoseCollectionById]}
                      title={
                        // @ts-ignore
                        athleteStickersSmallNameById[id as RoseCollectionById]
                      }
                      own={
                        id in balancesAthleteStickersById
                          ? balancesAthleteStickersById[id]
                          : 0
                      }
                      openSeaUrl={urlGenerator.createOpenSeaLink(
                        ROSE_COLLECTION_CONTRACT_ADDRESS,
                        id
                      )}
                      isShortVariant
                    />
                  ))}
                </>
              </div>
            </>
          )}
          {!!itemStickersIds.length && (
            <>
              <RoseInventoryHeadingLine
                title={'Item stickers'}
                className={cx(
                  classes.titleLine,
                  isTransitioning.itemsTitle && classes.opacityVisible
                )}
              />
              <div
                className={cx(
                  classes.list,
                  isTransitioning.itemsList && classes.opacityVisible
                )}
              >
                <>
                  {itemStickersIds.map((id, idx) => (
                    <InventoryItemSticker
                      key={`${id}-${idx}`}
                      // @ts-ignore
                      imgUrl={itemStickersImages[id as RoseCollectionById]}
                      // @ts-ignore
                      name={itemStickersNameById[id as RoseCollectionById]}
                      own={
                        id in balancesItemStickersById
                          ? balancesItemStickersById[id]
                          : 0
                      }
                      openSeaUrl={urlGenerator.createOpenSeaLink(
                        ROSE_COLLECTION_CONTRACT_ADDRESS,
                        id
                      )}
                      isShortVariant
                    />
                  ))}
                </>
              </div>
            </>
          )}
          <div className={classes.bottom}>
            <Button
              variant={'contained'}
              className={cx(
                classes.btn,
                isTransitioning.btn && classes.opacityVisible
              )}
              size={'large'}
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </div>
      </Container>
    </>
  )
}
