import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { CartIcon } from 'assets/icons'
import { fontSpaceMono } from 'styles/fonts'
import { useAppDispatch } from 'store/hooks'
import { setModal } from 'store/reducers/app/actions'
import { ModalName } from 'constants/modals'

const useStyles = makeStyles({ name: 'BuyPacks' })((theme) => ({
  root: {
    maxWidth: 308,
    minHeight: 472,
    width: '100%',
    borderRadius: theme.spacing(2),
    backdropFilter: 'blur(12px)',
    padding: theme.spacing(0, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    position: 'relative',
    backgroundImage:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',

    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },

    '&::before': {
      content: "''",
      left: 0,
      bottom: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      background: 'rgba(255, 255, 255, 0.2)',
      zIndex: -1,
      transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      willChange: 'opacity',
      opacity: 0,
      borderRadius: 'inherit',
      border: '1px solid rgba(255, 255, 255, 0.4)',
    },

    '&:hover::before': {
      opacity: 1,
    },
  },
  image: {
    width: 200,
    minWidth: 200,
  },
  description: {
    color: theme.palette.text.secondary,
    width: '100%',
  },
  actions: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.spacing(1),
    alignItems: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  btn: {
    gap: theme.spacing(4.5),

    svg: {
      width: 24,
      minWidth: 24,
      height: 24,
    },
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  price: {
    fontFamily: fontSpaceMono,
    fontSize: 20,
    lineHeight: '32px',
  },
  priceTitle: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 400,
  },
}))

interface Props {
  name: string
  description: string
  imageUrl: string
  price: number | string
}

export const BuyPacksItem: FC<Props> = ({
  name,
  description,
  imageUrl,
  price,
}) => {
  const { classes } = useStyles()

  const dispatch = useAppDispatch()

  return (
    <div className={classes.root}>
      <img src={imageUrl} alt={name} />
      <Typography variant={'h3'}>{name}</Typography>
      <Typography
        variant={'body2'}
        className={classes.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className={classes.actions}>
        <Button
          variant={'contained'}
          color={'primary'}
          className={classes.btn}
          onClick={() => {
            dispatch(setModal(ModalName.BuyPacks, true))
          }}
          disabled
        >
          <span>Buy Now</span>
          <CartIcon />
        </Button>
        <div className={classes.priceContainer}>
          <Typography variant={'caption'} className={classes.priceTitle}>
            Starting from:
          </Typography>
          <Typography variant={'subtitle1'} className={classes.price}>
            ${price}
          </Typography>
        </div>
      </div>
    </div>
  )
}
