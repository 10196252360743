import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { fontSpaceMono } from 'styles/fonts'
import { FC, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ConfettiAnimation from 'components/ConfettiAnimation/ConfettiAnimation'
import { OpenSeaIcon } from 'assets/icons'

const useStyles = makeStyles({ name: 'RoseMerchDetailsClaimSuccessStep' })(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 0, 3),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 2),
      },
    },
    subtitle1: {
      width: '100%',
      fontFamily: fontSpaceMono,
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      lineHeight: '40px',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      textAlign: 'center',
      willChange: 'opacity',
    },
    title: {
      fontSize: 64,
      fontWeight: 800,
      lineHeight: '72px',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      width: '100%',
      textAlign: 'center',
      willChange: 'opacity',
    },
    opacityVisible: {
      opacity: 1,
    },
    bottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    btn: {
      maxWidth: 416,
      width: '100%',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      marginBottom: theme.spacing(2),
      willChange: 'opacity',
    },
    description: {
      maxWidth: 416,
      opacity: 0,
      transition: 'opacity 0.5s ease',
      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 0, 4),
      textAlign: 'center',
      willChange: 'opacity',
    },
    item: {
      maxWidth: 308,
      width: '100%',
      borderRadius: theme.spacing(2),
      background: 'rgba(38, 38, 38, 0.9)',
      backdropFilter: 'blur(12px)',
      overflow: 'hidden',
      margin: theme.spacing(3, 0),
      opacity: 0,
      transition: 'opacity 0.5s ease',
      willChange: 'opacity',
    },
    image: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',

      '& img': {
        width: '100%',
        height: 'auto',
      },
    },
    itemBottom: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    itemBottomSubtitle: {
      fontSize: 10,
      lineHeight: '16px',
      fontWeight: 400,
    },
    itemBottomTitle: {
      lineHeight: '32px',
      fontWeight: 700,
    },
  })
)

const sxClasses = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    pt: 3,
  },
}

interface Props {
  className?: string
  onClose: () => void
  id: number
  imageUrl: string
}

export const RoseMerchDetailsClaimSuccessStep: FC<Props> = ({
  className,
  onClose,
  id,
  imageUrl,
}) => {
  const { classes, cx } = useStyles()
  const [isTransitioning, setTransitioning] = useState({
    subtitle: false,
    title: false,
    item: false,
    btn: false,
    description: false,
  })

  const [recycle, setRecycle] = useState(true)

  useEffect(() => {
    ;(async () => {
      for (const key in isTransitioning) {
        setTransitioning((prevState) => ({ ...prevState, [key]: true }))
        await new Promise((resolve) => setTimeout(resolve, 300))
      }
    })()

    setTimeout(() => {
      setRecycle(false)
    }, 3500)
  }, [])

  return (
    <>
      <ConfettiAnimation recycle={recycle} />
      <Container maxWidth="lg" sx={sxClasses.container}>
        <div className={cx(classes.root, className && className)}>
          <Typography
            variant={'subtitle1'}
            className={cx(
              classes.subtitle1,
              isTransitioning.subtitle && classes.opacityVisible
            )}
          >
            Success!
          </Typography>
          <Typography
            variant={'h2'}
            className={cx(
              classes.title,
              isTransitioning.title && classes.opacityVisible
            )}
          >
            Here is your Proof of Ownership!
          </Typography>
          <div
            className={cx(
              classes.item,
              isTransitioning.item && classes.opacityVisible
            )}
          >
            <div className={classes.image}>
              <img src={imageUrl} alt="item" />
            </div>
            <div className={classes.itemBottom}>
              <Typography variant={'h5'} className={classes.itemBottomTitle}>
                Certificate
              </Typography>
              <Typography
                variant={'caption'}
                className={classes.itemBottomSubtitle}
              >
                Item ID: #{id}
              </Typography>
            </div>
          </div>
          <div className={classes.bottom}>
            <Button
              variant={'contained'}
              className={cx(
                classes.btn,
                isTransitioning.btn && classes.opacityVisible
              )}
              onClick={onClose}
            >
              Close
            </Button>
          </div>
          <Typography
            variant={'body2'}
            className={cx(
              classes.description,
              isTransitioning.description && classes.opacityVisible
            )}
          >
            Congratulations on receiving your Certificate. You can now find this
            NFT in your “Inventory”.
          </Typography>
        </div>
      </Container>
    </>
  )
}
