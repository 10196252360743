import { makeStyles } from 'styles/makeStyles'
import { FC, ReactNode } from 'react'
import { RoseHeader } from '../components/RoseHeader'

const useStyles = makeStyles({ name: 'MainLandingLayoutTop' })(() => ({
  main: {
    position: 'relative',
    overflow: 'hidden',
  },
  content: {
    minHeight: '100vh',
    position: 'relative',
    zIndex: 5,
  },
}))

interface Props {
  children: ReactNode
}

export const MainLandingLayoutTop: FC<Props> = ({ children }) => {
  const { classes } = useStyles()

  return (
    <main className={classes.main}>
      <RoseHeader />
      <div className={classes.content}>{children}</div>
    </main>
  )
}
