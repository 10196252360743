import { ethersProvider } from '../../providers'
import { IERC1155Tradable__factory } from 'models/contracts/ethers/factories/IERC1155Tradable__factory'
import { ContractTransaction } from '@ethersproject/contracts'
import { RoseCollection__factory } from 'models/contracts/ethers/factories/RoseCollection__factory'
import { BigNumber } from 'ethers'

export const contractErc1155Api = {
  getProvider: () => ethersProvider.getProvider(),

  async getErc1155BalanceBatch(
    ids: string[] | number[],
    nftContractAddress: string,
    userAddress: string
  ): Promise<number[]> {
    const nftInstance = IERC1155Tradable__factory.connect(
      nftContractAddress,
      this.getProvider()!
    )

    const result = await nftInstance.balanceOfBatch(
      new Array(ids.length).fill(userAddress),
      ids
    )

    return result.map((balance) => balance.toNumber())
  },

  async isApprovedErc1155(
    userAddress: string,
    nftContractAddress: string,
    toAddress: string
  ): Promise<boolean> {
    const nftInstance = IERC1155Tradable__factory.connect(
      nftContractAddress,
      this.getProvider()!
    )

    return await nftInstance.isApprovedForAll(userAddress, toAddress)
  },

  async approveErc1155(
    nftContractAddress: string,
    toAddress: string
  ): Promise<ContractTransaction> {
    const nftInstance = IERC1155Tradable__factory.connect(
      nftContractAddress,
      this.getProvider()!.getSigner()
    )

    return nftInstance.setApprovalForAll(toAddress, true)
  },

  async getTokenURI(
    tokenId: number,
    nftContractAddress: string
  ): Promise<string> {
    const nftInstance = RoseCollection__factory.connect(
      nftContractAddress,
      this.getProvider()!
    )

    return nftInstance.uri(tokenId)
  },

  // TODO: make batch request
  async getTokenMaxSupply(
    nftContractAddress: string,
    tokenId: string
  ): Promise<BigNumber> {
    const nftInstance = RoseCollection__factory.connect(
      nftContractAddress,
      this.getProvider()!
    )

    return await nftInstance.maxSupply(tokenId)
  },
}
