import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import { Skeleton } from '@mui/material'
import Button from '@mui/material/Button'
import { ArrowRightLineIcon } from 'assets/icons'
import { AppAlertCircle } from 'components/common'
import { SwitchableNetworkButton } from 'components/ConnectButton'
import { validChainId } from 'constants/validChainId'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { urlGenerator } from 'services/urlGenerator'

const useStyles = makeStyles({ name: 'RoseMerchDetailsHeadingActions' })(
  (theme) => ({
    contentBtn: {
      maxWidth: 358,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(1),

      svg: {
        minWidth: 24,
        width: 24,
        height: 24,
      },
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      width: '100%',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },

      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
  })
)

interface Props {
  isCompleted: boolean
  setIsOpenClaimModal: (isOpen: boolean) => void
  isApproved: boolean
  onApprove: () => void
  isValidChainId: boolean
  isLoading: boolean
  isExists: boolean
}
export const RoseMerchDetailsHeadingActions: FC<Props> = ({
  isCompleted,
  setIsOpenClaimModal,
  isApproved,
  onApprove,
  isValidChainId,
  isLoading,
  isExists,
}) => {
  const { classes } = useStyles()

  const location = useLocation()

  if (isLoading) {
    return (
      <Skeleton
        sx={{
          transform: 'inherit',
          width: '100%',
          height: 64,
          borderRadius: 4,
          my: 1,
        }}
      />
    )
  }

  return (
    <>
      {isValidChainId ? (
        <>
          {isCompleted && isExists && (
            <AppAlertCircle
              severity="success"
              title={'You have completed the collection! Claim it now!'}
              sx={{ mb: 3 }}
            />
          )}
          {isCompleted && !isExists && (
            <AppAlertCircle
              severity="success"
              title={
                "Great job on finishing this challenge. There's just one more step - provide your shipping information. After this, you can claim your prize. You'll get a Certificate NFT as proof of ownership, and we'll send the item right to your doorstep."
              }
              sx={{ mb: 3 }}
            />
          )}
          {!isCompleted && (
            <Button
              className={classes.contentBtn}
              variant="contained"
              size={'large'}
              disabled
            >
              <span>Collect all to claim</span>
              <ArrowRightLineIcon />
            </Button>
          )}
          {isCompleted && !isApproved && (
            <Button
              className={classes.contentBtn}
              variant="contained"
              size={'large'}
              disabled={!isCompleted}
              onClick={onApprove}
            >
              <span>Approve</span>
              <ArrowRightLineIcon />
            </Button>
          )}
          {isCompleted && isApproved && isExists && (
            <Button
              className={classes.contentBtn}
              variant="contained"
              size={'large'}
              onClick={() => setIsOpenClaimModal(true)}
            >
              <span>Claim prize</span>
              <ArrowRightLineIcon />
            </Button>
          )}
          {isCompleted && isApproved && !isExists && (
            <div className={classes.box}>
              <Link to={urlGenerator.createProfileEditLink(location.pathname)}>
                <Button
                  className={classes.contentBtn}
                  variant="contained"
                  size={'large'}
                >
                  <span>Fill details</span>
                  <ArrowRightLineIcon />
                </Button>
              </Link>
              <Button
                className={classes.contentBtn}
                variant="contained"
                size={'large'}
                disabled
              >
                <span>Claim</span>
                <ArrowRightLineIcon />
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          <SwitchableNetworkButton
            validChain={validChainId}
            className={classes.contentBtn}
            size={'large'}
          />
        </>
      )}
    </>
  )
}
