import { useEffect, lazy } from 'react'
import { useAppDispatch } from 'store/hooks'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'styles/theme'
import { tryAuth } from 'store/reducers/user/actions'
import { router } from 'routes/router'
import { RouterProvider } from 'react-router-dom'
import ReactGA from 'react-ga'
import { TRACKING_ID } from 'constants/ga'
import ReactPixel from 'react-facebook-pixel'
import { PIXEL_ID } from 'constants/fbPixel'
import { Notifier } from 'components/Notifier'
import { useCheckValidChainId } from 'hooks/useCheckValidChainId'
import { useQuery_GetIsAccepted_TermsAndConditions } from 'services/useApi/termsAndConditions/useQuery'
import { useQuery_GetIsUserInfoExists_UserInfo } from 'services/useApi/userInfo/useQuery'
import { useRefreshPageByEth } from 'hooks/useRefreshPageByEth'

const AuthModal = lazy(() => import('components/Modals/AuthModal'))
const BoxDropRatesModal = lazy(
  () => import('components/Modals/BoxDropRatesModal')
)
const BoxSaleOverModal = lazy(
  () => import('components/Modals/BoxSaleOverModal')
)

ReactGA.initialize(TRACKING_ID)

const App = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(tryAuth())
    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactPixel.init(PIXEL_ID)
  }, [])

  useCheckValidChainId()
  useQuery_GetIsAccepted_TermsAndConditions()
  useQuery_GetIsUserInfoExists_UserInfo()
  useRefreshPageByEth()

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <RouterProvider router={router} />
      <Notifier />
      <AuthModal />
      <BoxDropRatesModal />
      <BoxSaleOverModal />
    </ThemeProvider>
  )
}

export default App
