import BigNumber from 'bignumber.js'
import { BigNumber as EthersBigNumber } from 'ethers'

export const num10e18: BigNumber = new BigNumber(10).pow(18)
export const MAX_BIGNUMBER: BigNumber = new BigNumber(
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  16
)
export const AVERAGE_BIGNUMBER: BigNumber = new BigNumber(
  '0x8888888888888888888888888888888888888888888888888888888888888888',
  16
)

export function toBN(bn: EthersBigNumber): BigNumber {
  return new BigNumber(bn.toHexString(), 16)
}

export function toLowBN(bn: EthersBigNumber, decimals = 18): BigNumber {
  return toBN(bn).dividedBy(new BigNumber(10).pow(decimals))
}

export function numberToTransactionalNumber(
  amount: number,
  decimals = 18
): string {
  return new BigNumber(amount, 10)
    .multipliedBy(new BigNumber(10).pow(decimals))
    .toFixed(0)
}

export function transactionalNumberToNumber(
  amount: number | string,
  decimals = 18
): number {
  return new BigNumber(amount, 10)
    .dividedBy(new BigNumber(10).pow(decimals))
    .toNumber()
}

export function realNumberToTransactionalNumber(
  amount: string,
  decimals = 18
): string {
  return new BigNumber(amount, 10).multipliedBy(`1e${decimals}`).toFixed()
}

export function cropDecimals(amount: number): number {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return Number(toFixed(amount, 10))
}

function toFixed(num: number, fixed: number) {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`)
  return num.toString().match(re)![0]
}
