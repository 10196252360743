import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import { useIsMounted } from 'hooks/useIsMounted'
import { linearGradients } from 'styles/other'
import { publicImages } from 'utils/getPublicImages'

const useStyles = makeStyles({ name: 'MainLandingGridHeroes' })((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    opacity: 0,
    transition: 'opacity 1.5s ease-in-out',
    willChange: 'opacity',
  },
  row: {
    display: 'flex',
    position: 'relative',

    '&:not(:last-of-type)::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },
  },
  cell: {
    padding: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.25),
    },
  },
  item: {
    display: 'block',
    minWidth: 184,
    width: 184,
    height: 184,
    borderRadius: theme.spacing(2),
    overflow: 'hidden',

    img: {
      width: '100%',
      height: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 58,
      width: 58,
      height: 58,
    },
  },
  itemEmptyOpacity: {
    display: 'block',
    width: '100%',
    height: '100%',
    opacity: 0.1,
    backgroundColor: theme.palette.text.primary,
  },
  opacityVisible: {
    opacity: 1,
  },
  animateFirstStep: {
    animation: 'opacityAnimation 3s infinite ease-in',
    animationDelay: '2s',
    willChange: 'opacity',

    '@keyframes opacityAnimation': {
      '0%': {
        opacity: 1,
      },
      '20%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  },
  animateSecondStep: {
    animation: 'opacityAnimation 2s infinite ease-in',
    animationDelay: '4s',
    willChange: 'opacity',

    '@keyframes opacityAnimation': {
      '0%': {
        opacity: 0,
      },
      '20%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
  },
  dividerVertical: {
    position: 'absolute',
    top: 0,
    left: '20%',
    transform: 'translateX(-20%)',
    width: 1,
    height: '100%',
    background: linearGradients.backgroundLinesVertical,
  },
  dividerVerticalSecond: {
    left: '40%',
    transform: 'translateX(-40%)',
  },
  dividerVerticalThird: {
    left: '60%',
    transform: 'translateX(-60%)',
  },
  dividerVerticalForth: {
    left: '80%',
    transform: 'translateX(-80%)',
  },
}))

interface Props {
  className?: string
}

export const MainLandingGridHeroes: FC<Props> = ({ className }) => {
  const { classes, cx } = useStyles()

  const [isMounted] = useIsMounted()

  const imgHero = (
    <img
      src={`${process.env.PUBLIC_URL}/images/illustrations/main-landing/hero.webp`}
      alt={'petals'}
    />
  )

  return (
    <div
      className={cx(
        classes.root,
        isMounted && classes.opacityVisible,
        className
      )}
    >
      <div className={classes.dividerVertical} />
      <div
        className={cx(classes.dividerVertical, classes.dividerVerticalSecond)}
      />
      <div
        className={cx(classes.dividerVertical, classes.dividerVerticalThird)}
      />
      <div
        className={cx(classes.dividerVertical, classes.dividerVerticalForth)}
      />
      <div className={classes.row}>
        <div className={classes.cell}>
          <span className={cx(classes.item, classes.animateFirstStep)}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/beginner-nft-1.webp'
              )}`}
              alt={'petals'}
            />
          </span>
        </div>
        <div className={classes.cell}>
          <span className={classes.item}>
            <span className={classes.itemEmptyOpacity} />
          </span>
        </div>
        <div className={classes.cell}>
          <span className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/elite-nft-2.webp'
              )}`}
              alt={'petals'}
            />
          </span>
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={cx(classes.item, classes.animateFirstStep)}>
            <span className={classes.itemEmptyOpacity} />
          </span>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={cx(classes.item, classes.animateFirstStep)}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/advanced-nft-3.webp'
              )}`}
              alt={'petals'}
            />
          </span>
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.cell}>
          <span className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/legendary-nft-6.webp'
              )}`}
              alt={'petals'}
            />
          </span>
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={cx(classes.item, classes.animateSecondStep)}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/legendary-nft-3.webp'
              )}`}
              alt={'petals'}
            />
          </span>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={classes.item}>
            <span className={classes.itemEmptyOpacity} />
          </span>
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.cell}>
          <span className={cx(classes.item, classes.animateSecondStep)}>
            <span className={classes.itemEmptyOpacity} />
          </span>
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={cx(classes.item, classes.animateSecondStep)}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/elite-nft-6.webp'
              )}`}
              alt={'petals'}
            />
          </span>
        </div>
        <div className={classes.cell}>
          <span className={classes.item} />
        </div>
        <div className={classes.cell}>
          <span className={cx(classes.item, classes.animateFirstStep)}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/advanced-nft-6.webp'
              )}`}
              alt={'petals'}
            />
          </span>
        </div>
      </div>
    </div>
  )
}
