import { publicImages } from 'utils/getPublicImages'
import { ROUTER_PATHS } from 'routes/routerPaths'

export enum RoseCollectionTabs {
  FighterNft = 'Fighter NFTs',
  Merch = 'Merch',
}

export const TABS_ROSE_COLLECTION_INFO = [
  {
    title: 'Fighter NFTs',
    description:
      'Your inner ultimate fighter is waiting to be released! Collect digital Avatar stickers and merge them to build your avatar!',
    imageUrl: publicImages.getIllustration(
      'rose/fighter-nfts-collection-tab.png'
    ),
    link: ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS,
  },
  {
    title: 'Merch',
    description:
      'Show off your Thug Rose spirit with limited-edition merchandise! Collect digital Item stickers and redeem them for real-life prizes.prizes.',
    imageUrl: publicImages.getIllustration('rose/merch-collection-tab.webp'),
    link: ROUTER_PATHS.ROSE_NFT_COLLECTION_MERCH,
  },
]
