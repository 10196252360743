import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { ROUTER_PATHS } from 'routes/routerPaths'
import { LogoDigi } from 'assets/logo'
import { Link, NavLink } from 'react-router-dom'
import { SocialSection } from 'components/SocialSection'
import { ConnectButton } from 'components/ConnectButton'

const useStyles = makeStyles({ name: 'Footer' })((theme) => ({
  root: {
    borderTop: `1px solid rgba(255, 255, 255, .4)`,
    padding: theme.spacing(5, 0),
    backgroundColor: `rgba(0, 0, 0, 0.1)`,
    backdropFilter: 'blur(6px)',
    position: 'relative',
    zIndex: 2,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  top: {
    display: 'flex',
    gap: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  logoContainer: {
    width: 266,
    height: 64,
    display: 'block',

    [theme.breakpoints.down('md')]: {
      width: 134,
      height: 32,
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  social: {
    margin: theme.spacing(6, 0, 2),

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0, 0),
    },
  },
  text: {
    color: theme.palette.text.secondary,
    textAlign: 'center',

    span: {
      textDecoration: 'underline',
      padding: theme.spacing(0, 0.25),
    },
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  link: {
    color: theme.palette.text.secondary,

    '&:hover': {},
  },
  btn: {
    gap: theme.spacing(5),

    svg: {
      width: 24,
      height: 24,
      minWidth: 24,
    },

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0, 5),
    },
  },
  displaySmNone: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  displaySmBlock: {
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}))

export const Footer = () => {
  const { classes, cx } = useStyles()

  const currentYear = new Date().getFullYear()

  return (
    <div className={classes.root}>
      <Container maxWidth={'lg'}>
        <div className={classes.content}>
          <div className={classes.top}>
            <NavLink to={ROUTER_PATHS.MAIN} className={classes.logoContainer}>
              <LogoDigi className={classes.logo} />
            </NavLink>
            <ConnectButton withIcon />
          </div>
          <SocialSection className={classes.social} />
          <Typography variant={'body2'} className={cx(classes.text)}>
            © {currentYear} Lympo. All rights reserved.{' '}
            <Link to={ROUTER_PATHS.ROSE_PRIVACY_POLICY}>
              <span>Privacy Policy</span>
            </Link>
            <Link to={ROUTER_PATHS.ROSE_TERMS_AND_CONDITIONS}>
              <span>Terms and conditions</span>
            </Link>
            <Link to={ROUTER_PATHS.ROSE_LICENCE_AGREEMENT}>
              <span>NFT licence</span>
            </Link>
          </Typography>
        </div>
      </Container>
    </div>
  )
}
