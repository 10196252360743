import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { ConfettiIcon, OpenSeaIcon } from 'assets/icons'
import { fontSpaceMono } from 'styles/fonts'
import { openSeaUrls } from 'constants/staticUrls'
import { urlGenerator } from 'services/urlGenerator'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'

const useStyles = makeStyles({ name: 'BuyPacksItemHorizontal' })((theme) => ({
  root: {
    maxWidth: 308,
    width: '100%',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2, 2, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    position: 'relative',
    backgroundImage:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',

    [theme.breakpoints.down('md')]: {
      minWidth: 192,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    width: 104,
    minWidth: 104,

    img: {
      width: '100%',
      height: 'auto',
    },

    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  title: {
    display: 'flex',
    gap: theme.spacing(1),
    fontWeight: 700,
  },
  text: {
    fontWeight: 400,
    fontFamily: fontSpaceMono,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.spacing(1),
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  btnContained: {
    gap: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 130,
    width: '100%',
    padding: theme.spacing(1, 2),

    svg: {
      width: 24,
      minWidth: 24,
      height: 24,
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  btnOutlined: {
    gap: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 130,
    width: '100%',
    padding: theme.spacing(1, 2),

    svg: {
      width: 24,
      minWidth: 24,
      height: 24,
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}))

interface Props {
  className?: string
  id: string | number
  name: string
  imageUrl: string
  own: number
  onOpenClick?: () => void
}

export const BuyPacksItemHorizontal: FC<Props> = ({
  id,
  className,
  name,
  imageUrl,
  own,
  onOpenClick,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <img src={imageUrl} alt={name} />
        </div>
        <div className={classes.content}>
          <Typography variant={'h5'} className={classes.title}>
            {name}
          </Typography>
          <div>
            {/*<Typography variant={'subtitle2'} className={classes.text}>*/}
            {/*  Total: {total}*/}
            {/*</Typography>*/}
            <Typography variant={'subtitle2'} className={classes.text}>
              You own: {own}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.actions}>
        <Button
          variant={'contained'}
          color={'primary'}
          className={classes.btnContained}
          onClick={onOpenClick}
          disabled={!own}
        >
          <span>Open</span>
          <ConfettiIcon />
        </Button>
        <Button
          variant={'outlined'}
          color={'secondary'}
          className={classes.btnOutlined}
          target={'_blank'}
          href={urlGenerator.createOpenSeaLink(
            ROSE_COLLECTION_CONTRACT_ADDRESS,
            id
          )}
        >
          <span>Buy</span>
          <OpenSeaIcon />
        </Button>
      </div>
    </div>
  )
}
