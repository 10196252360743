export enum NftGroupRarity {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced',
  Elite = 'Elite',
  Legendary = 'Legendary',
}

export interface NftAvatar {
  id: string | number
  balance: number
  image: string
  name: string
}
