export const ROUTER_PATHS = {
  MAIN: '/',
  ROSE: '/rose',
  ROSE_INVENTORY: '/rose/inventory',
  ROSE_INVENTORY_NFT_STICKERS: '/rose/inventory/nft-stickers',
  ROSE_INVENTORY_FIGHTER_AVATARS: '/rose/inventory/fighter-avatars',
  ROSE_INVENTORY_NFT_FUSION: '/rose/inventory/nft-fusion',
  ROSE_INVENTORY_ITEMS: '/rose/inventory/items',
  ROSE_INVENTORY_CERTIFICATE_NFTS: '/rose/inventory/certificate-nfts',
  ROSE_NFT_COLLECTION_FIGHTERS: '/rose/nft-collection/fighters',
  ROSE_NFT_COLLECTION_FIGHTERS_ID: '/rose/nft-collection/fighters/:id',
  ROSE_NFT_COLLECTION_FUSION: '/rose/nft-collection/fusion',
  ROSE_NFT_COLLECTION_FUSION_ID: '/rose/nft-collection/fusion/:id',
  ROSE_NFT_COLLECTION_MERCH: '/rose/nft-collection/merch',
  ROSE_NFT_COLLECTION_MERCH_ID: '/rose/nft-collection/merch/:id',
  ROSE_LICENCE_AGREEMENT: '/rose/licence-agreement',
  ROSE_TERMS_AND_CONDITIONS: '/rose/terms-and-conditions',
  ROSE_PRIVACY_POLICY: '/rose/privacy-policy',
  ROSE_PROFILE: '/rose/profile',
  ROSE_PROFILE_EDIT: '/rose/profile/edit',
}
