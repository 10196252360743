import { RoseCollectionById } from 'constants/stickers'
import { publicImages } from 'utils/getPublicImages'

const beginnerPrizeImage = publicImages.getIllustration(
  'rose/prize/beginner-prize.webp'
)

const intermediatePrizeImage = publicImages.getIllustration(
  'rose/prize/intermediate-prize.webp'
)

const advancedPrizeImage = publicImages.getIllustration(
  'rose/prize/advanced-prize.webp'
)

const elitePrizeImage = publicImages.getIllustration(
  'rose/prize/elite-prize.webp'
)

export const ROSE_ITEMS_CONTENT_MODAL = {
  [RoseCollectionById['Beginner Head']]: {
    title: 'Beginner',
    name: 'Head',
    prizeImageUrl: beginnerPrizeImage,
  },
  [RoseCollectionById['Beginner Torso']]: {
    title: 'Beginner',
    name: 'Torso',
    prizeImageUrl: beginnerPrizeImage,
  },
  [RoseCollectionById['Intermediate Head']]: {
    title: 'Intermediate',
    name: 'Head',
    prizeImageUrl: intermediatePrizeImage,
  },
  [RoseCollectionById['Intermediate Torso']]: {
    title: 'Intermediate',
    name: 'Torso',
    prizeImageUrl: intermediatePrizeImage,
  },
  [RoseCollectionById['Intermediate Hands']]: {
    title: 'Intermediate',
    name: 'Hands',
    prizeImageUrl: intermediatePrizeImage,
  },
  [RoseCollectionById['Advanced Head']]: {
    title: 'Advanced',
    name: 'Head',
    prizeImageUrl: advancedPrizeImage,
  },
  [RoseCollectionById['Advanced Torso']]: {
    title: 'Advanced',
    name: 'Torso',
    prizeImageUrl: advancedPrizeImage,
  },
  [RoseCollectionById['Advanced Hands']]: {
    title: 'Advanced',
    name: 'Hands',
    prizeImageUrl: advancedPrizeImage,
  },
  [RoseCollectionById['Advanced Legs']]: {
    title: 'Advanced',
    name: 'Legs',
    prizeImageUrl: advancedPrizeImage,
  },
  [RoseCollectionById['Elite Head']]: {
    title: 'Elite',
    name: 'Head',
    prizeImageUrl: elitePrizeImage,
  },
  [RoseCollectionById['Elite Torso']]: {
    title: 'Elite',
    name: 'Torso',
    prizeImageUrl: elitePrizeImage,
  },
  [RoseCollectionById['Elite Hands']]: {
    title: 'Elite',
    name: 'Hands',
    prizeImageUrl: elitePrizeImage,
  },
  [RoseCollectionById['Elite Legs']]: {
    title: 'Elite',
    name: 'Legs',
    prizeImageUrl: elitePrizeImage,
  },
  [RoseCollectionById['Elite Belt']]: {
    title: 'Elite',
    name: 'Belt',
    prizeImageUrl: elitePrizeImage,
  },
}
