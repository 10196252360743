import { AppDispatch } from 'store/store'
import { ISnackbarState, ISnackbarTimersInfo } from './types'
import { snackbarSlice } from './slice'

export const enqueueSnack =
  (data: Omit<ISnackbarState, 'timersInfo'>) =>
  async (dispatch: AppDispatch) => {
    dispatch(snackbarSlice.actions.enqueueSnack({ ...data }))
  }

export const setStartTimerSnackbarKey =
  (startTimerSnackbarId: string) => async (dispatch: AppDispatch) => {
    dispatch(
      snackbarSlice.actions.setStartTimerSnackbarKey(startTimerSnackbarId)
    )
  }

export const setCloseTimerSnackbarKey =
  (closeTimerSnackbarId: string) => async (dispatch: AppDispatch) => {
    dispatch(
      snackbarSlice.actions.setCloseTimerSnackbarKey(closeTimerSnackbarId)
    )
  }

export const addTimersInfo =
  ({ snackbarKey, snackbarId, startDate }: ISnackbarTimersInfo) =>
  async (dispatch: AppDispatch) => {
    dispatch(
      snackbarSlice.actions.addTimersInfo({
        snackbarKey,
        snackbarId,
        startDate,
      })
    )
  }

export const deleteTimersInfo =
  (snackbarKey: string | number) => async (dispatch: AppDispatch) => {
    dispatch(snackbarSlice.actions.deleteTimersInfo(snackbarKey))
  }
