import { RoseWhatInsidePacks } from '../components/RoseWhatInsidePacks'
import { RoseCollectionInfo } from './RoseCollectionInfo'
import {
  RoseCollectionHeading,
  RoseCollectionLayout,
  RoseCollectionTabs,
} from '../components/RoseCollection'

export const RoseCollectionFighter = () => {
  return (
    <RoseCollectionLayout currentTab={RoseCollectionTabs.FighterNft}>
      <RoseCollectionHeading />
      <RoseCollectionInfo activeTab={RoseCollectionTabs.FighterNft} />
      <RoseWhatInsidePacks />
    </RoseCollectionLayout>
  )
}
