import { makeStyles } from 'styles/makeStyles'
import { FC, ReactNode } from 'react'
import { RoseHeader } from '@pages/Rose/components/RoseHeader'
import { publicImages } from 'utils/getPublicImages'
import { ScrollToTop } from 'components/common/ScrollToTop'
import { Footer } from 'components/Footer'

const useStyles = makeStyles({ name: 'TermsLayout' })((theme) => ({
  main: {
    background: `
        url('${publicImages.getBackground('rose/terms-head-bg.webp')}') 
        top left 
        no-repeat
      `,
  },
  content: {
    position: 'relative',
    zIndex: 10,
    minHeight: '100vh',
    paddingBottom: theme.spacing(10),

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(5),
    },
  },
}))

interface Props {
  children: ReactNode
}

export const TermsLayout: FC<Props> = ({ children }) => {
  const { classes } = useStyles()

  return (
    <main className={classes.main}>
      <RoseHeader />
      <div className={classes.content}>{children}</div>
      <Footer />
      <ScrollToTop />
    </main>
  )
}
