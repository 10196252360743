import { LicenceAgreementContent } from './LicenceAgreementContent'
import { TermsLayout } from 'components/Layouts/TermsLayout/TermsLayout'

export const LicenceAgreement = () => {
  return (
    <TermsLayout>
      <LicenceAgreementContent />
    </TermsLayout>
  )
}
