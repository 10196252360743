import { makeStyles } from 'styles/makeStyles'
import { Typography } from '@mui/material'
import {
  MaskHappyIcon,
  MedalIcon,
  MergeIcon,
  StackIcon,
  TShirtIcon,
} from 'assets/icons'
import { FC, useEffect, useRef } from 'react'
import { MenuInventory } from '@pages/Rose/RoseInventory/constants'
import { useNavigate } from 'react-router-dom'
import { ROUTER_PATHS } from 'routes/routerPaths'
import { useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { scrollToTop } from 'utils/scrollToTop'

const useStyles = makeStyles({ name: 'RoseInventoryMenu' })((theme) => ({
  root: {
    minWidth: '26%',
    width: '26%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    position: 'sticky',
    top: theme.spacing(10),
    left: 0,
    alignSelf: 'flex-start',
    float: 'left',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '100%',
      zIndex: 5,
      gap: theme.spacing(1),
      top: 0,
      backgroundColor: `rgba(0, 0, 0, 0.1)`,
      backdropFilter: 'blur(6px)',
      borderRadius: theme.spacing(1),
    },
  },
  title: {
    fontSize: 64,
    lineHeight: '80px',

    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      lineHeight: '40px',
    },
  },
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      overflowX: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% + 32px)',
      left: theme.spacing(-2),
      position: 'relative',
    },
  },
  link: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
    transition: 'background 0.3s ease',
    willChange: 'background',

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
      cursor: 'pointer',
    },
  },
  active: {
    background: 'rgba(255, 255, 255, 0.1)',
  },
}))

const argsScrollIntoView: ScrollIntoViewOptions = {
  block: 'nearest',
  inline: 'start',
}

interface Props {
  activeMenuItem: MenuInventory
}

export const RoseInventoryMenu: FC<Props> = ({ activeMenuItem }) => {
  const { classes, cx } = useStyles()

  const navigate = useNavigate()

  const itemsLinkRef = useRef<HTMLDivElement>(null)
  const stickersLinkRef = useRef<HTMLDivElement>(null)
  const fighterAvatarsLinkRef = useRef<HTMLDivElement>(null)
  const fusionLinkRef = useRef<HTMLDivElement>(null)
  const certificateLinkRef = useRef<HTMLDivElement>(null)

  const isSmDownMediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  )
  const isMdDownMediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  useEffect(() => {
    if (isSmDownMediaQuery) {
      if (
        activeMenuItem === MenuInventory.NFTStickers &&
        stickersLinkRef.current
      ) {
        stickersLinkRef.current.scrollIntoView(argsScrollIntoView)
      }
      if (
        activeMenuItem === MenuInventory.FighterAvatars &&
        fighterAvatarsLinkRef.current
      ) {
        fighterAvatarsLinkRef.current.scrollIntoView(argsScrollIntoView)
      }
      if (activeMenuItem === MenuInventory.NFTFusion && fusionLinkRef.current) {
        fusionLinkRef.current.scrollIntoView(argsScrollIntoView)
      }
      if (activeMenuItem === MenuInventory.Items && itemsLinkRef.current) {
        itemsLinkRef.current.scrollIntoView(argsScrollIntoView)
      }
      if (
        activeMenuItem === MenuInventory.CertificateNFTs &&
        certificateLinkRef.current
      ) {
        certificateLinkRef.current.scrollIntoView(argsScrollIntoView)
      }
    }
  }, [isSmDownMediaQuery, activeMenuItem])

  const onLinkClick = (path: string) => {
    if (isMdDownMediaQuery) {
      scrollToTop(460)
    } else {
      scrollToTop(300)
    }

    navigate(path)
  }

  return (
    <div className={cx(classes.root)}>
      <Typography variant="h3" className={classes.title}>
        Your inventory
      </Typography>
      <div className={classes.container}>
        <Typography
          variant="caption"
          className={cx(
            classes.link,
            activeMenuItem === MenuInventory.NFTStickers && classes.active
          )}
          minWidth={152}
          ref={stickersLinkRef}
          onClick={() => {
            onLinkClick(ROUTER_PATHS.ROSE_INVENTORY_NFT_STICKERS)
          }}
        >
          <StackIcon />
          <span>{MenuInventory.NFTStickers}</span>
        </Typography>
        <Typography
          variant="caption"
          className={cx(
            classes.link,
            activeMenuItem === MenuInventory.FighterAvatars && classes.active
          )}
          minWidth={178}
          ref={fighterAvatarsLinkRef}
          onClick={() => {
            onLinkClick(ROUTER_PATHS.ROSE_INVENTORY_FIGHTER_AVATARS)
          }}
        >
          <MaskHappyIcon />
          <span>{MenuInventory.FighterAvatars}</span>
        </Typography>
        <Typography
          variant="caption"
          className={cx(
            classes.link,
            activeMenuItem === MenuInventory.NFTFusion && classes.active
          )}
          minWidth={136}
          ref={fusionLinkRef}
          onClick={() => {
            onLinkClick(ROUTER_PATHS.ROSE_INVENTORY_NFT_FUSION)
          }}
        >
          <MergeIcon />
          <span>{MenuInventory.NFTFusion}</span>
        </Typography>
        <Typography
          variant="caption"
          className={cx(
            classes.link,
            activeMenuItem === MenuInventory.Items && classes.active
          )}
          ref={itemsLinkRef}
          onClick={() => {
            onLinkClick(ROUTER_PATHS.ROSE_INVENTORY_ITEMS)
          }}
        >
          <TShirtIcon />
          <span>{MenuInventory.Items}</span>
        </Typography>
        <Typography
          variant="caption"
          className={cx(
            classes.link,
            activeMenuItem === MenuInventory.CertificateNFTs && classes.active
          )}
          minWidth={180}
          ref={certificateLinkRef}
          onClick={() => {
            onLinkClick(ROUTER_PATHS.ROSE_INVENTORY_CERTIFICATE_NFTS)
          }}
        >
          <MedalIcon />
          <span>{MenuInventory.CertificateNFTs}</span>
        </Typography>
      </div>
    </div>
  )
}
