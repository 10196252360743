import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { FadeInComponent } from 'components/Animation/FadeInComponent'
import { MainLandingGridHeroes } from './MainLandingGridHeroes'
import { LINKS_ANCHOR } from 'constants/linksAnchor'
import { localStorageKeys } from 'constants/localStorageKeys'

const useStyles = makeStyles({ name: 'MainLandingHeading' })((theme) => ({
  root: {
    padding: theme.spacing(23.75, 0, 30),
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 0, 13),
    },
  },
  container: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textBlock: {
    minWidth: 616,
    width: 616,

    [theme.breakpoints.down('md')]: {
      minWidth: 400,
      width: 400,
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      width: '100%',
      order: 2,
    },
  },
  subTitle: {
    fontFamily: fontSpaceMono,
    color: theme.palette.info.light,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  text: {
    margin: theme.spacing(1, 0, 5),
    color: theme.palette.text.secondary,

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0, 3),
    },
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    maxWidth: 358,
    width: '100%',

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
  imgContainer: {
    maxWidth: 700,
    width: '100%',
    height: 758,

    img: {
      width: '100%',
      height: 'auto',
    },

    [theme.breakpoints.down('md')]: {
      height: 580,
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: 280,
      height: 317,
      width: '100%',
      order: 1,
      margin: '0 auto',
    },
  },
  animationGrid: {
    position: 'absolute',
    top: theme.spacing(11.75),
    left: '50%',
    zIndex: 1,

    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
}))

export const MainLandingHeading = () => {
  const { classes } = useStyles()

  return (
    <div
      className={classes.root}
      id={LINKS_ANCHOR.roseCollectionPageHeading.id}
    >
      <Container maxWidth={'lg'}>
        <div className={classes.container}>
          <FadeInComponent
            variant={'left'}
            msDelay={700}
            className={classes.textBlock}
            isDisabled={
              localStorage.getItem(localStorageKeys.isAnimationDisabled) ===
              'true'
            }
          >
            <>
              <Typography variant={'h3'} className={classes.subTitle}>
                Build your own <br /> fighter with
              </Typography>
              <Typography variant={'h1'}>Thug Rose</Typography>
              <Typography variant={'h5'} className={classes.text}>
                Join the ultimate challenge of becoming a Legendary Fighter and
                win real-life prizes! Take action now and be the first to claim
                the title of the ultimate collector.
              </Typography>
            </>
          </FadeInComponent>
          <FadeInComponent
            variant={'right'}
            msDelay={700}
            className={classes.imgContainer}
            isDisabled={
              localStorage.getItem(localStorageKeys.isAnimationDisabled) ===
              'true'
            }
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/illustrations/main/rose-top.webp`}
              alt={'rose'}
            />
          </FadeInComponent>
        </div>
      </Container>
      <MainLandingGridHeroes className={classes.animationGrid} />
    </div>
  )
}
