import { NftGroupRarity } from 'models/INfts'

type RoseNftCollectionDetailsContentType = {
  title: string
  subtitle: string
  description: string
  allItems: {
    title: string
    description: string
    href: string
    btnCaption: string
  }
}
export const ROSE_BREEDING_DETAILS_CONTENT: Record<
  string,
  RoseNftCollectionDetailsContentType
> = {
  [NftGroupRarity.Legendary]: {
    title: 'Legendary',
    subtitle: 'Fighter Avatar NFT',
    description:
      'Fusing lower-level fighters will give you the chance to enter the ranks of Legendary fighters. Will you rise to the challenge?',
    allItems: {
      title: 'How to Build an Avatar?',
      description:
        'Acquire Mystery Boxes with digital stickers inside. Collect enough stickers to build your avatar. Keep collecting to upgrade your Fighter to reach the ultimate Legendary title!',
      href: '',
      btnCaption: 'Start Building',
    },
  },
}
