import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'

const useStyles = makeStyles({ name: 'LoaderBoxesMove' })(() => ({
  root: {
    width: '100%',
    position: 'relative',
    height: 200,
  },
  wrapper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    margin: '-100px',
    width: 200,
    height: 200,
    backgroundColor: 'transparent',
    border: 'none',
  },
  boxWrap: {
    width: '70%',
    height: '70%',
    margin: 'calc((100% - 70%) / 2) calc((100% - 70%) / 2)',
    position: 'relative',
    transform: 'rotate(-45deg)',
  },
  box: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    background: `linear-gradient(to right, #141562, #486fbc, #eab5a1, #8dd6ff, #4973c9, #d07ca7, #f4915e, #f5919e, #b46f89, #141562, #486fbc)`,
    backgroundPosition: '0% 50%',
    backgroundSize: '1000% 1000%',
    visibility: 'hidden',
  },
  one: {
    animation: 'moveGradient 15s infinite, oneMove 3.5s infinite',

    '@keyframes moveGradient': {
      to: {
        backgroundPosition: '100% 50%',
      },
    },

    '@keyframes oneMove': {
      '0%': {
        visibility: 'visible',
        clipPath: 'inset(0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '14.2857%': {
        clipPath: 'inset(0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '28.5714%': {
        clipPath: 'inset(35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '42.8571%': {
        clipPath: 'inset(35% 70% 35% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '57.1428%': {
        clipPath: 'inset(35% 70% 35% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '71.4285%': {
        clipPath: 'inset(0% 70% 70% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '85.7142%': {
        clipPath: 'inset(0% 70% 70% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '100%': {
        clipPath: 'inset(0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
    },
  },
  two: {
    animation: 'moveGradient 15s infinite, twoMove 3.5s 0.15s infinite',

    '@keyframes moveGradient': {
      to: {
        backgroundPosition: '100% 50%',
      },
    },

    '@keyframes twoMove': {
      '0%': {
        visibility: 'visible',
        clipPath: 'inset(0% 70% 70% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '14.2857%': {
        clipPath: 'inset(0% 70% 70% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '28.5714%': {
        clipPath: 'inset(0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '42.8571%': {
        clipPath: 'inset(0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '57.1428%': {
        clipPath: 'inset(35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '71.4285%': {
        clipPath: 'inset(35% 70% 35% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '85.7142%': {
        clipPath: 'inset(35% 70% 35% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '100%': {
        clipPath: 'inset(0% 70% 70% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
    },
  },
  three: {
    animation: 'moveGradient 15s infinite, threeMove 3.5s 0.3s infinite',

    '@keyframes moveGradient': {
      to: {
        backgroundPosition: '100% 50%',
      },
    },

    '@keyframes threeMove': {
      '0%': {
        visibility: 'visible',
        clipPath: 'inset(35% 70% 35% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '14.2857%': {
        clipPath: 'inset(35% 70% 35% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '28.5714%': {
        clipPath: 'inset(0% 70% 70% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '42.8571%': {
        clipPath: 'inset(0% 70% 70% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '57.1428%': {
        clipPath: 'inset(0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '71.4285%': {
        clipPath: 'inset(0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '85.7142%': {
        clipPath: 'inset(35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '100%': {
        clipPath: 'inset(35% 70% 35% 0 round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
    },
  },
  four: {
    animation: 'moveGradient 15s infinite, fourMove 3.5s 0.575s infinite',

    '@keyframes moveGradient': {
      to: {
        backgroundPosition: '100% 50%',
      },
    },

    '@keyframes fourMove': {
      '0%': {
        visibility: 'visible',
        clipPath: 'inset(35% 0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '14.2857%': {
        clipPath: 'inset(35% 0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '28.5714%': {
        clipPath: 'inset(35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '42.8571%': {
        clipPath: 'inset(70% 35% 0% 35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '57.1428%': {
        clipPath: 'inset(70% 35% 0% 35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '71.4285%': {
        clipPath: 'inset(70% 0 0 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '85.7142%': {
        clipPath: 'inset(70% 0 0 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '100%': {
        clipPath: 'inset(35% 0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
    },
  },
  five: {
    animation: 'moveGradient 15s infinite, fiveMove 3.5s 0.725s infinite',

    '@keyframes moveGradient': {
      to: {
        backgroundPosition: '100% 50%',
      },
    },

    '@keyframes fiveMove': {
      '0%': {
        visibility: 'visible',
        clipPath: 'inset(70% 0 0 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '14.2857%': {
        clipPath: 'inset(70% 0 0 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '28.5714%': {
        clipPath: 'inset(35% 0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '42.8571%': {
        clipPath: 'inset(35% 0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '57.1428%': {
        clipPath: 'inset(35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '71.4285%': {
        clipPath: 'inset(70% 35% 0% 35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '85.7142%': {
        clipPath: 'inset(70% 35% 0% 35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '100%': {
        clipPath: 'inset(70% 0 0 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
    },
  },
  six: {
    animation: 'moveGradient 15s infinite, sixMove 3.5s 0.875s infinite',

    '@keyframes moveGradient': {
      to: {
        backgroundPosition: '100% 50%',
      },
    },

    '@keyframes sixMove': {
      '0%': {
        visibility: 'visible',
        clipPath: 'inset(70% 35% 0% 35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '14.2857%': {
        clipPath: 'inset(70% 35% 0% 35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '28.5714%': {
        clipPath: 'inset(70% 0 0 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '42.8571%': {
        clipPath: 'inset(70% 0 0 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '57.1428%': {
        clipPath: 'inset(35% 0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '71.4285%': {
        clipPath: 'inset(35% 0% 35% 70% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '85.7142%': {
        clipPath: 'inset(35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
      '100%': {
        clipPath: 'inset(70% 35% 0% 35% round 5%)',
        animationTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
      },
    },
  },
}))

interface Props {
  className?: string
}

export const LoaderBoxesMove: FC<Props> = ({ className }) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={classes.wrapper}>
        <div className={classes.boxWrap}>
          <div className={cx(classes.box, classes.one)} />
          <div className={cx(classes.box, classes.two)} />
          <div className={cx(classes.box, classes.three)} />
          <div className={cx(classes.box, classes.four)} />
          <div className={cx(classes.box, classes.five)} />
          <div className={cx(classes.box, classes.six)} />
        </div>
      </div>
    </div>
  )
}
