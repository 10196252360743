export const linearGradients = {
  backgroundLinesHorizontal:
    'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.4) 33%, rgba(255,255,255,.4) 63%, rgba(255,255,255,0) 100%)',
  backgroundLinesVertical:
    'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 33%, rgba(255,255,255,0.4) 63%, rgba(255,255,255,0) 100%)',
}

export const backgroundLayer = {
  background: `rgba(255, 255, 255, 0.1)`,
  backdropFilter: 'blur(8px)',
}

export const cardLayer = {
  borderRadius: 8,
  background: `rgba(255, 255, 255, 0.1)`,
}

export const defaultLayer = {
  borderRadius: '16px',
  padding: '16px',
  background: `rgba(255, 255, 255, 0.1)`,
}
