import { useMemo } from 'react'
import { useAppSelector } from 'store/hooks'
import {
  selectUserAddress,
  selectUserChainId,
} from 'store/reducers/user/selectors'

export const useLocalStorageKeys = (key: string): string[] => {
  const userChainId = useAppSelector(selectUserChainId)
  const userAddress = useAppSelector(selectUserAddress)

  const keyLS = useMemo(() => {
    return `${key}-${userAddress}-${userChainId}`
  }, [userChainId, userAddress, key])

  return [keyLS]
}
