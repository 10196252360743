import { ROUTER_PATHS } from 'routes/routerPaths'
import { URL_SEARCH_PARAM } from 'constants/urlSearchParams'

export const urlGenerator = {
  createOpenSeaLink: (address: string, id: string | number) => {
    return `https://opensea.io/assets/matic/${address}/${id}`
  },
  createProfileEditLink: (backUrl: string) => {
    return `${ROUTER_PATHS.ROSE_PROFILE_EDIT}?${URL_SEARCH_PARAM.backUrl}=${backUrl}`
  },
}
