class Logger {
  isDevMode: boolean

  constructor() {
    this.isDevMode = process.env.NODE_ENV === 'development'
  }

  error(data: Error | undefined) {
    if (this.isDevMode) {
      console.error(data)
    }
  }

  msg(data: string | number | object) {
    console.log(data)
  }
}

const logger = new Logger()

export default logger
