import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { fontSpaceMono } from 'styles/fonts'
import { FC, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ConfettiAnimation from 'components/ConfettiAnimation/ConfettiAnimation'
import { OpenSeaIcon } from 'assets/icons'
import { urlGenerator } from 'services/urlGenerator'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'

const useStyles = makeStyles({ name: 'RoseNftCollectionClaimSuccessStep' })(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 0, 3),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 2),
      },
    },
    subtitle1: {
      width: '100%',
      fontFamily: fontSpaceMono,
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      lineHeight: '40px',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      textAlign: 'center',
      willChange: 'opacity',
    },
    title: {
      fontSize: 64,
      fontWeight: 800,
      lineHeight: '72px',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      width: '100%',
      textAlign: 'center',
      willChange: 'opacity',
    },
    opacityVisible: {
      opacity: 1,
    },
    bottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    btn: {
      maxWidth: 416,
      width: '100%',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      marginBottom: theme.spacing(2),
      willChange: 'opacity',
    },
    description: {
      maxWidth: 416,
      opacity: 0,
      transition: 'opacity 0.5s ease',
      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 0, 4),
      textAlign: 'center',
      willChange: 'opacity',
    },
    item: {
      maxWidth: 308,
      width: '100%',
      borderRadius: theme.spacing(2),
      background: 'rgba(38, 38, 38, 0.9)',
      backdropFilter: 'blur(12px)',
      overflow: 'hidden',
      margin: theme.spacing(3, 0),
      opacity: 0,
      transition: 'opacity 0.5s ease',
      willChange: 'opacity',
    },
    image: {
      width: '100%',
      borderRadius: theme.spacing(2),
      background: 'rgba(0, 38, 38, 1)',
      display: 'flex',
      justifyContent: 'center',

      img: {
        width: '100%',
        height: 'auto',
      },
    },
    itemBottom: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    itemBottomSubtitle: {
      color: theme.palette.secondary.light,
      fontSize: 14,
      marginBottom: theme.spacing(1),
    },
    itemBottomBtn: {
      svg: {
        minWidth: 24,
        width: 24,
        height: 24,
      },
    },
  })
)

const sxClasses = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    pt: 3,
  },
}

interface Props {
  className?: string
  onClose: () => void
  imageUrl: string
  id: number | string
  title: string
  subtitle?: string
}

export const RoseNftCollectionClaimSuccessStep: FC<Props> = ({
  className,
  onClose,
  imageUrl,
  id,
  title,
  subtitle = 'NFT Title',
}) => {
  const { classes, cx } = useStyles()
  const [isTransitioning, setTransitioning] = useState({
    subtitle: false,
    title: false,
    item: false,
    btn: false,
    description: false,
  })

  const [recycle, setRecycle] = useState(true)

  useEffect(() => {
    ;(async () => {
      for (const key in isTransitioning) {
        setTransitioning((prevState) => ({ ...prevState, [key]: true }))
        await new Promise((resolve) => setTimeout(resolve, 300))
      }
    })()

    setTimeout(() => {
      setRecycle(false)
    }, 3500)
  }, [])

  return (
    <>
      <ConfettiAnimation recycle={recycle} />
      <Container maxWidth="lg" sx={sxClasses.container}>
        <div className={cx(classes.root, className && className)}>
          <Typography
            variant={'subtitle1'}
            className={cx(
              classes.subtitle1,
              isTransitioning.subtitle && classes.opacityVisible
            )}
          >
            Success!
          </Typography>
          <Typography
            variant={'h2'}
            className={cx(
              classes.title,
              isTransitioning.title && classes.opacityVisible
            )}
          >
            Here are your rewards !
          </Typography>
          <div
            className={cx(
              classes.item,
              isTransitioning.item && classes.opacityVisible
            )}
          >
            <div className={classes.image}>
              <img src={imageUrl} alt={'NFT'} />
            </div>
            <div className={classes.itemBottom}>
              <Typography variant={'h5'} fontWeight={800}>
                {title}
              </Typography>
              <Typography
                variant={'caption'}
                className={classes.itemBottomSubtitle}
              >
                {subtitle}
              </Typography>
              <Button
                variant={'text'}
                className={cx(classes.itemBottomBtn)}
                href={urlGenerator.createOpenSeaLink(
                  ROSE_COLLECTION_CONTRACT_ADDRESS,
                  id
                )}
                target={'_blank'}
              >
                <OpenSeaIcon />
                <span>View on Opensea</span>
              </Button>
            </div>
          </div>
          <div className={classes.bottom}>
            <Button
              variant={'contained'}
              className={cx(
                classes.btn,
                isTransitioning.btn && classes.opacityVisible
              )}
              onClick={onClose}
            >
              Close
            </Button>
          </div>
          <Typography
            variant={'body2'}
            className={cx(
              classes.description,
              isTransitioning.description && classes.opacityVisible
            )}
          >
            Congratulations on receiving your Fighter. You can now find this NFT
            in your “Inventory”.
          </Typography>
        </div>
      </Container>
    </>
  )
}
