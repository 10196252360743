import { publicImages } from 'utils/getPublicImages'
import { NftGroupRarity } from 'models/INfts'

export enum RoseCollectionById {
  BronzeBox = 1,
  SilverBox,
  GoldBox,
  DiamondBox,
  'Beginner Torso',
  'Beginner Head',
  'Intermediate Torso',
  'Intermediate Head',
  'Intermediate Hands',
  'Advanced Torso',
  'Advanced Head',
  'Advanced Hands',
  'Advanced Legs',
  'Elite Torso',
  'Elite Head',
  'Elite Hands',
  'Elite Legs',
  'Elite Belt',
  Hoodie,
  Cap,
  'T-Shirt',
}

export const itemsStickersListById = [
  RoseCollectionById.Hoodie,
  RoseCollectionById.Cap,
  RoseCollectionById['T-Shirt'],
]

export enum ItemStickersName {
  Hoodie = 'Hoodie',
  Cap = 'Cap',
  'T-Shirt' = 'T-shirt',
}

export const itemStickersImages = {
  [RoseCollectionById.Hoodie]: publicImages.getIllustration(
    'rose/stickers/sticker-hoodie.webp'
  ),
  [RoseCollectionById.Cap]: publicImages.getIllustration(
    'rose/stickers/sticker-cap.webp'
  ),
  [RoseCollectionById['T-Shirt']]: publicImages.getIllustration(
    'rose/stickers/sticker-tshirt.webp'
  ),
}

export const itemStickersNameById: Record<number, string> = {
  [RoseCollectionById.Hoodie]: 'Hoodie',
  [RoseCollectionById.Cap]: 'Cap',
  [RoseCollectionById['T-Shirt']]: 'T-shirt',
}

export const itemMerchImages: Record<number, string> = {
  [RoseCollectionById.Hoodie]: publicImages.getIllustration(
    'rose/merch/hoodie.webp'
  ),
  [RoseCollectionById.Cap]: publicImages.getIllustration('rose/merch/cap.webp'),
  [RoseCollectionById['T-Shirt']]: publicImages.getIllustration(
    'rose/merch/tshirt.webp'
  ),
}

export const itemStickerList = [
  {
    id: RoseCollectionById.Cap,
    name: itemStickersNameById[RoseCollectionById.Cap],
    image: itemStickersImages[RoseCollectionById.Cap],
  },
  {
    id: RoseCollectionById.Hoodie,
    name: itemStickersNameById[RoseCollectionById.Hoodie],
    image: itemStickersImages[RoseCollectionById.Hoodie],
  },
  {
    id: RoseCollectionById['T-Shirt'],
    name: itemStickersNameById[RoseCollectionById['T-Shirt']],
    image: itemStickersImages[RoseCollectionById['T-Shirt']],
  },
]

export const athletesStickersListById = [
  RoseCollectionById['Beginner Torso'],
  RoseCollectionById['Beginner Head'],
  RoseCollectionById['Intermediate Torso'],
  RoseCollectionById['Intermediate Head'],
  RoseCollectionById['Intermediate Hands'],
  RoseCollectionById['Advanced Torso'],
  RoseCollectionById['Advanced Head'],
  RoseCollectionById['Advanced Hands'],
  RoseCollectionById['Advanced Legs'],
  RoseCollectionById['Elite Torso'],
  RoseCollectionById['Elite Head'],
  RoseCollectionById['Elite Hands'],
  RoseCollectionById['Elite Legs'],
  RoseCollectionById['Elite Belt'],
]

export const athleteStickersRarityById = {
  [RoseCollectionById['Beginner Torso']]: 'Beginner',
  [RoseCollectionById['Beginner Head']]: 'Beginner',
  [RoseCollectionById['Intermediate Torso']]: 'Intermediate',
  [RoseCollectionById['Intermediate Head']]: 'Intermediate',
  [RoseCollectionById['Intermediate Hands']]: 'Intermediate',
  [RoseCollectionById['Advanced Torso']]: 'Advanced',
  [RoseCollectionById['Advanced Head']]: 'Advanced',
  [RoseCollectionById['Advanced Hands']]: 'Advanced',
  [RoseCollectionById['Advanced Legs']]: 'Advanced',
  [RoseCollectionById['Elite Torso']]: 'Elite',
  [RoseCollectionById['Elite Head']]: 'Elite',
  [RoseCollectionById['Elite Hands']]: 'Elite',
  [RoseCollectionById['Elite Legs']]: 'Elite',
  [RoseCollectionById['Elite Belt']]: 'Elite',
}

export const athleteStickersSmallNameById = {
  [RoseCollectionById['Beginner Torso']]: 'Torso',
  [RoseCollectionById['Beginner Head']]: 'Head',
  [RoseCollectionById['Intermediate Torso']]: 'Torso',
  [RoseCollectionById['Intermediate Head']]: 'Head',
  [RoseCollectionById['Intermediate Hands']]: 'Hands',
  [RoseCollectionById['Advanced Torso']]: 'Torso',
  [RoseCollectionById['Advanced Head']]: 'Head',
  [RoseCollectionById['Advanced Hands']]: 'Hands',
  [RoseCollectionById['Advanced Legs']]: 'Legs',
  [RoseCollectionById['Elite Torso']]: 'Torso',
  [RoseCollectionById['Elite Head']]: 'Head',
  [RoseCollectionById['Elite Hands']]: 'Hands',
  [RoseCollectionById['Elite Legs']]: 'Legs',
  [RoseCollectionById['Elite Belt']]: 'Belt',
}

export const athleteStickersImages = {
  [RoseCollectionById['Beginner Torso']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-beginner-2.webp'
  ),
  [RoseCollectionById['Beginner Head']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-beginner-1.webp'
  ),
  [RoseCollectionById['Intermediate Torso']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-intermediate-2.webp'
  ),
  [RoseCollectionById['Intermediate Head']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-intermediate-1.webp'
  ),
  [RoseCollectionById['Intermediate Hands']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-intermediate-3.webp'
  ),
  [RoseCollectionById['Advanced Torso']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-advanced-2.webp'
  ),
  [RoseCollectionById['Advanced Head']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-advanced-1.webp'
  ),
  [RoseCollectionById['Advanced Hands']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-advanced-3.webp'
  ),
  [RoseCollectionById['Advanced Legs']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-advanced-4.webp'
  ),
  [RoseCollectionById['Elite Torso']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-elite-1.webp'
  ),
  [RoseCollectionById['Elite Head']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-elite-2.webp'
  ),
  [RoseCollectionById['Elite Hands']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-elite-4.webp'
  ),
  [RoseCollectionById['Elite Legs']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-elite-5.webp'
  ),
  [RoseCollectionById['Elite Belt']]: publicImages.getIllustration(
    'rose/nfts-tiny/nft-tiny-elite-3.webp'
  ),
}

export const athleteStickersByGroupRarity: Record<string, Array<number>> = {
  [NftGroupRarity.Beginner]: [
    RoseCollectionById['Beginner Torso'],
    RoseCollectionById['Beginner Head'],
  ],
  [NftGroupRarity.Intermediate]: [
    RoseCollectionById['Intermediate Torso'],
    RoseCollectionById['Intermediate Head'],
    RoseCollectionById['Intermediate Hands'],
  ],
  [NftGroupRarity.Advanced]: [
    RoseCollectionById['Advanced Torso'],
    RoseCollectionById['Advanced Head'],
    RoseCollectionById['Advanced Hands'],
    RoseCollectionById['Advanced Legs'],
  ],
  [NftGroupRarity.Elite]: [
    RoseCollectionById['Elite Torso'],
    RoseCollectionById['Elite Head'],
    RoseCollectionById['Elite Hands'],
    RoseCollectionById['Elite Legs'],
    RoseCollectionById['Elite Belt'],
  ],
}

export const athleteBeginnerStickerList = [
  {
    id: RoseCollectionById['Beginner Head'],
    rarity: athleteStickersRarityById[RoseCollectionById['Beginner Head']],
    name: athleteStickersSmallNameById[RoseCollectionById['Beginner Head']],
    image: athleteStickersImages[RoseCollectionById['Beginner Head']],
  },
  {
    id: RoseCollectionById['Beginner Torso'],
    rarity: athleteStickersRarityById[RoseCollectionById['Beginner Torso']],
    name: athleteStickersSmallNameById[RoseCollectionById['Beginner Torso']],
    image: athleteStickersImages[RoseCollectionById['Beginner Torso']],
  },
]

export const athleteIntermediateStickerList = [
  {
    id: RoseCollectionById['Intermediate Head'],
    rarity: athleteStickersRarityById[RoseCollectionById['Intermediate Head']],
    name: athleteStickersSmallNameById[RoseCollectionById['Intermediate Head']],
    image: athleteStickersImages[RoseCollectionById['Intermediate Head']],
  },
  {
    id: RoseCollectionById['Intermediate Torso'],
    rarity: athleteStickersRarityById[RoseCollectionById['Intermediate Torso']],
    name: athleteStickersSmallNameById[
      RoseCollectionById['Intermediate Torso']
    ],
    image: athleteStickersImages[RoseCollectionById['Intermediate Torso']],
  },
  {
    id: RoseCollectionById['Intermediate Hands'],
    rarity: athleteStickersRarityById[RoseCollectionById['Intermediate Hands']],
    name: athleteStickersSmallNameById[
      RoseCollectionById['Intermediate Hands']
    ],
    image: athleteStickersImages[RoseCollectionById['Intermediate Hands']],
  },
]

export const athleteAdvancedStickerList = [
  {
    id: RoseCollectionById['Advanced Head'],
    rarity: athleteStickersRarityById[RoseCollectionById['Advanced Head']],
    name: athleteStickersSmallNameById[RoseCollectionById['Advanced Head']],
    image: athleteStickersImages[RoseCollectionById['Advanced Head']],
  },
  {
    id: RoseCollectionById['Advanced Torso'],
    rarity: athleteStickersRarityById[RoseCollectionById['Advanced Torso']],
    name: athleteStickersSmallNameById[RoseCollectionById['Advanced Torso']],
    image: athleteStickersImages[RoseCollectionById['Advanced Torso']],
  },
  {
    id: RoseCollectionById['Advanced Hands'],
    rarity: athleteStickersRarityById[RoseCollectionById['Advanced Hands']],
    name: athleteStickersSmallNameById[RoseCollectionById['Advanced Hands']],
    image: athleteStickersImages[RoseCollectionById['Advanced Hands']],
  },
  {
    id: RoseCollectionById['Advanced Legs'],
    rarity: athleteStickersRarityById[RoseCollectionById['Advanced Legs']],
    name: athleteStickersSmallNameById[RoseCollectionById['Advanced Legs']],
    image: athleteStickersImages[RoseCollectionById['Advanced Legs']],
  },
]

export const athleteEliteStickerList = [
  {
    id: RoseCollectionById['Elite Head'],
    rarity: athleteStickersRarityById[RoseCollectionById['Elite Head']],
    name: athleteStickersSmallNameById[RoseCollectionById['Elite Head']],
    image: athleteStickersImages[RoseCollectionById['Elite Head']],
  },
  {
    id: RoseCollectionById['Elite Torso'],
    rarity: athleteStickersRarityById[RoseCollectionById['Elite Torso']],
    name: athleteStickersSmallNameById[RoseCollectionById['Elite Torso']],
    image: athleteStickersImages[RoseCollectionById['Elite Torso']],
  },
  {
    id: RoseCollectionById['Elite Hands'],
    rarity: athleteStickersRarityById[RoseCollectionById['Elite Hands']],
    name: athleteStickersSmallNameById[RoseCollectionById['Elite Hands']],
    image: athleteStickersImages[RoseCollectionById['Elite Hands']],
  },
  {
    id: RoseCollectionById['Elite Legs'],
    rarity: athleteStickersRarityById[RoseCollectionById['Elite Legs']],
    name: athleteStickersSmallNameById[RoseCollectionById['Elite Legs']],
    image: athleteStickersImages[RoseCollectionById['Elite Legs']],
  },
  {
    id: RoseCollectionById['Elite Belt'],
    rarity: athleteStickersRarityById[RoseCollectionById['Elite Belt']],
    name: athleteStickersSmallNameById[RoseCollectionById['Elite Belt']],
    image: athleteStickersImages[RoseCollectionById['Elite Belt']],
  },
]
