import { makeStyles } from 'styles/makeStyles'
import { FC, ReactNode } from 'react'
import { publicImages } from 'utils/getPublicImages'

const useStyles = makeStyles({ name: 'MainLandingLayout' })(() => ({
  main: {
    background: `
        url('${publicImages.getBackground(
          'main-landing/main-landing-header-bg-new.webp'
        )}') 
        top center 
        no-repeat
      `,
    position: 'relative',
  },
}))

interface Props {
  children: ReactNode
}

export const MainLandingLayout: FC<Props> = ({ children }) => {
  const { classes } = useStyles()

  return (
    <main className={classes.main}>
      <>{children}</>
    </main>
  )
}
