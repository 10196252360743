import { useQuery_GetCollection_Collection } from 'services/useApi/collection/useQuery'
import { RoseInventoryCertificateComponent } from './RoseInventoryCertificateComponent'
import { MenuInventory } from '../constants'

export const RoseInventoryCertificateContainer = () => {
  const { data, isInitialLoading } = useQuery_GetCollection_Collection()

  return (
    <RoseInventoryCertificateComponent
      activeMenuItem={MenuInventory.CertificateNFTs}
      collection={data}
      isInitialLoading={isInitialLoading}
    />
  )
}
