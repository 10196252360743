import { RoseCollectionDetailsLayout } from '../components/RoseCollectionDetailsLayout'
import { RoseCollectionDetailsHeading } from './RoseCollectionDetailsHeading'

export const RoseNftCollectionDetails = () => {
  return (
    <RoseCollectionDetailsLayout>
      <RoseCollectionDetailsHeading />
    </RoseCollectionDetailsLayout>
  )
}
