import { FC, ReactNode, useEffect } from 'react'
import { makeStyles } from 'styles/makeStyles'
import { motion, Transition, useAnimation } from 'framer-motion'
import { useInViewTrigger } from 'hooks/useInViewTrigger'
import { useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles({ name: 'FadeInComponent' })(() => ({
  root: {
    willChange: 'opacity, transform',
  },
}))

type Props = {
  className?: string
  children: ReactNode
  transition?: Transition
  msDelay?: number
  variant: 'left' | 'right' | 'up' | 'down'
  isDisabled?: boolean
}

const variantsDefenition = {
  left: {
    start: { x: 0, opacity: 1 },
    exit: { x: -150, opacity: 0 },
    initial: { x: -150, opacity: 0 },
  },
  right: {
    start: { x: 0, opacity: 1 },
    exit: { x: 150, opacity: 0 },
    initial: { x: 150, opacity: 0 },
  },
  up: {
    start: { y: 0, opacity: 1 },
    exit: { y: 150, opacity: 0 },
    initial: { y: 150, opacity: 0 },
  },
  down: {
    start: { y: 0, opacity: 1 },
    exit: { y: -150, opacity: 0 },
    initial: { y: -150, opacity: 0 },
  },
}

export const FadeInComponent: FC<Props> = ({
  className,
  children,
  transition = {
    type: 'easy',
    duration: 1,
  },
  msDelay = 200,
  variant,
  isDisabled,
}) => {
  const { classes, cx } = useStyles()
  const controls = useAnimation()

  const { ref, trigger } = useInViewTrigger(msDelay)

  const isMdDownMediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  useEffect(() => {
    if (trigger && !isMdDownMediaQuery) {
      controls.start(variantsDefenition[variant].start)
    }
  }, [trigger, isMdDownMediaQuery])

  if (isMdDownMediaQuery || isDisabled) {
    return <div className={cx(classes.root, className)}>{children}</div>
  }

  return (
    <motion.div
      ref={ref}
      initial={variantsDefenition[variant].initial}
      animate={controls}
      exit={variantsDefenition[variant].exit}
      transition={transition}
      className={cx(classes.root, className)}
    >
      <>{children}</>
    </motion.div>
  )
}
