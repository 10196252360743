export const contentAddItemModal = {
  item: {
    title: 'Add sticker to the slot?',
    description:
      'Once you click confirm, the sticker will be added to the sticker slot. You can, at any time, remove the sticker from the dedicated slot.',
    successDescription:
      'You have now completed one of the challenges and are eligible to receive this item. To proceed, please click the "Claim item" button and provide the necessary information for shipping.',
  },
  itemRemove: {
    title: 'Remove sticker?',
    description:
      'Stickers can be removed at any time. Once a sticker is removed, it automatically returns to your inventory, ready to be reapplied whenever you wish.',
  },
  athlete: {
    title: 'Add sticker to the slot?',
    description:
      'Once you click confirm, the sticker will be added to the sticker slot. You can, at any time, remove the sticker from the dedicated slot.',
    successDescription:
      'You have successfully completed this challenge. Click “Claim item” and receive your NFT fighter!',
  },
  athleteRemove: {
    title: 'Remove sticker?',
    description:
      'Stickers can be removed at any time. Once a sticker is removed, it automatically returns to your inventory, ready to be reapplied whenever you wish.',
  },
}
