import { PrivacyPolicyContent } from './PrivacyPolicyContent'
import { TermsLayout } from 'components/Layouts/TermsLayout/TermsLayout'

export const PrivacyPolicy = () => {
  return (
    <TermsLayout>
      <PrivacyPolicyContent />
    </TermsLayout>
  )
}
