import { RoseProfileLayout } from '../RoseProfileLayout'
import { RoseProfileInnerLayout } from '../RoseProfileInnerLayout'
import { RoseProfileEditForm } from './RoseProfileEditForm'
import { useMutation_SetUserInfo_UserInfo } from 'services/useApi/userInfo/useMutation'
import { IUserInfo } from 'models/IUserInfo'

export const RoseProfileEditContainer = () => {
  const { mutateAsync } = useMutation_SetUserInfo_UserInfo()

  const onSubmit = async (userInfo: IUserInfo) => {
    await mutateAsync({ userData: userInfo })
  }

  return (
    <RoseProfileLayout>
      <RoseProfileInnerLayout>
        <RoseProfileEditForm onSubmitRequest={onSubmit} />
      </RoseProfileInnerLayout>
    </RoseProfileLayout>
  )
}
