import { AthleteRarityId } from 'constants/rarity'
import { publicImages } from 'utils/getPublicImages'

export enum StepsNftBreedingModal {
  SELECT_NFT = 'selectNft',
  LOADING = 'loading',
  OPENING = 'opening',
  SUCCESS = 'success',
}

export const nftBreedingModalContent: Record<
  AthleteRarityId,
  {
    title: string
    prizeTitle: string
    prizeUrlImage: string
  }
> = {
  [AthleteRarityId.Beginner]: {
    title: 'Beginner',
    prizeTitle: 'Intermediate',
    prizeUrlImage: publicImages.getIllustration(
      'rose/packs/pack-intermediate-label.webp'
    ),
  },
  [AthleteRarityId.Intermediate]: {
    title: 'Intermediate',
    prizeTitle: 'Advanced',
    prizeUrlImage: publicImages.getIllustration(
      'rose/packs/pack-advanced-label.webp'
    ),
  },
  [AthleteRarityId.Advanced]: {
    title: 'Advanced',
    prizeTitle: 'Elite',
    prizeUrlImage: publicImages.getIllustration(
      'rose/packs/pack-elite-label.webp'
    ),
  },
  [AthleteRarityId.Elite]: {
    title: 'Elite',
    prizeTitle: 'Legendary',
    prizeUrlImage: publicImages.getIllustration(
      'rose/packs/pack-legendary-label.webp'
    ),
  },
  [AthleteRarityId.Legendary]: {
    title: 'Legendary',
    prizeTitle: 'Legendary',
    prizeUrlImage: publicImages.getIllustration(
      'rose/packs/pack-legendary-label.webp'
    ),
  },
}
