import { makeStyles } from 'styles/makeStyles'
import { MysteryPacksSmallItem } from 'components/MysteryPacks/MysteryPacksSmallItem'
import { HEADER_MYSTERY_PACKS_LIST } from './constants'
import { useQuery_BalanceOfBatch_Erc1155 } from 'services/useApi/erc1155/useQuery'
import { getCacheKey } from 'utils/getCacheKey'
import { cacheKeys } from 'constants/cacheKeys'
import { RoseCollectionById } from 'constants/stickers'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'
import { useAppSelector } from 'store/hooks'
import { reFetchedFlagSelector } from 'store/reducers/app/selectors'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { useState } from 'react'
import OpenMysteryBoxModal from 'components/Modals/OpenMysteryBoxModal'
import Button from '@mui/material/Button'
import { OpenSeaIcon } from 'assets/icons'
import { openSeaUrls } from 'constants/staticUrls'

const useStyles = makeStyles({ name: 'RoseHeaderPacks' })((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: 0,

    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  btn: {
    minWidth: 40,
    width: 40,
    height: 40,
    background: 'rgba(255, 255, 255, 0.1)',
    border: `1px solid rgba(255, 255, 255, 0.4)`,
    borderBottom: 'none',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

interface ModalData {
  id: number
  name: string
  imageUrl: string
  imageBgUrl: string
  own: number
}

export const RoseHeaderPacks = () => {
  const { classes } = useStyles()

  const reFetchedFlag = useAppSelector(reFetchedFlagSelector)
  const [modalData, setModalData] = useState<null | ModalData>(null)

  const { data = [] } = useQuery_BalanceOfBatch_Erc1155(
    getCacheKey(cacheKeys.erc1155.balanceOfBatch, 'RoseHeaderPacks'),
    [
      RoseCollectionById.BronzeBox,
      RoseCollectionById.SilverBox,
      RoseCollectionById.GoldBox,
      RoseCollectionById.DiamondBox,
    ],
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    reFetchedFlag[ReFetchFlag.SellBoxes]
  )

  return (
    <>
      <div className={classes.root} id={'rose-header-packs'}>
        {HEADER_MYSTERY_PACKS_LIST.map((pack, idx) => (
          <MysteryPacksSmallItem
            key={pack.name}
            name={pack.name}
            imageUrl={pack.imageUrl}
            own={data[idx] || 0}
            openPackBtnClick={() => {
              setModalData({
                id: pack.id,
                name: pack.name,
                imageUrl: pack.imageUrl,
                imageBgUrl: pack.imageBgUrl,
                own: data[idx] || 0,
              })
            }}
          />
        ))}
        <Button
          className={classes.btn}
          href={openSeaUrls.roseCollection}
          target={'_blank'}
        >
          <OpenSeaIcon />
        </Button>
      </div>
      <OpenMysteryBoxModal
        isOpen={!!modalData}
        onClose={() => {
          setModalData(null)
        }}
        own={modalData?.own || 0}
        boxImageUrl={modalData?.imageUrl || ''}
        boxImageBgUrl={modalData?.imageBgUrl || ''}
        name={modalData?.name || ''}
        id={modalData?.id || 1}
      />
    </>
  )
}
