import { useQuery_GetCollection_Collection } from 'services/useApi/collection/useQuery'
import { RoseInventoryNftFusionComponent } from './RoseInventoryNftFusionComponent'
import { MenuInventory } from '../constants'
import { useAppSelector } from 'store/hooks'
import { selectIsValidChain } from 'store/reducers/user/selectors'

export const RoseInventoryNftFusionContainer = () => {
  const { data, isInitialLoading } = useQuery_GetCollection_Collection()
  const isValidChain = useAppSelector(selectIsValidChain)

  return (
    <RoseInventoryNftFusionComponent
      activeMenuItem={MenuInventory.NFTFusion}
      collection={data}
      isInitialLoading={isInitialLoading}
      isValidChain={isValidChain}
    />
  )
}
