import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { fontSpaceMono } from 'styles/fonts'
import { FC, useState } from 'react'
import Button from '@mui/material/Button'
import { ArrowRightLineIcon } from 'assets/icons'

const useStyles = makeStyles({ name: 'RoseNftCollectionClaimOpeningStep' })(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 0, 3),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 2),
      },
    },
    subtitle: {
      lineHeight: '40px',
      textTransform: 'uppercase',
      color: theme.palette.secondary.light,
      fontFamily: fontSpaceMono,
      transition: 'opacity 0.5s ease',
      willChange: 'opacity',
    },
    title: {
      fontSize: 64,
      lineHeight: '80px',
      fontWeight: 800,
      transition: 'opacity 0.5s ease',
      willChange: 'opacity',

      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(5),
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    imageContainer: {
      width: 200,
      minWidth: 200,
      margin: theme.spacing(5, 0),
      transition: 'opacity 0.5s ease',
      position: 'relative',
      willChange: 'opacity',
    },
    image: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      zIndex: 2,
    },
    btn: {
      maxWidth: 358,
      width: '100%',
      justifyContent: 'space-between',
      transition: 'opacity 0.5s ease',
      willChange: 'opacity',

      svg: {
        minWidth: 24,
        width: 24,
        height: 24,
      },
    },
    opacity: {
      opacity: 0,
    },
  })
)

const sxClasses = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
  },
}

interface Props {
  className?: string
  imageUrl: string
  onNext: () => void
  subtitle?: string
  title?: string
}

export const RoseNftCollectionClaimOpeningStep: FC<Props> = ({
  className,
  imageUrl,
  onNext,
  subtitle = 'Prize on its way...',
  title = 'Claiming Your NFT',
}) => {
  const { classes, cx } = useStyles()
  const [isTransitioning, setTransitioning] = useState({
    subtitle: false,
    title: false,
    image: false,
    button: false,
  })

  const handleNext = async () => {
    for (const key in isTransitioning) {
      setTransitioning((prevState) => ({ ...prevState, [key]: true }))
      await new Promise((resolve) => setTimeout(resolve, 300))
    }
    onNext()
  }

  return (
    <Container maxWidth="lg" sx={sxClasses.container}>
      <div className={cx(classes.root, className && className)}>
        <Typography
          variant={'subtitle1'}
          className={cx(
            classes.subtitle,
            isTransitioning.subtitle && classes.opacity
          )}
        >
          {subtitle}
        </Typography>
        <Typography
          variant={'h3'}
          className={cx(
            classes.title,
            isTransitioning.title && classes.opacity
          )}
        >
          {title}
        </Typography>
        <div className={classes.content}>
          <div
            className={cx(
              classes.imageContainer,
              isTransitioning.image && classes.opacity
            )}
          >
            <img src={imageUrl} alt="prize" className={classes.image} />
          </div>
          <Button
            variant="contained"
            size={'large'}
            className={cx(
              classes.btn,
              isTransitioning.button && classes.opacity
            )}
            onClick={handleNext}
            disabled={isTransitioning.subtitle}
          >
            <span>Reveal NFT</span>
            <ArrowRightLineIcon />
          </Button>
        </div>
      </div>
    </Container>
  )
}
