import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import theme from 'styles/theme'
import {
  SecretType,
  VenlyProvider,
  VenlyProviderOptions,
} from '@venly/web3-provider'

export let venlyProvider: VenlyProvider | null = null

const OverreideVenlyProvider = VenlyProvider as any
OverreideVenlyProvider.prototype.createProviderEngine = async () => {
  venlyProvider = new VenlyProvider()
  const options: VenlyProviderOptions = {
    clientId: 'Lympo',
    environment: 'production',
    secretType: SecretType.MATIC,
  }

  const provider = await venlyProvider.createProvider(options)
  return provider
}

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        137: 'https://polygon-rpc.com',
      },
      infuraId: '46315a93037d47818abb496cf12952f2',
      qrcodeModalOptions: {
        desktopLinks: [
          'Zerion',
          'Ledger',
          'Tokenary',
          'Infinity Wallet',
          'Wallet 3',
          'SecuX',
          'Ambire',
          'RICE',
          'UniPass',
          'nufinetes',
          'NOW Wallet',
          'SafeMoon',
          'Fireblocks',
          'bobablocks',
          'Bitski',
          'Paper',
          'pier',
          'Crossmint',
          'Verso',
          'KryptoGO',
          'Defiant',
          'Exodus',
          'PREMA',
          'Marble',
          'ApolloX',
          'Sequence',
          'OneKey',
          'Vision',
        ],
      },
    },
  },
  venly: {
    package: OverreideVenlyProvider,
    options: {
      clientId: 'Lympo',
      environment: 'production',
      rpc: {
        80001: 'https://mumbai.polygonscan.com/',
      },
      secretType: SecretType.MATIC,
    },
  },
}

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: providerOptions,
  theme: {
    background: theme.palette.grey['A100'],
    main: theme.palette.text.primary,
    secondary: theme.palette.text.secondary,
    border: 'none',
    hover: theme.palette.grey[900],
  },
})

export default web3Modal
