import axios from 'axios'
import { apiUrls } from 'constants/apiUrls'
import { ICollectionResponse, IRoseItemResponse } from 'models/ICollectionData'

export const httpCollectionApi = {
  async getCollectionList(userAddress: string): Promise<ICollectionResponse> {
    const response = await axios.post(`${apiUrls.thegraph}/digisport-prod`, {
      query: `{
              accounts(where: {id: "${userAddress}"}) {
                id
                ERC1155balances(first: 1000, skip: 0) {
                  contract {
                    id
                  }
                  token {
                    identifier
                    uri
                  }
                }
              }
            }`,
    })

    return response.data.data?.accounts[0] || []
  },
  async getRoseItems(userAddress: string): Promise<IRoseItemResponse> {
    const response = await axios.post(
      `${apiUrls.thegraph}/digisport-rose-items`,
      {
        query: `{
              accounts(where: {id: "${userAddress}"}) {
                items {
                  id
                  typeId
                  typeName
                  status
                }
              }
            }`,
      }
    )

    return response.data.data?.accounts[0] || []
  },
}
