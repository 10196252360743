import { makeStyles } from 'styles/makeStyles'
import { fontSpaceMono } from 'styles/fonts'
import { FC } from 'react'
import { Button, Typography } from '@mui/material'
import { BuyPacksItem } from './BuyPacksItem'
import { linearGradients } from 'styles/other'
import { BUY_PACKS_DEFAULT_INFO } from './constants'
import { ArrowRightLineIcon, OpenSeaIcon } from 'assets/icons'
import { useAppDispatch } from 'store/hooks'
import { setModal } from 'store/reducers/app/actions'
import { ModalName } from 'constants/modals'
import { openSeaUrls } from 'constants/staticUrls'
import { Currency } from 'constants/currency'
import { IconCurrencyByAddress } from 'components/common'
import { CURRENCY_ADDRESSES, currencyNamesByAddress } from 'constants/addresses'

const useStyles = makeStyles({ name: 'BuyPacks' })((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 2),
    position: 'static',
    top: 0,

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      position: 'static',
    },
  },
  container: {
    width: '100%',
    borderRadius: theme.spacing(3),
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',
    border: '1px solid rgba(80, 80, 80, 0.4)',
    padding: theme.spacing(5, 2, 5),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2, 5),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  subtitle1: {
    fontFamily: fontSpaceMono,
    textTransform: 'uppercase',
    color: theme.palette.secondary.light,
    lineHeight: '40px',
  },
  description: {
    maxWidth: 850,
    fontWeight: 700,
    lineHeight: '32px',
    margin: theme.spacing(3, 0, 3),

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  list: {
    display: 'flex',
    alignSelf: 'center',
    position: 'relative',
    justifyContent: 'center',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    padding: theme.spacing(1),
    position: 'relative',

    '&:not(:first-of-type)::before': {
      content: '""',
      position: 'absolute',
      top: '-48px',
      left: 0,
      width: 1,
      height: 'calc(100% + 96px)',
      background: linearGradients.backgroundLinesVertical,

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    [theme.breakpoints.down('md')]: {
      borderRight: 'none',
    },
  },
  btn: {
    maxWidth: 288,
    width: '100%',
    padding: theme.spacing(1, 2),
    justifyContent: 'space-between',

    svg: {
      width: 24,
      height: 24,
      minWidth: 24,
    },
  },
  btnGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(7),
    gap: theme.spacing(3),
    flexWrap: 'wrap',
  },
  bottomContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2.5),
    gap: theme.spacing(2),
  },
  titleBottom: {
    lineHeight: '48px',
    textAlign: 'center',
    fontWeight: 800,
    maxWidth: 600,
  },
  currencyList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  currencyItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
}))

interface Props {
  className?: string
  subtitle?: string
  title?: string
  description?: string
  listItems?: Array<{
    id: number
    name: string
    description: string
    imageUrl: string
    price: number | string
  }>
  bottomTitle?: string
  currencyList?: Array<Currency>
}

export const BuyPacks: FC<Props> = ({
  className,
  subtitle = BUY_PACKS_DEFAULT_INFO.subtitle,
  title = BUY_PACKS_DEFAULT_INFO.title,
  description = BUY_PACKS_DEFAULT_INFO.description,
  listItems = BUY_PACKS_DEFAULT_INFO.list,
  bottomTitle = BUY_PACKS_DEFAULT_INFO.bottomTitle,
  currencyList = BUY_PACKS_DEFAULT_INFO.currencyList,
}) => {
  const { classes, cx } = useStyles()

  const dispatch = useAppDispatch()

  const handleShowDropRatesClick = () => {
    dispatch(setModal(ModalName.BoxDropRates, true))
  }

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant={'subtitle1'} className={classes.subtitle1}>
            {subtitle}
          </Typography>
          <Typography variant={'h2'}>{title}</Typography>
          <Typography variant={'h6'} className={classes.description}>
            {description}
          </Typography>
          <div className={classes.btnGroup}>
            <Button
              variant="outlined"
              className={classes.btn}
              onClick={handleShowDropRatesClick}
            >
              <span>Show box drop rates</span>
              <ArrowRightLineIcon />
            </Button>
            <Button
              variant="outlined"
              className={classes.btn}
              href={openSeaUrls.roseCollection}
              target={'_blank'}
            >
              <span>View on OpenSea</span>
              <OpenSeaIcon />
            </Button>
          </div>
        </div>
        <div className={classes.listContainer}>
          <div className={classes.list}>
            {listItems.map((item) => (
              <div key={item.id} className={classes.item}>
                <BuyPacksItem
                  name={item.name}
                  description={item.description}
                  imageUrl={item.imageUrl}
                  price={item.price}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.bottomContent}>
          <Typography variant={'h4'} className={classes.titleBottom}>
            {bottomTitle}
          </Typography>
          <div className={classes.currencyList}>
            {currencyList.map((item) => (
              <div key={item} className={classes.currencyItem}>
                <IconCurrencyByAddress address={CURRENCY_ADDRESSES[item]} />
                <Typography variant={'body1'}>
                  {currencyNamesByAddress[CURRENCY_ADDRESSES[item]]}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
