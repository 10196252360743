const getPublicImages = (path: string) => {
  return `${process.env.PUBLIC_URL}/images/${path}`
}

export const publicImages = {
  getBackground: (path: string) => getPublicImages(`backgrounds/${path}`),
  getIcon: (path: string) => getPublicImages(`icons/${path}`),
  getIllustration: (path: string) => getPublicImages(`illustrations/${path}`),
  getLogo: (path: string) => getPublicImages(`logos/${path}`),
}
