import { ComponentProps, FC, memo } from 'react'
import { makeStyles } from 'styles/makeStyles'
import Button from '@mui/material/Button'
import { chainNames, Chains } from 'constants/chains'
import { ConnectButton } from 'components/ConnectButton/ConnectButton'
import { useAppSelector } from 'store/hooks'
import { isLoggedInSelector } from 'store/reducers/user/selectors'
import { useSwitchChain } from 'hooks/useSwitchChain'

const useStyles = makeStyles({ name: 'SwitchableNetworkButton' })(() => ({}))

type Props = ComponentProps<typeof Button> & {
  className?: string
  caption?: string
  validChain: Chains
}

const SwitchableNetworkButtonComponent: FC<Props> = ({
  className,
  variant = 'contained',
  color = 'primary',
  validChain,
  caption,
  ...rest
}) => {
  const { cx } = useStyles()

  const isLoggedIn = useAppSelector(isLoggedInSelector)

  const [switchChain] = useSwitchChain(validChain)

  if (!isLoggedIn) {
    return (
      <ConnectButton
        className={cx(className)}
        variant={variant}
        size={'medium'}
        color={color}
        {...rest}
      />
    )
  }

  return (
    <Button
      className={cx(className)}
      variant={variant}
      size={'medium'}
      color={color}
      onClick={switchChain}
      {...rest}
    >
      {!!caption ? (
        caption
      ) : (
        <>Switch to the {validChain && chainNames[validChain]}</>
      )}
    </Button>
  )
}

export const SwitchableNetworkButton = memo(SwitchableNetworkButtonComponent)
