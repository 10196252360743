import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { RoseBreadcrumbs } from '@pages/Rose/components/RoseBreadcrumbs'
import { roseCollectionDetailsUtils } from './utils'
import { FC, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { ROSE_BREEDING_DETAILS_CONTENT } from './constants'
import { capitalizeFirstLetter } from 'utils/formatValue'
import { fontSpaceMono } from 'styles/fonts'
import { linearGradients } from 'styles/other'
import Button from '@mui/material/Button'
import { ArrowRightLineIcon } from 'assets/icons'
import { NftGroupRarity } from 'models/INfts'
import { RoseAllItemsBanner } from '@pages/Rose/components/RoseAllItemsBanner'
import { RoseFighterStepBreeding } from '@pages/Rose/components/RoseFighterItems'
import NftBreedingModal from 'components/Modals/NftBreedingModal'
import { useQuery_GetCollection_Collection } from 'services/useApi/collection/useQuery'
import { AthleteRarityId } from 'constants/rarity'
import { useAppSelector } from 'store/hooks'
import {
  isLoggedInSelector,
  selectIsValidChain,
} from 'store/reducers/user/selectors'
import { validChainId } from 'constants/validChainId'
import { SwitchableNetworkButton } from 'components/ConnectButton'

const useStyles = makeStyles({ name: 'RoseBreedingDetailsHeadingComponent' })(
  (theme) => ({
    root: {
      padding: theme.spacing(10, 0, 20),
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(10, 0, 10),
      },
    },
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(4, 0, 0),
      gap: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: theme.spacing(3),
      },
    },
    content: {
      maxWidth: 608,

      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    title: {
      fontSize: 64,
      lineHeight: '80px',
    },
    subtitle: {
      color: theme.palette.secondary.light,
      lineHeight: '32px',
      margin: theme.spacing(1, 0, 3),
      fontWeight: 700,
      fontFamily: fontSpaceMono,
    },
    contentInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 0,
        alignItems: 'flex-start',
      },
    },
    contentInfoItem: {
      fontWeight: 700,
      fontFamily: fontSpaceMono,
      position: 'relative',

      '&:not(:last-of-type)::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: -16,
        width: 1,
        height: '100%',
        background: linearGradients.backgroundLinesVertical,

        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    contentDescription: {
      margin: theme.spacing(2, 0, 3),
      fontWeight: 700,
    },
    contentBtn: {
      maxWidth: 358,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(1),

      svg: {
        minWidth: 24,
        width: 24,
        height: 24,
      },
    },
    imageContainer: {
      position: 'relative',
      padding: theme.spacing(0, 0, 0, 5),

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3, 0, 0, 0),
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 1,
        height: '100%',
        background: linearGradients.backgroundLinesVertical,

        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: 1,
          background: linearGradients.backgroundLinesHorizontal,
        },
      },
    },
  })
)

interface Props {
  paramId: string
}
export const RoseBreedingDetailsHeadingComponent: FC<Props> = ({ paramId }) => {
  const { classes } = useStyles()

  const { title, subtitle, description, allItems } =
    ROSE_BREEDING_DETAILS_CONTENT[capitalizeFirstLetter(paramId)]

  const isValidChain = useAppSelector(selectIsValidChain)

  const { data, isInitialLoading } = useQuery_GetCollection_Collection()

  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    if (!isValidChain) {
      setIsOpenModal(false)
    }
  }, [isValidChain])

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth={'lg'}>
          <RoseBreadcrumbs
            items={roseCollectionDetailsUtils.getBreadcrumb(paramId)}
          />
          <div className={classes.container}>
            <div className={classes.content}>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="h5" className={classes.subtitle}>
                {subtitle}
              </Typography>
              <div className={classes.contentInfo}>
                <Typography variant="body2" className={classes.contentInfoItem}>
                  You own: {data?.eliteNft?.length || 0}
                </Typography>
              </div>
              <Typography
                variant="body1"
                className={classes.contentDescription}
              >
                {description}
              </Typography>
              {isValidChain ? (
                <Button
                  className={classes.contentBtn}
                  variant="contained"
                  size={'large'}
                  onClick={() => setIsOpenModal(true)}
                  disabled={
                    isInitialLoading ||
                    (typeof data?.eliteNft.length === 'number' &&
                      data?.eliteNft.length < 4) ||
                    !isValidChain
                  }
                >
                  <span>Fuse NFTs</span>
                  <ArrowRightLineIcon />
                </Button>
              ) : (
                <SwitchableNetworkButton
                  validChain={validChainId}
                  className={classes.contentBtn}
                  size={'large'}
                />
              )}
            </div>
            <div className={classes.imageContainer}>
              <RoseFighterStepBreeding
                groupRarity={capitalizeFirstLetter(paramId) as NftGroupRarity}
                requiredForBreeding={4}
                availableForBreeding={data?.eliteNft.length || 0}
                disabled={
                  typeof data?.eliteNft.length === 'number' &&
                  data?.eliteNft.length < 4
                }
              />
            </div>
          </div>
        </Container>
      </div>
      <RoseAllItemsBanner
        title={allItems.title}
        description={allItems.description}
        btnCaption={allItems.btnCaption}
        href={allItems.href}
      />
      <NftBreedingModal
        rarityId={AthleteRarityId.Elite}
        nftList={data?.eliteNft || []}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </>
  )
}
