import { publicImages } from 'utils/getPublicImages'

const getImageUrl = (name: string) =>
  publicImages.getIllustration(`main-landing/packs/${name}`)

const getImageBgUrl = (name: string) =>
  publicImages.getBackground(`rose/packs/${name}`)

export const HEADER_MYSTERY_PACKS_LIST = [
  {
    id: 1,
    name: 'Bronze',
    imageUrl: getImageUrl('box-bronze.webp'),
    imageBgUrl: getImageBgUrl('box-bronze-bg.webp'),
  },
  {
    id: 2,
    name: 'Silver',
    imageUrl: getImageUrl('box-silver.webp'),
    imageBgUrl: getImageBgUrl('box-silver-bg.webp'),
  },
  {
    id: 3,
    name: 'Gold',
    imageUrl: getImageUrl('box-gold.webp'),
    imageBgUrl: getImageBgUrl('box-gold-bg.webp'),
  },
  {
    id: 4,
    name: 'Diamond',
    imageUrl: getImageUrl('box-diamond.webp'),
    imageBgUrl: getImageBgUrl('box-diamond-bg.webp'),
  },
]
