export const scrollToTop = (
  top = 0,
  left = 0,
  behavior: ScrollBehavior = 'smooth'
) => {
  document.documentElement.scrollTo({
    top,
    left,
    behavior,
  })
}
