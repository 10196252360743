import { FC, useEffect, useState } from 'react'
import { RoseMerchClaimSteps } from './constants'
import WrapperLargeModal from 'components/Modals/WrapperLargeModal'
import { BoxesLoaderBig } from 'components/Loaders'
import { RoseMerchDetailsClaimSuccessStep } from './RoseMerchDetailsClaimSuccessStep'
import { useLocalStorageKeys } from 'hooks/useLocalStorageKeys'
import { localStorageKeys } from 'constants/localStorageKeys'
import { useMutation_SwapStickers_OpenBox } from 'services/useApi/openBox/useMutation'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'

interface Props {
  isOpen: boolean
  onClose: () => void
  itemId: number
  updateLocalStorage: () => void
}

const RoseMerchDetailsClaimModalContainer: FC<Props> = ({
  isOpen,
  onClose,
  itemId,
  updateLocalStorage,
}) => {
  const [step, setStep] = useState<null | RoseMerchClaimSteps>(null)

  const [successData, setSuccessData] = useState<{
    id: number
    image: string
  }>({
    id: 0,
    image: '',
  })

  const [keyLS] = useLocalStorageKeys(localStorageKeys.itemStickersSelected)

  const { mutateAsync: mutateAsyncSwapItemsStickers } =
    useMutation_SwapStickers_OpenBox(
      () => {
        setStep(RoseMerchClaimSteps.SUCCESS)
        localStorage.removeItem(keyLS)
        updateLocalStorage()
      },
      () => {
        handleClose()
      }
    )

  const swapItemsStickers = async () => {
    const { id, image } = await mutateAsyncSwapItemsStickers({
      itemId: itemId,
      eventContractAddress: ROSE_COLLECTION_CONTRACT_ADDRESS,
      type: 'item',
    })

    setSuccessData({
      id,
      image,
    })
  }

  useEffect(() => {
    if (!isOpen) {
      setStep(null)
    } else {
      setStep(RoseMerchClaimSteps.LOADING)
      ;(async () => {
        await swapItemsStickers()
      })()
    }
  }, [isOpen])

  const handleClose = () => {
    onClose()
    setStep(null)
  }

  return (
    <WrapperLargeModal
      isOpen={
        step === RoseMerchClaimSteps.LOADING ||
        step === RoseMerchClaimSteps.SUCCESS
      }
      onClose={handleClose}
      isLoading={step === RoseMerchClaimSteps.LOADING}
    >
      <>
        {step === RoseMerchClaimSteps.LOADING && (
          <BoxesLoaderBig
            subtitle={'Heating up the blockchain FURNACE ...'}
            title={'Forging your NFT Certificate'}
          />
        )}
        {step === RoseMerchClaimSteps.SUCCESS && (
          <RoseMerchDetailsClaimSuccessStep
            onClose={handleClose}
            id={successData.id}
            imageUrl={successData.image}
          />
        )}
      </>
    </WrapperLargeModal>
  )
}

export default RoseMerchDetailsClaimModalContainer
