import { makeStyles } from 'styles/makeStyles'
import { FC, ReactNode } from 'react'
import { publicImages } from 'utils/getPublicImages'
import { RoseHeader } from '../RoseHeader'
import { ScrollToTop } from 'components/common/ScrollToTop'
import { RoseWhatInsidePacks } from '../RoseWhatInsidePacks'
import { Footer } from 'components/Footer'

const useStyles = makeStyles({ name: 'RoseCollectionDetailsLayout' })(() => ({
  root: {
    background: `
        url('${publicImages.getBackground('rose/collection-head-bg.webp')}') 
        top center 
        no-repeat,
        url('${publicImages.getBackground('rose/collection-bottom-bg.webp')}') 
        bottom center 
        no-repeat
      `,
    position: 'relative',
  },
  merchRoot: {
    background: `
        url('${publicImages.getBackground(
          'rose/collection-head-merch-bg.webp'
        )}') 
        top center 
        no-repeat,
        url('${publicImages.getBackground('rose/collection-bottom-bg.webp')}') 
        bottom center 
        no-repeat
      `,
  },
}))

interface Props {
  children: ReactNode
  isMerchPage?: boolean
}

export const RoseCollectionDetailsLayout: FC<Props> = ({
  children,
  isMerchPage,
}) => {
  const { classes, cx } = useStyles()

  return (
    <main className={cx(classes.root, isMerchPage && classes.merchRoot)}>
      <ScrollToTop />
      <RoseHeader />
      <>
        {children}
        <RoseWhatInsidePacks />
      </>
      <Footer />
    </main>
  )
}
