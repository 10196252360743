import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { ArrowRightLineIcon } from 'assets/icons'
import { publicImages } from 'utils/getPublicImages'
import { useAppDispatch } from 'store/hooks'
import { setModal } from 'store/reducers/app/actions'
import { ModalName } from 'constants/modals'

const useStyles = makeStyles({ name: 'RoseAllItemsBanner' })((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(6),
    background: `
        url('${publicImages.getBackground('rose/heroes-tile-bg-new.webp')}') 
        top center 
        no-repeat
      `,
  },
  container: {
    display: 'flex',
    padding: theme.spacing(7, 2, 8),
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.spacing(3),
    background: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(8px)',
    textAlign: 'center',
    border: `1px solid rgba(255, 255, 255, 0.4)`,
    borderBottom: 'none',
  },
  description: {
    maxWidth: 964,
    margin: theme.spacing(2, 0, 5),
  },
  btn: {
    maxWidth: 320,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    fontSize: 14,
    lineHeight: '24px',
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.spacing(2),

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
}))

interface Props {
  className?: string
  title: string
  description: string
  btnCaption: string
  href: string
}

export const RoseAllItemsBanner: FC<Props> = ({
  className,
  title,
  description,
  btnCaption,
}) => {
  const { classes, cx } = useStyles()
  const dispatch = useAppDispatch()

  const handleOnClick = () => {
    dispatch(setModal(ModalName.BoxDropRates, true))
  }

  return (
    <div className={cx(classes.root, className && className)}>
      <Container maxWidth={'lg'}>
        <div className={classes.container}>
          <Typography variant="h3">{title}</Typography>
          <Typography variant="subtitle1" className={classes.description}>
            {description}
          </Typography>
          <Button
            variant="contained"
            className={classes.btn}
            size={'large'}
            onClick={handleOnClick}
          >
            <span>{btnCaption}</span>
            <ArrowRightLineIcon />
          </Button>
        </div>
      </Container>
    </div>
  )
}
