import { makeStyles } from 'styles/makeStyles'
import { RoseInventoryHeading } from '../RoseInventoryHeading'
import { InventoryFighterAvatar } from 'components/InventoryItems/InventoryFighterAvatar'
import { Skeleton, Typography } from '@mui/material'
import { NftAvatar, NftGroupRarity } from 'models/INfts'
import { CheckboxBtn } from 'components/common'
import { LINKS_ANCHOR } from 'constants/linksAnchor'
import { urlGenerator } from 'services/urlGenerator'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'
import { FC, memo } from 'react'
import { fontSpaceMono } from 'styles/fonts'
import { MaskHappyIcon } from 'assets/icons'
import { IFilterFighterAvatars } from './constants'
import { MenuInventory } from '@pages/Rose/RoseInventory/constants'
import { RoseInventoryLayout } from '../RoseInventoryLayout'
import { arePropsEqual } from 'utils/arePropsEqual'

const useStyles = makeStyles({ name: 'RoseInventoryFighterAvatarsComponent' })(
  (theme) => ({
    root: {
      width: '100%',
    },
    content: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
      margin: theme.spacing(0, 0, 10),

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    filterContainer: {
      width: '100%',
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      margin: theme.spacing(3, 0, 2),

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    filterContent: {
      width: '100%',
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        overflowX: 'auto',
      },
    },
    checkbox: {
      background: 'transparent',

      [theme.breakpoints.down('md')]: {
        minWidth: 130,
      },
    },
    emptyBlock: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(2),
      margin: theme.spacing(3, 0, 10),
      borderRadius: theme.spacing(3),
      border: `1px solid rgba(255, 255, 255, 0.4)`,
      borderBottom: 'none',
      padding: theme.spacing(5, 2, 0),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2, 0),
      },

      svg: {
        minWidth: 120,
        width: 120,
      },
    },
    emptyBlockTitle: {
      fontFamily: fontSpaceMono,
      lineHeight: '32px',
      textAlign: 'center',
      width: '100%',
      fontWeight: 700,
    },
  })
)

interface Props {
  filter: IFilterFighterAvatars
  setFilter: (filter: IFilterFighterAvatars) => void
  isFilterActive: boolean
  beginnerList: NftAvatar[]
  intermediateList: NftAvatar[]
  advancedList: NftAvatar[]
  eliteList: NftAvatar[]
  legendaryList: NftAvatar[]
  isEmptyList: boolean
  isInitialLoading: boolean
  activeMenuItem: MenuInventory
}

const RoseInventoryFighterAvatars: FC<Props> = ({
  filter,
  setFilter,
  isFilterActive,
  beginnerList,
  intermediateList,
  advancedList,
  eliteList,
  legendaryList,
  isEmptyList,
  isInitialLoading,
  activeMenuItem,
}) => {
  const { classes, cx } = useStyles()

  return (
    <RoseInventoryLayout activeMenuItem={activeMenuItem}>
      <div
        className={cx(classes.root)}
        id={LINKS_ANCHOR.inventoryFighterAvatars.id}
      >
        <RoseInventoryHeading
          title={'Fighter avatars'}
          description={
            'Unleash your inner fighter with these avatars! Each rarity avatar embodies your inner fighters spirit, reflecting your determination to never give up and keep striving for your goals.'
          }
        />
        {isInitialLoading ? (
          <Skeleton
            sx={{
              transform: 'inherit',
              width: '100%',
              height: 300,
              borderRadius: 4,
              mt: 3,
              mb: 10,
            }}
          />
        ) : (
          <>
            {isEmptyList && !isFilterActive ? (
              <div className={classes.emptyBlock}>
                <MaskHappyIcon />
                <Typography variant={'h5'} className={classes.emptyBlockTitle}>
                  You don’t own any avatars
                </Typography>
              </div>
            ) : (
              <>
                <div className={classes.filterContainer}>
                  <Typography variant={'caption'}>Filter:</Typography>
                  <div className={classes.filterContent}>
                    {Object.values(NftGroupRarity).map((rarity) => (
                      <CheckboxBtn
                        label={rarity}
                        key={rarity}
                        checked={!!filter && filter[rarity]}
                        className={classes.checkbox}
                        onClick={() => {
                          setFilter({
                            ...filter,
                            [rarity]: !filter[rarity],
                          })
                        }}
                      />
                    ))}
                  </div>
                </div>
                {isEmptyList ? (
                  <div className={classes.emptyBlock}>
                    <Typography
                      variant={'h5'}
                      className={classes.emptyBlockTitle}
                    >
                      List is empty
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.content}>
                    {beginnerList.map((item) => (
                      <InventoryFighterAvatar
                        key={item.id}
                        imgUrl={item.image}
                        name={'Beginner'}
                        title={'Fighter Avatar NFT'}
                        openSeaUrl={urlGenerator.createOpenSeaLink(
                          ROSE_COLLECTION_CONTRACT_ADDRESS,
                          item.id
                        )}
                      />
                    ))}
                    {intermediateList.map((item) => (
                      <InventoryFighterAvatar
                        key={item.id}
                        imgUrl={item.image}
                        name={'Intermediate'}
                        title={'Fighter Avatar NFT'}
                        openSeaUrl={urlGenerator.createOpenSeaLink(
                          ROSE_COLLECTION_CONTRACT_ADDRESS,
                          item.id
                        )}
                      />
                    ))}
                    {advancedList.map((item) => (
                      <InventoryFighterAvatar
                        key={item.id}
                        imgUrl={item.image}
                        name={'Advanced'}
                        title={'Fighter Avatar NFT'}
                        openSeaUrl={urlGenerator.createOpenSeaLink(
                          ROSE_COLLECTION_CONTRACT_ADDRESS,
                          item.id
                        )}
                      />
                    ))}
                    {eliteList.map((item) => (
                      <InventoryFighterAvatar
                        key={item.id}
                        imgUrl={item.image}
                        name={'Elite'}
                        title={'Fighter Avatar NFT'}
                        openSeaUrl={urlGenerator.createOpenSeaLink(
                          ROSE_COLLECTION_CONTRACT_ADDRESS,
                          item.id
                        )}
                      />
                    ))}
                    {legendaryList.map((item) => (
                      <InventoryFighterAvatar
                        key={item.id}
                        imgUrl={item.image}
                        name={'Legendary'}
                        title={'Fighter Avatar NFT'}
                        openSeaUrl={urlGenerator.createOpenSeaLink(
                          ROSE_COLLECTION_CONTRACT_ADDRESS,
                          item.id
                        )}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </RoseInventoryLayout>
  )
}

export const RoseInventoryFighterAvatarsComponent = memo(
  RoseInventoryFighterAvatars,
  arePropsEqual
)
