export const MAIN_LANDING_FAQ = [
  {
    id: 1,
    title:
      "What is the Digisport collector's experience with Rose Namajunas, and how can I join?",
    content: `
    The Digisport collector's experience with Rose Namajunas is a joint collectible project by Animoca Brands, Lympo, and Rose Namajunas. This digital memorabilia collecting challenge is a limited-time event designed to provide sports fans with an engaging Collect&Earn experience and usher in a new era of sports entertainment with an unparalleled Web3 experience. To participate, simply join the challenge and start collecting!
<br />
<br />
If you have any other questions regarding Digisport Rose Namajunas, please feel free to contact <a href="https://support.lympo.io/hc/en-us/requests/new?ticket_form_id=11038899653149" target="_blank">our support</a>.
    `,
  },
  //   {
  //     id: 2,
  //     title: 'What are the benefits of becoming a Legendary fighter?',
  //     content: `
  //     Becoming a Legendary fighter is a unique achievement that can only be attained through fusing. Once you acquire a Legendary Fighter avatar, you can participate in the Legendary raffle for a chance to win exclusive items signed by Rose Namajunas and a one-of-a-kind GOAT tier Fighter Avatar. Additionally, you can stake your Legendary fighter NFTs on the Lympo platform to earn additional benefits.
  // <br />
  // <br />
  // If you have any other questions regarding Digisport Rose Namajunas, please feel free to contact <a href="https://support.lympo.io/hc/en-us/requests/new?ticket_form_id=11038899653149" target="_blank">our support</a>.
  //     `,
  //   },
  {
    id: 3,
    title:
      "Do I need a digital wallet or crypto to participate in the collector's experience?",
    content: `
    You can purchase Mystery Boxes using your credit card, but you will need a digital wallet to store and manage your digital collectibles. To make it easy for you to join the challenge, we have partnered with Venly, a user-friendly blockchain technology provider that offers easy-to-use tools and services for individuals and businesses. Follow this short step-by-step guide to create your Venly wallet: <a href="https://help.venly.io/en/article/how-to-create-a-wallet-1crrua5/" target="_blank">https://help.venly.io/en/article/how-to-create-a-wallet-1crrua5/</a>.
<br />
<br />
If you have any other questions regarding Digisport Rose Namajunas, please feel free to contact <a href="https://support.lympo.io/hc/en-us/requests/new?ticket_form_id=11038899653149" target="_blank">our support</a>.
    `,
  },
  {
    id: 4,
    title: 'What real-life items can I win in the experience?',
    content: `
Collect digital stickers of your favorite items and redeem them for unique real-life prizes from Rose Namajunas herself. The prizes include signed gloves and signed merch - limited-edition hoodies, t-shirts, and caps.
<br />
<br />
If you have any other questions regarding Digisport Rose Namajunas, please feel free to contact <a href="https://support.lympo.io/hc/en-us/requests/new?ticket_form_id=11038899653149" target="_blank">our support</a>.
    `,
  },
]
