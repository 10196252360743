import { makeStyles } from 'styles/makeStyles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { TransitionDialog } from 'components/common'
import IconButton from '@mui/material/IconButton'
import { CloseIcon } from 'assets/icons'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { FC, useState } from 'react'
import Typography from '@mui/material/Typography'
import { contentAddItemModal } from './constants'

const useStyles = makeStyles({ name: 'RoseClaimItemActionModal' })((theme) => ({
  closeIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.text.secondary,

    svg: {
      width: 24,
      height: 24,
    },
  },
  content: {
    padding: theme.spacing(2),
    width: '100%',
  },
  actions: {
    padding: theme.spacing(2),
    borderTop: `1px solid rgba(255, 255, 255, .4)`,
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  btn: {
    minWidth: 224,
    width: 224,

    [theme.breakpoints.down('sm')]: {
      minWidth: 120,
      width: 120,
    },
  },
  successContent: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  prizeImageContainer: {
    minWidth: 240,
    width: 240,

    img: {
      width: '100%',
      height: 'auto',
    },
  },
  titleGreen: {
    color: theme.palette.success.main,
    fontSize: 16,
  },
  description: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  stickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stickerBlock: {
    display: 'flex',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: 308,
    width: '100%',
    alignItems: 'center',
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  stickerImage: {
    minWidth: 120,
    width: 120,

    img: {
      width: '100%',
      height: 'auto',
      borderRadius: theme.spacing(2),
    },
  },
  stickerName: {
    color: theme.palette.secondary.light,
    fontSize: 12,
    lineHeight: '16px',
  },
}))

interface Props {
  isOpen: boolean
  onClose: () => void
  type: 'item' | 'itemRemove' | 'athlete' | 'athleteRemove'
  own: string | number
  title: string
  name?: string
  imageUrl: string
  prizeImageUrl: string
  onConfirm: () => void
  onRemove: () => void
  startRequest: () => void
  isSuccessful?: boolean
  isApproved: boolean
  onApprove: () => void
  isLoading: boolean
  isExists?: boolean
}

const RoseClaimItemActionModal: FC<Props> = ({
  isOpen,
  onClose,
  type,
  own,
  title,
  name,
  imageUrl,
  prizeImageUrl,
  onConfirm,
  onRemove,
  startRequest,
  isSuccessful,
  isApproved,
  onApprove,
  isLoading,
  isExists,
}) => {
  const { classes } = useStyles()

  const [isSuccess, setIsSuccess] = useState(false)

  const handleSuccessClose = () => {
    onClose()
    onConfirm()
  }

  const handleClaim = () => {
    startRequest()
    handleSuccessClose()
  }

  const handleConfirm = () => {
    if (type === 'itemRemove' || type === 'athleteRemove') {
      onRemove()
      onClose()

      setTimeout(() => {
        setIsSuccess(false)
      }, 300)
      return
    }

    if ((type === 'item' || type === 'athlete') && isSuccessful) {
      setIsSuccess(true)
    } else {
      handleSuccessClose()
    }
  }

  return (
    <Dialog
      open={isOpen}
      PaperProps={{ sx: { maxWidth: 632, width: '100%' } }}
      aria-labelledby="dialog-OpenMysteryBoxCalcModal-labelledby"
      aria-describedby="dialog-OpenMysteryBoxCalcModal-describedby"
      TransitionComponent={TransitionDialog}
    >
      <DialogTitle id="dialog-OpenMysteryBoxCalcModal-title">
        <span></span>
        {!isLoading && (
          <IconButton
            aria-label="close"
            className={classes.closeIcon}
            onClick={isSuccess ? handleSuccessClose : onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        className={classes.content}
        id="dialog-OpenMysteryBoxCalcModal-content"
      >
        {isSuccess ? (
          <div className={classes.successContent}>
            <div className={classes.prizeImageContainer}>
              <img src={prizeImageUrl} alt="prize" />
            </div>
            <div>
              <Typography variant="caption" className={classes.titleGreen}>
                Collection completed!
              </Typography>
              <Typography variant="h3" className={classes.description}>
                Congratulations!
              </Typography>
              <Typography variant="body2" className={classes.description}>
                {(type === 'item' || type === 'athlete') &&
                  contentAddItemModal[type].successDescription}
              </Typography>
            </div>
          </div>
        ) : (
          <div className={classes.stickerContainer}>
            <Typography variant="h3">
              {contentAddItemModal[type].title}
            </Typography>
            <div className={classes.stickerBlock}>
              <div className={classes.stickerImage}>
                <img src={imageUrl} alt={'sticker'} />
              </div>
              <div>
                <Typography variant="body2" fontWeight={700}>
                  {title}
                </Typography>
                {name && (
                  <Typography variant="caption" className={classes.stickerName}>
                    {name}
                  </Typography>
                )}
                <Typography variant="subtitle2" fontWeight={400}>
                  You own: {own}
                </Typography>
              </div>
            </div>
            <Typography
              variant="body2"
              sx={{ textAlign: 'center' }}
              className={classes.description}
            >
              {contentAddItemModal[type].description}
            </Typography>
          </div>
        )}
      </DialogContent>
      {isSuccess ? (
        <DialogActions className={classes.actions}>
          <Button
            variant="outlined"
            className={classes.btn}
            onClick={handleSuccessClose}
          >
            Close
          </Button>
          <>
            {isApproved ? (
              <>
                {isExists && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    onClick={handleClaim}
                  >
                    Claim item
                  </Button>
                )}
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={onApprove}
                disabled={isLoading}
              >
                Approve
              </Button>
            )}
          </>
        </DialogActions>
      ) : (
        <DialogActions className={classes.actions}>
          <Button variant="outlined" className={classes.btn} onClick={onClose}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default RoseClaimItemActionModal
