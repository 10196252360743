import BigNumber from 'bignumber.js'
import { addMinutes, format } from 'date-fns'
import { TimerResult } from 'react-timer-hook'

export const toFixedSafe = (value: number, count?: number): number | string => {
  try {
    return Number((value + Number.EPSILON).toFixed(count || 6)).toString()
  } catch (e) {
    return value
  }
}

export const toFloorSafe = (
  value: number | string | undefined | null
): number | string => {
  if (value === undefined || value === null) return ''

  try {
    return Number(Math.floor(Number(value) * 100) / 100).toString()
  } catch (e) {
    return value
  }
}

export function formatValue(
  value: number | string | undefined,
  decimals = 0,
  count?: number
) {
  if (value === undefined) return ''

  try {
    const parts = (+toFixedSafe(Number(value) / Math.pow(10, decimals), count))
      .toString()
      .split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  } catch (e) {
    return value
  }
}

export function formatBigNumberValue(
  value?: BigNumber,
  decimals = 0,
  count?: number
) {
  if (!value) return null

  const transformedValue = value
    .dividedBy(new BigNumber(10).pow(decimals))
    .toString()

  return formatValue(transformedValue, 0, count)
}

export const formatValueWithToFixed = (
  value: number | string | undefined,
  count = 2
): string | number => {
  if (value === 0) {
    return value
  }

  if (!value) return ''

  let transformedValue = value

  if (typeof value === 'string') {
    transformedValue = Number(value)
  }

  try {
    const parts = toFixedSafe(transformedValue as number, count)
      .toString()
      .split('.')

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  } catch (e) {
    return value
  }
}

export function formatDate(
  dateProp?: string | Date | number,
  formatDate = 'MM/dd/yyyy'
) {
  if (!dateProp) {
    return ''
  }

  const date = new Date(dateProp)
  return format(addMinutes(date, date.getTimezoneOffset()), formatDate)
}

export function formatDateTime(date: number | Date | undefined): Date {
  if (typeof date === 'undefined') {
    return new Date(0)
  }

  if (typeof date === 'number') {
    return new Date(Number(date + '000'))
  }

  return date
}

export function formatTimer(timer: TimerResult): string {
  const { seconds, minutes, hours, days } = timer

  const hoursFormatted = hours < 10 ? '0' + hours : hours
  const minutesFormatted = minutes < 10 ? '0' + minutes : minutes
  const secondsFormatted = seconds < 10 ? '0' + seconds : seconds

  if (!days) {
    return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`
  }

  return `${days} days`
}

export function toNumberSafe(
  value: number | string | undefined | null
): number {
  if (typeof value === 'number') {
    return value
  }

  if (typeof value === 'string') {
    const transformedValue = Number(value)
    if (isNaN(transformedValue)) {
      return 0
    }

    return transformedValue
  }

  return 0
}

export function formatNumberToDateFormat(value: number) {
  if (value === 0) {
    return '00'
  }

  if (value < 10) {
    return `0${value}`
  }

  return value
}

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}
