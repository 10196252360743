import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { fontSpaceMono } from 'styles/fonts'
import { FC } from 'react'
import { publicImages } from 'utils/getPublicImages'
import Button from '@mui/material/Button'
import { ArrowRightLineIcon, OpenSeaIcon } from 'assets/icons'
import { setModal } from 'store/reducers/app/actions'
import { ModalName } from 'constants/modals'
import { useAppDispatch } from 'store/hooks'
import { openSeaUrls } from 'constants/staticUrls'

const useStyles = makeStyles({ name: 'MainLandingMysteryPacksInfo' })(
  (theme) => ({
    root: {
      padding: theme.spacing(11, 0),
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(5, 0, 10),
      },
    },
    textContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
    },
    subtitle1: {
      fontFamily: fontSpaceMono,
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      lineHeight: '40px',
    },
    text: {
      maxWidth: 524,
    },
    imageContainer: {
      width: '100%',

      img: {
        width: '100%',
        height: 'auto',
      },
    },
    bottom: {
      display: 'flex',
      marginTop: theme.spacing(8),
      flexWrap: 'wrap',
      gap: theme.spacing(3),
      justifyContent: 'center',

      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
        gap: theme.spacing(2),
      },
    },
    btn: {
      maxWidth: 358,
      width: '100%',
      justifyContent: 'space-between',

      svg: {
        minWidth: 24,
        width: 24,
        height: 24,
      },
    },
  })
)

interface Props {
  className?: string
}

export const MainLandingMysteryPacksInfo: FC<Props> = ({ className }) => {
  const { classes, cx } = useStyles()

  const dispatch = useAppDispatch()

  const handleShowDropRatesClick = () => {
    dispatch(setModal(ModalName.BoxDropRates, true))
  }

  return (
    <div className={cx(classes.root, className)}>
      <Container maxWidth={'lg'}>
        <div className={classes.imageContainer}>
          <img
            src={publicImages.getIllustration(
              'rose/packs/mystery-packs-landing.webp'
            )}
            alt={'mystery-packs-landing'}
          />
        </div>
        <div className={classes.textContainer}>
          <div>
            <Typography variant={'subtitle1'} className={classes.subtitle1}>
              What’s inside
            </Typography>
            <Typography variant={'h2'}>Mystery packs</Typography>
          </div>
          <Typography variant={'subtitle1'} className={classes.text}>
            Acquire mystery boxes and unlock the components of a fighter spirit
            hidden inside! Collect stickers and unleash your inner champion!
          </Typography>
        </div>
        <div className={classes.bottom}>
          <Button
            variant={'contained'}
            className={classes.btn}
            size={'large'}
            href={openSeaUrls.roseCollection}
            target={'_blank'}
          >
            <span>View on OpenSea</span>
            <OpenSeaIcon />
          </Button>
          <Button
            variant={'outlined'}
            className={classes.btn}
            size={'large'}
            onClick={handleShowDropRatesClick}
          >
            <span>Show drop rates</span>
            <ArrowRightLineIcon />
          </Button>
        </div>
      </Container>
    </div>
  )
}
