import { makeStyles } from 'styles/makeStyles'
import { publicImages } from 'utils/getPublicImages'
import { FC } from 'react'
import { capitalizeFirstLetter } from 'utils/formatValue'
import { ItemStickersName } from 'constants/stickers'
import { fontSpaceMono } from 'styles/fonts'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles({ name: 'RoseMerchDetailsHeadingImage' })(
  (theme) => ({
    root: {
      position: 'relative',
      borderRadius: theme.spacing(5),
      overflow: 'hidden',
    },
    container: {
      width: 593,
      minWidth: 593,
      height: 593,
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'center center',

      [theme.breakpoints.down('sm')]: {
        width: 288,
        minWidth: 288,
        height: 288,
        backgroundSize: '288px 288px',
        backgroundPosition: 'top center',
        marginBottom: theme.spacing(3),
      },
    },
    bgCap: {
      backgroundImage: `url(${publicImages.getIllustration(
        `rose/merch/cap.webp`
      )})`,
    },
    bgHoodie: {
      backgroundImage: `url(${publicImages.getIllustration(
        `rose/merch/hoodie.webp`
      )})`,
    },
    bgTShirt: {
      backgroundImage: `url(${publicImages.getIllustration(
        `rose/merch/tshirt.webp`
      )})`,
    },
    bgCameo: {
      backgroundImage: `url(${publicImages.getIllustration(
        `rose/merch/cameo.webp`
      )})`,
    },
    bgPhonecall: {
      backgroundImage: `url(${publicImages.getIllustration(
        `rose/merch/phonecall.webp`
      )})`,
    },
    bgSigned: {
      backgroundImage: `url(${publicImages.getIllustration(
        `rose/merch/signed.webp`
      )})`,
      backgroundSize: 'cover',
    },
    borderYellow: {
      border: `4px solid ${theme.palette.secondary.light}`,
    },
    labelYellow: {
      position: 'absolute',
      top: -4,
      left: '50%',
      transform: 'translateX(-50%)',
      padding: theme.spacing(0.5, 1),
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      background: theme.palette.secondary.light,
      zIndex: 3,
      maxWidth: 210,
      width: '100%',
      textAlign: 'center',
    },
    labelYellowText: {
      color: theme.palette.grey['A100'],
      fontFamily: fontSpaceMono,
    },
    borderGreen: {
      border: `4px solid ${theme.palette.success.main}`,
    },
    labelGreen: {
      background: theme.palette.success.main,
      maxWidth: 155,
    },
    labelGreenText: {
      color: theme.palette.common.white,
    },
  })
)

interface Props {
  className?: string
  path: string
  isReadyToSelect: boolean
  isCompleted: boolean
}

export const RoseMerchDetailsHeadingImage: FC<Props> = ({
  className,
  path,
  isReadyToSelect,
  isCompleted,
}) => {
  const { classes, cx } = useStyles()

  const transformedPath = capitalizeFirstLetter(path)

  const bgClass: Record<string, string> = {
    [ItemStickersName.Hoodie]: classes.bgHoodie,
    [ItemStickersName.Cap]: classes.bgCap,
    [ItemStickersName['T-Shirt']]: classes.bgTShirt,
  }

  return (
    <div
      className={cx(
        classes.root,
        isReadyToSelect && !isCompleted && classes.borderYellow,
        isCompleted && classes.borderGreen,
        className && className
      )}
    >
      {isReadyToSelect && !isCompleted && (
        <div className={cx(classes.labelYellow)}>
          <Typography variant="subtitle2" className={classes.labelYellowText}>
            New stickers available to add
          </Typography>
        </div>
      )}
      {isCompleted && (
        <div className={cx(classes.labelYellow, classes.labelGreen)}>
          <Typography
            variant="subtitle2"
            className={cx(classes.labelYellowText, classes.labelGreenText)}
          >
            Collection completed!
          </Typography>
        </div>
      )}
      <div className={cx(classes.container, bgClass[transformedPath])} />
    </div>
  )
}
