import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { cacheKeys } from 'constants/cacheKeys'
import { httpGeneralApi } from 'services/api/http/general'
import { CountriesResponse } from 'models/ICountry'
import { apiUrls } from 'constants/apiUrls'

export const useQuery_GetCountries_General = (
  options?: UseQueryOptions<CountriesResponse>
) => {
  return useQuery<CountriesResponse>(
    [cacheKeys.countries],
    async () =>
      await httpGeneralApi.getDataByUrl(
        `${apiUrls.restcountriesApi}/all?fields=name,flags`
      ),
    {
      ...options,
    }
  )
}
