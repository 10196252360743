import { ComponentProps, FC } from 'react'
import { makeStyles } from 'styles/makeStyles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

const useStyles = makeStyles({ name: 'LoadingButton' })((theme) => ({
  dotFlashing: {
    position: 'relative',
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.grey[800],
    animation: 'dotFlashing 1s infinite linear alternate',
    animationDelay: '.5s',
    marginRight: theme.spacing(2.25),
    willChange: 'background-color, color',

    '&::before': {
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: -8,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.grey[800],
      animation: 'dotFlashing 1s infinite linear alternate',
      animationDelay: '0s',
      willChange: 'background-color',
    },

    '&::after': {
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 8,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.grey[800],
      animation: 'dotFlashing 1s infinite linear alternate',
      animationDelay: '1s',
      willChange: 'background-color',
    },
    '@keyframes dotFlashing': {
      '0%': {
        backgroundColor: theme.palette.grey[800],
      },
      '50%, 100%': {
        backgroundColor: theme.palette.grey[300],
      },
    },
  },
}))

type Props = ComponentProps<typeof Button> & {
  loading: boolean
}

export const LoadingButton: FC<Props> = ({
  loading = false,
  disabled,
  children,
  ...rest
}) => {
  const { classes } = useStyles()

  return (
    <Button disabled={disabled || loading} {...rest}>
      {loading ? (
        <>
          <Box className={classes.dotFlashing} /> Loading
        </>
      ) : (
        children
      )}
    </Button>
  )
}
