import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { BuyPacksItemHorizontal } from 'components/MysteryPacks/BuyPacks'
import { useQuery_BalanceOfBatch_Erc1155 } from 'services/useApi/erc1155/useQuery'
import { getCacheKey } from 'utils/getCacheKey'
import { cacheKeys } from 'constants/cacheKeys'
import { RoseCollectionById } from 'constants/stickers'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { useAppSelector } from 'store/hooks'
import { reFetchedFlagSelector } from 'store/reducers/app/selectors'
import { useState } from 'react'
import OpenMysteryBoxModal from 'components/Modals/OpenMysteryBoxModal'
import { HEADER_MYSTERY_PACKS_LIST } from '@pages/Rose/components/RoseHeader/constants'

const useStyles = makeStyles({ name: 'RoseInventoryMysteryBoxes' })(
  (theme) => ({
    root: {
      position: 'relative',
      borderBottom: `1px solid ${theme.palette.common.white}`,
      padding: theme.spacing(10, 0, 3),

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(7, 0, 1),
      },
    },
    title: {
      marginBottom: theme.spacing(2),
      fontWeight: 700,
      fontFamily: fontSpaceMono,
      opacity: 0.4,
    },
    content: {
      display: 'flex',
      gap: theme.spacing(2),
      overflowX: 'auto',
    },
  })
)

interface ModalData {
  id: number
  name: string
  imageUrl: string
  imageBgUrl: string
  own: number
}

export const RoseInventoryMysteryBoxes = () => {
  const { classes, cx } = useStyles()

  const [modalData, setModalData] = useState<null | ModalData>(null)

  const reFetchedFlag = useAppSelector(reFetchedFlagSelector)

  const { data = [] } = useQuery_BalanceOfBatch_Erc1155(
    getCacheKey(cacheKeys.erc1155.balanceOfBatch, 'RoseHeaderPacks'),
    [
      RoseCollectionById.BronzeBox,
      RoseCollectionById.SilverBox,
      RoseCollectionById.GoldBox,
      RoseCollectionById.DiamondBox,
    ],
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    reFetchedFlag[ReFetchFlag.SellBoxes]
  )

  return (
    <>
      <div className={cx(classes.root)}>
        <Container maxWidth={'lg'}>
          <Typography variant="body1" className={classes.title}>
            Mystery Boxes
          </Typography>
          <div className={classes.content}>
            {HEADER_MYSTERY_PACKS_LIST.map((pack, idx) => (
              <BuyPacksItemHorizontal
                id={pack.id}
                key={pack.name}
                name={pack.name}
                imageUrl={pack.imageUrl}
                own={data[idx] || 0}
                onOpenClick={() => {
                  setModalData({
                    id: pack.id,
                    name: pack.name,
                    imageUrl: pack.imageUrl,
                    imageBgUrl: pack.imageBgUrl,
                    own: data[idx] || 0,
                  })
                }}
              />
            ))}
          </div>
        </Container>
      </div>
      <OpenMysteryBoxModal
        isOpen={!!modalData}
        onClose={() => {
          setModalData(null)
        }}
        own={modalData?.own || 0}
        boxImageUrl={modalData?.imageUrl || ''}
        boxImageBgUrl={modalData?.imageBgUrl || ''}
        name={modalData?.name || ''}
        id={modalData?.id || 1}
      />
    </>
  )
}
