import { useMemo } from 'react'
import { useAppSelector } from 'store/hooks'
import { selectUserChainId } from 'store/reducers/user/selectors'

export const useIsValidChainId = (validChainId: number | undefined) => {
  const userChainId = useAppSelector(selectUserChainId)

  const isValidChainId = useMemo(() => {
    if (!validChainId) {
      return false
    }

    return userChainId === validChainId
  }, [userChainId, validChainId])

  return [isValidChainId]
}
