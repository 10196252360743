import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { RoseItemStatus } from 'models/ICollectionData'

const useStyles = makeStyles({ name: 'InventoryItem' })((theme) => ({
  root: {
    maxWidth: 308,
    width: '100%',
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    width: '100%',
    flexDirection: 'column',
  },
  imageContainer: {
    width: '100%',
    minHeight: 310,

    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  content: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 700,
    lineHeight: '32px',
  },
  label: {
    padding: theme.spacing(0, 0.5),
    borderRadius: theme.spacing(0.5),
    fontFamily: fontSpaceMono,
    alignSelf: 'flex-start',
  },
  labelClaimed: {
    background: theme.palette.info.light,
    color: theme.palette.info.contrastText,
  },
  labelShipped: {
    background: theme.palette.success.dark,
  },
  labelWaiting: {
    background: theme.palette.primary.main,
  },
  labelReceived: {
    background: theme.palette.info.contrastText,
  },
  text: {
    fontWeight: 400,
    fontFamily: fontSpaceMono,
  },
}))

interface Props {
  className?: string
  imgUrl: string
  title: string
  id: string
  status: RoseItemStatus
}

export const InventoryItem: FC<Props> = ({
  className,
  imgUrl,
  title,
  id,
  status = 'Claimed',
}) => {
  const { classes, cx } = useStyles()

  const labelClass: Record<string, string> = {
    Claimed: classes.labelClaimed,
    Shipped: classes.labelShipped,
    'Waiting confirmation': classes.labelWaiting,
    Received: classes.labelReceived,
  }

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={classes.imageContainer}>
        <img src={imgUrl} alt={title} />
      </div>
      <div className={classes.container}>
        <div className={classes.content}>
          <div>
            <Typography variant={'h5'} className={classes.title}>
              {title}
            </Typography>
            <Typography variant={'subtitle2'} className={classes.text}>
              Item ID: <span>#{parseInt(id, 16)}</span>
            </Typography>
          </div>
        </div>
        <Typography
          variant={'subtitle2'}
          className={cx(classes.label, labelClass[status])}
        >
          Status: {status}
        </Typography>
      </div>
    </div>
  )
}
