import React, { ComponentProps, FC } from 'react'
import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { WarningFillIcon, CheckboxCircleFillIcon } from 'assets/icons'
import Box from '@mui/material/Box'

type Props = ComponentProps<typeof Alert> & {
  title?: string
  withCloseBtn?: boolean
  onCloseCallback?: () => void
}

const useStyles = makeStyles({ name: 'AppAlert' })((theme) => ({
  root: {
    padding: `4px 0 !important`,
    color: theme.palette.text.primary,
    textAlign: 'left',
    backgroundColor: 'transparent',
    margin: 0,

    '& .MuiAlert-message': {
      width: '100%',
    },

    svg: {
      width: 16,
      height: 16,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  standardSuccess: {
    color: `${theme.palette.success.main} !important`,

    '& .MuiAlert-icon': {
      color: `${theme.palette.success.main} !important`,
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  standardError: {
    color: `${theme.palette.error.light} !important`,

    '& .MuiAlert-icon': {
      color: `${theme.palette.error.light} !important`,
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  standardWarning: {
    color: `${theme.palette.warning.main} !important`,

    '& .MuiAlert-icon': {
      color: `${theme.palette.warning.main} !important`,
      display: 'flex',
      alignItems: 'center',
    },
  },
  standardInfo: {
    color: `${theme.palette.info.main} !important`,

    '& .MuiAlert-icon': {
      color: `${theme.palette.info.main} !important`,
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    width: '100%',

    a: {
      textDecoration: 'underline',
    },
  },
  description: {
    a: {
      textDecoration: 'underline',
    },
  },
  iconBtn: {
    minWidth: 16,
    width: 16,
    height: 16,
    color: theme.palette.text.primary,

    svg: {
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },
}))

export const AppAlert: FC<Props> = ({ children, classes, title, ...rest }) => {
  const { classes: styles } = useStyles()

  return (
    <Alert
      classes={{
        standardSuccess: styles.standardSuccess,
        standardError: styles.standardError,
        standardWarning: styles.standardWarning,
        standardInfo: styles.standardInfo,
        root: styles.root,
        ...classes,
      }}
      iconMapping={{
        success: <CheckboxCircleFillIcon />,
        warning: <WarningFillIcon />,
        error: <WarningFillIcon />,
        info: <WarningFillIcon />,
      }}
      {...rest}
    >
      <Box className={styles.content}>
        {!!title && (
          <Typography variant={'caption'} className={styles.title}>
            <span dangerouslySetInnerHTML={{ __html: title }} />{' '}
          </Typography>
        )}
        {!!children && (
          <Typography
            variant={'body1'}
            className={styles.description}
            sx={{ fontSize: 12, lineHeight: '16px' }}
          >
            {children}
          </Typography>
        )}
      </Box>
    </Alert>
  )
}
