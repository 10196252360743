import { createBrowserRouter, Navigate } from 'react-router-dom'
import { ROUTER_PATHS } from './routerPaths'
import { MainLanding } from '@pages/Rose/RoseLanding'
import { RoseNftCollectionDetails } from '@pages/Rose/RoseNftCollectionDetails'
import { ProtectedRoseNftDetailsRoute } from './protectedRoutes/ProtectedRoseNftDetailsRoute'
import { ProtectedRoseBreedingDetailsRoute } from './protectedRoutes/ProtectedRoseBreedingDetailsRoute'
import { RoseBreedingDetails } from '@pages/Rose/RoseBreedingDetails'
import { ProtectedRoseMerchDetailsRoute } from './protectedRoutes/ProtectedRoseMerchDetailsRoute'
import { RoseMerchDetails } from '@pages/Rose/RoseMerchDetails'
import {
  RoseInventoryCertificate,
  RoseInventoryFighterAvatars,
  RoseInventoryItems,
  RoseInventoryNftFusion,
  RoseInventoryNftStickers,
} from '@pages/Rose/RoseInventory'
import { LicenceAgreement } from '@pages/LicenceAgreement'
import { PrivacyPolicy } from '@pages/PrivacyPolicy'
import { TermsAndConditions } from '@pages/TermsAndConditions'
import { RoseProfileDetails, RoseProfileEdit } from '@pages/Rose/RoseProfile'
import { ProtectedRoseProfileEditRoute } from 'routes/protectedRoutes/ProtectedRoseProfileEditRoute'
import { RoseCollectionFighter } from '@pages/Rose/RoseCollectionFighter'
import { RoseCollectionMerch } from '@pages/Rose/RoseCollectionMerch'

export const router = createBrowserRouter([
  {
    path: ROUTER_PATHS.MAIN,
    element: <Navigate to={ROUTER_PATHS.ROSE} />,
  },
  {
    path: ROUTER_PATHS.ROSE,
    element: <MainLanding />,
  },
  {
    path: ROUTER_PATHS.ROSE_INVENTORY,
    element: <Navigate to={ROUTER_PATHS.ROSE_INVENTORY_NFT_STICKERS} />,
  },
  {
    path: ROUTER_PATHS.ROSE_INVENTORY_NFT_STICKERS,
    element: <RoseInventoryNftStickers />,
  },
  {
    path: ROUTER_PATHS.ROSE_INVENTORY_FIGHTER_AVATARS,
    element: <RoseInventoryFighterAvatars />,
  },
  {
    path: ROUTER_PATHS.ROSE_INVENTORY_NFT_FUSION,
    element: <RoseInventoryNftFusion />,
  },
  {
    path: ROUTER_PATHS.ROSE_INVENTORY_ITEMS,
    element: <RoseInventoryItems />,
  },
  {
    path: ROUTER_PATHS.ROSE_INVENTORY_CERTIFICATE_NFTS,
    element: <RoseInventoryCertificate />,
  },
  {
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS,
    element: <RoseCollectionFighter />,
  },
  {
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS_ID,
    element: (
      <ProtectedRoseNftDetailsRoute>
        <RoseNftCollectionDetails />
      </ProtectedRoseNftDetailsRoute>
    ),
  },
  {
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_MERCH,
    element: <RoseCollectionMerch />,
  },
  {
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_MERCH_ID,
    element: (
      <ProtectedRoseMerchDetailsRoute>
        <RoseMerchDetails />
      </ProtectedRoseMerchDetailsRoute>
    ),
  },
  {
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_FUSION,
    element: <Navigate to={ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS} />,
  },
  {
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_FUSION_ID,
    element: (
      <ProtectedRoseBreedingDetailsRoute>
        <RoseBreedingDetails />
      </ProtectedRoseBreedingDetailsRoute>
    ),
  },
  {
    path: ROUTER_PATHS.ROSE_LICENCE_AGREEMENT,
    element: <LicenceAgreement />,
  },
  {
    path: ROUTER_PATHS.ROSE_PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: ROUTER_PATHS.ROSE_TERMS_AND_CONDITIONS,
    element: <TermsAndConditions />,
  },
  {
    path: ROUTER_PATHS.ROSE_PROFILE,
    element: <RoseProfileDetails />,
  },
  {
    path: ROUTER_PATHS.ROSE_PROFILE_EDIT,
    element: (
      <ProtectedRoseProfileEditRoute>
        <RoseProfileEdit />
      </ProtectedRoseProfileEditRoute>
    ),
  },
])
