import { makeStyles } from 'styles/makeStyles'
import { FC, ReactElement } from 'react'
import { FourIcon, OneIcon, ThreeIcon, TwoIcon } from 'assets/numbers'
import Typography from '@mui/material/Typography'
import { fontSpaceMono } from 'styles/fonts'
import { useAppSelector } from 'store/hooks'
import { whiteListSelector } from 'store/reducers/app/selectors'

const useStyles = makeStyles({ name: 'MainLandingHiWItem' })((theme) => ({
  root: {
    padding: theme.spacing(3.5, 3),
    maxWidth: 980,
    width: '100%',
    display: 'flex',
    gap: theme.spacing(3),
    background: 'rgba(20, 29, 24, 0.75)',
    backdropFilter: 'blur(8px)',
    borderRadius: theme.spacing(3),
    border: `1px solid ${theme.palette.success.dark}`,
    willChange: 'transform opacity',
    position: 'sticky',

    [theme.breakpoints.down('md')]: {
      position: 'static',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(2),
      gap: theme.spacing(1),
    },
  },
  root1: {
    top: 150,
  },
  root2: {
    top: 165,
  },
  root3: {
    top: 180,
  },
  root4: {
    top: 195,
  },
  numberContainer: {
    width: 96,
    minWidth: 96,
    height: 96,
    color: theme.palette.secondary.light,

    [theme.breakpoints.down('sm')]: {
      width: 40,
      minWidth: 40,
      height: 40,
    },
  },
  content: {
    display: 'flex',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  subtitle: {
    fontFamily: fontSpaceMono,
    color: theme.palette.secondary.light,
    fontWeight: 700,
  },
  description: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(14),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  imgContainer: {
    minWidth: 352,
    width: 352,
    position: 'relative',

    img: {
      width: '100%',
      height: 'auto',
    },

    [theme.breakpoints.down('md')]: {
      minWidth: 200,
      width: 200,
    },

    [theme.breakpoints.down('sm')]: {
      order: -1,
      margin: '0 auto',
      minWidth: 121,
      width: 121,
    },
  },
  btn: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: 271,
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2),

    [theme.breakpoints.down('sm')]: {
      position: 'static',
      transform: 'translateX(0)',
      margin: '8px auto 0',
    },

    svg: {
      width: 24,
      height: 24,
      minWidth: 24,
    },
  },
  displayNoneSm: {
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  displayBlockSm: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

interface Props {
  id: number
  title: string
  subtitle: string
  description: string
  imgUrl: string
  withBtn?: boolean
}

const numbersIcon: Record<number, ReactElement> = {
  1: <OneIcon />,
  2: <TwoIcon />,
  3: <ThreeIcon />,
  4: <FourIcon />,
}

export const MainLandingHiWItem: FC<Props> = ({
  id,
  title,
  subtitle,
  description,
  imgUrl,
}) => {
  const { classes, cx } = useStyles()

  const { isWhiteListed } = useAppSelector(whiteListSelector)

  const rootClass: Record<string, any> = {
    1: classes.root1,
    2: classes.root2,
    3: classes.root3,
    4: classes.root4,
  }

  return (
    <div
      className={cx(classes.root, rootClass[id])}
      id={`hiw-item-${id}`}
      style={{ zIndex: 10 }}
    >
      <div>
        <div className={classes.content}>
          <div className={classes.numberContainer}>{numbersIcon[id]}</div>
          <div>
            <Typography variant={'h3'}>{title}</Typography>
            <Typography
              variant={'body1'}
              className={classes.subtitle}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </div>
        </div>
        <Typography
          variant={'body2'}
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className={classes.imgContainer}>
        <img src={imgUrl} alt={title} />
      </div>
    </div>
  )
}
