import { makeStyles } from 'styles/makeStyles'
import { Typography } from '@mui/material'
import { FC, memo } from 'react'
import { linearGradients } from 'styles/other'

const useStyles = makeStyles({ name: 'RoseInventoryHeadingLine' })((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      lineHeight: '16px',
    },
  },
  line: {
    width: '100%',
    height: 1,
    background: linearGradients.backgroundLinesHorizontal,
  },
}))

interface Props {
  className?: string
  title: string
}

const RoseInventoryHeadingLineComponent: FC<Props> = ({ className, title }) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <Typography variant="caption" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.line} />
    </div>
  )
}

export const RoseInventoryHeadingLine = memo(RoseInventoryHeadingLineComponent)
