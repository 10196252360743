import { makeStyles } from 'styles/makeStyles'
import { RoseInventoryHeading } from '../RoseInventoryHeading'
import { LINKS_ANCHOR } from 'constants/linksAnchor'
import { InventoryItemCertificate } from 'components/InventoryItems/InventoryItemCertificate'
import Skeleton from '@mui/material/Skeleton'
import { fontSpaceMono } from 'styles/fonts'
import { MedalIcon } from 'assets/icons'
import Typography from '@mui/material/Typography'
import { ICollectionAvatarResponse } from 'models/ICollectionData'
import { MenuInventory } from '../constants'
import { FC, memo } from 'react'
import { arePropsEqual } from 'utils/arePropsEqual'
import { RoseInventoryLayout } from '../RoseInventoryLayout'

const useStyles = makeStyles({ name: 'RoseInventoryCertificateComponent' })(
  (theme) => ({
    root: {
      width: '100%',
    },
    content: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
      margin: theme.spacing(3, 0, 10),

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    emptyBlock: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(2),
      margin: theme.spacing(3, 0, 10),
      borderRadius: theme.spacing(3),
      border: `1px solid rgba(255, 255, 255, 0.4)`,
      borderBottom: 'none',
      padding: theme.spacing(5, 2, 0),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2, 0),
      },

      svg: {
        minWidth: 120,
        width: 120,
      },
    },
    emptyBlockTitle: {
      fontFamily: fontSpaceMono,
      lineHeight: '32px',
      textAlign: 'center',
      width: '100%',
      fontWeight: 700,
    },
  })
)

interface Props {
  collection: ICollectionAvatarResponse | undefined
  activeMenuItem: MenuInventory
  isInitialLoading: boolean
}

export const RoseInventoryCertificate: FC<Props> = ({
  collection,
  activeMenuItem,
  isInitialLoading,
}) => {
  const { classes, cx } = useStyles()

  return (
    <RoseInventoryLayout activeMenuItem={activeMenuItem}>
      <div
        className={cx(classes.root)}
        id={LINKS_ANCHOR.inventoryCertificate.id}
      >
        <RoseInventoryHeading
          title={'Certificate NFTs'}
          description={
            'This certificate of authenticity is hereby presented to certify that the item displayed on our website is genuine, authentic, and approved by Rose Namajunas. '
          }
        />
        <div className={classes.content}>
          {isInitialLoading ? (
            <Skeleton
              sx={{
                transform: 'inherit',
                width: '100%',
                height: 300,
                borderRadius: 4,
                mt: 3,
                mb: 10,
              }}
            />
          ) : (
            <>
              {!collection?.certificates.length ? (
                <div className={classes.emptyBlock}>
                  <MedalIcon />
                  <Typography
                    variant={'h5'}
                    className={classes.emptyBlockTitle}
                  >
                    You don’t own any certificates
                  </Typography>
                </div>
              ) : (
                <>
                  {collection?.certificates.map((item) => (
                    <InventoryItemCertificate
                      key={item.id}
                      imgUrl={item.image}
                      id={item.id}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </RoseInventoryLayout>
  )
}

export const RoseInventoryCertificateComponent = memo(
  RoseInventoryCertificate,
  arePropsEqual
)
