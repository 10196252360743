import { FC, useEffect, useMemo, useState } from 'react'
import { itemStickersPathId } from './constants'
import { localStorageKeys } from 'constants/localStorageKeys'
import { useAppSelector } from 'store/hooks'
import { reFetchedFlagSelector } from 'store/reducers/app/selectors'
import { itemsStickersListById } from 'constants/stickers'
import {
  useQuery_BalanceOfBatch_Erc1155,
  useQuery_IsApproved_Erc1155,
} from 'services/useApi/erc1155/useQuery'
import { getCacheKey } from 'utils/getCacheKey'
import { cacheKeys } from 'constants/cacheKeys'
import {
  ROSE_COLLECTION_CONTRACT_ADDRESS,
  ROUTER_CONTRACT_ADDRESS,
} from 'constants/addresses'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { useMutation_Approve_Erc1155 } from 'services/useApi/erc1155/useMutation'
import { queryClient } from 'services/providers'
import { RoseMerchDetailsHeadingComponent } from './RoseMerchDetailsHeadingComponent'
import { useLocalStorageKeys } from 'hooks/useLocalStorageKeys'
import { useIsValidChainId } from 'hooks/useIsValidChainId'
import { validChainId } from 'constants/validChainId'
import { selectProfileIsExists } from 'store/reducers/user/selectors'

interface Props {
  paramId: string
}
export const RoseMerchDetailsHeadingContainer: FC<Props> = ({ paramId }) => {
  const [selectedItems, setSelectedItems] = useState<Record<number, number>>({})
  const [modalState, setModalState] = useState<null | 'item' | 'itemRemove'>(
    null
  )
  const [isOpenClaimModal, setIsOpenClaimModal] = useState<boolean>(false)
  const [isValidChainId] = useIsValidChainId(validChainId)

  const isExists = useAppSelector(selectProfileIsExists)

  const [keyLS] = useLocalStorageKeys(localStorageKeys.itemStickersSelected)
  const updateSelectedItems = () => {
    const items = localStorage.getItem(keyLS)

    if (items) {
      setSelectedItems(JSON.parse(items))
    } else {
      setSelectedItems({})
    }
  }

  useEffect(() => {
    updateSelectedItems()
  }, [keyLS])

  const reFetchedFlag = useAppSelector(reFetchedFlagSelector)

  const ids = Object.values(itemsStickersListById).filter(
    (item) => typeof item === 'number'
  ) as number[]

  const { data: balance = [] } = useQuery_BalanceOfBatch_Erc1155(
    getCacheKey(
      cacheKeys.erc1155.balanceOfBatch,
      'RoseMerchDetailsHeadingComponent'
    ),
    ids,
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    reFetchedFlag[ReFetchFlag.ClaimMerch]
  )

  const balanceById = useMemo(() => {
    return balance.reduce((acc, item, index) => {
      acc[ids[index]] = item
      return acc
    }, {} as Record<number, number>)
  }, [balance])

  const balanceLength = Array(balanceById[itemStickersPathId[paramId]]).length
  const selectedLength = Array(
    selectedItems[itemStickersPathId[paramId]]
  ).length

  const isCompleted = selectedLength === 5

  const isReadyToSelect = useMemo(() => {
    return (
      itemStickersPathId[paramId] in balanceById &&
      balanceById[itemStickersPathId[paramId]] > 0
    )
  }, [balanceById, paramId])

  const { data: isApproved = false } = useQuery_IsApproved_Erc1155(
    getCacheKey('IsApproved_Erc1155', 'RoseMerchDetailsHeadingComponent'),
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    ROUTER_CONTRACT_ADDRESS
  )

  const { mutateAsync: approveMutateAsync, isLoading: approveLoading } =
    useMutation_Approve_Erc1155(async () => {
      await queryClient.invalidateQueries([
        getCacheKey('IsApproved_Erc1155', 'RoseMerchDetailsHeadingComponent'),
      ])
    })

  const onApprove = async () => {
    await approveMutateAsync({
      nftContractAddress: ROSE_COLLECTION_CONTRACT_ADDRESS,
      toAddress: ROUTER_CONTRACT_ADDRESS,
    })
  }

  const onAddToLocalStorage = (id: number) => {
    const items = localStorage.getItem(keyLS)

    if (items) {
      const parsedItems = JSON.parse(items)
      const currentItem = id in parsedItems ? parsedItems[id] : 0

      const newItems = {
        ...parsedItems,
        [id]: currentItem + 1,
      }
      localStorage.setItem(keyLS, JSON.stringify(newItems))
    } else {
      localStorage.setItem(keyLS, JSON.stringify({ [id]: 1 }))
    }

    updateSelectedItems()
  }

  const onRemoveFromLocalStorage = (id: number) => {
    const items = localStorage.getItem(keyLS)

    if (items) {
      const parsedItems = JSON.parse(items)
      const newItems = {
        ...parsedItems,
      }

      if (newItems[id] === 1) {
        delete newItems[id]
      } else {
        newItems[id] = newItems[id] - 1
      }

      localStorage.setItem(keyLS, JSON.stringify(newItems))

      updateSelectedItems()
    }
  }

  return (
    <RoseMerchDetailsHeadingComponent
      paramId={paramId}
      balanceLength={balanceLength}
      selectedLength={selectedLength}
      selectedItems={selectedItems}
      isCompleted={isCompleted}
      onAddToLocalStorage={onAddToLocalStorage}
      onRemoveFromLocalStorage={onRemoveFromLocalStorage}
      isOpenClaimModal={isOpenClaimModal}
      setIsOpenClaimModal={setIsOpenClaimModal}
      updateSelectedItems={updateSelectedItems}
      isReadyToSelect={isReadyToSelect}
      balanceById={balanceById}
      modalState={modalState}
      setModalState={setModalState}
      isApproved={isApproved}
      approveLoading={approveLoading}
      onApprove={onApprove}
      isValidChainId={isValidChainId}
      isLoading={false}
      isExists={isExists}
    />
  )
}
