import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { fontSpaceMono } from 'styles/fonts'
import { Link } from 'react-router-dom'
import { ROUTER_PATHS } from 'routes/routerPaths'

const useStyles = makeStyles({ name: 'TermsAndConditionsContent' })(
  (theme) => ({
    main: {
      width: '100%',
      margin: `${theme.spacing(16)} auto 0`,
      textAlign: 'left',

      a: {
        textDecoration: 'underline',
      },

      ul: {
        li: {
          marginBottom: 0,
          fontSize: 16,
          lineHeight: '24px',
        },
      },

      [theme.breakpoints.down('md')]: {
        margin: `${theme.spacing(8)} auto 0`,
      },
    },
    containerInner: {
      maxWidth: 847,
      margin: '0 auto',
    },
    title: {
      fontSize: 64,
      lineHeight: '80px',
      fontWeight: 800,
      textAlign: 'center',
      width: '100%',
      marginBottom: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        lineHeight: '48px',
      },
    },
    titleYellow: {
      fontFamily: fontSpaceMono,
      fontSize: 40,
      lineHeight: '64px',
      fontWeight: 700,
      textAlign: 'center',
      color: theme.palette.secondary.light,
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    titleDate: {
      textAlign: 'center',
      width: '100%',
      marginBottom: theme.spacing(5),

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    subTitle: {
      lineHeight: '32px',
      fontWeight: 700,
    },
    listLowerAlpha: {
      listStyle: 'lower-alpha',

      li: {
        marginBottom: theme.spacing(2),
      },
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(12),

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(7),
      },
    },
    tableRow: {
      display: 'flex',
      gap: theme.spacing(2),
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: theme.spacing(1),
      },
    },
    tableRowFirstItem: {
      minWidth: 200,
    },
    tableRowFirstTitle: {
      fontWeight: 800,
      color: theme.palette.text.secondary,
    },
  })
)

const sxClasses = {
  bodyM2: {
    mb: 2,
  },
  bodyM3: {
    mb: 3,
  },
  bodyM4: {
    mb: 4,
  },
}

export const TermsAndConditionsContent = () => {
  const { classes } = useStyles()

  return (
    <Container maxWidth={'lg'}>
      <main className={classes.main}>
        <div className={classes.containerInner}>
          <Typography className={classes.title}>
            Terms and Conditions
          </Typography>
          <Typography
            variant={'caption'}
            component={'p'}
            className={classes.titleDate}
          >
            Last updated: 06-06-2023
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            <b>
              PLEASE READ THESE DIGISPORT.XYZ/ROSE TERMS AND CONDITIONS
              CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR USE
              OF DIGISPORT.XYZ/ROSE PLATFORM. THESE TERMS AND CONDITIONS
              CONSTITUTES LEGALLY BINDING CONTRACT, THESE TERMS INCLUDE A
              BINDING ARBITRATION AGREEMENT.
            </b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            THESE DIGISPORT.XYZ/ROSE <b>TERMS</b> AND CONDITIONS (THE TERMS) SET
            FORTH THE LEGALLY BINDING TERMS AND CONDITIONS (CONTRACT), BETWEEN
            THE COMPANY (LATGALA OÜ) AND EACH REGISTERED OR UNREGISTERED USER,
            THAT GOVERN YOUR USE OF THE PLATFORM. BY ACCESSING OR USING THE
            PLATFORM YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE
            ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU
            HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS
            (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            You (<b>You, User</b> or <b>I</b>) must read, agree to and accept
            all of the terms and conditions contained in these Terms to use the
            Platform, which is available through website
            HTTPS://DIGISPORT.XYZ/ROSE and any affiliated mobile websites and
            mobile applications, owned and/or operated by Us. If You do not
            agree to these Terms You shall stop using the Platform immediately.
            In consideration of the foregoing You unconditionally accept and
            agree to these Terms in accordance with the terms and conditions
            provided below:
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            1. DEFINITIONS
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            1.1 Capitalized terms, which are not described separately, but are
            used in these Terms are provided below in the Appendix 1
            (“Definitions”) of the Terms.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            2. ACCEPTANCE AND APPLICATION OF THE TERMS.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            2.1 These Terms regulate terms and conditions of the usage and
            operation of the Platform, legal relationship between You and Us
            (the Company), acquisition of NFT, legal rights, legal obligations,
            remedies, limitation of liability, binding arbitration agreement and
            other matters stipulated in these Terms.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            <b>
              {' '}
              2.2 Please carefully review these Terms. By accessing and using
              the Platform, You are deemed to have read, accepted, executed and
              agreed to be bound by these Terms.
            </b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            2.3 By accepting these Terms, the User agrees to comply with
            additional terms and conditions, e.g. privacy policy, cookie policy
            and other policies and documents, which may be obligatory for the
            use of the Platform and which are available at the website{' '}
            <a href="https://digisport.xyz/rose">
              HTTPS://DIGISPORT.XYZ/ROSE
            </a>{' '}
            (the Additional Policies).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            2.4 The Company is entitled without any restrictions and approval of
            any User to unilaterally terminate, change, amend and supplement
            these Terms and any other Additional Policies. Any such changes will
            be effective upon the posting of the revised Terms and respectively
            Additional Policies on the Platform. The User is solely responsible
            for reviewing any such notice and the corresponding changes to the
            Terms and Additional Policies. Any and all modifications or changes
            to the Terms and Additional Policies will be effective immediately
            upon being announced on the Platform. In any case, use of the
            Platform shall be considered as acceptance of the amended Terms and
            Additional Policies.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            3. DESCRIPTION OF THE PLATFORM
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            <b>3.1. General Description</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.1.1 The Platform is a Collector’s Challenge, which provides You
            with the opportunity to purchase (or as We call it, mint), showcase
            and transfer Mystery pack NFTs (NFT definition is provided Appendix
            1 “Definitions”).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.1.2 In case of acquisition of NFT at the Platform, the Company is
            operating as the seller and the User is operating as the Buyer of
            respective NFT, in that respect the parties enter into binding and
            final agreement.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.1.3 When acquiring SPORT tokens on the Platform, Swipelux acts as
            the third-party seller, and the User acts as the Buyer of the
            respective amount of SPORT tokens. As such, the User and Swipelux
            enter into a binding and final agreement. It is the User&apos;s sole
            responsibility to provide a valid WEB3 address to receive the SPORT
            tokens purchased from Swipelux. The Company assumes no
            responsibility or liability for any issues or disputes that may
            arise between the User and Swipelux, as Swipelux is a third-party
            seller and operates independently from the Company.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.1.4. The Company collects revenue on the Platform via transaction
            fees and other applicable fees, which are included in the aggregate
            NFT price and which are not indicated separately.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.1.5. The Company at the Platform may provide additional functions,
            which are not described in these Terms, but constitutes part of the
            ecosystem of the Platform and these Terms shall be applicable
            thereto as well.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.1.6. No communication or information provided to You by the
            Company in any manner is intended as, or shall be considered or
            construed as any advice or recommendation.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            <b>3.2. Acquisition of NFT</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.2.1 Users who wish to acquire a Mystery Box (NFT) must select a
            specific rarity level for each box. The contents of each Mystery Box
            may include item stickers (NFTs) or Fighter avatar stickers (NFTs).
            Once a Mystery box has been acquired, the User will be able to
            unpack it and view its contents after a predetermined unlock period
            (Reveal phase). The specific date for opening boxes will be
            announced by the Company. Note, that users will not be able to open
            the boxes until the Reveal phase. Moreover, the trade on the
            secondary markets such as OpenSea.io and transfer to other wallets
            are restricted until the Reveal phase is launched. Users may collect
            a combination of stickers to receive rewards, such as merchandise or
            Fighter avatar NFTs. Users have the option to fuse Fighter avatar
            NFTs to obtain higher rarity avatar NFTs. Notably, Legendary Fighter
            avatar NFTs can only be acquired through the NFT breeding process.
            By participating in the acquisition of NFTs, including Mystery
            Boxes, Users agree to comply with these terms and conditions.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.2.2 Prices of NFT are indicated in the Platform in various crypto
            currencies and fiat (via Swipelux). In case, the Platform entitles
            the function, that NFT can be acquired by other means of payment,
            then such option will be indicated in the Platform and can be used
            by the entitled Users.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.2.3. Offers on the Platform made by the User to acquire respective
            NFT are legally binding, irrevocable offers to purchase the NFT. By
            making an offer, the User enter in binding agreement regarding
            acquisition of the NFT and, once approved by the Company (or as the
            case may be – by the smart contract), the agreement regarding NFT is
            executed at the moment or going to be executed in the future
            (depending on the case). Any offer made by the User shall be
            considered as final and correct, no offers made can be revoked or
            amended, unless otherwise indicated or allowed by the Company
            explicitly. In case of acquisition of NFT at the Platform, the
            Company is operating as the seller and the User is operating as the
            Buyer of respective NFT, in that respect the parties enter into
            binding and final agreement.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.2.4. NFT provided for sale on the Platform, can be limited in
            numbers and time, when such NFT can be acquired. Numbers and
            offering time of particular NFTs are indicated in the Platform and
            varies.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.2.5. Merchandise items and athlete experiences claimed using
            stickers must be redeemed until the 1st of November, 2023.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            3.2.6. The Company does not undertake the obligation (though the
            Company has a non-binding right to do so) to organize, facilitate or
            engage otherwise ensuring secondary market and/or transfer of the
            NFT and/or SPORT, including listing on any type of exchange.
            However, the Users under their decision may be entitled to transfer
            the NFTs also to the Company’s partners (subject, that User is
            allowed to use partner’s platform and services), such as for example
            current partner OpenSea, where the User can try to sell NFT to third
            parties, also the Company will not restrict transfer of NFT to other
            third parties selected at User’s discretion (subject to that
            respective NFT is transferred to User’s own wallet outside the
            Platform before). The Company do not control in any way activities
            of its partners and therefore do not take any obligations,
            responsibilities and/or liability in that respect. Please note, that
            any other platforms, venues, applications and/or websites (e.g.
            OpenSea) whether partners of the Company or no, where NFT can be
            made available for sale, may collect fees from their users.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            4. NFT AND RELATED RIGHTS, LIMITATION AND REPRESENTATIONS
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            <b>4.1. Ownership of NFT, Limited License and Limitations</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            4.1.1. By owning an NFT, You own a cryptographic token – NFT, that
            is associated with the Work itself, but you do not acquire and own
            Work and rights related to or associated with it, including, but not
            limiting to ownership, intellectual property and other rights and
            interest to Work, excepting the limited licence granted by these
            Terms to underlying Work (i.e. the NFT Licence as described below).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            4.1.2 By acquiring any NFT on the Platform, you will be granted a{' '}
            <Link to={ROUTER_PATHS.ROSE_LICENCE_AGREEMENT}>licence</Link> to
            use, copy and display the Work underlying NFT for the purposes
            specified in the licence (the “
            <Link to={ROUTER_PATHS.ROSE_LICENCE_AGREEMENT}>
              <b>NFT Licence</b>
            </Link>{' '}
            ”). Owning an NFT you might be subject to other restrictions and
            obligations that will be specified in the NFT License.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>4.2. Representations and Warranties in Respect of the Work</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            4.2.1. The Company represents and warrants, that it owns or
            otherwise legal poses legal right, title and all intellectual
            property rights to the Work associated with the NFT for sale on the
            Platform and respectively is entitled to sell NFT on the Platform.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            4.2.2. The Company does not guarantee, or warrant the accuracy or
            fairness of the price of NFT sold or offered for sale on or off of
            the Platform. The User agrees and acknowledges that the Company is
            not a fiduciary and do not owe any duties to any User of the
            Platform.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            5. ELIGIBILITY AND ACCEPTABLE USE
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            5.1. In order to use the Platform, You must, and hereby represent
            that You: (a) are either a legal entity or an individual who is 18
            years or older, or have otherwise reached the age of majority
            (including less than 18 years old), in each case who can form
            legally binding contracts; (b) are an employee or agent of and
            authorized to act for and bind an independent business (whether it
            be as a self-employed individual/sole proprietor or as a
            corporation, limited liability company, or other entity); (c) will
            comply with any licensing, registration, or other requirements with
            respect to your business, or the business for which you are acting;
            and, (e) have not previously been suspended or removed from using
            Platform; (f) have full power and authority to enter into these
            Terms and in doing so will not violate any other agreement to which
            You are obliged.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.2. When accessing or using Platform, You agree and confirm, that
            You will not violate any applicable laws, contract or rights of
            third parties, commit a tort or breach of contract, and that You are
            solely responsible for Your conduct while using Platform. Without
            limiting the generality of the foregoing, You agree that You:
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.2.1. will act in good faith, in cooperative manner, professionally
            and in compliance with applicable laws and these Terms, including,
            that these requirements shall apply while You conduct Your business
            or otherwise use Platform;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.2.2. will not use Platform in any manner that could interfere
            with, disrupt, negatively affect or inhibit other Users from fully
            using our Platform, or that could damage, disable, overburden or
            impair the functioning of Platform in any manner;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.2.3. will not use Platform for, support or otherwise engage in any
            illegal activities, including fraud, money-laundering or terrorist
            financing activities;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.2.4. will not use or attempt to use another User&apos;s Account
            without authorization;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.2.5. will not develop any third-party applications that interact
            with Platform without our prior written consent.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.2.6. will not provide false, inaccurate, or misleading
            information;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.2.7. will not encourage or induce any third party to engage in any
            of the activities prohibited under this Section.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.3. LATGALA OÜ does not offer NFT staking services to individuals
            who are located in the United States or who are US persons as
            defined under applicable US laws and regulations. By using our
            platform, you confirm that you are not located in the United States
            or a US person.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            6. USER ACCOUNT
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            <b>6.1. MetaMask, WalletConnect and Venly</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.1.1. In order for you to access the Platform and engage in
            transactions on the Platform, you may need to use third-party
            services, such as browser extensions or login services provided by
            our partners. These third-party services may include but are not
            limited to MetaMask, WalletConnect, and Venly. If you do not use
            these third-party services, you may not be able to engage in any
            transactions on the Platform. It is your sole responsibility to
            ensure that you have access to any necessary third-party services in
            order to enable transactions on the Platform. For more information
            about the collection, use, and management of your data, please
            review our{' '}
            <Link to={ROUTER_PATHS.ROSE_PRIVACY_POLICY}>privacy policy</Link>
            {''}.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>6.2. User Account and Security</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.2.1. In order to use the Platform, you must connect to the
            Platform via a third-party account registered with MetaMask,
            WalletConnect, or Venly, and then you will be directed to your User
            Profile (the User Account). Unless otherwise indicated in the
            Platform or otherwise provided by the Company, no further details
            are needed for User Account registration. However, the Company may
            indicate that additional information such as name, surname, email,
            or other contact information, and the creation of a username are
            needed for registration and use of the Platform. All tokens to and
            from the Platform can be respectively made with your MetaMask,
            WalletConnect or Venly account. It is your responsibility to ensure
            the security of, and your continuous control over, any device or
            account associated with the enhanced security features. If you lose
            access to your MetaMask, WalletConnect, or Venly account, you will
            not be able to access the User Account or the Platform. The Company
            is not responsible for any losses or risks associated with lost
            access to User Account or the Platform.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.2.2. Please note, that upon request of competent governmental
            authority, in compliance of applicable laws or due to the change of
            the policies of the Company, the Company can request You to provide
            additional information and/or proceed with identity verification and
            other screening procedures with respect to Your User Account
            creation or further use of the User Account and the Platform, or
            transactions associated with You or Your User Account. If You fail
            to provide requested information, the Company is entitled to
            suspend, restrict, cancel and/or terminate Your User Account and/or
            use of the Platform and You shall bear all loses, damages and costs
            in relation thereof.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.2.3. User is responsible for restricting unauthorized access to
            the computer, other device or respectively User’s MetaMask,
            WalletConnect or Venly to prevent unauthorised access to the User’s
            Account and should inform the Company immediately if the User has
            any reason to believe that User’s password has become known to
            anyone else, or if the password is being, or is likely to be, used
            in an unauthorised manner.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.2.4. The Company prioritizes maintaining the safety of Users’
            Account, however, the Company does not guarantee, that User Account
            may not be breached, accessed or exploited by third parties or they
            function without flaws or errors, therefore there still exist risk
            regarding Your User Account, User Content and other personal or
            non-public information may be accessed, used, leaked, exploited or
            stolen by unauthorized persons. Therefore, You confirm Your
            understanding, that the Company does not guarantee security of Your
            User Account and You need to independently take safety precautions
            to protect Your own User Account and personal information.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.2.5. Users’ Accounts can only be used by the person whose name
            they are registered under. The Company reserves the right to
            suspend, freeze or cancel accounts that are used by persons other
            than the persons whose names they are registered under.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.2.6. The Company may, in its sole discretion, refuse to allow the
            establishment of a User Account or suspend existing User Account and
            use of the Platform. We also reserve the right to revoke User
            Account or access to or use Platform, and those of any and all
            linked accounts without warning if, in our sole discretion, false or
            misleading information has been provided in creating, marketing, or
            maintaining your account.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>6.3. Agency</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.3.1. If You create User Account as an employee, agent or other
            representative (the Agent) of and on behalf of a natural or legal
            person or a business (the Principal), You represent and warrant that
            You are authorized to enter into binding contracts, including these
            Terms, on behalf of yourself and the Principal. In case a User is
            acting as the Agent, the Company is not going to investigate,
            review, analyze or otherwise check or engage into relationship of
            the Agent and the Principal. The legal relationship between Agent
            and Principal is considered as sole responsibility of the Agent and
            Principal and Company is not responsible and not a party to such
            legal relationship.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>6.4. Responsibility for Account Activities</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.4.1. You will be bound by, and hereby authorize Company to accept
            and rely on agreements, instructions, orders, offers, purchases,
            authorizations, transactions and any other actions made, provided or
            taken by anyone who has accessed or used Your User Account. If Your
            User Account has been breached, You shall notify Company immediately
            about such breach, and if there is sufficient proof of the breach
            and the Company can identify Users (e.g. due to the lack of provided
            information during registration at the Platform, there may not be
            enough information to identify specific User in order to make
            certain actions), the Company may decide to suspend the User Account
            and, if legally and/or technically possible to revoke or cancel
            actions taken by You. Upon receipt of written notice from You that
            the security of Your account has been compromised, the Company may
            take reasonable steps to protect Your account.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>6.5. Deletion of User Account and Information</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.5.1. If, User Account is inactive for period of 6 months, then the
            Company at is sole discretion is entitled to delete User Account and
            information about such User without separate notification to such
            User.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.5.2. User may also request the Company to delete User Content and
            other information, which is the Company can delete, e.g. Company
            cannot delete information, which is on public blockchain.
            Nevertheless, the Company may keep and use such information about
            User and User Content, that the Company under applicable laws has
            legal background to hold.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>6.6. Privacy Policy</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            6.6.1. Please refer to Platform{' '}
            <Link to={ROUTER_PATHS.ROSE_PRIVACY_POLICY}>Privacy Policy</Link>
            {''} for information about how Your information is collected and
            used by <Link to={ROUTER_PATHS.ROSE_PRIVACY_POLICY}>Us</Link>
            {''} . By agreeing to these Terms, among other Additional Policies,
            You also fully agree to the Platform Privacy Policy, including
            collection, use, storage, and disclosure of your data. Your personal
            information will be collected and managed in compliance with
            applicable laws.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            7. OWNERSHIP OF THE PLATFORM
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            7.1. You acknowledge and agree that the Company (or, as applicable,
            our licensors) owns all legal rights, title’s and interests in and
            to all elements of the Platform, including the Works, graphics,
            design, systems, methods, information, computer code, software,
            services, “look and feel”, organization, a compilation of the
            content, code, data, and all other elements of the Platform
            (collectively, the “<b>Company Materials</b>”). Company Materials
            are protected by copyright, trade dress, patent, and trademark laws,
            international conventions, other relevant intellectual property and
            proprietary rights, and applicable laws. All Company Materials are
            the copyrighted property of the Company or its licensors, and all
            trademarks, service marks, and trade names contained in the Company
            Materials are proprietary to Company or its licensors. Your use of
            the Platform does not grant you ownership of or any other rights
            with respect to any Company Materials, other content, code, data, or
            other materials that you may access on or through the Platform. For
            the avoidance of doubt, this Section does not limit rights granted
            under the NFT License.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            8. OTHER TERMS
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            <b>8.1. Gas and other Fees</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            8.1.1 All User transactions on the Platform, which are facilitated
            by smart contracts existing on the ETH network, requires the payment
            of a transaction fee (a “Gas Fee”) for every transaction that occurs
            on the ETH network, and thus respectively on the Platform. The value
            of the Gas Fee changes, often unpredictably, and is entirely outside
            of the control of the Company. The User acknowledges that under no
            circumstances will a contract, agreement, offer, sale or other
            transaction on the Platform be invalidated, revocable, retractable,
            or otherwise unenforceable on the basis that the Gas Fee for the
            given transaction was unknown, too high, or otherwise unacceptable
            to a User. Users also acknowledge and agree that gas is
            non-refundable under all circumstances.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            8.1.2. Your wallet address controller or other third parties may
            impose fees in relation to Your transactions. Any fees imposed by
            such third party providers may not be reflected on the transaction
            screens provided in the Platform. You are solely responsible for
            paying any fees imposed by third party providers.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>8.2. Taxes</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            8.2.1. Each User acknowledges and agrees that User is responsible to
            pay any and all sales, use, value-added, income, profit and other
            taxes, duties, and assessments now or hereafter claimed or imposed
            by any governmental authority associated with your use of the
            Platform (including, without limitation, any taxes that may become
            payable as the result of your ownership, transfer, purchase, sale,
            or other transaction made, including in respect of NFT, SPORT or
            other tokens).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>8.3. User Content</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            8.3.1. The Company without any payments, compensations and royalties
            to You will own all rights to any User Content, suggestions and
            information posted on the Platform or otherwise made available to
            the maximum extent allowed under applicable laws. The Company will
            be entitled to the unrestricted use, except as restricted under
            applicable laws, of such User Content information for any purpose,
            commercial or otherwise. User Content may be used only in accordance
            with the privacy policy and other regulations in respect of personal
            data as applicable to the Company.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>8.4. Third Party Content</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            8.4.1. While using the Platform, You may view content provided by
            third parties, including links to web pages, social media networks,
            platforms and other source not controlled by the Company (the
            Third-Party Content). The Company does not control, endorses or
            adopts any Third-Party Content and shall have no responsibility for
            Third-Party Content, including without limitation material that may
            be misleading, incomplete, erroneous, offensive, indecent or
            otherwise objectionable.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>8.5. Changes, Suspension or Termination of the Platform</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            8.5.1. The Company may, at its discretion with or without prior
            notice and at any time at its sole discretion, modify, suspend or
            discontinue, temporarily or permanently, all or any part of the
            Platform, including the functionalities and services available and
            provided by the Company thereat.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>8.6. Error Correction Attempts</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            8.6.1. The Company may, at its option and discretion, may attempt to
            correct, reverse or cancel any transfer regarding to which the
            Company has discovered that there was an error, whether such error
            was by You or a third party, or there was illegal activity included
            due to which transfer was made. You hereby authorize the Company to
            attempt any such correction, reversal or cancellation. The Company
            provides no guarantee or warranty that any such attempt will be
            successful or made at all and will have no responsibility or
            liability for the error or any correction attempt or inaction to
            make any correction or attempt.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            9. DISCLAIMER OF WARRANTIES
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            9.1. To the maximum extent permitted under applicable laws, the
            Platform (including all and any services, products, functions and
            assistances provided) is provided on an “as is” and “as available”
            basis and the Company expressly disclaims and You fully and
            unconditionally waive, any and all other warranties of any kind,
            whether express or implied, including, without limitation, implied
            warranties of merchantability, fitness for a particular purpose,
            title or non-infringement or warranties arising from course of
            performance, course of dealing or usage in trade of otherwise.
            Without limiting the foregoing, the Company does not represent or
            warrant that the Platform is accurate, complete, reliable,
            error-free, continuously functioning without any interruptions,
            disruptions or is free of viruses or other harmful components.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            9.2. You acknowledge all the risks mentioned above and that there
            are risks associated with utilizing an internet-based systems (such
            as Platform) including, but not limited to, the failure of hardware,
            software, smart contract and internet connections and You accept
            such risks. You further acknowledge that the Company shall not be
            responsible for any failures, disruptions, errors, distortions or
            delays in respect of the Platform, which You may experience,
            howsoever caused.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            10. LIMITATION OF LIABILITY
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            10.1. The Company and all Affiliated Persons under these Terms are
            fully, irrevocably and unconditionally released from all and any
            liability and damages to the maximum extent allowed under applicable
            laws (including without limitation damages for loss of information,
            revenue or business, indirect, incidental, special, punitive,
            consequential or similar damages) arising out of or in connection
            with Your use of the Platform, including, but not limiting to the
            Company services, transactions in relation to NFT(-s), and other the
            Company and Affiliated Persons obligations, performance and
            non-performance thereof, whether under contractual, tort, statue,
            strict liability or other theory (including, for avoidance of doubt,
            any negligence of Company and the Affiliated Persons), except to the
            extent of a final judicial determination that such damages were a
            result of the Company’s gross negligence, fraud, willful misconduct
            or intentional violation of law.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.2. For the avoidance of doubt, above indicated limitation of
            liability means, that among other matters, the Company and
            Affiliated Persons are also not liable for any damages or losses
            (direct and indirect) arising out of or in connection with:
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.2.1 Your use of or your inability to use the Platform, including
            suspension or termination of the Platform;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.2.2. delays or disruptions in the Platform;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.2.3. viruses or other malicious software obtained by accessing,
            or linking to the Platform (including here and elsewhere - services
            related to the Platform);
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.2.4. glitches, bugs, errors, or inaccuracies of any kind in the
            Platform;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.2.5. damage to your hardware device from the use of the Platform;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.2.6. the content, actions, or inactions of third parties’ use of
            Platform;
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.2.7. a suspension or other action taken with respect to Your User
            Account.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.3. The Company will have no liability (i) for any failure or
            delay in performance of undertakings in respect of the Platform and
            its Users, under these Terms and any other obligations, or (ii) for
            any loss or damage (direct and indirect) that You may incur,
            including due to any circumstance or event beyond the control of the
            Company, including without limitation any flood, extraordinary
            weather conditions, earthquake, viruses, state or municipal
            institutions actions, other force majeure events or fire, war,
            insurrection, riot, accident, action of government, spread of
            viruses, pandemics, communications, power failure, or equipment or
            software malfunction.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            10.4. In no event shall the liability of Company and any Affiliated
            Person, arising out of or in connection with the use of Platform or
            under these Terms, any performance or non-performance thereof,
            whether under contract, tort, strict liability or other theory
            (including, for avoidance of doubt, any negligence) exceed the
            amount of the Company fees / payments received by Company from User
            in the 12-month period immediately preceding the event giving rise
            to the claim for liability.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            11. INDEMNIFICATION
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            11.1. You will defend, indemnify, and hold harmless the Company and
            all the Affiliated Persons from all and any claim, demand, lawsuit,
            action, proceeding, investigation, liability, damage, loss, cost or
            expense, including without limitation reasonable attorneys’ fees,
            arising out or relating to (a) Your use of Platform, including
            actions and in-actions made via Platform; (b) any User Content or
            information You make available; (c) Your violation of these Terms;
            or (d) Your violation of any applicable law or the rights or
            interests of any other person or entity. If You are obligated to
            indemnify the Company or Affiliated Person, the Company and
            Affiliated Persons will have the right, in its sole discretion, to
            control any action or proceeding and to determine whether to settle,
            and if so, on what terms.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            12. GOVERNING LAW AND DISPUTE RESOLUTION
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            12.1. These Terms, use of Platform and all rights and obligations
            arising thereof shall be governed, construed and interpreted in
            accordance with the Laws of the Republic of Lithuania without regard
            to conflict of law rules or principles, that would cause the
            application of the laws of any other jurisdiction than Lithuania,
            and excluding the United Nations Convention on Contracts for the
            International Sale of Goods (CISG).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            12.2. Any dispute arising out of or in connection with this
            Agreement shall be finally settled by arbitration in Vilnius Court
            of Commercial Arbitration in accordance with its Rules of
            Arbitration. The number of arbitrators shall be three. The venue of
            arbitration shall be Vilnius, Lithuania. The language of arbitration
            shall be English. Any dispute shall be governed by the law of
            Lithuania. In case the Parties to the dispute do not appoint a
            respective arbitrator in a timely manner, such arbitrator (which may
            also include the chairman) shall be appointed by the Chairman of
            Vilnius Court of Commercial Arbitration. Lithuanian law shall be
            applicable to the dispute. The language of arbitration shall be
            English. All documents to the User shall be delivered to e-mail at
            the addresses indicated by respective parties and in case of Company
            – to support@lympo.io.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            12.3. The User, in case of arbitration, undertakes to provide e-mail
            for arbitration purposes.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            12.4. You agree that any dispute between You and the Company arising
            out of or related to these Terms and use of the Platform shall be
            resolved solely through individual arbitration and shall not be
            brought as a class arbitration, class action or any other type of
            collective basis. There will be no right or authority for any
            dispute to be brought, heard or arbitrated as a class or collective
            action, or as a member in any such class or collective proceeding.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            12.5. All disputes between the Company and any User shall be settled
            in a manner indicated in this Section. However, before applying to
            court (arbitrage) disputes between any User and Company firstly
            shall be settled in mutual negotiations of the Company and a User
            and in informal manner and only if such dispute is not settled
            within 30 days any party may apply to arbitrage.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            13. TERMINATION OF THE TERMS AND SURVIVAL
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            13.1. You may terminate these Terms at any time by cancelling your
            User Account on the Platform and discontinuing your access to and
            use of the Platform. You will not receive any refunds if you cancel
            your account, or otherwise terminate these Terms. You agree that We,
            in our sole discretion and for any or no reason, may at any time
            terminate these Terms and suspend and/or terminate Your User Account
            on the Platform.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            13.2. The following clauses and the clauses, that by their nature
            contemplate survival, shall survive and continue in full force and
            effect after termination or expiry of these Terms. For example, the
            provisions regarding dispute resolutions, applicable law, limitation
            of liability, indemnification. Without limiting any other provisions
            of the Terms, the termination of these Terms for any reason will not
            release You or the Company from any obligations incurred prior to
            termination of these Terms or that thereafter may accrue in respect
            of any act or omission prior to such termination.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            14. FINAL PROVISIONS
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            14.1. These Terms between You and the Company set forth the entire
            agreement and understanding relating to the subject matter herein
            and supersedes all prior or contemporaneous disclosures,
            discussions, understandings and agreements, whether oral or written,
            between them.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            14.2. If any provision of these Terms is held invalid, illegal or
            unenforceable, the validity, legality and enforceability of the
            remaining provisions of these Terms shall not in any way be affected
            or impaired.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            14.3. You may not assign or transfer any rights or obligations under
            these Terms without prior written consent from the Company,
            including by operation of law or in connection with any change of
            control. The Company is entitled to assign or transfer any or all of
            its rights or obligations under these Terms, in whole or in part,
            without notice or obtaining Your consent or approval.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            14.4. These Terms shall bind and benefit all the permitted
            successors and assignees.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            14.5. The failure of one party to require performance of any
            provision will not affect that party’s right to require performance
            at any time thereafter. At the same time, the waiver of one party to
            seek recovery for the other party’s violation of these Terms or any
            provision of applicable terms shall not constitute a waiver by that
            party of any subsequent breach or violation by the other party or of
            the provision itself.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            14.6. Headings of sections are for convenience only and shall not be
            used to limit or construe such sections.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            14.7. All appendixes (if any) to these Terms shall be considered as
            integral and inseparable part of these Terms.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            If you have questions regarding these Terms, please feel free to
            contact the Company for clarification via{' '}
            <a href="mailto:support@lympo.io">support@lympo.io</a>. Please check
            the Platform frequently for any changes to these Terms.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            APPENDIX 1: DEFINITIONS
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            <b>1. Definitions</b>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            1.1. Wherever used in these Terms, capitalized terms shall have the
            following meaning:
          </Typography>
          <div className={classes.table}>
            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  Affiliate(-s)
                </Typography>
              </div>
              <Typography variant={'body1'}>
                means Company’s respective past, present, and future employees,
                officers, directors, contractors, licensors, consultants,
                advisors, shareholders, equity holders, parent companies,
                subsidiaries, attorneys, agents, representatives, predecessors,
                community members, successors and assigns.
              </Typography>
            </div>
            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  Company, We or Us
                </Typography>
              </div>
              <Typography variant={'body1'}>
                means Latgala OÜ, registered address at Tornimäe tn 5, Tallinn,
                Estonia. Contact e-mail{' '}
                <a href="mailto:support@lympo.io">support@lympo.io</a>.
              </Typography>
            </div>
            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  ETH
                </Typography>
              </div>
              <Typography variant={'body1'}>
                means Ether the native cryptocurrency of the Ethereum
                blockchain.
              </Typography>
            </div>
            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  SPORT
                </Typography>
              </div>
              <Typography variant={'body1'}>
                means SPORT Token(-s), based on ERC-20 standard
              </Typography>
            </div>
            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  NFT
                </Typography>
              </div>
              <Typography variant={'body1'}>
                means digital blockchain collectible represented as non-fungible
                token(-s) based on ERC-1155 standard, which is (are) associated
                with the Work and which is (are) minted by the Company while
                using smart contract.
              </Typography>
            </div>

            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  Platform
                </Typography>
              </div>
              <Typography variant={'body1'}>
                means a platform, programming interface (API), applications,
                materials, any products, services, solutions and other items
                provided by or on behalf of the Company and available through
                website{' '}
                <a
                  href="HTTPS://DIGISPORT.XYZ/ROSE"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  HTTPS://DIGISPORT.XYZ/ROSE
                </a>{' '}
                and any affiliated mobile websites and mobile applications,
                owned and/or operated by the Company.
              </Typography>
            </div>
            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  Third-party company/service
                </Typography>
              </div>
              <Typography variant={'body1'}>
                separate legal entity that has entered into a service agreement
                with LATGALA OÜ to provide a service that enables users to
                perform additional actions (e.g. buy cryptocurrency on the
                platform using credit or debit card or enable users to sign up
                and log in with social accounts and create WEB3 wallets). This
                company is not owned or controlled by LATGALA OÜ, and LATGALA OÜ
                assumes no responsibility or liability for the services provided
                by the third-party company. Users who choose to use the services
                of the third-party company may be subject to additional terms
                and conditions, fees, and risks, which are governed solely by
                the third-party company&apos;s policies and agreements. LATGALA
                OÜ strongly recommends that users carefully review and
                understand the terms and conditions of the third-party company
                before using their services.
              </Typography>
            </div>
            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  User
                </Typography>
              </div>
              <Typography variant={'body1'}>
                means a User of the Platform, whether registered or unregistered
                user.
              </Typography>
            </div>
            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  User Content
                </Typography>
              </div>
              <Typography variant={'body1'}>
                means any information, content, comments, remarks, data, text or
                images, that User posts to any part of Platform or provide to
                Company.
              </Typography>
            </div>
            <div className={classes.tableRow}>
              <div className={classes.tableRowFirstItem}>
                <Typography
                  variant={'body1'}
                  className={classes.tableRowFirstTitle}
                >
                  Work
                </Typography>
              </div>
              <Typography variant={'body1'}>
                means any painting, picture, image, design, art, video or other
                work in a type or form or media, which is associated with NFT.
              </Typography>
            </div>
          </div>
        </div>
      </main>
    </Container>
  )
}
