import { FC } from 'react'
import { makeStyles } from 'styles/makeStyles'
import Button from '@mui/material/Button'
import { AddCircleFillIcon, RemoveCircleFillIcon } from 'assets/icons'
import Typography from '@mui/material/Typography'
import { fontSpaceMono } from 'styles/fonts'

const useStyles = makeStyles({ name: 'IncrementDecrementCounter' })(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 1),
      gap: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: theme.spacing(2),
      color: theme.palette.grey[400],
      border: `1px solid ${theme.palette.grey[400]}`,
      minWidth: 160,
      width: 160,
    },
    active: {
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.white}`,
    },
    btn: {
      padding: 0,
      width: 24,
      minWidth: 24,
      height: 24,
      color: theme.palette.grey[400],

      svg: {
        width: 24,
        minWidth: 24,
        height: 24,
      },
    },
    btnActive: {
      color: `${theme.palette.common.white} !important`,
    },
    text: {
      lineHeight: '32px',
      fontFamily: fontSpaceMono,
    },
  })
)

interface Props {
  className?: string
  count: number
  maxCount: number
  onIncrementButtonClick: () => void
  onDecrementButtonClick: () => void
  isDisabled?: boolean
}

export const IncrementDecrementCounter: FC<Props> = ({
  className,
  count = 0,
  maxCount = 0,
  onIncrementButtonClick,
  onDecrementButtonClick,
  isDisabled,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(
        classes.root,
        count > 0 && classes.active,
        className && className
      )}
    >
      <Button
        variant={'text'}
        className={cx(
          classes.btn,
          count > 0 && !isDisabled && classes.btnActive
        )}
        onClick={onDecrementButtonClick}
        disabled={isDisabled || count <= 0}
      >
        <RemoveCircleFillIcon />
      </Button>
      <Typography variant={'h5'} className={classes.text}>
        {count}
      </Typography>
      <Button
        variant={'text'}
        className={cx(
          classes.btn,
          count <= maxCount && !isDisabled && classes.btnActive
        )}
        onClick={onIncrementButtonClick}
        disabled={isDisabled || count >= maxCount || maxCount <= 0}
      >
        <AddCircleFillIcon />
      </Button>
    </div>
  )
}
