export const LINKS_ANCHOR = {
  inventoryNftStickers: {
    id: 'inventory-nft-stickers',
    href: '#inventory-nft-stickers',
  },
  inventoryFighterAvatars: {
    id: 'inventory-fighter-avatars',
    href: '#inventory-fighter-avatars',
  },
  inventoryNftFusion: {
    id: 'inventory-nft-fusion',
    href: '#inventory-nft-fusion',
  },
  inventoryItems: {
    id: 'inventory-items',
    href: '#inventory-items',
  },
  inventoryCertificate: {
    id: 'inventory-certificate',
    href: '#inventory-certificate',
  },
  buildPacks: {
    id: 'build-packs',
    href: '#build-packs',
  },
  roseCollectionPageHeading: {
    id: 'rose-collection-page-heading',
    href: '#rose-collection-page-heading',
  },
  roseHIW: {
    id: 'rose-hiw',
    href: '#rose-hiw',
  },
  roseItems: {
    id: 'rose-items',
    href: '#rose-items',
  },
  roseFAQ: {
    id: 'rose-faq',
    href: '#rose-faq',
  },
}
