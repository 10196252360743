import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { RoseBreadcrumbs } from '@pages/Rose/components/RoseBreadcrumbs'
import { roseCollectionDetailsUtils } from './utils'
import { FC } from 'react'
import { Typography } from '@mui/material'
import { ROSE_NFT_COLLECTION_DETAILS_CONTENT } from './constants'
import { capitalizeFirstLetter } from 'utils/formatValue'
import { fontSpaceMono } from 'styles/fonts'
import { linearGradients } from 'styles/other'
import Button from '@mui/material/Button'
import { ArrowRightLineIcon } from 'assets/icons'
import { RoseFighterStepItemAction } from '@pages/Rose/components/RoseFighterItems/RoseFighterStepItemAction'
import { NftGroupRarity } from 'models/INfts'
import { RoseAllItemsBanner } from '@pages/Rose/components/RoseAllItemsBanner'
import { AppAlertCircle } from 'components/common'
import RoseNftCollectionClaimModal from '@pages/Rose/RoseNftCollectionDetails/RoseNftCollectionClaimModal/RoseNftCollectionClaimModalContainer'
import { SwitchableNetworkButton } from 'components/ConnectButton'
import { validChainId } from 'constants/validChainId'

const useStyles = makeStyles({ name: 'RoseCollectionDetailsHeading' })(
  (theme) => ({
    root: {
      padding: theme.spacing(10, 0, 20),
      width: '100%',
    },
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(4, 0, 0),
      gap: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: theme.spacing(3),
      },
    },
    content: {
      maxWidth: 608,

      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    title: {
      fontSize: 64,
      lineHeight: '80px',
    },
    subtitle: {
      color: theme.palette.secondary.light,
      lineHeight: '32px',
      margin: theme.spacing(1, 0, 3),
      fontWeight: 700,
      fontFamily: fontSpaceMono,
    },
    contentInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 0,
        alignItems: 'flex-start',
      },
    },
    contentInfoItem: {
      fontWeight: 700,
      fontFamily: fontSpaceMono,
      position: 'relative',

      '&:not(:last-of-type)::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: -16,
        width: 1,
        height: '100%',
        background: linearGradients.backgroundLinesVertical,

        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    contentDescription: {
      margin: theme.spacing(2, 0, 3),
      fontWeight: 700,
    },
    contentBtn: {
      maxWidth: 358,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(1),

      svg: {
        minWidth: 24,
        width: 24,
        height: 24,
      },
    },
    imageContainer: {
      position: 'relative',
      padding: theme.spacing(0, 0, 0, 5),

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3, 0, 0, 0),
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 1,
        height: '100%',
        background: linearGradients.backgroundLinesVertical,

        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: 1,
          background: linearGradients.backgroundLinesHorizontal,
        },
      },
    },
  })
)

interface Props {
  paramId: string
  isCompleted: boolean
  isApproved: boolean
  isOpenClaimModal: boolean
  setIsOpenClaimModal: (isOpen: boolean) => void
  balanceById: Record<number, number>
  selectedItems: Record<number, number>
  isPrevCompleted: boolean
  updateSelectedItems: () => void
  onAddToLocalStorage: (id: number) => void
  onRemoveFromLocalStorage: (id: number) => void
  approveLoading: boolean
  onApprove: () => void
  isValidChainId: boolean
  ownedGeneral: number
}
export const RoseCollectionDetailsHeadingComponent: FC<Props> = ({
  paramId,
  isCompleted,
  isApproved,
  isOpenClaimModal,
  setIsOpenClaimModal,
  balanceById,
  selectedItems,
  isPrevCompleted,
  updateSelectedItems,
  onAddToLocalStorage,
  onRemoveFromLocalStorage,
  approveLoading,
  onApprove,
  isValidChainId,
  ownedGeneral,
}) => {
  const { classes } = useStyles()

  const { title, subtitle, description, allItems, prizeUrlImage, rarityId } =
    ROSE_NFT_COLLECTION_DETAILS_CONTENT[capitalizeFirstLetter(paramId)]

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth={'lg'}>
          <RoseBreadcrumbs
            items={roseCollectionDetailsUtils.getBreadcrumb(paramId)}
          />
          <div className={classes.container}>
            <div className={classes.content}>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="h5" className={classes.subtitle}>
                {subtitle}
              </Typography>
              <div className={classes.contentInfo}>
                <Typography variant="body2" className={classes.contentInfoItem}>
                  Stickers you own: {ownedGeneral}
                </Typography>
              </div>
              <Typography
                variant="body1"
                className={classes.contentDescription}
              >
                {description}
              </Typography>
              {isValidChainId ? (
                <>
                  {isCompleted && (
                    <AppAlertCircle
                      severity="success"
                      title={'You have completed the collection!'}
                      sx={{ mb: 3 }}
                    />
                  )}
                  {!isCompleted && (
                    <Button
                      className={classes.contentBtn}
                      variant="contained"
                      size={'large'}
                      disabled
                    >
                      <span>Collect all to claim</span>
                      <ArrowRightLineIcon />
                    </Button>
                  )}
                  {isCompleted && !isApproved && (
                    <Button
                      className={classes.contentBtn}
                      variant="contained"
                      size={'large'}
                      disabled={!isCompleted}
                      onClick={onApprove}
                    >
                      <span>Approve</span>
                      <ArrowRightLineIcon />
                    </Button>
                  )}
                  {isCompleted && isApproved && (
                    <Button
                      className={classes.contentBtn}
                      variant="contained"
                      size={'large'}
                      onClick={() => setIsOpenClaimModal(true)}
                    >
                      <span>Claim</span>
                      <ArrowRightLineIcon />
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <SwitchableNetworkButton
                    validChain={validChainId}
                    className={classes.contentBtn}
                    size={'large'}
                  />
                </>
              )}
            </div>
            <div className={classes.imageContainer}>
              <RoseFighterStepItemAction
                groupRarity={capitalizeFirstLetter(paramId) as NftGroupRarity}
                balanceById={balanceById}
                selectedItems={selectedItems}
                onAddBtnClick={onAddToLocalStorage}
                startClaimRequest={() => setIsOpenClaimModal(true)}
                onRemoveBtnClick={onRemoveFromLocalStorage}
                isCompleted={isCompleted}
                isPrevCompleted={isPrevCompleted}
                isApproved={isApproved}
                onApprove={onApprove}
                isLoading={approveLoading}
              />
            </div>
          </div>
        </Container>
      </div>
      <RoseAllItemsBanner
        title={allItems.title}
        description={allItems.description}
        btnCaption={allItems.btnCaption}
        href={allItems.href}
      />
      <RoseNftCollectionClaimModal
        isOpen={isOpenClaimModal}
        onClose={() => setIsOpenClaimModal(false)}
        prizeImageUrl={prizeUrlImage}
        athleteId={rarityId}
        updateLocalStorage={updateSelectedItems}
        title={title}
      />
    </>
  )
}
