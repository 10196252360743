import { makeStyles } from 'styles/makeStyles'
import {
  ArrowRightLineIcon,
  ConnectIcon,
  CopyIcon,
  UserIcon,
  WalletIcon,
} from 'assets/icons'
import Button from '@mui/material/Button'
import { linearGradients } from 'styles/other'
import Typography from '@mui/material/Typography'
import { selectUserAddress } from 'store/reducers/user/selectors'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { transformTextToWithDots } from 'utils/transformTextToWithDots'
import { enqueueSnack } from 'store/reducers/snackbar/actions'
import { disconnect } from 'store/reducers/user/actions'
import { fontSpaceMono } from 'styles/fonts'
import { Link } from 'react-router-dom'
import { ROUTER_PATHS } from 'routes/routerPaths'
import Box from '@mui/material/Box'

const useStyles = makeStyles({ name: 'RoseHeaderUserInfo' })((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  content: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(5),
    alignItems: 'center',
    position: 'relative',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
  },
  icon: {
    minWidth: 24,
    width: 24,
    height: 24,
  },
  userBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  btn: {
    textTransform: 'none',
    fontSize: 14,
    lineHeight: '24px',
  },
  address: {
    fontWeight: 700,
  },
  balancesBlock: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    position: 'relative',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },
  },
  balancesItem: {
    width: '45%',
  },
  balanceTitle: {
    fontFamily: fontSpaceMono,
  },
  balanceValue: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',

    svg: {
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },
  btnTop: {
    width: '100%',
    justifyContent: 'space-between',
  },
  link: {
    width: '100%',
  },
  displayNoneMd: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

export const RoseHeaderUserInfo = () => {
  const { classes, cx } = useStyles()
  const dispatch = useAppDispatch()

  const userAddress = useAppSelector(selectUserAddress)

  const onCopyClick = () => {
    navigator.clipboard.writeText(userAddress || '')
    dispatch(enqueueSnack({ message: 'Copied!', variant: 'info' }))
  }

  const onDisconnectClick = () => {
    dispatch(disconnect())
  }

  return (
    <div className={classes.root}>
      <div className={cx(classes.content, classes.displayNoneMd)}>
        <Link to={ROUTER_PATHS.ROSE_PROFILE} className={classes.link}>
          <Button variant="text" className={cx(classes.btn, classes.btnTop)}>
            <Box
              component={'span'}
              display={'flex'}
              alignItems={'center'}
              gap={1}
            >
              <UserIcon className={classes.icon} /> Profile
            </Box>
            <ArrowRightLineIcon className={classes.icon} />
          </Button>
        </Link>
      </div>
      <div className={classes.content}>
        <div className={classes.userBlock}>
          <WalletIcon className={classes.icon} />
          <div>
            <Typography variant="subtitle2">Wallet address</Typography>
            <Typography variant="body1" className={classes.address}>
              {transformTextToWithDots(userAddress, 6)}
            </Typography>
          </div>
        </div>
        <Button variant="text" className={classes.btn} onClick={onCopyClick}>
          <CopyIcon className={classes.icon} />
          <span>Copy</span>
        </Button>
      </div>
      <Button
        variant="text"
        className={classes.btn}
        onClick={onDisconnectClick}
      >
        <ConnectIcon className={classes.icon} />
        <span>Log out</span>
      </Button>
    </div>
  )
}
