import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  isLoggedInSelector,
  selectUserChainId,
} from 'store/reducers/user/selectors'
import { validChainId } from 'constants/validChainId'
import { setIsValidChain } from 'store/reducers/user/actions'

export const useCheckValidChainId = () => {
  const dispatch = useAppDispatch()
  const userChainId = useAppSelector(selectUserChainId)
  const isLoggedIn = useAppSelector(isLoggedInSelector)

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(setIsValidChain(false))
    }

    if (userChainId == validChainId) {
      dispatch(setIsValidChain(true))
    } else {
      dispatch(setIsValidChain(false))
    }
  }, [userChainId, isLoggedIn])
}
