import { AppDispatch } from '../../store'
import { appSlice } from './slice'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { ModalName } from 'constants/modals'
import { IAppBalances, IAppWhiteList } from 'store/reducers/app/types'
import { termsAndConditionsIdx } from 'constants/termsAndConditions'

export const setAppLoading =
  (flag: boolean | undefined) => async (dispatch: AppDispatch) => {
    dispatch(appSlice.actions.setAppLoading(flag))
  }

export const setReFetchFlag =
  (flagName: ReFetchFlag) => async (dispatch: AppDispatch) => {
    dispatch(appSlice.actions.setReFetchFlag(flagName))
  }

export const setModal =
  (modalName: ModalName, value: boolean) => async (dispatch: AppDispatch) => {
    dispatch(appSlice.actions.setModal({ modalName, value }))
  }

export const setWhiteList =
  (data: IAppWhiteList) => async (dispatch: AppDispatch) => {
    dispatch(appSlice.actions.setWhiteList(data))
  }

export const setBalances =
  (data: IAppBalances) => async (dispatch: AppDispatch) => {
    dispatch(appSlice.actions.setBalances(data))
  }

export const setTermsAndConditions =
  (temrsKey: termsAndConditionsIdx, isAccepted: boolean, sigMsg: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(
      appSlice.actions.setTermsAndConditions({
        temrsKey,
        isAccepted,
        sigMsg,
      })
    )
  }
