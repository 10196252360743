import { useMutation } from '@tanstack/react-query'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectUserAddress } from 'store/reducers/user/selectors'
import { contractAccountApi } from 'services/api/contract/account'
import { enqueueSnack } from 'store/reducers/snackbar/actions'
import isFunction from 'lodash/isFunction'
import { setProfileUserInfo } from 'store/reducers/user/actions'
import { httpGeneralApi } from 'services/api/http/general'
import { IUserInfo } from 'models/IUserInfo'
import { apiUrls } from 'constants/apiUrls'
import { queryClient } from 'services/providers'
import { cacheKeys } from 'constants/cacheKeys'
import { USER_INFO_SIG_MSG } from 'constants/sigMsg'

export const useMutation_GetUserInfo_UserInfo = (onSuccessCb?: () => void) => {
  const userAddress = useAppSelector(selectUserAddress)
  const dispatch = useAppDispatch()

  return useMutation(
    async () => {
      const currentTimestampInSeconds = Math.round(Date.now() / 1000)
      const messageToSign = `${USER_INFO_SIG_MSG} ${userAddress} ${currentTimestampInSeconds}`

      const signedMessage = await contractAccountApi.signMessage(
        userAddress as string,
        messageToSign
      )

      const { userInfo } = await httpGeneralApi.postDataByUrl<{
        userInfo: IUserInfo
      }>(`${apiUrls.userInfo}/user_info?user_address=${userAddress}`, {
        messageToSign,
        signedMessage,
      })

      dispatch(setProfileUserInfo(userInfo))

      return userInfo
    },
    {
      onSuccess: async () => {
        isFunction(onSuccessCb) && onSuccessCb()

        dispatch(
          enqueueSnack({
            message: `Success`,
            variant: 'success',
          })
        )
      },
      onError: () => {
        dispatch(
          enqueueSnack({
            message: `Error, please try later.`,
            variant: 'error',
          })
        )
      },
    }
  )
}

export const useMutation_SetUserInfo_UserInfo = (onSuccessCb?: () => void) => {
  const userAddress = useAppSelector(selectUserAddress)
  const dispatch = useAppDispatch()

  return useMutation(
    async (data: { userData: IUserInfo }) => {
      if (!data.userData) {
        dispatch(
          enqueueSnack({
            message: `Error , please try later.`,
            variant: 'error',
          })
        )

        return
      }

      const currentTimestampInSeconds = Math.round(Date.now() / 1000)
      const messageToSign = `${USER_INFO_SIG_MSG} ${userAddress} ${currentTimestampInSeconds}`

      const signedMessage = await contractAccountApi.signMessage(
        userAddress as string,
        messageToSign
      )

      const userInfo = await httpGeneralApi.postDataByUrl<IUserInfo>(
        `${apiUrls.userInfo}/user_info_update?user_address=${userAddress}`,
        {
          messageToSign,
          signedMessage,
          userInfo: data.userData,
        }
      )

      dispatch(setProfileUserInfo(userInfo))
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          cacheKeys.userInfo.isUserInfoExists,
        ])

        isFunction(onSuccessCb) && onSuccessCb()

        dispatch(
          enqueueSnack({
            message: `Success`,
            variant: 'success',
          })
        )
      },
      onError: () => {
        dispatch(
          enqueueSnack({
            message: `Error, please try later.`,
            variant: 'error',
          })
        )
      },
    }
  )
}
