import { Navigate, useParams } from 'react-router-dom'
import { NftGroupRarity } from 'models/INfts'
import { ROUTER_PATHS } from 'routes/routerPaths'
import { FC } from 'react'

interface NftDetailsRouteProps {
  children: JSX.Element
}

const isNftGroupRarityValid = (rarityId: string): boolean => {
  const validRarities = [
    NftGroupRarity.Beginner,
    NftGroupRarity.Intermediate,
    NftGroupRarity.Advanced,
    NftGroupRarity.Elite,
  ]

  return validRarities.some(
    (rarity) => rarity.toLowerCase() === rarityId.toLowerCase()
  )
}

export const ProtectedRoseNftDetailsRoute: FC<NftDetailsRouteProps> = ({
  children,
}) => {
  const { id = '' } = useParams()

  if (isNftGroupRarityValid(id)) {
    return children
  }

  return <Navigate to={ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS} />
}
