import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { fontSpaceMono } from 'styles/fonts'
import { FC } from 'react'
import Typography from '@mui/material/Typography'
import { RoseInventoryHeadingLine } from '@pages/Rose/RoseInventory/RoseInventoryHeadingLine'
import { NftBreedingTinyItem } from 'components/Modals/NftBreedingModal/NftBreedingTinyItem'
import Button from '@mui/material/Button'
import { NftBreedingItem } from 'components/Modals/NftBreedingModal/NftBreedingItem'
import { NftAvatar } from 'models/INfts'

const useStyles = makeStyles({ name: 'NftBreedingSelectStep' })((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 2),
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  subtitle1: {
    fontFamily: fontSpaceMono,
    textTransform: 'uppercase',
    color: theme.palette.success.main,
    lineHeight: '40px',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    fontSize: 48,
    fontWeight: 800,
    lineHeight: '56px',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  titleLine: {
    margin: theme.spacing(0, 0, 2),
  },
  topListContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  topList: {
    display: 'flex',
    gap: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      width: '100%',
      paddingBottom: theme.spacing(1),
    },

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1),
    },
  },
  topListTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: 523,
  },
  topListText: {
    lineHeight: '32px',
    fontWeight: 700,

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '24px',
    },
  },
  topListBtnContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  btn: {
    width: '100%',
    textTransform: 'none',
  },
  selectedList: {
    display: 'flex',
    width: '100%',
    gap: theme.spacing(2),
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

const sxClasses = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    pt: 3,
  },
}

interface Props {
  className?: string
  title: string
  nftList: NftAvatar[]
  selectedNft: Array<null | NftAvatar>
  onAutoFillBtnClick: () => void
  onBreedNftBtnClick: () => void
  isCompleted: boolean
  onSelectNftBtnClick: (nft: NftAvatar) => void
  onUnSelectNftBtnClick: (nft: NftAvatar) => void
}

export const NftBreedingSelectStep: FC<Props> = ({
  className,
  title,
  nftList,
  selectedNft,
  onAutoFillBtnClick,
  onBreedNftBtnClick,
  isCompleted,
  onSelectNftBtnClick,
  onUnSelectNftBtnClick,
}) => {
  const { classes, cx } = useStyles()

  const isSelected = (nft: NftAvatar) => {
    return selectedNft.some((item) => item?.id === nft.id)
  }

  return (
    <>
      <Container maxWidth="lg" sx={sxClasses.container}>
        <div className={cx(classes.root, className && className)}>
          <div className={classes.textContainer}>
            <Typography variant={'subtitle1'} className={cx(classes.subtitle1)}>
              NFT Fusion
            </Typography>
            <Typography variant={'h2'} className={cx(classes.title)}>
              Upgrade your NFTs
            </Typography>
          </div>
          <RoseInventoryHeadingLine
            title={'Slots required for fusion'}
            className={cx(classes.titleLine)}
          />
          <div className={classes.topListContainer}>
            <div className={classes.topList}>
              {selectedNft.map((nft, idx) => (
                <NftBreedingTinyItem
                  title={title}
                  key={idx}
                  data={nft}
                  onRemoveBtnClick={onUnSelectNftBtnClick}
                />
              ))}
            </div>
            <div className={classes.topListTextContainer}>
              <Typography variant={'h6'} className={cx(classes.topListText)}>
                Fusing lower-level fighters will give you the chance to enter
                the ranks of higher-level fighters, and reach the ultimate
                Legendary tier! Will you rise to the challenge?
              </Typography>
              <div className={classes.topListBtnContainer}>
                <Button
                  variant="contained"
                  onClick={() => onBreedNftBtnClick()}
                  className={classes.btn}
                  disabled={!isCompleted}
                >
                  Fuse NFTs
                </Button>
                <Button
                  variant="contained"
                  onClick={() => onAutoFillBtnClick()}
                  className={classes.btn}
                  disabled={isCompleted}
                >
                  Auto fill
                </Button>
              </div>
            </div>
          </div>
          <RoseInventoryHeadingLine
            title={'Available for fusion'}
            className={cx(classes.titleLine)}
          />
          <div className={classes.selectedList}>
            {nftList.map((nft, idx) => (
              <NftBreedingItem
                title={title}
                key={idx}
                data={nft}
                onSelectBtnClick={onSelectNftBtnClick}
                onUnSelectBtnClick={onUnSelectNftBtnClick}
                isDisabledSelect={isCompleted}
                isSelected={isSelected(nft)}
              />
            ))}
          </div>
        </div>
      </Container>
    </>
  )
}
