import { RoseProfileLayout } from '../RoseProfileLayout'
import { RoseProfileInnerLayout } from '../RoseProfileInnerLayout'
import { RoseProfileDetailsComponent } from './RoseProfileDetailsComponent'
import { useAppSelector } from 'store/hooks'
import {
  isLoggedInSelector,
  selectProfileIsExists,
  selectProfileUserInfo,
} from 'store/reducers/user/selectors'
import { useMutation_GetUserInfo_UserInfo } from 'services/useApi/userInfo/useMutation'
import { useNavigate } from 'react-router-dom'
import { ROUTER_PATHS } from 'routes/routerPaths'

export const RoseProfileDetailsContainer = () => {
  const isExists = useAppSelector(selectProfileIsExists)
  const userInfo = useAppSelector(selectProfileUserInfo)

  const navigate = useNavigate()

  const isLoggedIn = useAppSelector(isLoggedInSelector)

  const { mutateAsync, isLoading } = useMutation_GetUserInfo_UserInfo()

  const handleSignMessage = async () => {
    if (!isExists) {
      navigate(ROUTER_PATHS.ROSE_PROFILE_EDIT)
      return
    }
    await mutateAsync()
  }

  return (
    <RoseProfileLayout>
      <RoseProfileInnerLayout>
        <RoseProfileDetailsComponent
          isExists={isExists}
          isLoading={isLoading}
          userInfo={userInfo}
          onRequestDataBtnClick={handleSignMessage}
          isLoggedIn={isLoggedIn}
        />
      </RoseProfileInnerLayout>
    </RoseProfileLayout>
  )
}
