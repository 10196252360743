import { FC, memo, useEffect, useMemo, useState } from 'react'
import { StepsOpenMysteryBoxModal } from './constants'
import OpenMysteryBoxCalcModal from './OpenMysteryBoxCalcModal'
import WrapperLargeModal from 'components/Modals/WrapperLargeModal'
import { PrizeLoaderBig } from 'components/Loaders'
import { OpenMysteryBoxOpeningStep } from 'components/Modals/OpenMysteryBoxModal/OpenMysteryBoxOpeningStep'
import { OpenMysteryBoxSuccessStep } from 'components/Modals/OpenMysteryBoxModal/OpenMysteryBoxSuccessStep'
import { useMutation_OpenBox_OpenBox } from 'services/useApi/openBox/useMutation'
import {
  useQuery_BalanceOfBatch_Erc1155,
  useQuery_IsApproved_Erc1155,
} from 'services/useApi/erc1155/useQuery'
import { getCacheKey } from 'utils/getCacheKey'
import {
  ROSE_COLLECTION_CONTRACT_ADDRESS,
  ROUTER_CONTRACT_ADDRESS,
} from 'constants/addresses'
import { useMutation_Approve_Erc1155 } from 'services/useApi/erc1155/useMutation'
import { queryClient } from 'services/providers'
import { cacheKeys } from 'constants/cacheKeys'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { useAppSelector } from 'store/hooks'
import { reFetchedFlagSelector } from 'store/reducers/app/selectors'
import { isEqual } from 'lodash'

interface Props {
  isOpen: boolean
  onClose: () => void
  own: number
  boxImageUrl: string
  boxImageBgUrl: string
  name: string
  id: number
}

const OpenMysteryBoxModalContainer: FC<Props> = ({
  isOpen,
  onClose,
  own,
  boxImageUrl,
  boxImageBgUrl,
  name,
  id,
}) => {
  const [step, setStep] = useState<null | StepsOpenMysteryBoxModal>(null)
  const [amount, setAmount] = useState(1)
  const [athleteStickersIds, setAthleteStickersIds] = useState<number[]>([])
  const [itemStickersIds, setItemStickersIds] = useState<number[]>([])
  const reFetchedFlag = useAppSelector(reFetchedFlagSelector)

  useEffect(() => {
    if (!isOpen) {
      setStep(null)
      setAmount(1)
    } else {
      setStep(StepsOpenMysteryBoxModal.CALCULATING)
    }
  }, [isOpen])

  const {
    data: isApproved = false,
    isInitialLoading: isApprovedInitialLoading,
  } = useQuery_IsApproved_Erc1155(
    getCacheKey('IsApproved_Erc1155', 'openMysteryBoxModalContainer', id),
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    ROUTER_CONTRACT_ADDRESS
  )

  const { mutateAsync: approveMutateAsync, isLoading: approveLoading } =
    useMutation_Approve_Erc1155(async () => {
      await queryClient.invalidateQueries([
        getCacheKey('IsApproved_Erc1155', 'openMysteryBoxModalContainer', id),
      ])
    })

  const { mutateAsync: openBoxMutateAsync } = useMutation_OpenBox_OpenBox(
    () => {
      setStep(StepsOpenMysteryBoxModal.OPENING)
    },
    () => {
      setStep(StepsOpenMysteryBoxModal.CALCULATING)
    }
  )

  const { data: balancesAthleteStickers = [] } =
    useQuery_BalanceOfBatch_Erc1155(
      getCacheKey(
        cacheKeys.erc1155.balanceOfBatch,
        'openMysteryBoxModal',
        'balancesAthleteStickers',
        id
      ),
      athleteStickersIds,
      ROSE_COLLECTION_CONTRACT_ADDRESS,
      reFetchedFlag[ReFetchFlag.ClaimMerch]
    )

  const balancesAthleteStickersById = useMemo(() => {
    return balancesAthleteStickers.reduce((acc, item, index) => {
      acc[athleteStickersIds[index]] = item
      return acc
    }, {} as Record<number, number>)
  }, [balancesAthleteStickers, athleteStickersIds])

  const { data: balancesItemStickers = [] } = useQuery_BalanceOfBatch_Erc1155(
    getCacheKey(
      cacheKeys.erc1155.balanceOfBatch,
      'openMysteryBoxModal',
      'balancesItemStickers',
      id
    ),
    itemStickersIds,
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    reFetchedFlag[ReFetchFlag.ClaimMerch]
  )

  const balancesItemStickersById = useMemo(() => {
    return balancesItemStickers.reduce((acc, item, index) => {
      acc[itemStickersIds[index]] = item
      return acc
    }, {} as Record<number, number>)
  }, [balancesItemStickers, itemStickersIds])

  const openBoxRequest = async () => {
    setStep(StepsOpenMysteryBoxModal.LOADING)
    const result = await openBoxMutateAsync({ amount, boxId: id })

    setAthleteStickersIds(result.athleteStickersIds)
    setItemStickersIds(result.itemStickersIds)
  }

  const handleClose = () => {
    onClose()
    setStep(null)
  }

  return (
    <>
      <OpenMysteryBoxCalcModal
        isOpen={step === StepsOpenMysteryBoxModal.CALCULATING}
        onClose={handleClose}
        amount={amount}
        setAmount={setAmount}
        name={name}
        own={own}
        boxImageUrl={boxImageUrl}
        onConfirm={openBoxRequest}
        isApproved={isApproved}
        isLoading={isApprovedInitialLoading || approveLoading}
        onApproveBtnClick={async () => {
          await approveMutateAsync({
            nftContractAddress: ROSE_COLLECTION_CONTRACT_ADDRESS,
            toAddress: ROUTER_CONTRACT_ADDRESS,
          })
        }}
      />
      <WrapperLargeModal
        isOpen={
          step === StepsOpenMysteryBoxModal.LOADING ||
          step === StepsOpenMysteryBoxModal.SUCCESS ||
          step === StepsOpenMysteryBoxModal.OPENING
        }
        onClose={handleClose}
        isLoading={
          step === StepsOpenMysteryBoxModal.LOADING ||
          step === StepsOpenMysteryBoxModal.OPENING
        }
      >
        <>
          {step === StepsOpenMysteryBoxModal.LOADING && (
            <PrizeLoaderBig title={'Opening Mystery Boxes'} />
          )}
          {step === StepsOpenMysteryBoxModal.OPENING && (
            <OpenMysteryBoxOpeningStep
              imageUrl={boxImageUrl}
              boxImageBgUrl={boxImageBgUrl}
              onNext={() => setStep(StepsOpenMysteryBoxModal.SUCCESS)}
              disabled={!balancesAthleteStickers.length}
            />
          )}
          {step === StepsOpenMysteryBoxModal.SUCCESS && (
            <OpenMysteryBoxSuccessStep
              onClose={handleClose}
              athleteStickersIds={athleteStickersIds}
              itemStickersIds={itemStickersIds}
              balancesAthleteStickersById={balancesAthleteStickersById}
              balancesItemStickersById={balancesItemStickersById}
            />
          )}
        </>
      </WrapperLargeModal>
    </>
  )
}

const arePropsEqual = (prevProps: Props, nextProps: Props) => {
  return isEqual(prevProps, nextProps)
}

const OpenMysteryBoxModal = memo(OpenMysteryBoxModalContainer, arePropsEqual)

export default OpenMysteryBoxModal
