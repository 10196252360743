import { makeStyles } from 'styles/makeStyles'
import { FC, ReactNode } from 'react'
import { MainLandingGridHeroes } from './MainLandingGridHeroes'
import { publicImages } from 'utils/getPublicImages'
import { Footer } from 'components/Footer'

const useStyles = makeStyles({ name: 'MainLandingLayoutBottom' })((theme) => ({
  main: {
    position: 'relative',
    overflow: 'hidden',
    background: `
        url('${publicImages.getBackground(
          'main-landing/main-landing-bottom-bg.webp'
        )}')
        no-repeat
      `,
    backgroundPositionX: `calc(100% + 400px)`,
    backgroundPositionY: `calc(100% + 210px)`,
  },
  content: {
    minHeight: '100vh',
    position: 'relative',
    zIndex: 5,
  },
  animationGrid: {
    position: 'absolute',
    bottom: theme.spacing(10),
    left: '50%',
    zIndex: 1,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

interface Props {
  children: ReactNode
}

export const MainLandingLayoutBottom: FC<Props> = ({ children }) => {
  const { classes } = useStyles()

  return (
    <main className={classes.main}>
      <div className={classes.content}>{children}</div>
      <Footer />
      <MainLandingGridHeroes className={classes.animationGrid} />
    </main>
  )
}
