import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { FadeInComponent } from 'components/Animation/FadeInComponent'
import { useInViewTrigger } from 'hooks/useInViewTrigger'
import { linearGradients } from 'styles/other'
import { publicImages } from 'utils/getPublicImages'

const useStyles = makeStyles({ name: 'MainLandingMerch' })((theme) => ({
  root: {
    padding: theme.spacing(23.25, 0, 13.75),
    background: `
        url('${process.env.PUBLIC_URL}/images/backgrounds/main-landing/merch-bg.webp') 
        center left 
        no-repeat
      `,
    backgroundSize: 'contain',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 0),
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 524,
  },
  subtitle1: {
    fontFamily: fontSpaceMono,
    textTransform: 'uppercase',
    color: theme.palette.info.light,
    lineHeight: '40px',
  },
  description: {
    fontWeight: 700,
    lineHeight: '32px',
    margin: theme.spacing(4, 0, 5),
  },
  btn: {
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    maxWidth: 358,
    width: '100%',

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
  table: {
    display: 'flex',
    position: 'relative',
    paddingLeft: theme.spacing(8),

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 5,

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 1,
      height: '100%',
      background: 'rgba(255,255,255,0.4)',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: 1,
      height: '100%',
      background: 'rgba(255,255,255,0.4)',
    },
  },
  item: {
    padding: theme.spacing(1),
    position: 'relative',

    '&:first-of-type::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -64,
      width: 'calc(100% + 64px)',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: -64,
      width: 'calc(100% + 64px)',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },

    img: {
      width: 96,
      height: 96,
      border: `2px solid ${theme.palette.common.white}`,
      borderRadius: theme.spacing(1),
    },
  },
  heroImage: {
    width: 579,
    minWidth: 579,
    transform: 'rotate(-7.5deg)',
    transition: 'transform 0.5s ease-in-out',
    marginLeft: theme.spacing(-5.5),
    willChange: 'transform',
  },
  heroImageAnimated: {
    transform: 'rotate(0)',
  },
}))

export const MainLandingMerch = () => {
  const { classes, cx } = useStyles()

  const { ref, trigger } = useInViewTrigger(600)

  const imgHero = (
    <img
      src={`${publicImages.getIllustration(
        'rose/stickers/sticker-hoodie.webp'
      )}`}
      alt={'petals'}
    />
  )

  return (
    <div className={classes.root}>
      <Container maxWidth={'lg'}>
        <div className={classes.container}>
          <div className={classes.content}>
            <Typography variant={'subtitle1'} className={classes.subtitle1}>
              Merch:
            </Typography>
            <Typography variant={'h2'}>
              Collect <br /> Your Own
            </Typography>
            <Typography variant={'h6'} className={classes.description}>
              Become the owner of exclusive merch signed by the Rose Namajunas
              herself - limited-edition hoodies, t-shirts, and caps. Collect 5
              item stickers and immediately claim prizes.
            </Typography>
          </div>
          <div className={classes.table}>
            <div className={classes.col}>
              <div className={classes.item}>
                <FadeInComponent variant={'left'} msDelay={200}>
                  <>{imgHero}</>
                </FadeInComponent>
              </div>
              <div className={classes.item}>
                <FadeInComponent variant={'left'} msDelay={400}>
                  <>{imgHero}</>
                </FadeInComponent>
              </div>
              <div className={classes.item}>
                <FadeInComponent variant={'left'} msDelay={600}>
                  <>{imgHero}</>
                </FadeInComponent>
              </div>
              <div className={classes.item}>
                <FadeInComponent variant={'left'} msDelay={800}>
                  <>{imgHero}</>
                </FadeInComponent>
              </div>
              <div className={classes.item}>
                <FadeInComponent variant={'left'} msDelay={1000}>
                  <>{imgHero}</>
                </FadeInComponent>
              </div>
            </div>
            <img
              ref={ref}
              src={`${process.env.PUBLIC_URL}/images/illustrations/main-landing/skinny-rose.webp`}
              alt={'rose'}
              className={cx(
                classes.heroImage,
                trigger && classes.heroImageAnimated
              )}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
