import { makeStyles } from 'styles/makeStyles'
import { FC, ReactNode } from 'react'
import { publicImages } from 'utils/getPublicImages'
import { RoseHeader } from '../RoseHeader'
import { RoseCollectionTabs } from './constants'
import { Footer } from 'components/Footer'

const useStyles = makeStyles({ name: 'RoseCollectionLayout' })(() => ({
  main: {
    position: 'relative',
  },
  bgFighter: {
    background: `
        url('${publicImages.getBackground('rose/collection-head-bg.webp')}') 
        top center 
        no-repeat,
        url('${publicImages.getBackground('rose/collection-bottom-bg.webp')}') 
        bottom center 
        no-repeat
      `,
  },
  bgMerch: {
    background: `
        url('${publicImages.getBackground(
          'rose/collection-head-merch-bg.webp'
        )}') 
        top center 
        no-repeat,
        url('${publicImages.getBackground('rose/collection-bottom-bg.webp')}') 
        bottom center 
        no-repeat
      `,
  },
}))

interface Props {
  children: ReactNode
  currentTab: string
}

export const RoseCollectionLayout: FC<Props> = ({ children, currentTab }) => {
  const { classes, cx } = useStyles()

  return (
    <main
      className={cx(
        classes.main,
        currentTab === RoseCollectionTabs.FighterNft && classes.bgFighter,
        currentTab === RoseCollectionTabs.Merch && classes.bgMerch
      )}
    >
      <RoseHeader />
      <>{children}</>
      <Footer />
    </main>
  )
}
