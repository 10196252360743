import axios from 'axios'
import { apiUrls } from 'constants/apiUrls'
import { ITermsList } from 'models/ITermsAndConditions'

export const httpTermsAndConditionsTrackerApi = {
  async getTermsList(): Promise<ITermsList[]> {
    const { data } = await axios.get<ITermsList[]>(
      `${apiUrls.termsAndConditionsApiUrl}/terms`
    )

    return data
  },

  async getIsAcceptedTermsAndConditions(
    userAddress: string,
    sigMsg: string
  ): Promise<boolean> {
    const { data } = await axios.post<{ isExists: boolean }>(
      `${
        apiUrls.termsAndConditionsApiUrl
      }/sign/user/${userAddress.toLowerCase()}`,
      {
        sigMsg,
      }
    )

    return data?.isExists
  },

  async setAcceptanceTermsAndConditions(
    userAddress: string,
    sigMsg: string,
    signature: string
  ) {
    return await axios.put<string>(
      `${
        apiUrls.termsAndConditionsApiUrl
      }/sign/user/${userAddress.toLowerCase()}`,
      {
        sigMsg,
        signature,
      }
    )
  },
}
