import { Currency } from 'constants/currency'

const getItemImage = (image: string) =>
  `${process.env.PUBLIC_URL}/images/illustrations/main-landing/packs/${image}`

export const BUY_PACKS_DEFAULT_INFO = {
  subtitle: 'Buy ‘em now',
  title: 'Mystery Boxes',
  bottomTitle:
    'Purchase your box on Polygon network with FIAT or Crypto tokens:',
  description:
    'Acquire limited-edition Mystery Boxes and unlock the components of a fighter spirit hidden inside! Each mystery box contains Fighter avatar stickers and a chance at item sticker. ',
  list: [
    {
      id: 1,
      name: 'Bronze',
      description:
        'Unleash your inner fighter with 2 guaranteed beginner Avatar stickers, a chance at a bonus higher-level Avatar stickers, and a chance at an item sticker.',
      imageUrl: getItemImage('box-bronze.webp'),
      price: 4.99,
    },
    {
      id: 2,
      name: 'Silver',
      description:
        'Take your skills to the next level with 3 guaranteed Intermediate Avatar stickers, a chance at a bonus higher-level Avatar sticker, and a chance at an item sticker.',
      imageUrl: getItemImage('box-silver.webp'),
      price: 24.99,
    },
    {
      id: 3,
      name: 'Gold',
      description:
        'Level up with 4 Advanced Avatar stickers, a chance at a bonus higher-level Avatar stickers, a guaranteed item sticker and a chance at a bonus item sticker.',
      imageUrl: getItemImage('box-gold.webp'),
      price: 99.99,
    },
    {
      id: 4,
      name: 'Diamond',
      description:
        'Unleash your true potential with 5 elite stickers, a chance at bonus Avatar sticker of lower-level Avatar, and guaranteed 4 item stickers for the ultimate success. ',
      imageUrl: getItemImage('box-diamond.webp'),
      price: 399.0,
    },
  ],
  currencyList: [
    Currency.Sport,
    Currency.WETH,
    Currency.USDT,
    Currency.Sand,
    Currency.ApeCoin,
  ],
}
