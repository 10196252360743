import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { RoseBreadcrumbs } from '@pages/Rose/components/RoseBreadcrumbs'
import { roseCollectionDetailsUtils } from './utils'
import { FC, useState } from 'react'
import { Typography } from '@mui/material'
import { itemStickersPathId, ROSE_MERCH_DETAILS_CONTENT } from './constants'
import { capitalizeFirstLetter } from 'utils/formatValue'
import { fontSpaceMono } from 'styles/fonts'
import { linearGradients } from 'styles/other'
import { RoseMerchDetailsHeadingImage } from './RoseMerchDetailsHeadingImage'
import { RoseCertificate } from '@pages/Rose/components/RoseCertificate'
import { RoseNftTinyAction } from '@pages/Rose/components/RoseNfts/RoseNftTinyAction'
import RoseClaimItemActionModal from '@pages/Rose/components/RoseClaimItemActionModal'
import RoseMerchDetailsClaimModal from '@pages/Rose/RoseMerchDetails/RoseMerchDetailsClaimModal/RoseMerchDetailsClaimModalContainer'
import RoseClaimDontOwnModal from '@pages/Rose/components/RoseClaimDontOwnModal'
import { RoseMerchDetailsHeadingActions } from './RoseMerchDetailsHeadingActions'

const useStyles = makeStyles({ name: 'RoseMerchDetailsHeading' })((theme) => ({
  root: {
    padding: theme.spacing(10, 0, 6),
    width: '100%',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(4, 0, 0),
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
  },
  content: {
    maxWidth: 608,

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  title: {
    fontSize: 64,
    lineHeight: '80px',
  },
  subtitle: {
    color: theme.palette.secondary.light,
    lineHeight: '32px',
    margin: theme.spacing(1, 0, 3),
    fontWeight: 700,
    fontFamily: fontSpaceMono,
  },
  contentInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 0,
      alignItems: 'flex-start',
    },
  },
  contentInfoItem: {
    fontWeight: 700,
    fontFamily: fontSpaceMono,
    position: 'relative',

    '&:not(:last-of-type)::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: -16,
      width: 1,
      height: '100%',
      background: linearGradients.backgroundLinesVertical,

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  contentDescription: {
    margin: theme.spacing(2, 0, 3),
    fontWeight: 700,
  },
  imageContainer: {
    position: 'relative',
    padding: theme.spacing(0, 0, 0, 5),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 0, 0, 0),
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 1,
      height: '100%',
      background: linearGradients.backgroundLinesVertical,

      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 1,
        background: linearGradients.backgroundLinesHorizontal,
      },
    },
  },
  itemList: {
    display: 'flex',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(5.5),

    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
    },
  },
}))

interface Props {
  paramId: string
  balanceLength: number
  selectedLength: number
  selectedItems: Record<number, number>
  isCompleted: boolean
  onAddToLocalStorage: (id: number) => void
  onRemoveFromLocalStorage: (id: number) => void
  isOpenClaimModal: boolean
  setIsOpenClaimModal: (isOpen: boolean) => void
  updateSelectedItems: () => void
  isReadyToSelect: boolean
  balanceById: Record<number, number>
  modalState: null | 'item' | 'itemRemove'
  setModalState: (state: null | 'item' | 'itemRemove') => void
  isApproved: boolean
  approveLoading: boolean
  onApprove: () => void
  isValidChainId: boolean
  isLoading: boolean
  isExists: boolean
}
export const RoseMerchDetailsHeadingComponent: FC<Props> = ({
  paramId,
  balanceLength,
  selectedLength,
  selectedItems,
  isCompleted,
  onAddToLocalStorage,
  onRemoveFromLocalStorage,
  isOpenClaimModal,
  setIsOpenClaimModal,
  updateSelectedItems,
  isReadyToSelect,
  balanceById,
  modalState,
  setModalState,
  isApproved,
  approveLoading,
  onApprove,
  isValidChainId,
  isLoading,
  isExists,
}) => {
  const { classes } = useStyles()

  const { title, description, stickerImgUrl, merchImgUrl, itemStickerId } =
    ROSE_MERCH_DETAILS_CONTENT[capitalizeFirstLetter(paramId)]

  const stickersList: string[] = Array(5).fill(stickerImgUrl)

  const [shouldShowDontOwnModal, setShouldShowDontOwnModal] = useState(false)

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth={'lg'}>
          <RoseBreadcrumbs
            items={roseCollectionDetailsUtils.getBreadcrumb(paramId)}
          />
          <div className={classes.container}>
            <div className={classes.content}>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
              <div className={classes.contentInfo}>
                <Typography variant="body2" className={classes.contentInfoItem}>
                  Stickers you own:{' '}
                  {balanceById[itemStickersPathId[paramId]] || 0}
                </Typography>
              </div>
              <Typography
                variant="body1"
                className={classes.contentDescription}
              >
                {description}
              </Typography>
              <div className={classes.itemList}>
                {stickersList.map((item, idx) => {
                  return (
                    <RoseNftTinyAction
                      key={idx}
                      isActive={idx < balanceLength && isValidChainId}
                      isChecked={
                        !!selectedItems[itemStickersPathId[paramId]] &&
                        idx < selectedLength &&
                        isValidChainId
                      }
                      imageUrl={item}
                      isSmall
                      onAddBtnClick={() => {
                        if (idx < balanceLength) {
                          setModalState('item')
                        } else {
                          setShouldShowDontOwnModal(true)
                        }
                      }}
                      onRemoveBtnClick={() => {
                        setModalState('itemRemove')
                      }}
                      isDisabled={isLoading || !isValidChainId}
                    />
                  )
                })}
              </div>
              <RoseMerchDetailsHeadingActions
                isCompleted={isCompleted}
                setIsOpenClaimModal={setIsOpenClaimModal}
                isApproved={isApproved}
                onApprove={onApprove}
                isValidChainId={isValidChainId}
                isLoading={isLoading}
                isExists={isExists}
              />
            </div>
            <div className={classes.imageContainer}>
              <RoseMerchDetailsHeadingImage
                path={paramId}
                isCompleted={isCompleted}
                isReadyToSelect={isReadyToSelect}
              />
            </div>
          </div>
        </Container>
      </div>
      {modalState !== null && (
        <RoseClaimItemActionModal
          isOpen={!!modalState}
          onClose={() => setModalState(null)}
          onConfirm={() => {
            onAddToLocalStorage(itemStickersPathId[paramId])
          }}
          startRequest={() => setIsOpenClaimModal(true)}
          onRemove={() => {
            onRemoveFromLocalStorage(itemStickersPathId[paramId])
          }}
          type={modalState}
          title={title}
          imageUrl={stickerImgUrl}
          prizeImageUrl={merchImgUrl}
          own={balanceById[itemStickersPathId[paramId]] || 0}
          isSuccessful={selectedLength === 4}
          isApproved={isApproved}
          isLoading={approveLoading}
          onApprove={onApprove}
          isExists={isExists}
        />
      )}
      <RoseClaimDontOwnModal
        isOpen={shouldShowDontOwnModal}
        onClose={() => setShouldShowDontOwnModal(false)}
        title={title}
        imageUrl={stickerImgUrl}
        own={balanceById[itemStickersPathId[paramId]] || 0}
        itemId={itemStickerId}
      />

      <RoseMerchDetailsClaimModal
        isOpen={isOpenClaimModal}
        onClose={() => setIsOpenClaimModal(false)}
        itemId={itemStickerId}
        updateLocalStorage={updateSelectedItems}
      />
      <RoseCertificate />
    </>
  )
}
