import { NftGroupRarity } from 'models/INfts'
import { useQuery_GetCollection_Collection } from 'services/useApi/collection/useQuery'
import { useMemo, useState } from 'react'
import { IFilterFighterAvatars } from './constants'
import { RoseInventoryFighterAvatarsComponent } from './RoseInventoryFighterAvatarsComponent'
import { MenuInventory } from '../constants'

export const RoseInventoryFighterAvatarsContainer = () => {
  const { data, isInitialLoading } = useQuery_GetCollection_Collection()
  const [filter, setFilter] = useState<IFilterFighterAvatars>({
    [NftGroupRarity.Beginner]: false,
    [NftGroupRarity.Intermediate]: false,
    [NftGroupRarity.Advanced]: false,
    [NftGroupRarity.Elite]: false,
    [NftGroupRarity.Legendary]: false,
  })

  const isFilterActive = !!filter && Object.values(filter).some((v) => v)

  const beginnerList = useMemo(() => {
    if (!!data?.beginnerNft.length) {
      return data.beginnerNft.filter(
        () => !isFilterActive || !!filter[NftGroupRarity.Beginner]
      )
    } else {
      return []
    }
  }, [data, isFilterActive, filter])

  const intermediateList = useMemo(() => {
    if (!!data?.intermediateNft.length) {
      return data.intermediateNft.filter(
        () => !isFilterActive || !!filter[NftGroupRarity.Intermediate]
      )
    } else {
      return []
    }
  }, [data, isFilterActive, filter])

  const advancedList = useMemo(() => {
    if (!!data?.advancedNft.length) {
      return data.advancedNft.filter(
        () => !isFilterActive || !!filter[NftGroupRarity.Advanced]
      )
    } else {
      return []
    }
  }, [data, isFilterActive, filter])

  const eliteList = useMemo(() => {
    if (!!data?.eliteNft.length) {
      return data.eliteNft.filter(
        () => !isFilterActive || !!filter[NftGroupRarity.Elite]
      )
    } else {
      return []
    }
  }, [data, isFilterActive, filter])

  const legendaryList = useMemo(() => {
    if (!!data?.legendaryNft.length) {
      return data.legendaryNft.filter(
        () => !isFilterActive || !!filter[NftGroupRarity.Legendary]
      )
    } else {
      return []
    }
  }, [data, isFilterActive, filter])

  const isEmptyList = useMemo(() => {
    return (
      !beginnerList.length &&
      !intermediateList.length &&
      !advancedList.length &&
      !eliteList.length &&
      !legendaryList.length
    )
  }, [beginnerList, intermediateList, advancedList, eliteList, legendaryList])

  return (
    <RoseInventoryFighterAvatarsComponent
      filter={filter}
      setFilter={setFilter}
      isFilterActive={isFilterActive}
      beginnerList={beginnerList}
      intermediateList={intermediateList}
      advancedList={advancedList}
      eliteList={eliteList}
      legendaryList={legendaryList}
      isEmptyList={isEmptyList}
      isInitialLoading={isInitialLoading}
      activeMenuItem={MenuInventory.FighterAvatars}
    />
  )
}
