import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { Link, NavLink, useMatch } from 'react-router-dom'
import { ROUTER_PATHS } from 'routes/routerPaths'
import { Typography, useMediaQuery, useScrollTrigger } from '@mui/material'
import {
  AppsIcon,
  ArrowRightSmallLineIcon,
  CloseIcon,
  HomeIcon,
  MenuBurgerIcon,
  ShelvesIcon,
  UserIcon,
} from 'assets/icons'
import { Theme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { LogoDigi } from 'assets/logo'
import {
  ConnectButton,
  SwitchableNetworkButton,
} from 'components/ConnectButton'
import { useAppSelector } from 'store/hooks'
import {
  isLoggedInSelector,
  selectIsValidChain,
} from 'store/reducers/user/selectors'
import Button from '@mui/material/Button'
import { RoseHeaderPacks } from './RoseHeaderPacks'
import { useEffect, useState } from 'react'
import RoseHeaderMenu from '@pages/Rose/components/RoseHeader/RoseHeaderMenu'
import { AppAlertCircle, Tooltip } from 'components/common'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { RoseHeaderUserInfo } from '@pages/Rose/components/RoseHeader/RoseHeaderUserInfo'
import { validChainId } from 'constants/validChainId'
import { chainNames } from 'constants/chains'
import { fontNeuePlak } from 'styles/fonts'
import { scrollToTop } from 'utils/scrollToTop'

const useStyles = makeStyles({ name: 'RoseHeader' })((theme) => ({
  root: {
    transition: 'background-color .2s linear',
    borderBottom: `1px solid rgba(255, 255, 255, .4)`,
    willChange: 'background-color',

    [theme.breakpoints.down('sm')]: {
      backgroundColor: `rgba(0, 0, 0, 0.1)`,
      backdropFilter: 'blur(6px)',
    },
  },
  scrolled: {
    backgroundColor: `rgba(0, 0, 0, 0.1)`,
    backdropFilter: 'blur(6px)',

    [theme.breakpoints.down('sm')]: {
      background: 'none',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logoContainer: {
    width: 133,
    height: 32,
    display: 'block',
    marginRight: theme.spacing(4),
    color: theme.palette.text.primary,

    [theme.breakpoints.down('md')]: {
      width: 100,
      height: 24,
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),

    svg: {
      width: 24,
      height: 24,
      minWidth: 24,
    },
  },
  btn: {
    gap: theme.spacing(5),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0),
    },
  },
  btnBurger: {
    padding: 0,
    color: theme.palette.text.primary,

    svg: {
      width: 24,
      height: 24,
      minWidth: 24,
    },
  },
  userBtn: {
    minWidth: 40,
    width: 40,
    height: 40,
  },
  displayNoneMd: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  arrowBack: {
    transform: 'rotate(180deg)',
  },
  alertContainer: {
    display: 'none',

    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(7, 0, -2),
      position: 'relative',
      zIndex: 8,
    },
  },
  alertTitle: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  alertBtn: {
    fontSize: 12,
    lineHeight: '16px',
    padding: 0,
    margin: 0,
    textDecoration: 'underline',
    fontFamily: fontNeuePlak,
  },
}))

const sxClasses = {
  appBar: {
    backgroundColor: 'transparent',
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%',
    py: { xs: 1, md: 1.5 },
    left: 0,
    top: 0,
  },
}

export const RoseHeader = () => {
  const { classes, cx } = useStyles()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const isValidChain = useAppSelector(selectIsValidChain)

  const handleCloseTooltip = () => {
    setIsOpenTooltip(false)
  }

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  const inventoryPaths = [
    ROUTER_PATHS.ROSE_INVENTORY_NFT_STICKERS,
    ROUTER_PATHS.ROSE_INVENTORY_FIGHTER_AVATARS,
    ROUTER_PATHS.ROSE_INVENTORY_NFT_FUSION,
    ROUTER_PATHS.ROSE_INVENTORY_ITEMS,
    ROUTER_PATHS.ROSE_INVENTORY_CERTIFICATE_NFTS,
  ]

  const matchRoseInventory = inventoryPaths.some((path) =>
    useMatch({
      path,
    })
  )

  const matchRoseCollectionFighters = useMatch({
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS,
  })
  const matchRoseCollectionMerch = useMatch({
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_MERCH,
  })
  const matchRoseCollectionId = useMatch({
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS_ID,
  })
  const matchRoseMerchCollectionId = useMatch({
    path: ROUTER_PATHS.ROSE_NFT_COLLECTION_MERCH_ID,
  })

  const isLoggedIn = useAppSelector(isLoggedInSelector)

  const isMdDownMediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  useEffect(() => {
    setIsMenuOpen(false)
    setIsOpenTooltip(false)
  }, [isMdDownMediaQuery])

  const roseHeaderUserInfoComponent = <RoseHeaderUserInfo />
  const warningAlert = (
    <AppAlertCircle severity={'error'}>
      <Typography
        variant={'subtitle2'}
        className={classes.alertTitle}
        component={'span'}
      >
        <span>
          Wrong network. You need to be on {chainNames[validChainId]}.
        </span>
        <SwitchableNetworkButton
          validChain={validChainId}
          caption={'Switch network'}
          variant={'text'}
          className={classes.alertBtn}
        />
      </Typography>
    </AppAlertCircle>
  )

  return (
    <>
      <AppBar
        position={'fixed'}
        sx={sxClasses.appBar}
        className={cx(classes.root, trigger && classes.scrolled)}
      >
        <Container maxWidth={'lg'}>
          <Box className={classes.container}>
            {/* Logo */}
            <div className={cx(classes.leftContainer)}>
              <NavLink to={ROUTER_PATHS.MAIN} className={classes.logoContainer}>
                <LogoDigi className={classes.logo} />
              </NavLink>
              {!matchRoseInventory && isLoggedIn && isValidChain && (
                <div className={classes.displayNoneMd}>
                  <RoseHeaderPacks />
                </div>
              )}
            </div>
            {/* Connect button */}
            {!isLoggedIn && (
              <div className={cx(classes.content)}>
                {!matchRoseCollectionFighters &&
                  !matchRoseCollectionMerch &&
                  !matchRoseCollectionId &&
                  !matchRoseMerchCollectionId && (
                    <Link to={ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS}>
                      <Button
                        variant="text"
                        sx={{
                          gap: 1,
                          display: {
                            xs: 'none',
                            sm: 'flex',
                          },
                        }}
                      >
                        <AppsIcon />
                        <span>Collection</span>
                      </Button>
                    </Link>
                  )}
                <ConnectButton className={classes.btn} withIcon />
              </div>
            )}
            {/* Alert network */}
            {isLoggedIn && !isValidChain && (
              <div className={classes.displayNoneMd}>{warningAlert}</div>
            )}
            {/* Desktop logged content */}
            {isLoggedIn && (
              <div className={cx(classes.content, classes.displayNoneMd)}>
                {(matchRoseCollectionFighters ||
                  matchRoseCollectionMerch ||
                  matchRoseCollectionId ||
                  matchRoseMerchCollectionId) &&
                  isValidChain && (
                    <Link to={ROUTER_PATHS.ROSE}>
                      <Button variant="text" sx={{ gap: 1 }}>
                        <HomeIcon />
                        <span>Home</span>
                      </Button>
                    </Link>
                  )}
                {!matchRoseCollectionFighters &&
                  !matchRoseCollectionMerch &&
                  !matchRoseCollectionId &&
                  !matchRoseMerchCollectionId && (
                    <Link to={ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS}>
                      <Button
                        variant="text"
                        sx={{
                          gap: 1,
                        }}
                        onClick={() => scrollToTop()}
                      >
                        <AppsIcon />
                        <span>Collection</span>
                      </Button>
                    </Link>
                  )}
                {!matchRoseInventory && isValidChain && (
                  <Link to={ROUTER_PATHS.ROSE_INVENTORY_NFT_STICKERS}>
                    <Button
                      variant="contained"
                      sx={{ gap: 3 }}
                      onClick={() => scrollToTop()}
                    >
                      <span>Inventory</span>
                      <ShelvesIcon />
                    </Button>
                  </Link>
                )}
                {matchRoseInventory && isValidChain && (
                  <Link to={ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS}>
                    <Button
                      variant="contained"
                      sx={{ gap: 3 }}
                      onClick={() => scrollToTop()}
                    >
                      <ArrowRightSmallLineIcon className={classes.arrowBack} />
                      <span>Back to collection</span>
                    </Button>
                  </Link>
                )}
                <ClickAwayListener onClickAway={handleCloseTooltip}>
                  <div>
                    <Tooltip
                      onClose={handleCloseTooltip}
                      open={isOpenTooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={''}
                      isSmall
                      description={[roseHeaderUserInfoComponent]}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.userBtn}
                        onClick={() => setIsOpenTooltip((flag) => !flag)}
                      >
                        <UserIcon />
                      </Button>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </div>
            )}
            {/* Mobile menu button */}
            {isMdDownMediaQuery && isLoggedIn && (
              <IconButton
                className={classes.btnBurger}
                onClick={() => setIsMenuOpen((flag) => !flag)}
              >
                {isMenuOpen ? <CloseIcon /> : <MenuBurgerIcon />}
              </IconButton>
            )}
          </Box>
        </Container>
      </AppBar>
      {isMdDownMediaQuery && (
        <RoseHeaderMenu
          isOpenMenu={isMenuOpen}
          isValidChain={isValidChain}
          isMdDownMediaQuery={isMdDownMediaQuery}
          matchRoseInventory={matchRoseInventory}
          matchRoseCollectionFighters={matchRoseCollectionFighters}
          matchRoseCollectionId={matchRoseCollectionId}
          matchRoseMerchCollectionId={matchRoseMerchCollectionId}
          matchRoseCollectionMerch={matchRoseCollectionMerch}
        />
      )}
      {/* Alert network */}
      {isLoggedIn && !isValidChain && (
        <div className={classes.alertContainer}>{warningAlert}</div>
      )}
    </>
  )
}
