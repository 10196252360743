import { makeStyles } from 'styles/makeStyles'
import { FC, ReactNode } from 'react'
import { ROSE_PROFILE_CONTENT } from './constants'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles({ name: 'RoseProfileInnerLayout' })((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(13),
    minHeight: 'calc(100vh - 370px)',

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(8),
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(5),
    },
  },
  text: {
    maxWidth: 308,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
    },
  },
  title: {
    fontSize: 64,
    lineHeight: '80px',
    fontWeight: 800,
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      fontSize: 40,
      lineHeight: '64px',
    },

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  description: {
    fontWeight: 700,
  },
  bottomDescription: {
    marginTop: theme.spacing(5),
    fontWeight: 700,

    a: {
      textDecoration: 'underline',
    },
  },
}))

interface Props {
  title?: string
  description?: string
  bottomDescription?: string
  children: ReactNode
}

export const RoseProfileInnerLayout: FC<Props> = ({
  title = ROSE_PROFILE_CONTENT.title,
  description = ROSE_PROFILE_CONTENT.description,
  bottomDescription = ROSE_PROFILE_CONTENT.bottomDescription,
  children,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.container}>
          <div className={classes.text}>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Typography
              variant="body2"
              className={classes.bottomDescription}
              dangerouslySetInnerHTML={{ __html: bottomDescription }}
            />
          </div>
          <>{children}</>
        </div>
      </Container>
    </div>
  )
}
