import { makeStyles } from 'styles/makeStyles'
import { MainLandingMysteryPacksInfo } from '../MainLandingMysteryPacksInfo'
import { MainLandingBuildInfo } from '../MainLandingBuildInfo'

const useStyles = makeStyles({ name: 'MainLandingBuildWithPacks' })(() => ({
  buildInfo: {
    position: 'static',
  },
}))

export const MainLandingBuildWithPacks = () => {
  const { classes } = useStyles()

  return (
    <div>
      <MainLandingBuildInfo className={classes.buildInfo} />
      <MainLandingMysteryPacksInfo />
    </div>
  )
}
