import { web3Modal } from 'services/providers'
import { ethersProvider } from 'services/providers'
import { AppDispatch } from 'store/store'
import { userSlice } from './slice'
import { ethers } from 'ethers'
import { contractAccountApi } from 'services/api/contract/account'
import logger from 'services/logger'
import { enqueueSnack } from '../snackbar/actions'
import { transformTextToWithDots } from 'utils/transformTextToWithDots'
import { getChainName } from 'utils/getChainName'
import { venlyProvider } from 'services/providers/web3modal'
import { IUserInfo } from 'models/IUserInfo'
import { localStorageKeys } from 'constants/localStorageKeys'

export const tryDisconnectWeb3 = () => async (dispatch: AppDispatch) => {
  await web3Modal?.clearCachedProvider()

  dispatch(userSlice.actions.clearUserData())

  ethersProvider.setProvider(null)

  const tempProvider = ethersProvider.getTempProvider()
  tempProvider?.removeAllListeners()

  localStorage.clear()
}

export const initProvider =
  (web3Provider: any) => async (dispatch: AppDispatch) => {
    const provider = new ethers.providers.Web3Provider(web3Provider)

    await ethersProvider.setProvider(provider)
    await ethersProvider.setTempProvider(web3Provider)

    const userAddress = await contractAccountApi.getAccount()
    const userChainId = await contractAccountApi.getChainId()

    if (web3Modal.cachedProvider === 'injected') {
      localStorage.setItem(localStorageKeys.isLoadedMM, 'true')
      localStorage.removeItem(localStorageKeys.isAnimationDisabled)
    }

    dispatch(userSlice.actions.setUserAddress(userAddress || null))
    dispatch(userSlice.actions.setUserChainId(userChainId))

    web3Provider.on('accountsChanged', async (data: any) => {
      if (!data[0]) {
        dispatch(tryDisconnectWeb3())
        return
      }

      const userAddress = await contractAccountApi.getAccount()

      dispatch(userSlice.actions.setUserAddress(userAddress || null))
      dispatch(
        enqueueSnack({
          message: `Address changed to ${transformTextToWithDots(userAddress)}`,
          variant: 'info',
          id: 2,
        })
      )
    })

    web3Provider.on('chainChanged', async (chainId: string) => {
      dispatch(userSlice.actions.setUserChainId(parseInt(chainId)))

      dispatch(tryAuth())

      dispatch(
        enqueueSnack({
          message: `Chain Changed to ${getChainName(parseInt(chainId))}`,
          variant: 'info',
          id: 1,
        })
      )
    })

    web3Provider.on('disconnect', async (_: number, reason: string) => {
      logger.msg(reason)
      dispatch(tryDisconnectWeb3())
    })

    dispatch(userSlice.actions.setIsLoadingUserAuth(false))
  }

export const disconnect = () => async (dispatch: AppDispatch) => {
  const provider = ethersProvider.getProvider()

  if (venlyProvider !== null) {
    venlyProvider.logout()
  }

  if ((provider?.provider as any).disconnect) {
    ;(provider?.provider as any).disconnect()
    dispatch(tryDisconnectWeb3())
  } else {
    dispatch(tryDisconnectWeb3())
  }

  dispatch(enqueueSnack({ message: 'Disconnected', variant: 'info' }))
  localStorage.clear()
}

export const initConnectToWeb3 = () => async (dispatch: AppDispatch) => {
  try {
    localStorage.removeItem(localStorageKeys.isLoadedMM)
    const web3Provider = await web3Modal?.connect()

    await dispatch(initProvider(web3Provider))
  } catch (e) {
    dispatch(userSlice.actions.setIsLoadingUserAuth(false))
    logger.error(e as Error)
  }
}

export const tryConnectToMetaMask = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(tryDisconnectWeb3())

    const web3Provider = await web3Modal?.connectTo('injected')

    await dispatch(initProvider(web3Provider))
  } catch (e) {
    logger.error(e as Error)
    dispatch(
      enqueueSnack({ message: 'Please connect to web3', variant: 'warning' })
    )
  }
}

export const tryConnectToWalletConnect =
  () => async (dispatch: AppDispatch) => {
    try {
      dispatch(tryDisconnectWeb3())

      const web3Provider = await web3Modal?.connectTo('walletconnect')

      await dispatch(initProvider(web3Provider))
    } catch (e) {
      logger.error(e as Error)
      dispatch(
        enqueueSnack({ message: 'Please connect to web3', variant: 'warning' })
      )
    }
  }

export const tryConnectToVenly = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(tryDisconnectWeb3())

    const web3Provider = await web3Modal?.connectTo('venly')

    await dispatch(initProvider(web3Provider))
  } catch (e) {
    logger.error(e as Error)
    dispatch(
      enqueueSnack({ message: 'Please connect to web3', variant: 'warning' })
    )
  }
}

export const tryConnectToWeb3 = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(tryDisconnectWeb3())

    const web3Provider = await web3Modal?.connect()

    await dispatch(initProvider(web3Provider))
  } catch (e) {
    logger.error(e as Error)
    dispatch(
      enqueueSnack({ message: 'Please connect to web3', variant: 'warning' })
    )
  }
}

export const tryAuth = () => async (dispatch: AppDispatch) => {
  if (web3Modal?.cachedProvider) {
    dispatch(initConnectToWeb3())
  } else {
    dispatch(userSlice.actions.setIsLoadingUserAuth(false))
  }
}

export const setIsValidChain =
  (isOpen: boolean) => async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setIsValidChain(isOpen))
  }

export const setProfileIsExists =
  (isEditable: boolean) => async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setProfileIsExists(isEditable))
  }

export const setProfileUserInfo =
  (userInfo: IUserInfo) => async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setProfileUserInfo(userInfo))
  }
