export enum Currency {
  Sport = 'Sport',
  WETH = 'WETH',
  Sand = 'Sand',
  ApeCoin = 'ApeCoin',
  USDC = 'USDC',
  USDT = 'USDT',
  USDC_USDT = 'USDC/USDT',
  Matic = 'Matic',
}
