import { makeStyles } from 'styles/makeStyles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { TransitionDialog } from 'components/common'
import IconButton from '@mui/material/IconButton'
import { CloseIcon, OpenSeaIcon } from 'assets/icons'
import { FC } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { urlGenerator } from 'services/urlGenerator'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'

const useStyles = makeStyles({ name: 'RoseClaimDontOwnModal' })((theme) => ({
  closeIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.text.secondary,

    svg: {
      width: 24,
      height: 24,
    },
  },
  content: {
    padding: theme.spacing(2),
    width: '100%',
  },
  actions: {
    padding: theme.spacing(2),
    borderTop: `1px solid rgba(255, 255, 255, .4)`,
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  btn: {
    maxWidth: 308,
    width: '100%',
    margin: theme.spacing(2, 0, 3),
    justifyContent: 'space-between',

    svg: {
      width: 24,
      minWidth: 24,
      height: 24,
    },
  },
  successContent: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  prizeImageContainer: {
    minWidth: 240,
    width: 240,

    img: {
      width: '100%',
      height: 'auto',
    },
  },
  titleGreen: {
    color: theme.palette.success.main,
    fontSize: 16,
  },
  description: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  stickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stickerBlock: {
    display: 'flex',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: 308,
    width: '100%',
    alignItems: 'center',
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  stickerImage: {
    minWidth: 120,
    width: 120,

    img: {
      width: '100%',
      height: 'auto',
      borderRadius: theme.spacing(2),
    },
  },
  stickerName: {
    color: theme.palette.secondary.light,
    fontSize: 12,
    lineHeight: '16px',
  },
}))

interface Props {
  isOpen: boolean
  onClose: () => void
  own: string | number
  title: string
  name?: string
  imageUrl: string
  itemId: string | number
}

const RoseClaimDontOwnModal: FC<Props> = ({
  isOpen,
  onClose,
  own,
  title,
  name,
  imageUrl,
  itemId,
}) => {
  const { classes } = useStyles()

  return (
    <Dialog
      open={isOpen}
      PaperProps={{ sx: { maxWidth: 632, width: '100%' } }}
      aria-labelledby="dialog-RoseClaimDontOwnModal-labelledby"
      aria-describedby="dialog-RoseClaimDontOwnModal-describedby"
      TransitionComponent={TransitionDialog}
    >
      <DialogTitle id="dialog-RoseClaimDontOwnModal-title">
        <span></span>
        <IconButton
          aria-label="close"
          className={classes.closeIcon}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={classes.content}
        id="dialog-RoseClaimDontOwnModal-content"
      >
        <div className={classes.stickerContainer}>
          <Typography variant="h3">You don’t own that sticker</Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'center' }}
            className={classes.description}
          >
            Tough luck, you currently do not own this type of sticker. However,
            you can always try and find one on OpenSea. Click the button bellow
            to browse through our sticker collection.
          </Typography>
          <div className={classes.stickerBlock}>
            <div className={classes.stickerImage}>
              <img src={imageUrl} alt={'sticker'} />
            </div>
            <div>
              <Typography variant="body2" fontWeight={700}>
                {title}
              </Typography>
              {name && (
                <Typography variant="caption" className={classes.stickerName}>
                  {name}
                </Typography>
              )}
              <Typography variant="subtitle2" fontWeight={400}>
                You own: {own}
              </Typography>
            </div>
          </div>
          <Button
            variant={'contained'}
            className={classes.btn}
            target="_blank"
            href={urlGenerator.createOpenSeaLink(
              ROSE_COLLECTION_CONTRACT_ADDRESS,
              itemId
            )}
          >
            <span>Check out OpenSea</span>
            <OpenSeaIcon />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default RoseClaimDontOwnModal
