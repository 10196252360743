import { RoseCollectionDetailsLayout } from '../components/RoseCollectionDetailsLayout'
import { RoseBreedingDetailsHeading } from './RoseBreedingDetailsHeading'
import { useParams } from 'react-router-dom'

export const RoseBreedingDetails = () => {
  const { id: paramId = '' } = useParams()

  return (
    <RoseCollectionDetailsLayout>
      <RoseBreedingDetailsHeading paramId={paramId} />
    </RoseCollectionDetailsLayout>
  )
}
