import { capitalizeFirstLetter } from 'utils/formatValue'
import { ROUTER_PATHS } from 'routes/routerPaths'

export const roseCollectionDetailsUtils = {
  getBreadcrumb: (currentPath: string) => {
    return [
      {
        label: 'Merch Collection',
        href: ROUTER_PATHS.ROSE_NFT_COLLECTION_MERCH,
      },
      `${capitalizeFirstLetter(currentPath)}`,
    ]
  },
}
