import { ethersProvider } from '../../providers'
import { toLowBN } from 'utils/toBN'
import { ContractTransaction } from 'ethers'
import BigNumber from 'bignumber.js'
import { Erc20Factory } from 'models/contracts/ethers/factories/Erc20Factory'
import { IERC20__factory } from 'models/contracts/ethers/factories/IERC20__factory'

export const contractAccountApi = {
  getProvider: () => ethersProvider.getProvider(),

  async getAccount(): Promise<string | undefined> {
    const accounts = await this.getProvider()!.listAccounts()

    return accounts[0]
  },

  async getChainId(): Promise<number> {
    const network = await this.getProvider()!.getNetwork()

    return network.chainId
  },

  async getTokenBalance(
    userAddress: string,
    tokenAddress: string
  ): Promise<number> {
    const tokenInstance = Erc20Factory.connect(
      tokenAddress,
      this.getProvider()!
    )

    return toLowBN(await tokenInstance.balanceOf(userAddress!), 18).toNumber()
  },

  async sendTransaction(
    contractAddress: string,
    contractData: string
  ): Promise<ContractTransaction> {
    const signer = this.getProvider()!.getSigner()

    return signer.sendTransaction({ to: contractAddress, data: contractData })
  },

  async signMessage(
    userAddress: string,
    nonce: string
  ): Promise<string | undefined> {
    return await this.getProvider()?.getSigner().signMessage(nonce)
  },

  /* token address || deposit token address */
  async getTokenBalanceBigNumber(
    userAddress: string,
    tokenAddress: string
  ): Promise<BigNumber> {
    const tokenInstance = IERC20__factory.connect(
      tokenAddress,
      this.getProvider()!
    )

    return toLowBN(await tokenInstance.balanceOf(userAddress!), 18)
  },
}
