import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAppState, IAppModal, IAppWhiteList, IAppBalances } from './types'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { ModalName } from 'constants/modals'
import { Currency } from 'constants/currency'
import BigNumber from 'bignumber.js'
import { termsAndConditionsIdx } from 'constants/termsAndConditions'

export const initialState: IAppState = {
  isLoading: false,
  reFetchFlags: {
    [ReFetchFlag.Account]: false,
    [ReFetchFlag.SellBoxes]: false,
    [ReFetchFlag.Erc20Approve]: false,
    [ReFetchFlag.ClaimAthleteNft]: false,
    [ReFetchFlag.ClaimMerch]: false,
  },
  modals: {
    [ModalName.Auth]: false,
    [ModalName.BuyPacks]: false,
    [ModalName.BoxDropRates]: false,
    [ModalName.OpenMysteryBox]: false,
    [ModalName.BoxSaleOver]: false,
  },
  whiteList: {
    isLoading: true,
    sig: '',
    startDate: 0,
    isWhiteListed: false,
  },
  balances: {
    [Currency.Sport]: new BigNumber(0),
    [Currency.WETH]: new BigNumber(0),
    [Currency.USDT]: new BigNumber(0),
    [Currency.Sand]: new BigNumber(0),
    [Currency.ApeCoin]: new BigNumber(0),
  },
  termsAndConditions: {
    [termsAndConditionsIdx.PurchaseMysteryPacks]: {
      isAccepted: false,
      sigMsg: '',
    },
  },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading(state, action: PayloadAction<boolean | undefined>) {
      if (typeof action.payload === 'undefined') {
        state.isLoading = !state.isLoading
      } else {
        state.isLoading = action.payload
      }
    },

    setReFetchFlag(state, action: PayloadAction<ReFetchFlag>) {
      state.reFetchFlags = {
        ...state.reFetchFlags,
        [action.payload]: !state.reFetchFlags[action.payload],
      }
    },

    setModal(state, action: PayloadAction<IAppModal>) {
      state.modals = {
        ...state.modals,
        [action.payload.modalName]: action.payload.value,
      }
    },

    setWhiteList(state, action: PayloadAction<IAppWhiteList>) {
      state.whiteList = action.payload
    },

    setBalances(state, action: PayloadAction<IAppBalances>) {
      state.balances = action.payload
    },

    setTermsAndConditions(
      state,
      action: PayloadAction<{
        temrsKey: termsAndConditionsIdx
        isAccepted: boolean
        sigMsg: string
      }>
    ) {
      state.termsAndConditions = {
        ...state.termsAndConditions,
        [action.payload.temrsKey]: {
          isAccepted: action.payload.isAccepted,
          sigMsg: action.payload.sigMsg,
        },
      }
    },
  },
})

export default appSlice.reducer
