import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'

const useStyles = makeStyles({ name: 'Loader3dCube' })(() => ({
  root: {
    display: 'grid',
    placeItems: 'center',
    height: 300,
    transformOrigin: '50% 50%',
    animation: 'scale 2.6s 0.65s infinite linear',
    transformStyle: 'preserve-3d',
    boxSizing: 'border-box',

    '& *': {
      transformStyle: 'preserve-3d',
      boxSizing: 'border-box',
    },

    '@keyframes scale': {
      '0%, 10%': {
        transform: 'scaleX(1) scaleY(1)',
      },
      '35%, 100%': {
        transform: 'scaleX(0.5) scaleY(0.5)',
      },
    },
  },
  scene: {
    position: 'relative',
    transform:
      'translate3d(0, 0, 100vmin) rotateX(-24deg) rotateY(28deg) rotateX(0deg)',
  },
  shadow: {
    width: 120,
    position: 'absolute',
    bottom: 0,
    aspectRatio: '1',
    transformOrigin: '50% 50%',
    background: '#000',
    transform: 'rotateX(90deg) translate3d(0, 0, -60px) scale(0.96)',
    animation: 'squishSquosh 2.6s 0.65s infinite, fade 2.6s 0.65s infinite',

    '@keyframes squishSquosh': {
      '0%, 50%, 60%': {
        scale: '1 1 1',
      },
      '10%, 35%': {
        scale: '1.2 0.8 1.2',
      },
      '25%': {
        scale: '0.8 1.2 0.8',
      },
      '70%': {
        scale: '1 1 2',
      },
      '80%': {
        scale: '2 1 2',
      },
      '90%, 100%': {
        scale: '2 2 2',
      },
    },

    '@keyframes fade': {
      '0%, 10%, 40%, 50%, 60%, 100%': {
        opacity: 1,
      },
      '25%': {
        opacity: 0.5,
      },
    },
  },
  jumper: {
    animation: 'jump 2.6s 0.65s infinite',

    '@keyframes jump': {
      '0%, 10%, 35%, 50%': {
        transform: 'translate(0, 0)',
      },
      '25%': {
        transform: 'translate(0, -150%)',
      },
    },
  },
  spinner: {
    animation: 'spin 2.6s 0.65s infinite',

    '@keyframes spin': {
      '0%, 10%': {
        transform: 'rotate(0deg)',
      },
      '35%, 100%': {
        transform: 'rotate(-360deg)',
      },
    },
  },
  scaler: {
    transformStyle: 'preserve-3d',
  },
  loader: {
    width: 120,
    aspectRatio: '1',
    transformOrigin: '50% 50%',
    animation: 'squishSquosh 2.6s 0.65s infinite',

    '@keyframes squishSquosh': {
      '0%, 50%, 60%': {
        scale: '1 1 1',
      },
      '10%, 35%': {
        scale: '1.2 0.8 1.2',
      },
      '25%': {
        scale: '0.8 1.2 0.8',
      },
      '70%': {
        scale: '1 1 2',
      },
      '80%': {
        scale: '2 1 2',
      },
      '90%, 100%': {
        scale: '2 2 2',
      },
    },
  },
  cuboid: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  cuboidSide: {
    position: 'absolute',
    background: '#FFAE75',

    '&:nth-of-type(1)': {
      height: 120,
      width: '100%',
      top: 0,
      transform: 'translate(0, -50%) rotateX(90deg)',
    },

    '&:nth-of-type(2)': {
      background: '#E20058',
      height: '100%',
      width: 120,
      top: '50%',
      right: 0,
      transform: 'translate(50%, -50%) rotateY(90deg)',
    },

    '&:nth-of-type(3)': {
      width: '100%',
      height: 120,
      bottom: 0,
      transform: 'translate(0%, 50%) rotateX(90deg)',
    },

    '&:nth-of-type(4)': {
      background: '#E20058',
      height: '100%',
      width: 120,
      left: 0,
      top: '50%',
      transform: 'translate(-50%, -50%) rotateY(90deg)',
    },

    '&:nth-of-type(5)': {
      background: '#6A3FBF',
      height: '100%',
      width: '100%',
      transform: 'translate3d(0, 0, calc(120 * (1px * 0.5)))',
      top: 0,
      left: 0,
    },

    '&:nth-of-type(6)': {
      height: '100%',
      width: '100%',
      transform: 'translate3d(0, 0, calc(120 * (1px * -0.5))) rotateY(180deg)',
      top: 0,
      left: 0,
    },
  },
}))

interface Props {
  className?: string
}

export const Loader3dCube: FC<Props> = ({ className }) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={classes.scene}>
        <div className={classes.shadow} />
        <div className={classes.jumper}>
          <div className={classes.spinner}>
            <div className={classes.scaler}>
              <div className={classes.loader}>
                <div className={classes.cuboid}>
                  <div className={classes.cuboidSide} />
                  <div className={classes.cuboidSide} />
                  <div className={classes.cuboidSide} />
                  <div className={classes.cuboidSide} />
                  <div className={classes.cuboidSide} />
                  <div className={classes.cuboidSide} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
