import { makeStyles } from 'styles/makeStyles'
import { FC, ReactNode } from 'react'
import { publicImages } from 'utils/getPublicImages'
import { RoseHeader } from '../components/RoseHeader'
import { Footer } from 'components/Footer'
import { RoseInventoryMysteryBoxes } from './RoseInventoryMysteryBoxes'
import { MenuInventory } from './constants'
import { RoseInventoryMenu } from './RoseInventoryMenu'
import Container from '@mui/material/Container'

const useStyles = makeStyles({ name: 'RoseInventoryLayout' })((theme) => ({
  main: {
    position: 'relative',
    background: `
        url('${publicImages.getBackground('rose/inventory-bg.webp')}') 
        top left 
        no-repeat
      `,
    backgroundAttachment: 'fixed',
  },
  root: {
    padding: theme.spacing(5, 0, 15),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0, 10),
    },
  },
  container: {
    position: 'relative',
  },
  content: {
    width: '74%',
    marginLeft: '26%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
}))

interface Props {
  children: ReactNode
  activeMenuItem: MenuInventory
}

export const RoseInventoryLayout: FC<Props> = ({
  children,
  activeMenuItem,
}) => {
  const { classes, cx } = useStyles()

  return (
    <main className={cx(classes.main)}>
      <RoseHeader />
      <div>
        <RoseInventoryMysteryBoxes />
        <div className={cx(classes.root)}>
          <Container maxWidth={'lg'}>
            <div className={classes.container}>
              <RoseInventoryMenu activeMenuItem={activeMenuItem} />
              <div className={classes.content}>{children}</div>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </main>
  )
}
