import { makeStyles } from 'styles/makeStyles'
import { FC, memo } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { IUserInfo } from 'models/IUserInfo'
import { ROUTER_PATHS } from 'routes/routerPaths'
import { ArrowRightLineIcon } from 'assets/icons'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({ name: 'RoseProfileDetailsComponent' })(
  (theme) => ({
    root: {
      width: '100%',
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(20),

      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    block: {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    title: {
      fontWeight: 700,
      color: theme.palette.secondary.light,
      marginBottom: theme.spacing(2),
    },
    caption: {
      color: theme.palette.text.secondary,
      fontSize: 10,
      lineHeight: 1.5,
    },
    text: {
      marginBottom: theme.spacing(1.5),
      fontWeight: 700,
    },
    btn: {
      gap: theme.spacing(2),
      justifyContent: 'space-between',
      maxWidth: 300,
      padding: theme.spacing(1.5, 2),
      marginTop: theme.spacing(4),

      svg: {
        width: 24,
        height: 24,
        minWidth: 24,
      },
    },
  })
)

interface Props {
  isExists: boolean
  userInfo: IUserInfo
  isLoading: boolean
  onRequestDataBtnClick: () => void
  isLoggedIn: boolean
}

const RoseProfileDetails: FC<Props> = ({
  isExists,
  isLoading,
  userInfo,
  onRequestDataBtnClick,
  isLoggedIn,
}) => {
  const { classes } = useStyles()
  const isExistsWithInfo = isExists && !!userInfo?.name

  const getProfileDetails = (
    value: string,
    isSuccessForView: boolean,
    isLogged: boolean
  ) => {
    if (isSuccessForView && isLogged) {
      return value
    } else {
      return '************'
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <Typography className={classes.title} variant="h4">
          Contact information
        </Typography>
        <Typography className={classes.caption} variant="caption">
          First and last name:
        </Typography>
        <Typography className={classes.text} variant="body1">
          {getProfileDetails(userInfo.name, isExistsWithInfo, isLoggedIn)}
        </Typography>
        <Typography className={classes.caption} variant="caption">
          Email address:
        </Typography>
        <Typography className={classes.text} variant="body1">
          {getProfileDetails(userInfo.email, isExistsWithInfo, isLoggedIn)}
        </Typography>
        <Typography className={classes.caption} variant="caption">
          Phone number:
        </Typography>
        <Typography className={classes.text} variant="body1">
          {getProfileDetails(userInfo.phone, isExistsWithInfo, isLoggedIn)}
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography className={classes.title} variant="h4">
          Delivery information
        </Typography>
        <Typography className={classes.caption} variant="caption">
          Country:
        </Typography>
        <Typography className={classes.text} variant="body1">
          {getProfileDetails(userInfo.country, isExistsWithInfo, isLoggedIn)}
        </Typography>
        <Typography className={classes.caption} variant="caption">
          City:
        </Typography>
        <Typography className={classes.text} variant="body1">
          {getProfileDetails(userInfo.city, isExistsWithInfo, isLoggedIn)}
        </Typography>
        <Typography className={classes.caption} variant="caption">
          Post code:
        </Typography>
        <Typography className={classes.text} variant="body1">
          {getProfileDetails(userInfo.postCode, isExistsWithInfo, isLoggedIn)}
        </Typography>
        <Typography className={classes.caption} variant="caption">
          Address:
        </Typography>
        <Typography className={classes.text} variant="body1">
          {getProfileDetails(userInfo.address, isExistsWithInfo, isLoggedIn)}
        </Typography>
        {isExists && !userInfo?.name && isLoggedIn && (
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={onRequestDataBtnClick}
            disabled={isLoading}
          >
            Request Data
          </Button>
        )}
        {!isExists && isLoggedIn && (
          <Link to={ROUTER_PATHS.ROSE_PROFILE_EDIT}>
            <Button className={classes.btn} variant="contained">
              <span>Fill details</span>
              <ArrowRightLineIcon />
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}

export const RoseProfileDetailsComponent = memo(RoseProfileDetails)
