import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import { CheckboxGreenIcon, RemoveCircleIcon } from 'assets/icons'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { NftAvatar } from 'models/INfts'

const useStyles = makeStyles({ name: 'NftBreedingTinyItem' })((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2, 2),
    borderRadius: theme.spacing(2),
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  icon: {
    minWidth: 24,
    width: 24,
    height: 24,
    position: 'absolute',
    left: -2,
    top: -2,
  },
  imageContainer: {
    minWidth: 128,
    width: 128,
    height: 128,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: theme.spacing(1),
    background: theme.palette.common.black,
    overflow: 'hidden',
    position: 'relative',

    img: {
      width: '100%',
      height: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 54,
      width: 54,
      height: 54,
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '32px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  btn: {
    gap: theme.spacing(0.5),
    textTransform: 'none',
    padding: theme.spacing(1),
    width: '100%',
    borderRadius: theme.spacing(1),
    fontSize: 14,
    lineHeight: '16px',
    minWidth: 'auto',

    span: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    svg: {
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },
}))

interface Props {
  className?: string
  title: string
  data: NftAvatar | null
  onRemoveBtnClick: (data: NftAvatar) => void
}

export const NftBreedingTinyItem: FC<Props> = ({
  title,
  className,
  data,
  onRemoveBtnClick,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <Typography variant="caption" className={classes.title}>
        {title}
      </Typography>
      <div className={cx(classes.imageContainer)}>
        {!!data && (
          <>
            <CheckboxGreenIcon className={cx(classes.icon)} />
            <img src={data.image} alt={'rose nft'} />
          </>
        )}
      </div>
      <Button
        variant="outlined"
        onClick={() => onRemoveBtnClick(data!)}
        className={classes.btn}
        disabled={!data}
      >
        <RemoveCircleIcon />
        <span>Remove</span>
      </Button>
    </div>
  )
}
