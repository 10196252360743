import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

export const useInViewTrigger = (msDelay: number) => {
  const [trigger, setTrigger] = useState(false)

  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView && !trigger) {
      setTimeout(() => {
        setTrigger(true)
      }, msDelay)
    }
  }, [inView])

  return { trigger, ref }
}
