import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import {
  CheckboxCircleFillIcon,
  CheckboxCircleOutlinedIcon,
} from 'assets/icons'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { NftAvatar } from 'models/INfts'

const useStyles = makeStyles({ name: 'NftBreedingItem' })((theme) => ({
  root: {
    minWidth: 308,
    width: 308,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  imageContainer: {
    width: '100%',
    maxHeight: 308,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    position: 'relative',

    img: {
      width: '100%',
      height: 'auto',
    },
  },
  bottom: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  bottomLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomTitle: {
    lineHeight: '32px',
    fontWeight: 700,
  },
  bottomName: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
    color: theme.palette.secondary.light,
  },
  btn: {
    maxWidth: 141,
    gap: theme.spacing(0.5),
    justifyContent: 'space-between',
    textTransform: 'none',
    padding: theme.spacing(1, 2),
    width: '100%',
    fontSize: 14,
    lineHeight: '16px',

    svg: {
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },
}))

interface Props {
  className?: string
  title: string
  data: NftAvatar
  isSelected: boolean
  isDisabledSelect: boolean
  onSelectBtnClick: (data: NftAvatar) => void
  onUnSelectBtnClick: (data: NftAvatar) => void
}

export const NftBreedingItem: FC<Props> = ({
  className,
  title,
  data,
  isSelected,
  isDisabledSelect,
  onSelectBtnClick,
  onUnSelectBtnClick,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={cx(classes.imageContainer)}>
        <img src={data.image} alt={'nft'} />
      </div>
      <div className={classes.bottom}>
        <div className={classes.bottomLeft}>
          <Typography variant="h5" className={classes.bottomTitle}>
            {title}
          </Typography>
          <Typography variant="caption" className={classes.bottomName}>
            Nft Title
          </Typography>
        </div>
        {isSelected ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onUnSelectBtnClick(data)}
            className={classes.btn}
          >
            <span>Selected</span>
            <CheckboxCircleFillIcon />
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={() => onSelectBtnClick(data)}
            className={classes.btn}
            disabled={isDisabledSelect}
          >
            <span>Select</span>
            <CheckboxCircleOutlinedIcon />
          </Button>
        )}
      </div>
    </div>
  )
}
