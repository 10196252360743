import { makeStyles } from 'styles/makeStyles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { TransitionDialog } from 'components/common'
import IconButton from '@mui/material/IconButton'
import { CloseIcon } from 'assets/icons'
import { FC, useEffect, useRef } from 'react'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'

const useStyles = makeStyles({ name: 'WrapperLargeModal' })((theme) => ({
  root: {
    marginTop: `88px !important`,

    [theme.breakpoints.down('sm')]: {
      marginTop: `0 !important`,
    },
  },
  themeDefault: {
    '& .MuiDialog-paperFullScreen': {
      background: 'rgba(0, 0, 0, 0.85)',
      backdropFilter: 'blur(12px)',
      borderRadius: '40px 40px 0px 0px',

      [theme.breakpoints.down('sm')]: {
        borderRadius: `0 !important`,
      },
    },
  },
  themeLight: {
    '& .MuiDialog-paperFullScreen': {
      background: '#FEFAF9',
      backdropFilter: 'blur(12px)',
      borderRadius: '40px 40px 0px 0px',

      [theme.breakpoints.down('sm')]: {
        borderRadius: `0 !important`,
      },
    },
  },
  content: {
    display: 'block',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  colorWhite: {
    color: theme.palette.common.white,
  },
  colorBlack: {
    color: theme.palette.common.black,
  },
  closeBtn: {
    width: 40,
    minWidth: 40,
    height: 40,
    position: 'fixed',
    top: theme.spacing(3),
    right: theme.spacing(4),
    zIndex: 9,

    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(1),
      right: theme.spacing(2),
      width: 24,
      minWidth: 24,
      height: 24,
    },

    svg: {
      width: 40,
      minWidth: 40,
      height: 40,

      [theme.breakpoints.down('sm')]: {
        width: 24,
        minWidth: 24,
        height: 24,
      },
    },
  },
}))

interface Props {
  children: React.ReactNode
  isOpen: boolean
  onClose?: () => void
  theme?: 'light' | 'default'
  isLoading?: boolean
}

export const WrapperLargeModal: FC<Props> = ({
  children,
  isOpen,
  onClose,
  theme = 'default',
  isLoading = false,
}) => {
  const { classes, cx } = useStyles()

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isOpen && ref.current) {
      disableBodyScroll(ref.current)
    } else {
      if (ref.current) enableBodyScroll(ref.current)
    }

    return () => {
      if (ref.current) enableBodyScroll(ref.current)
      clearAllBodyScrollLocks()
    }
  }, [isOpen])

  return (
    <Dialog
      open={isOpen}
      className={cx(
        classes.root,
        theme === 'light' ? classes.themeLight : classes.themeDefault
      )}
      TransitionComponent={TransitionDialog}
      fullScreen
      ref={ref}
    >
      <DialogContent className={classes.content} id="auth-modal-content">
        {!isLoading && typeof onClose === 'function' && (
          <IconButton
            className={cx(
              classes.closeBtn,
              theme === 'light' ? classes.colorBlack : classes.colorWhite
            )}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
        <>{children}</>
      </DialogContent>
    </Dialog>
  )
}
