import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  snackbarAutoHideDurationSelector,
  snackbarCloseTimerSnackbarKeySelector,
  snackbarIdSelector,
  snackbarMessageSelector,
  snackbarStartTimerSnackbarKeySelector,
  snackbarTimersInfoSelector,
  snackbarVariantSelector,
} from 'store/reducers/snackbar/selectors'
import { useSnackbar } from 'notistack'
import {
  setStartTimerSnackbarKey,
  addTimersInfo,
  deleteTimersInfo,
  setCloseTimerSnackbarKey,
} from 'store/reducers/snackbar/actions'
import { Snackbar } from 'components/common'

export const Notifier = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const snackbarMessage = useAppSelector(snackbarMessageSelector)
  const snackbarVariant = useAppSelector(snackbarVariantSelector)
  const snackbarId = useAppSelector(snackbarIdSelector)
  const snackbarAutoHideDuration = useAppSelector(
    snackbarAutoHideDurationSelector
  )
  const snackbarStartTimerSnackbarKey = useAppSelector(
    snackbarStartTimerSnackbarKeySelector
  )
  const snackbarCloseTimerSnackbarKey = useAppSelector(
    snackbarCloseTimerSnackbarKeySelector
  )
  const snackbarTimersInfo = useAppSelector(snackbarTimersInfoSelector)

  useEffect(() => {
    if (!!snackbarMessage) {
      enqueueSnackbar(snackbarMessage, {
        variant: snackbarVariant,
        autoHideDuration: snackbarAutoHideDuration,
      })
    }
  }, [snackbarId, snackbarMessage])

  useEffect(() => {
    if (!!snackbarStartTimerSnackbarKey) {
      const startTime = new Date()

      const snackbarId = enqueueSnackbar('Your transaction has started', {
        autoHideDuration: null,
        content: (key, message) => (
          <Snackbar trackTime time={startTime} id={key} message={message} />
        ),
      })

      dispatch(
        addTimersInfo({
          snackbarKey: snackbarStartTimerSnackbarKey,
          snackbarId,
          startDate: startTime.toJSON(),
        })
      )
      dispatch(setStartTimerSnackbarKey(''))
    }
  }, [snackbarStartTimerSnackbarKey])

  useEffect(() => {
    if (!!snackbarCloseTimerSnackbarKey) {
      const idx = snackbarTimersInfo.findIndex(
        (item) => item.snackbarKey === snackbarCloseTimerSnackbarKey
      )

      if (idx === -1) return

      const currentItem = snackbarTimersInfo[idx]

      closeSnackbar(currentItem.snackbarId)

      enqueueSnackbar('Your transaction was successful', {
        autoHideDuration: 5000,
        content: (key, message) => (
          <Snackbar
            variant={'success'}
            time={new Date()}
            startTime={new Date(currentItem.startDate)}
            id={key}
            message={message}
          />
        ),
      })

      dispatch(deleteTimersInfo(snackbarCloseTimerSnackbarKey))
      dispatch(setCloseTimerSnackbarKey(''))
    }
  }, [snackbarCloseTimerSnackbarKey])

  return <></>
}
