import { RootState } from '../../store'

export const snackbarInfoSelector = (state: RootState) => state.snackbar

export const snackbarMessageSelector = (state: RootState) =>
  snackbarInfoSelector(state).message

export const snackbarVariantSelector = (state: RootState) =>
  snackbarInfoSelector(state).variant

export const snackbarAutoHideDurationSelector = (state: RootState) =>
  snackbarInfoSelector(state).autoHideDuration

export const snackbarIdSelector = (state: RootState) =>
  snackbarInfoSelector(state).id

export const snackbarStartTimerSnackbarKeySelector = (state: RootState) =>
  snackbarInfoSelector(state).startTimerSnackbarKey

export const snackbarCloseTimerSnackbarKeySelector = (state: RootState) =>
  snackbarInfoSelector(state).closeTimerSnackbarKey

export const snackbarTimersInfoSelector = (state: RootState) =>
  snackbarInfoSelector(state).timersInfo
