import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

interface Props {
  recycle?: boolean
  colors?: string[]
}

const ConfettiAnimation: FC<Props> = ({
  recycle = false,
  colors = ['#D34856', '#D9B240', '#9EDEFA'],
}) => {
  const [shouldRender, setShouldRender] = useState(recycle)

  const portalRoot =
    document.getElementById('confetti-root') || document.createElement('div')

  const { width, height } = useWindowSize()

  useEffect(() => {
    return () => {
      portalRoot.innerHTML = ''
    }
  }, [portalRoot])

  if (shouldRender) {
    return createPortal(
      <div
        style={{
          position: 'fixed',
          zIndex: 9999,
          top: 0,
          left: 0,
          width: '100%',
        }}
      >
        <Confetti
          width={width}
          height={height}
          recycle={recycle}
          colors={colors}
          onConfettiComplete={() => {
            setShouldRender(false)
          }}
        />
      </div>,
      portalRoot
    )
  }

  return null
}

export default ConfettiAnimation
