import { makeStyles } from 'styles/makeStyles'
import { NftGroupRarity } from 'models/INfts'
import { publicImages } from 'utils/getPublicImages'
import { FC, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { RoseNftTinyAction } from '@pages/Rose/components/RoseNfts/RoseNftTinyAction'
import {
  athleteStickersByGroupRarity,
  RoseCollectionById,
  athleteStickersImages,
} from 'constants/stickers'
import { fontSpaceMono } from 'styles/fonts'
import Typography from '@mui/material/Typography'
import RoseClaimItemActionModal from '@pages/Rose/components/RoseClaimItemActionModal'
import { ROSE_ITEMS_CONTENT_MODAL } from '@pages/Rose/components/RoseFighterItems/constants'
import RoseClaimDontOwnModal from '@pages/Rose/components/RoseClaimDontOwnModal'
import { useAppSelector } from 'store/hooks'
import { isLoggedInSelector } from 'store/reducers/user/selectors'

const useStyles = makeStyles({ name: 'RoseFighterStepItemAction' })(
  (theme) => ({
    root: {
      position: 'relative',
      boxSizing: 'border-box',
    },
    container: {
      width: 593,
      minWidth: 593,
      height: 593,
      borderRadius: theme.spacing(3),
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'center center',
      transition: 'all .3s ease-in-out',
      boxSizing: 'border-box',
      willChange: 'all',

      [theme.breakpoints.down('sm')]: {
        width: 288,
        minWidth: 288,
        height: 'auto',
        backgroundSize: '288px 288px',
        backgroundPosition: 'top center',
        marginBottom: theme.spacing(3),
      },

      '&::after': {
        content: "''",
        left: 0,
        position: 'absolute',
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2,
        width: '100%',
        height: '100%',
        opacity: 0,
        transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        willChange: 'opacity',
      },
    },
    filter: {
      left: 0,
      position: 'absolute',
      bottom: 0,
      zIndex: 1,
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(10px)',
    },
    bgBeginner: {
      backgroundImage: `url(${publicImages.getIllustration(
        'rose/nfts/beginner-nft-1.webp'
      )})`,
    },
    bgIntermediate: {
      backgroundImage: `url(${publicImages.getIllustration(
        'rose/nfts/intermediate-nft-6.webp'
      )})`,
    },
    bgAdvanced: {
      backgroundImage: `url(${publicImages.getIllustration(
        'rose/nfts/advanced-nft-1.webp'
      )})`,
    },
    bgElite: {
      backgroundImage: `url(${publicImages.getIllustration(
        'rose/nfts/elite-nft-2.webp'
      )})`,
    },
    bgLegendary: {
      backgroundImage: `url(${publicImages.getIllustration(
        'rose/nfts/legendary-nft-3.webp'
      )})`,
    },
    content: {
      width: '100%',
      position: 'relative',
      zIndex: 3,
      height: '100%',

      [theme.breakpoints.down('sm')]: {
        minHeight: 288,
        display: 'flex',
        alignItems: 'center',
      },
    },
    contentBeginner: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(5),

      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
    },
    contentIntermediate: {
      gap: theme.spacing(3),

      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        overflow: 'auto',
        justifyContent: 'flex-start',
        padding: theme.spacing(1),
      },
    },
    contentAdvanced: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      gap: theme.spacing(1),
      padding: theme.spacing(3),

      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        overflow: 'auto',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        padding: theme.spacing(1),
      },
    },
    contentElite: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: theme.spacing(3),

      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        overflow: 'auto',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        padding: theme.spacing(1),
      },
    },
    borderYellow: {
      border: `4px solid ${theme.palette.secondary.light}`,
    },
    labelYellow: {
      position: 'absolute',
      top: -4,
      left: '50%',
      transform: 'translateX(-50%)',
      padding: theme.spacing(0.5, 1),
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      background: theme.palette.secondary.light,
      zIndex: 3,
      maxWidth: 210,
      width: '100%',
      textAlign: 'center',
    },
    labelYellowText: {
      color: theme.palette.grey['A100'],
      fontFamily: fontSpaceMono,
    },
    borderGreen: {
      border: `4px solid ${theme.palette.success.main}`,
    },
    labelGreen: {
      background: theme.palette.success.main,
      maxWidth: 155,
    },
    labelGreenText: {
      color: theme.palette.common.white,
    },
  })
)

interface Props {
  className?: string
  groupRarity: NftGroupRarity
  selectedItems: Record<number, number>
  balanceById: Record<number, number>
  onAddBtnClick: (id: number) => void
  onRemoveBtnClick: (id: number) => void
  isCompleted?: boolean
  isPrevCompleted?: boolean
  startClaimRequest: () => void
  isApproved: boolean
  onApprove: () => void
  isLoading: boolean
}

export const RoseFighterStepItemAction: FC<Props> = ({
  className,
  groupRarity,
  selectedItems,
  balanceById,
  onAddBtnClick,
  onRemoveBtnClick,
  isCompleted,
  isPrevCompleted,
  startClaimRequest,
  isApproved,
  onApprove,
  isLoading,
}) => {
  const { classes, cx } = useStyles()

  const [modalState, setModalState] = useState<null | {
    id: RoseCollectionById
    type: 'athlete' | 'athleteRemove'
    isActive?: boolean
  }>(null)

  const bgClass = {
    [NftGroupRarity.Beginner]: classes.bgBeginner,
    [NftGroupRarity.Intermediate]: classes.bgIntermediate,
    [NftGroupRarity.Advanced]: classes.bgAdvanced,
    [NftGroupRarity.Elite]: classes.bgElite,
    [NftGroupRarity.Legendary]: classes.bgLegendary,
  }

  const isReadyToSelect = useMemo(() => {
    const ids = athleteStickersByGroupRarity[groupRarity] || []

    return ids.some((id) => balanceById[id] > 0)
  }, [balanceById, groupRarity])

  const content = {
    [NftGroupRarity.Beginner]: (
      <div className={classes.contentBeginner}>
        <RoseNftTinyAction
          isActive={balanceById[RoseCollectionById['Beginner Head']] > 0}
          isChecked={selectedItems[RoseCollectionById['Beginner Head']] > 0}
          imageUrl={athleteStickersImages[RoseCollectionById['Beginner Head']]}
          onAddBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Beginner Head'],
              type: 'athlete',
              isActive: balanceById[RoseCollectionById['Beginner Head']] > 0,
            })
          }}
          onRemoveBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Beginner Head'],
              type: 'athleteRemove',
              isActive: balanceById[RoseCollectionById['Beginner Head']] > 0,
            })
          }}
        />
        <RoseNftTinyAction
          isActive={balanceById[RoseCollectionById['Beginner Torso']] > 0}
          isChecked={selectedItems[RoseCollectionById['Beginner Torso']] > 0}
          imageUrl={athleteStickersImages[RoseCollectionById['Beginner Torso']]}
          onAddBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Beginner Torso'],
              type: 'athlete',
              isActive: balanceById[RoseCollectionById['Beginner Torso']] > 0,
            })
          }}
          onRemoveBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Beginner Torso'],
              type: 'athleteRemove',
              isActive: balanceById[RoseCollectionById['Beginner Torso']] > 0,
            })
          }}
        />
      </div>
    ),
    [NftGroupRarity.Intermediate]: (
      <div className={cx(classes.contentBeginner, classes.contentIntermediate)}>
        <RoseNftTinyAction
          isActive={balanceById[RoseCollectionById['Intermediate Head']] > 0}
          isChecked={selectedItems[RoseCollectionById['Intermediate Head']] > 0}
          imageUrl={
            athleteStickersImages[RoseCollectionById['Intermediate Head']]
          }
          onAddBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Intermediate Head'],
              type: 'athlete',
              isActive:
                balanceById[RoseCollectionById['Intermediate Head']] > 0,
            })
          }}
          onRemoveBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Intermediate Head'],
              type: 'athleteRemove',
              isActive:
                balanceById[RoseCollectionById['Intermediate Head']] > 0,
            })
          }}
        />
        <RoseNftTinyAction
          isActive={balanceById[RoseCollectionById['Intermediate Torso']] > 0}
          isChecked={
            selectedItems[RoseCollectionById['Intermediate Torso']] > 0
          }
          imageUrl={
            athleteStickersImages[RoseCollectionById['Intermediate Torso']]
          }
          onAddBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Intermediate Torso'],
              type: 'athlete',
              isActive:
                balanceById[RoseCollectionById['Intermediate Torso']] > 0,
            })
          }}
          onRemoveBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Intermediate Torso'],
              type: 'athleteRemove',
              isActive:
                balanceById[RoseCollectionById['Intermediate Torso']] > 0,
            })
          }}
        />
        <RoseNftTinyAction
          isActive={balanceById[RoseCollectionById['Intermediate Hands']] > 0}
          isChecked={
            selectedItems[RoseCollectionById['Intermediate Hands']] > 0
          }
          imageUrl={
            athleteStickersImages[RoseCollectionById['Intermediate Hands']]
          }
          onAddBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Intermediate Hands'],
              type: 'athlete',
              isActive:
                balanceById[RoseCollectionById['Intermediate Hands']] > 0,
            })
          }}
          onRemoveBtnClick={() => {
            setModalState({
              id: RoseCollectionById['Intermediate Hands'],
              type: 'athleteRemove',
              isActive:
                balanceById[RoseCollectionById['Intermediate Hands']] > 0,
            })
          }}
        />
      </div>
    ),
    [NftGroupRarity.Advanced]: (
      <div className={classes.contentAdvanced}>
        <Box
          display={'flex'}
          gap={1}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <RoseNftTinyAction
            isActive={balanceById[RoseCollectionById['Advanced Head']] > 0}
            isChecked={selectedItems[RoseCollectionById['Advanced Head']] > 0}
            imageUrl={
              athleteStickersImages[RoseCollectionById['Advanced Head']]
            }
            onAddBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Advanced Head'],
                type: 'athlete',
                isActive: balanceById[RoseCollectionById['Advanced Head']] > 0,
              })
            }}
            onRemoveBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Advanced Head'],
                type: 'athleteRemove',
                isActive: balanceById[RoseCollectionById['Advanced Head']] > 0,
              })
            }}
          />
          <RoseNftTinyAction
            isActive={balanceById[RoseCollectionById['Advanced Torso']] > 0}
            isChecked={selectedItems[RoseCollectionById['Advanced Torso']] > 0}
            imageUrl={
              athleteStickersImages[RoseCollectionById['Advanced Torso']]
            }
            onAddBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Advanced Torso'],
                type: 'athlete',
                isActive: balanceById[RoseCollectionById['Advanced Torso']] > 0,
              })
            }}
            onRemoveBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Advanced Torso'],
                type: 'athleteRemove',
                isActive: balanceById[RoseCollectionById['Advanced Torso']] > 0,
              })
            }}
          />
        </Box>
        <Box
          display={'flex'}
          gap={1}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <RoseNftTinyAction
            isActive={balanceById[RoseCollectionById['Advanced Hands']] > 0}
            isChecked={selectedItems[RoseCollectionById['Advanced Hands']] > 0}
            imageUrl={
              athleteStickersImages[RoseCollectionById['Advanced Hands']]
            }
            onAddBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Advanced Hands'],
                type: 'athlete',
                isActive: balanceById[RoseCollectionById['Advanced Hands']] > 0,
              })
            }}
            onRemoveBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Advanced Hands'],
                type: 'athleteRemove',
                isActive: balanceById[RoseCollectionById['Advanced Hands']] > 0,
              })
            }}
          />
          <RoseNftTinyAction
            isActive={balanceById[RoseCollectionById['Advanced Legs']] > 0}
            isChecked={selectedItems[RoseCollectionById['Advanced Legs']] > 0}
            imageUrl={
              athleteStickersImages[RoseCollectionById['Advanced Legs']]
            }
            onAddBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Advanced Legs'],
                type: 'athlete',
                isActive: balanceById[RoseCollectionById['Advanced Legs']] > 0,
              })
            }}
            onRemoveBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Advanced Legs'],
                type: 'athleteRemove',
                isActive: balanceById[RoseCollectionById['Advanced Legs']] > 0,
              })
            }}
          />
        </Box>
      </div>
    ),
    [NftGroupRarity.Elite]: (
      <div className={classes.contentElite}>
        <Box
          display={'flex'}
          gap={1}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <RoseNftTinyAction
            isActive={balanceById[RoseCollectionById['Elite Head']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Head']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Head']]}
            onAddBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Head'],
                type: 'athlete',
                isActive: balanceById[RoseCollectionById['Elite Head']] > 0,
              })
            }}
            onRemoveBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Head'],
                type: 'athleteRemove',
                isActive: balanceById[RoseCollectionById['Elite Head']] > 0,
              })
            }}
          />
          <RoseNftTinyAction
            isActive={balanceById[RoseCollectionById['Elite Torso']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Torso']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Torso']]}
            onAddBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Torso'],
                type: 'athlete',
                isActive: balanceById[RoseCollectionById['Elite Torso']] > 0,
              })
            }}
            onRemoveBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Torso'],
                type: 'athleteRemove',
                isActive: balanceById[RoseCollectionById['Elite Torso']] > 0,
              })
            }}
          />
        </Box>
        <Box
          display={'flex'}
          gap={1}
          justifyContent={'space-between'}
          sx={{
            margin: {
              xs: '0',
              sm: '-40px 0',
            },
          }}
        >
          <RoseNftTinyAction
            isActive={balanceById[RoseCollectionById['Elite Belt']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Belt']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Belt']]}
            onAddBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Belt'],
                type: 'athlete',
                isActive: balanceById[RoseCollectionById['Elite Belt']] > 0,
              })
            }}
            onRemoveBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Belt'],
                type: 'athleteRemove',
                isActive: balanceById[RoseCollectionById['Elite Belt']] > 0,
              })
            }}
          />
        </Box>
        <Box
          display={'flex'}
          gap={1}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <RoseNftTinyAction
            isActive={balanceById[RoseCollectionById['Elite Hands']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Hands']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Hands']]}
            onAddBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Hands'],
                type: 'athlete',
                isActive: balanceById[RoseCollectionById['Elite Hands']] > 0,
              })
            }}
            onRemoveBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Hands'],
                type: 'athleteRemove',
                isActive: balanceById[RoseCollectionById['Elite Hands']] > 0,
              })
            }}
          />
          <RoseNftTinyAction
            isActive={balanceById[RoseCollectionById['Elite Legs']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Legs']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Legs']]}
            onAddBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Legs'],
                type: 'athlete',
                isActive: balanceById[RoseCollectionById['Elite Legs']] > 0,
              })
            }}
            onRemoveBtnClick={() => {
              setModalState({
                id: RoseCollectionById['Elite Legs'],
                type: 'athleteRemove',
                isActive: balanceById[RoseCollectionById['Elite Legs']] > 0,
              })
            }}
          />
        </Box>
      </div>
    ),
    [NftGroupRarity.Legendary]: <></>,
  }

  return (
    <>
      <div className={cx(classes.root, className && className)}>
        <div
          className={cx(
            classes.container,
            isReadyToSelect && !isCompleted && classes.borderYellow,
            isCompleted && classes.borderGreen,
            bgClass[groupRarity]
          )}
        >
          {isReadyToSelect && !isCompleted && (
            <div className={cx(classes.labelYellow)}>
              <Typography
                variant="subtitle2"
                className={classes.labelYellowText}
              >
                New stickers available to add
              </Typography>
            </div>
          )}
          {isCompleted && (
            <div className={cx(classes.labelYellow, classes.labelGreen)}>
              <Typography
                variant="subtitle2"
                className={cx(classes.labelYellowText, classes.labelGreenText)}
              >
                Collection completed!
              </Typography>
            </div>
          )}
          <div className={cx(classes.filter)} />
          <div className={cx(classes.content)}>{content[groupRarity]}</div>
        </div>
      </div>
      {modalState !== null && modalState.isActive && (
        <RoseClaimItemActionModal
          isOpen={!!modalState}
          onClose={() => setModalState(null)}
          onConfirm={() => {
            onAddBtnClick(modalState.id)
          }}
          startRequest={startClaimRequest}
          onRemove={() => {
            onRemoveBtnClick(modalState.id)
          }}
          type={modalState.type}
          // @ts-ignore
          title={ROSE_ITEMS_CONTENT_MODAL[modalState.id].title}
          // @ts-ignore
          name={ROSE_ITEMS_CONTENT_MODAL[modalState.id].name}
          // @ts-ignore
          imageUrl={athleteStickersImages[modalState.id]}
          // @ts-ignore
          prizeImageUrl={ROSE_ITEMS_CONTENT_MODAL[modalState.id].prizeImageUrl}
          own={balanceById[modalState.id] || 0}
          isSuccessful={isPrevCompleted}
          isApproved={isApproved}
          isLoading={isLoading}
          onApprove={onApprove}
          isExists={true}
        />
      )}
      {modalState !== null && !modalState.isActive && (
        <RoseClaimDontOwnModal
          isOpen={!!modalState}
          onClose={() => setModalState(null)}
          // @ts-ignore
          title={ROSE_ITEMS_CONTENT_MODAL[modalState.id].title}
          // @ts-ignore
          name={ROSE_ITEMS_CONTENT_MODAL[modalState.id].name}
          // @ts-ignore
          imageUrl={athleteStickersImages[modalState.id]}
          own={balanceById[modalState.id] || 0}
          itemId={modalState.id}
        />
      )}
    </>
  )
}
