import { FC, useEffect, useState } from 'react'
import { RoseNftCollectionClaimSteps } from './constants'
import WrapperLargeModal from 'components/Modals/WrapperLargeModal'
import { BoxesLoaderBig } from 'components/Loaders'
import { RoseNftCollectionClaimOpeningStep } from './RoseNftCollectionClaimOpeningStep'
import { RoseNftCollectionClaimSuccessStep } from './RoseNftCollectionClaimSuccessStep'
import { useMutation_SwapStickers_OpenBox } from 'services/useApi/openBox/useMutation'
import { localStorageKeys } from 'constants/localStorageKeys'
import { useLocalStorageKeys } from 'hooks/useLocalStorageKeys'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'

interface Props {
  isOpen: boolean
  onClose: () => void
  prizeImageUrl: string
  athleteId: number
  updateLocalStorage: () => void
  title: string
}

const RoseNftCollectionClaimModalContainer: FC<Props> = ({
  isOpen,
  onClose,
  prizeImageUrl,
  athleteId,
  updateLocalStorage,
  title,
}) => {
  const [step, setStep] = useState<null | RoseNftCollectionClaimSteps>(null)
  const [successData, setSuccessData] = useState<{
    id: number
    image: string
  }>({
    id: 0,
    image: '',
  })

  const [keyLS] = useLocalStorageKeys(localStorageKeys.athleteStickersSelected)

  const { mutateAsync: mutateAsyncSwapAthletesStickers } =
    useMutation_SwapStickers_OpenBox(
      () => {
        setStep(RoseNftCollectionClaimSteps.OPENING)
        localStorage.removeItem(keyLS)
        updateLocalStorage()
      },
      () => {
        handleClose()
      }
    )

  const swapAthletesStickers = async () => {
    const { id, image } = await mutateAsyncSwapAthletesStickers({
      itemId: athleteId,
      eventContractAddress: ROSE_COLLECTION_CONTRACT_ADDRESS,
      type: 'athlete',
    })

    setSuccessData({
      id,
      image,
    })
  }

  useEffect(() => {
    if (!isOpen) {
      setStep(null)
    } else {
      setStep(RoseNftCollectionClaimSteps.LOADING)
      ;(async () => {
        await swapAthletesStickers()
      })()
    }
  }, [isOpen])

  const handleClose = () => {
    onClose()
    setStep(null)
  }

  return (
    <WrapperLargeModal
      isOpen={
        step === RoseNftCollectionClaimSteps.LOADING ||
        step === RoseNftCollectionClaimSteps.SUCCESS ||
        step === RoseNftCollectionClaimSteps.OPENING
      }
      onClose={handleClose}
      isLoading={
        step === RoseNftCollectionClaimSteps.LOADING ||
        step === RoseNftCollectionClaimSteps.OPENING
      }
    >
      <>
        {step === RoseNftCollectionClaimSteps.LOADING && (
          <BoxesLoaderBig title={'Claiming Your NFT'} />
        )}
        {step === RoseNftCollectionClaimSteps.OPENING && (
          <RoseNftCollectionClaimOpeningStep
            imageUrl={prizeImageUrl}
            onNext={() => setStep(RoseNftCollectionClaimSteps.SUCCESS)}
          />
        )}
        {step === RoseNftCollectionClaimSteps.SUCCESS && (
          <RoseNftCollectionClaimSuccessStep
            onClose={handleClose}
            id={successData.id}
            imageUrl={successData.image}
            title={title}
          />
        )}
      </>
    </WrapperLargeModal>
  )
}

export default RoseNftCollectionClaimModalContainer
