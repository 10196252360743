import { makeStyles } from 'styles/makeStyles'
import Slider, { Settings } from 'react-slick'
import { FC } from 'react'
import { publicImages } from 'utils/getPublicImages'

const useStyles = makeStyles({ name: 'RoseSliderHeroes' })((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    willChange: 'transform',
    transform: 'rotate(-4deg)',
    margin: theme.spacing(5, 0),
  },
  item: {
    padding: theme.spacing(0.5),
    overflow: 'hidden',
    borderRadius: theme.spacing(3),

    '&:focus': {
      outline: 'none',
    },

    img: {
      width: '100%',
      height: 'auto',
    },
  },
}))

const settings: Settings = {
  dots: true,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 1500,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
}

interface Props extends Settings {}

export const RoseSliderHeroes: FC<Props> = (props) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root)}>
      <Slider {...settings} {...props}>
        <div className={classes.item}>
          <img
            src={`${publicImages.getIllustration(
              'rose/nfts/beginner-nft-6.webp'
            )}`}
            alt={'nft'}
          />
        </div>
        <div className={classes.item}>
          <img
            src={`${publicImages.getIllustration(
              'rose/nfts/advanced-nft-6.webp'
            )}`}
            alt={'nft'}
          />
        </div>
        <div className={classes.item}>
          <img
            src={`${publicImages.getIllustration(
              'rose/nfts/elite-nft-6.webp'
            )}`}
            alt={'nft'}
          />
        </div>
        <div className={classes.item}>
          <img
            src={`${publicImages.getIllustration(
              'rose/nfts/legendary-nft-6.webp'
            )}`}
            alt={'nft'}
          />
        </div>

        <div className={classes.item}>
          <img
            src={`${publicImages.getIllustration(
              'rose/nfts/beginner-nft-1.webp'
            )}`}
            alt={'nft'}
          />
        </div>
        <div className={classes.item}>
          <img
            src={`${publicImages.getIllustration(
              'rose/nfts/advanced-nft-4.webp'
            )}`}
            alt={'nft'}
          />
        </div>
        <div className={classes.item}>
          <img
            src={`${publicImages.getIllustration(
              'rose/nfts/elite-nft-2.webp'
            )}`}
            alt={'nft'}
          />
        </div>
        <div className={classes.item}>
          <img
            src={`${publicImages.getIllustration(
              'rose/nfts/legendary-nft-3.webp'
            )}`}
            alt={'nft'}
          />
        </div>
      </Slider>
    </div>
  )
}
