import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { linearGradients } from 'styles/other'
import { publicImages } from 'utils/getPublicImages'

const useStyles = makeStyles({ name: 'RoseCertificate' })((theme) => ({
  root: {
    marginBottom: theme.spacing(15),
  },
  container: {
    display: 'flex',
    position: 'relative',
    gap: theme.spacing(5),

    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(3),
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },
  },
  imageContainer: {
    padding: theme.spacing(1),
    minWidth: 416,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      minWidth: 296,
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0, 0),
    },

    '&:not(:last-of-type)::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: -8,
      width: 1,
      height: '100%',
      background: linearGradients.backgroundLinesVertical,

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.secondary.light,
    fontFamily: fontSpaceMono,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  description: {
    marginTop: theme.spacing(2),
  },
}))

export const RoseCertificate = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.container}>
          <div className={classes.imageContainer}>
            <img
              src={publicImages.getIllustration(
                'rose/merch/rose-certificate.webp'
              )}
              alt={'rose-certificate'}
            />
          </div>
          <div className={classes.content}>
            <Typography variant="h6" className={classes.subtitle}>
              Authenticity
            </Typography>
            <Typography variant="h3" fontWeight={800}>
              Certificate
            </Typography>
            <Typography variant="subtitle1" className={classes.description}>
              This certificate of authenticity is hereby presented to certify
              that the item displayed on our website is genuine, authentic, and
              approved by Rose Namajunas. We confirm that the item has undergone
              a thorough examination and has been verified to be a legitimate
              product. It has not been altered or tampered with in any way.
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  )
}
