import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { fontSpaceMono } from 'styles/fonts'
import { Loader3dCube } from 'components/Loaders'
import { publicImages } from 'utils/getPublicImages'
import { FC } from 'react'

const useStyles = makeStyles({ name: 'PrizeLoaderBig' })((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 2),
    },
  },
  subtitle: {
    lineHeight: '40px',
    textTransform: 'uppercase',
    color: theme.palette.secondary.light,
    fontFamily: fontSpaceMono,
  },
  title: {
    fontSize: 64,
    lineHeight: '80px',
    marginBottom: theme.spacing(6),
    fontWeight: 800,

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
  },
  content: {
    img: {
      marginTop: theme.spacing(-24),
    },
  },
}))

const sxClasses = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
  },
}

interface Props {
  className?: string
  subtitle?: string
  title?: string
}

export const PrizeLoaderBig: FC<Props> = ({
  className,
  subtitle = 'Prize on its way...',
  title = 'Purchasing Mystery packs',
}) => {
  const { classes, cx } = useStyles()

  return (
    <Container maxWidth="lg" sx={sxClasses.container}>
      <div className={cx(classes.root, className && className)}>
        <Typography variant={'subtitle1'} className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Typography variant={'h3'} className={classes.title}>
          {title}
        </Typography>
        <div className={classes.content}>
          <Loader3dCube />
          <img
            src={publicImages.getBackground('rose/cube3d-bottom-bg.webp')}
            alt=""
          />
        </div>
      </div>
    </Container>
  )
}
