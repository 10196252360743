import { makeStyles } from 'styles/makeStyles'
import { Typography } from '@mui/material'
import { FC } from 'react'

const useStyles = makeStyles({ name: 'RoseCollectionTab' })((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    borderRadius: '16px 16px 0px 0px',
    border: `1px solid rgba(255, 255, 255, .4)`,
    borderBottom: '1px solid rgba(255, 255, 255, 0)',
    position: 'relative',
    zIndex: 2,

    '&::before': {
      content: "''",
      borderRadius: '16px 16px 0px 0px',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      height: 'calc(100% + 2px)',
      background:
        'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,1) 100%)',
      zIndex: 1,
      opacity: 0,
      transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      willChange: 'opacity',
    },

    '&:hover': {
      cursor: 'pointer',
    },

    '&:hover::before': {
      opacity: 1,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    zIndex: 4,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: 800,
  },
  active: {
    '&:hover': {
      cursor: 'default',
    },

    '&::before': {
      opacity: 1,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  imageContainer: {
    minWidth: 104,
    width: 104,
    height: 104,

    img: {
      width: '100%',
      height: 'auto',
    },
  },
  description: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}))

interface Props {
  className?: string
  title: string
  description: string
  imageUrl: string
  isActive?: boolean
  onClick?: () => void
}

export const RoseCollectionTab: FC<Props> = ({
  className,
  title,
  description,
  imageUrl,
  isActive,
  onClick,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(
        classes.root,
        isActive && classes.active,
        className && className
      )}
      onClick={() => {
        !!onClick && onClick()
      }}
    >
      <div className={classes.content}>
        <div className={classes.imageContainer}>
          <img src={imageUrl} alt={title} />
        </div>
        <div>
          <Typography variant={'h4'} className={classes.title}>
            {title}
          </Typography>
          <Typography variant={'body1'} className={classes.description}>
            {description}
          </Typography>
        </div>
      </div>
    </div>
  )
}
