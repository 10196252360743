export const socialUrls = {
  digi: {
    twitter: 'https://twitter.com/digisportxyz',
    discord: 'https://discord.com/invite/c6n2RwJEFH',
  },
  rose: {
    instagram: 'https://www.instagram.com/rosenamajunas/',
  },
  terms: {
    termsAndConditions: 'https://digisport.xyz/rose/terms-and-conditions',
  },
}

export const openSeaUrls = {
  roseCollection: 'https://opensea.io/collection/rose-thug-namajunas',
}

export const staticUrls = {
  supportRequest:
    'https://support.lympo.io/hc/en-us/requests/new?ticket_form_id=11038899653149',
}
