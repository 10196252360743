import { useMutation } from '@tanstack/react-query'
import { useAppDispatch } from 'store/hooks'
import isFunction from 'lodash/isFunction'
import {
  ROSE_COLLECTION_CONTRACT_ADDRESS,
  ROUTER_CONTRACT_ADDRESS,
} from 'constants/addresses'
import { setReFetchFlag } from 'store/reducers/app/actions'
import { enqueueSnack } from 'store/reducers/snackbar/actions'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { contractOpenBoxApi } from 'services/api/contract/openBox'
import { ethers } from 'ethers'
import { IERC1155Tradable__factory } from 'models/contracts/ethers/factories/IERC1155Tradable__factory'
import { httpGeneralApi } from 'services/api/http/general'
import {
  athletesStickersListById,
  itemsStickersListById,
} from 'constants/stickers'
import { RoseCollection__factory } from 'models/contracts/ethers/factories/RoseCollection__factory'

export const useMutation_OpenBox_OpenBox = (
  onSuccessCb?: () => void,
  onErrorCb?: () => void
) => {
  const dispatch = useAppDispatch()

  return useMutation(
    async (data: { boxId: number; amount: number }) => {
      const tx = await contractOpenBoxApi.openBox(
        data.boxId,
        data.amount,
        ROUTER_CONTRACT_ADDRESS
      )

      const receipt = await tx.wait(2)

      const iFace = new ethers.utils.Interface(IERC1155Tradable__factory.abi)

      const athleteStickersIds: number[] = []
      const itemStickersIds: number[] = []

      receipt.events?.forEach((event) => {
        if (event.address === ROSE_COLLECTION_CONTRACT_ADDRESS) {
          const parsedEvent = iFace.parseLog(event)

          for (let i = 0; i < parsedEvent.args.value.toNumber(); i++) {
            if (
              athletesStickersListById.indexOf(
                parsedEvent.args.id.toNumber()
              ) !== -1
            ) {
              athleteStickersIds.push(parsedEvent.args.id.toNumber())
            }

            if (
              itemsStickersListById.indexOf(parsedEvent.args.id.toNumber()) !==
              -1
            ) {
              itemStickersIds.push(parsedEvent.args.id.toNumber())
            }
          }
        }
      })

      dispatch(setReFetchFlag(ReFetchFlag.SellBoxes))
      dispatch(setReFetchFlag(ReFetchFlag.ClaimMerch))
      dispatch(setReFetchFlag(ReFetchFlag.ClaimAthleteNft))

      return {
        athleteStickersIds: athleteStickersIds.sort((a, b) => a - b),
        itemStickersIds: itemStickersIds.sort((a, b) => a - b),
      }
    },
    {
      onSuccess: () => {
        isFunction(onSuccessCb) && onSuccessCb()
      },
      onError: () => {
        isFunction(onErrorCb) && onErrorCb()
        dispatch(
          enqueueSnack({
            message: 'Error Open Box, please try later.',
            variant: 'error',
          })
        )
      },
    }
  )
}

export const useMutation_SwapStickers_OpenBox = (
  onSuccessCb?: () => void,
  onErrorCb?: () => void
) => {
  const dispatch = useAppDispatch()

  return useMutation(
    async (data: {
      itemId: number
      eventContractAddress: string
      type: 'athlete' | 'item'
    }) => {
      let tx

      if (data.type === 'athlete') {
        tx = await contractOpenBoxApi.swapAthletesStickers(
          data.itemId,
          ROUTER_CONTRACT_ADDRESS
        )
      } else {
        tx = await contractOpenBoxApi.swapItemStickers(
          data.itemId,
          ROUTER_CONTRACT_ADDRESS
        )
      }

      const receipt = await tx.wait(2)

      const iFace = new ethers.utils.Interface(RoseCollection__factory.abi)

      let id = 0

      receipt.events?.forEach((event) => {
        if (event.address === data.eventContractAddress) {
          const parsedEvent = iFace.parseLog(event)

          if (parsedEvent.args.from === ethers.constants.AddressZero) {
            id = parsedEvent.args.id.toString()
          }
        }
      })

      const { image } = await httpGeneralApi.getDataByUrl<{ image: string }>(
        `https://api.digisport.xyz/v1/api/metadata/rose/${id}`
      )

      dispatch(setReFetchFlag(ReFetchFlag.SellBoxes))
      dispatch(setReFetchFlag(ReFetchFlag.ClaimMerch))
      dispatch(setReFetchFlag(ReFetchFlag.ClaimAthleteNft))

      return { id, image }
    },
    {
      onSuccess: () => {
        isFunction(onSuccessCb) && onSuccessCb()
      },
      onError: () => {
        isFunction(onErrorCb) && onErrorCb()
        dispatch(
          enqueueSnack({
            message: 'Error Swap Athletes Stickers, please try later.',
            variant: 'error',
          })
        )
      },
    }
  )
}

export const useMutation_Breeding_OpenBox = (
  onSuccessCb?: () => void,
  onErrorCb?: () => void
) => {
  const dispatch = useAppDispatch()

  return useMutation(
    async (data: { itemIds: Array<string | number> }) => {
      const tx = await contractOpenBoxApi.breeding(
        data.itemIds,
        ROUTER_CONTRACT_ADDRESS
      )

      const receipt = await tx.wait(2)

      const iFace = new ethers.utils.Interface(RoseCollection__factory.abi)

      let id = 0

      receipt.events?.forEach((event) => {
        if (event.address === ROSE_COLLECTION_CONTRACT_ADDRESS) {
          const parsedEvent = iFace.parseLog(event)

          if (parsedEvent.args.from === ethers.constants.AddressZero) {
            id = parsedEvent.args.id.toString()
          }
        }
      })

      const { image } = await httpGeneralApi.getDataByUrl<{ image: string }>(
        `https://api.digisport.xyz/v1/api/metadata/rose/${id}`
      )

      return { id, image }
    },
    {
      onSuccess: () => {
        isFunction(onSuccessCb) && onSuccessCb()
      },
      onError: () => {
        isFunction(onErrorCb) && onErrorCb()
        dispatch(
          enqueueSnack({
            message: 'Error Fusion, please try later.',
            variant: 'error',
          })
        )
      },
    }
  )
}
