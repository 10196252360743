import { FC, memo } from 'react'
import { makeStyles } from 'styles/makeStyles'
import Button from '@mui/material/Button'
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'assets/icons'

const useStyles = makeStyles({ name: 'CheckboxBtn' })((theme) => ({
  root: {
    background: `rgba(13,13,13,0.8)`,
    padding: theme.spacing(1, 2, 1, 1),
    gap: theme.spacing(1),
    fontSize: 12,
    lineHeight: '16px',
    textTransform: 'none',
    borderRadius: theme.spacing(1),

    svg: {
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },
}))

interface Props {
  className?: string
  label: string
  checked: boolean
  onClick?: () => void
}

const CheckboxBtnComponent: FC<Props> = ({
  className,
  label,
  checked,
  onClick,
}) => {
  const { classes, cx } = useStyles()

  return (
    <Button
      variant={'outlined'}
      color={'secondary'}
      className={cx(classes.root, className && className)}
      onClick={() => onClick && onClick()}
    >
      {checked ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
      <span>{label}</span>
    </Button>
  )
}

export const CheckboxBtn = memo(CheckboxBtnComponent)
