import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { FC } from 'react'
import { RoseCollectionTab } from './RoseCollectionTab'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles({ name: 'RoseCollectionTabList' })((theme) => ({
  root: {
    width: '100%',
    borderBottom: `1px solid rgba(255, 255, 255, .4)`,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  content: {
    width: '100%',
    height: 600,
    background: theme.palette.background.default,
  },
}))

interface Props {
  tabs: Array<{
    title: string
    description: string
    imageUrl: string
    link: string
  }>
  activeTab: string
  className?: string
}

export const RoseCollectionTabList: FC<Props> = ({
  tabs,
  activeTab,
  className,
}) => {
  const { classes, cx } = useStyles()

  const navigate = useNavigate()

  return (
    <>
      <div className={cx(classes.root, className && className)}>
        <Container maxWidth={'lg'}>
          <div className={classes.container}>
            {tabs.map((tab) => (
              <RoseCollectionTab
                key={tab.title}
                title={tab.title}
                description={tab.description}
                imageUrl={tab.imageUrl}
                isActive={activeTab === tab.title}
                onClick={() => {
                  navigate(tab.link)
                }}
              />
            ))}
          </div>
        </Container>
      </div>
    </>
  )
}
