import {
  RoseCollectionById,
  itemStickersImages,
  ItemStickersName,
} from 'constants/stickers'
import { publicImages } from 'utils/getPublicImages'

export const ROSE_MERCH_DETAILS_CONTENT: Record<
  string,
  {
    title: string
    description: string
    stickerImgUrl: string
    merchImgUrl: string
    itemStickerId: RoseCollectionById
  }
> = {
  [ItemStickersName.Cap]: {
    title: 'Cap',
    description:
      "Stay cool and comfortable while showing off your love for Thug Rose and your ultimate Champion spirit with this stylish cap. With a sleek design featuring Rose's signature logo, this limited-edition cap is the perfect accessory for any fan.",
    stickerImgUrl: itemStickersImages[RoseCollectionById.Cap],
    merchImgUrl: publicImages.getIllustration(`rose/merch/cap.webp`),
    itemStickerId: RoseCollectionById.Cap,
  },
  [ItemStickersName.Hoodie]: {
    title: 'Hoodie',
    description:
      "Wrap yourself in the Thug Rose spirit with this limited-edition hoodie, representing strength, perseverance, dedication, and the Champion spirit. With a sleek design featuring Rose's signature logo, this limited-edition cap is the perfect accessory for any fan.",
    stickerImgUrl: itemStickersImages[RoseCollectionById.Hoodie],
    merchImgUrl: publicImages.getIllustration(`rose/merch/hoodie.webp`),
    itemStickerId: RoseCollectionById.Hoodie,
  },
  [ItemStickersName['T-Shirt']]: {
    title: 'T-Shirt',
    description:
      "Show off your love for Thug Rose and your inner fighter with this limited-edition t-shirt. With a unique design featuring Rose's signature logo, this t-shirt represents strength, perseverance, dedication, and the Champion spirit.",
    stickerImgUrl: itemStickersImages[RoseCollectionById['T-Shirt']],
    merchImgUrl: publicImages.getIllustration(`rose/merch/tshirt.webp`),
    itemStickerId: RoseCollectionById['T-Shirt'],
  },
}

export const itemStickersPathId: Record<string, number> = {
  cap: RoseCollectionById.Cap,
  hoodie: RoseCollectionById.Hoodie,
  't-shirt': RoseCollectionById['T-Shirt'],
}
