import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import { fontSpaceMono } from 'styles/fonts'
import { Typography } from '@mui/material'

const useStyles = makeStyles({ name: 'InventoryMissingSticker' })((theme) => ({
  root: {
    maxWidth: 275,
    width: '100%',
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  imageContainer: {
    minWidth: 120,
    width: 120,

    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.common.black,
    width: '100%',
  },
  title: {
    fontWeight: 700,
  },
  text: {
    fontWeight: 400,
    fontFamily: fontSpaceMono,
  },
  btn: {
    textTransform: 'none',

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
}))

interface Props {
  className?: string
  imgUrl: string
  name: string
  price: string | number
  lastSale: string | number
  openSeaUrl: string
}

export const InventoryMissingSticker: FC<Props> = ({
  className,
  imgUrl,
  name,
  price,
  lastSale,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={classes.imageContainer}>
        <img src={imgUrl} alt={name} />
      </div>
      <div className={classes.content}>
        <Typography variant={'body1'} className={classes.title}>
          {name}
        </Typography>
        <div>
          <Typography variant={'subtitle2'}>
            <span>{price}</span> ETH
          </Typography>
          <Typography variant={'subtitle2'} className={classes.text}>
            Last sale: <span>{lastSale}</span> ETH
          </Typography>
        </div>
      </div>
    </div>
  )
}
