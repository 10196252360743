import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  isLoggedInSelector,
  selectIsValidChain,
  selectUserAddress,
  selectUserChainId,
} from 'store/reducers/user/selectors'
import { cacheKeys } from 'constants/cacheKeys'
import { reFetchedFlagSelector } from 'store/reducers/app/selectors'
import { enqueueSnack } from 'store/reducers/snackbar/actions'
import { httpCollectionApi } from 'services/api/http/collection'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'
import { contractErc1155Api } from 'services/api/contract/erc1155'
import { httpGeneralApi } from 'services/api/http/general'
import { NftAvatar } from 'models/INfts'
import { ICollectionAvatarResponse, IRoseItem } from 'models/ICollectionData'

export const useQuery_GetCollection_Collection = (
  options?: UseQueryOptions<ICollectionAvatarResponse>
) => {
  const isLoggedIn = useAppSelector(isLoggedInSelector)
  const userAddress = useAppSelector(selectUserAddress)
  const userChainId = useAppSelector(selectUserChainId)
  const isValidChain = useAppSelector(selectIsValidChain)
  const reFetchedFlag = useAppSelector(reFetchedFlagSelector)
  const dispatch = useAppDispatch()

  return useQuery<ICollectionAvatarResponse>(
    [
      cacheKeys.collectionList,
      isLoggedIn,
      userAddress,
      userChainId,
      isValidChain,
      reFetchedFlag[ReFetchFlag.ClaimAthleteNft],
      reFetchedFlag[ReFetchFlag.ClaimMerch],
    ],
    async () => {
      const { ERC1155balances = [] } =
        await httpCollectionApi.getCollectionList(userAddress as string)

      const filteredERC1155balances = ERC1155balances.filter(
        (item) =>
          item.contract.id.toLowerCase() ===
          ROSE_COLLECTION_CONTRACT_ADDRESS.toLowerCase()
      ).filter((item) => Number(item.token.identifier) >= 100)

      const ids = filteredERC1155balances
        .map((item) => item.token.identifier)
        .sort((a, b) => Number(a) - Number(b))

      const balances = await contractErc1155Api.getErc1155BalanceBatch(
        ids,
        ROSE_COLLECTION_CONTRACT_ADDRESS,
        userAddress as string
      )

      const metadata = await Promise.all([
        ...balances?.map((item, idx) => {
          return httpGeneralApi.getDataByUrl<{ image: string; name: string }>(
            `https://api.digisport.xyz/v1/api/metadata/rose/${ids[idx]}`
          )
        }),
      ])

      const certificates: NftAvatar[] = []
      const beginnerNft: NftAvatar[] = []
      const intermediateNft: NftAvatar[] = []
      const advancedNft: NftAvatar[] = []
      const eliteNft: NftAvatar[] = []
      const legendaryNft: NftAvatar[] = []

      ids.forEach((item, idx) => {
        if (Math.floor(Number(item) / 1e18) === 0 && balances[idx]) {
          certificates.push({
            id: item,
            balance: balances[idx],
            image: metadata[idx].image,
            name: metadata[idx].name,
          })
        }

        if (Math.floor(Number(item) / 1e18) === 1 && balances[idx]) {
          beginnerNft.push({
            id: item,
            balance: balances[idx],
            image: metadata[idx].image,
            name: metadata[idx].name,
          })
        }

        if (Math.floor(Number(item) / 1e18) === 2 && balances[idx]) {
          intermediateNft.push({
            id: item,
            balance: balances[idx],
            image: metadata[idx].image,
            name: metadata[idx].name,
          })
        }

        if (Math.floor(Number(item) / 1e18) === 3 && balances[idx]) {
          advancedNft.push({
            id: item,
            balance: balances[idx],
            image: metadata[idx].image,
            name: metadata[idx].name,
          })
        }

        if (Math.floor(Number(item) / 1e18) === 4 && balances[idx]) {
          eliteNft.push({
            id: item,
            balance: balances[idx],
            image: metadata[idx].image,
            name: metadata[idx].name,
          })
        }

        if (Math.floor(Number(item) / 1e18) === 5 && balances[idx]) {
          legendaryNft.push({
            id: item,
            balance: balances[idx],
            image: metadata[idx].image,
            name: metadata[idx].name,
          })
        }
      })

      return {
        certificates,
        beginnerNft,
        intermediateNft,
        advancedNft,
        eliteNft,
        legendaryNft,
      }
    },
    {
      enabled: isLoggedIn && isValidChain,
      retry: 10,
      retryDelay: 500,
      onError: () => {
        dispatch(
          enqueueSnack({
            message: `Error Collection List, please try later.`,
            variant: 'error',
          })
        )
      },
      ...options,
    }
  )
}

export const useQuery_GetRoseItems_Collection = (
  options?: UseQueryOptions<IRoseItem[]>
) => {
  const userAddress = useAppSelector(selectUserAddress)
  const userChainId = useAppSelector(selectUserChainId)
  const isValidChain = useAppSelector(selectIsValidChain)
  const dispatch = useAppDispatch()

  return useQuery<IRoseItem[]>(
    [cacheKeys.roseItems, userAddress, userChainId, isValidChain],
    async () => {
      const { items } = await httpCollectionApi.getRoseItems(
        userAddress as string
      )

      return items || []
    },
    {
      enabled: !!userAddress && isValidChain,
      onError: () => {
        dispatch(
          enqueueSnack({
            message: `Error Rose Items, please try later.`,
            variant: 'error',
          })
        )
      },
      ...options,
    }
  )
}
