import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import { OpenSeaIcon } from 'assets/icons'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'

const useStyles = makeStyles({ name: 'InventoryFighterAvatar' })((theme) => ({
  root: {
    maxWidth: 308,
    width: '100%',
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
  },
  imageContainer: {
    width: '100%',
    minHeight: 315,

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  title: {
    fontWeight: 700,
    lineHeight: '32px',
  },
  subtitle: {
    color: theme.palette.secondary.light,
  },
  btn: {
    textTransform: 'none',
    marginTop: theme.spacing(1),

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
}))

interface Props {
  className?: string
  imgUrl: string
  name: string
  title: string
  openSeaUrl: string
}

export const InventoryFighterAvatar: FC<Props> = ({
  className,
  imgUrl,
  name,
  title,
  openSeaUrl,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={classes.imageContainer}>
        <img src={imgUrl} alt={name} />
      </div>
      <div className={classes.content}>
        <Typography variant={'h5'} className={classes.title}>
          {name}
        </Typography>
        <Typography variant={'caption'} className={classes.subtitle}>
          {title}
        </Typography>
        <Button
          variant={'text'}
          className={classes.btn}
          href={openSeaUrl}
          target={'_blank'}
        >
          <OpenSeaIcon />
          <span>View on Opensea</span>
        </Button>
      </div>
    </div>
  )
}
