import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import {
  RoseCollectionTabList,
  TABS_ROSE_COLLECTION_INFO,
} from '../../components/RoseCollection'
import Container from '@mui/material/Container'
import { RoseCollectionInfoFighter } from './RoseCollectionInfoFighter'

const useStyles = makeStyles({ name: 'RoseCollectionInfo' })((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    padding: theme.spacing(7, 0, 12),
    width: '100%',
    background: theme.palette.background.default,

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}))

interface Props {
  activeTab: string
}

export const RoseCollectionInfo: FC<Props> = ({ activeTab }) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <RoseCollectionTabList
        tabs={TABS_ROSE_COLLECTION_INFO}
        activeTab={activeTab}
      />
      <div className={classes.container}>
        <Container maxWidth={'lg'}>
          <RoseCollectionInfoFighter />
        </Container>
      </div>
    </div>
  )
}
