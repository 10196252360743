import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import { Typography } from '@mui/material'
import { ArrowRightLineIcon, WarningFillIcon } from 'assets/icons'
import { linearGradients } from 'styles/other'
import { fontSpaceMono } from 'styles/fonts'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

const useStyles = makeStyles({ name: 'InventoryNftBreeding' })((theme) => ({
  root: {
    position: 'relative',
  },
  container: {
    width: 472,
    minWidth: 472,
    minHeight: 472,
    borderRadius: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center center',

    [theme.breakpoints.down('sm')]: {
      width: 288,
      minWidth: 288,
      height: 'auto',
      backgroundSize: '288px 288px',
      backgroundPosition: 'top center',
      marginBottom: theme.spacing(3),
    },

    '&::after': {
      content: "''",
      left: 0,
      position: 'absolute',
      top: 0,
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: 1,
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(3),
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        height: 288,
      },
    },
  },
  content: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2, 2, 3),
    position: 'relative',
    zIndex: 3,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    lineHeight: '32px',
    fontWeight: 700,
  },
  description: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0, 2),
    textAlign: 'center',
    fontSize: 12,
  },
  contentBlock: {
    display: 'flex',
    gap: theme.spacing(1),
    height: '100%',
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentInnerBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',
    width: '100%',
    height: '100%',
    border: `1px solid rgba(255, 255, 255, 0.2)`,
    borderBottom: 'none',
    minHeight: 240,
  },
  contentArrow: {
    minWidth: 48,
    height: 48,
    width: 48,

    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
      minWidth: 24,
      height: 24,
      width: 24,
    },
  },
  contentTitle: {
    fontSize: 16,
    position: 'relative',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    height: '100%',
    width: '100%',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      gap: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
  },
  subtitle: {
    fontFamily: fontSpaceMono,
  },
  contentInfoBigNumber: {
    fontSize: 64,
    lineHeight: '80px',
  },
  btn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    padding: theme.spacing(2.5, 3),

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    gap: theme.spacing(1),
    color: theme.palette.warning.main,

    svg: {
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },
  colorOrange: {
    color: theme.palette.warning.main,
  },
}))

interface Props {
  className?: string
  imageUrl: string
  title: string
  description: string
  fromTitle: string
  toTitle: string
  requiredForBreeding: number
  availableForBreeding: number
  disabled?: boolean
  onBreedNftBtnClick?: () => void
}

export const InventoryNftBreeding: FC<Props> = ({
  className,
  imageUrl,
  title,
  description,
  fromTitle,
  toTitle,
  requiredForBreeding = 4,
  availableForBreeding = 0,
  disabled,
  onBreedNftBtnClick,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <Box
        className={cx(classes.container)}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className={classes.content}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" className={classes.description}>
            {description}
          </Typography>
          <div className={classes.contentBlock}>
            <div className={classes.contentInnerBlock}>
              <Typography variant="caption" className={classes.contentTitle}>
                {fromTitle}
              </Typography>
              <div className={classes.contentInfo}>
                <div>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Required for fusion
                  </Typography>
                  <Typography variant="h3" fontWeight={700}>
                    {requiredForBreeding}
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Available for fusion
                  </Typography>
                  <Typography variant="h3" fontWeight={700}>
                    {availableForBreeding}
                  </Typography>
                </div>
              </div>
            </div>
            <ArrowRightLineIcon className={classes.contentArrow} />
            <div className={classes.contentInnerBlock}>
              <Typography variant="caption" className={classes.contentTitle}>
                {toTitle}
              </Typography>
              <div className={classes.contentInfo}>
                <div>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Available to fuse
                  </Typography>
                  <Typography
                    variant="h3"
                    fontWeight={700}
                    className={cx(
                      classes.contentInfoBigNumber,
                      disabled && classes.colorOrange
                    )}
                  >
                    {Math.floor(availableForBreeding / requiredForBreeding)}
                  </Typography>
                </div>
                {disabled && (
                  <div className={classes.warning}>
                    <WarningFillIcon />
                    <Typography
                      variant="subtitle2"
                      className={classes.subtitle}
                    >
                      Not enough NFTs for fusion
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Button
            variant={'contained'}
            className={classes.btn}
            disabled={disabled}
            onClick={onBreedNftBtnClick}
          >
            <span>Fuse NFTs</span>
            <ArrowRightLineIcon />
          </Button>
        </div>
      </Box>
    </div>
  )
}
