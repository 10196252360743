import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import { Typography } from '@mui/material'

const useStyles = makeStyles({ name: 'InventoryItemCertificate' })((theme) => ({
  item: {
    maxWidth: 308,
    width: '100%',
    borderRadius: theme.spacing(2),
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    minHeight: 308,
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  itemBottom: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  itemBottomSubtitle: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 400,
  },
  itemBottomTitle: {
    lineHeight: '32px',
    fontWeight: 700,
  },
}))

interface Props {
  className?: string
  imgUrl: string
  id: string | number
}

export const InventoryItemCertificate: FC<Props> = ({
  className,
  imgUrl,
  id,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.item, className && className)}>
      <div className={classes.image}>
        <img src={imgUrl} alt="Certificate" />
      </div>
      <div className={classes.itemBottom}>
        <Typography variant={'h5'} className={classes.itemBottomTitle}>
          Certificate
        </Typography>
        <Typography variant={'caption'} className={classes.itemBottomSubtitle}>
          Item ID: #{id}
        </Typography>
      </div>
    </div>
  )
}
