import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { MAIN_LANDING_FAQ } from './constants'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { ArrowRightLineIcon } from 'assets/icons'
import { publicImages } from 'utils/getPublicImages'
import { LINKS_ANCHOR } from 'constants/linksAnchor'

const useStyles = makeStyles({ name: 'MainLandingFAQ' })((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    padding: theme.spacing(10, 0, 20),
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    background: `
        url('${publicImages.getBackground('main-landing/faq-bg.webp')}') 
        top left 
        no-repeat
      `,
    backgroundSize: 'contain',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0, 10),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    maxWidth: 671,
    position: 'relative',
    zIndex: 5,
  },
  subtitle1: {
    fontFamily: fontSpaceMono,
    textTransform: 'uppercase',
    color: theme.palette.info.contrastText,
    lineHeight: '40px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  icon: {
    minWidth: 24,
    width: 24,
    height: 24,
    color: theme.palette.info.contrastText,
    transform: 'rotate(90deg)',
  },
  imageHero: {
    position: 'absolute',
    top: theme.spacing(10),
    left: 0,
    maxWidth: 700,
    zIndex: 1,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  imageContainer: {
    maxWidth: 3272,
    margin: '0 auto',

    img: {
      width: '100%',
    },
  },
  accordion: {
    borderRadius: '16px !important',
    border: `1px solid rgba(255, 255, 255, .4)`,
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(8px)',
    padding: theme.spacing(3, 2, 3, 3),

    a: {
      textDecoration: 'underline',
    },

    '&.Mui-expanded': {
      padding: theme.spacing(3, 2, 3, 3),
      minHeight: 'auto',
    },
  },
}))

export const MainLandingFAQ = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root} id={LINKS_ANCHOR.roseFAQ.id}>
      <Container maxWidth={'lg'}>
        <div className={classes.container}>
          <img
            src={`${publicImages.getIllustration('rose/faq-big.webp')}`}
            alt="rose"
            className={classes.imageHero}
          />
          <div className={classes.content}>
            <div>
              <Typography variant={'subtitle1'} className={classes.subtitle1}>
                Still have Questions?
              </Typography>
              <Typography variant={'h2'}>We have an FAQ!</Typography>
            </div>
            <div className={classes.list}>
              {MAIN_LANDING_FAQ.map((item) => (
                <Accordion key={item.id} className={classes.accordion}>
                  <AccordionSummary
                    expandIcon={<ArrowRightLineIcon className={classes.icon} />}
                    id={`${item.id}_faq_main`}
                  >
                    <Typography variant={'body1'} fontWeight={700}>
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant={'body1'}
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
