export const getErrorApproveErc1155 = (errorMessage: string) => {
  if (errorMessage.indexOf('item already staked') > -1) {
    return 'Item already staked'
  }
  if (errorMessage.indexOf('item already active') > -1) {
    return 'item already active'
  }
  if (errorMessage.indexOf('Max 3 uses per day, per pool') > -1) {
    return 'Daily limit reached'
  }
  if (errorMessage.indexOf('Max 5 uses per day, per pool') > -1) {
    return 'Daily limit reached'
  }

  return 'Unexpected blockchain error'
}
