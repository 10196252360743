import { useQuery_GetRoseItems_Collection } from 'services/useApi/collection/useQuery'
import { RoseInventoryItemsComponent } from './RoseInventoryItemsComponent'
import { MenuInventory } from '../constants'

export const RoseInventoryItemsContainer = () => {
  const { data: items = [], isInitialLoading } =
    useQuery_GetRoseItems_Collection()

  return (
    <RoseInventoryItemsComponent
      activeMenuItem={MenuInventory.Items}
      roseItems={items}
      isInitialLoading={isInitialLoading}
    />
  )
}
