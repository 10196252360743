import { ROUTER_PATHS } from 'routes/routerPaths'
import { ItemStickersName, RoseCollectionById } from 'constants/stickers'
import { publicImages } from 'utils/getPublicImages'

const getHrefForMerch = (path: string) => {
  return `${ROUTER_PATHS.ROSE_NFT_COLLECTION_MERCH}/${path.toLowerCase()}`
}

export const ROSE_MERCH_NFT_CAP = {
  type: RoseCollectionById.Cap,
  merchImgUrl: publicImages.getIllustration(`rose/merch/cap.webp`),
  stickerImgUrl: publicImages.getIllustration(`rose/stickers/sticker-cap.webp`),
  href: getHrefForMerch('cap'),
  title: ItemStickersName.Cap,
}

export const ROSE_MERCH_NFT_HOODIE = {
  type: RoseCollectionById.Hoodie,
  merchImgUrl: publicImages.getIllustration(`rose/merch/hoodie.webp`),
  stickerImgUrl: publicImages.getIllustration(
    `rose/stickers/sticker-hoodie.webp`
  ),
  href: getHrefForMerch('hoodie'),
  title: ItemStickersName.Hoodie,
}

export const ROSE_MERCH_NFT_T_SHIRT = {
  type: RoseCollectionById['T-Shirt'],
  merchImgUrl: publicImages.getIllustration(`rose/merch/tshirt.webp`),
  stickerImgUrl: publicImages.getIllustration(
    `rose/stickers/sticker-tshirt.webp`
  ),
  href: getHrefForMerch('t-shirt'),
  title: ItemStickersName['T-Shirt'],
}

export const ROSE_MERCH_NFTs_TOP_LIST = [
  ROSE_MERCH_NFT_CAP,
  ROSE_MERCH_NFT_HOODIE,
  ROSE_MERCH_NFT_T_SHIRT,
]
