import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { fontSpaceMono } from 'styles/fonts'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const useStyles = makeStyles({ name: 'PrivacyPolicyContent' })((theme) => ({
  main: {
    width: '100%',
    margin: `${theme.spacing(16)} auto 0`,
    textAlign: 'left',

    a: {
      textDecoration: 'underline',
    },

    ul: {
      li: {
        marginBottom: 0,
        fontSize: 16,
        lineHeight: '24px',
      },
    },

    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(8)} auto 0`,
    },
  },
  containerInner: {
    maxWidth: 847,
    margin: '0 auto',
  },
  title: {
    fontSize: 64,
    lineHeight: '80px',
    fontWeight: 800,
    textAlign: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: '48px',
    },
  },
  titleYellow: {
    fontFamily: fontSpaceMono,
    fontSize: 40,
    lineHeight: '64px',
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.secondary.light,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  titleDate: {
    textAlign: 'center',
    width: '100%',
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  subTitle: {
    lineHeight: '32px',
    fontWeight: 700,
  },
  listLowerAlpha: {
    listStyle: 'lower-alpha',

    li: {
      marginBottom: theme.spacing(2),
    },
  },
  tableContainer: {
    marginBottom: theme.spacing(5),

    '& thead th': {
      fontWeight: 700,
      color: theme.palette.secondary.light,
    },
    '& thead p': {
      fontWeight: 700,
      color: theme.palette.secondary.light,
    },
    '& tbody tr': {
      position: 'relative',

      '&:nth-of-type(odd)': {
        '&::after': {
          content: "''",
          left: 0,
          position: 'absolute',
          top: 0,
          backgroundColor: theme.palette.grey['A200'],
          zIndex: 1,
          width: '100%',
          height: '100%',
          borderRadius: theme.spacing(1),
          overflow: 'hidden',
        },
      },
    },
    th: {
      fontSize: 16,
      position: 'relative',
      zIndex: 2,
      border: 'none',
    },
    td: {
      fontSize: 16,
      position: 'relative',
      zIndex: 2,
      border: 'none',
    },
  },
}))

const sxClasses = {
  bodyM2: {
    mb: 2,
  },
  bodyM3: {
    mb: 3,
  },
  bodyM4: {
    mb: 4,
  },
}

export const PrivacyPolicyContent = () => {
  const { classes } = useStyles()

  return (
    <Container maxWidth={'lg'}>
      <main className={classes.main}>
        <div className={classes.containerInner}>
          <Typography className={classes.title}>Privacy Policy</Typography>
          <Typography
            variant={'caption'}
            component={'p'}
            className={classes.titleDate}
          >
            Last updated: 26-05-2023
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            This DIGISPORT.XYZ/ROSE Privacy Policy (“Privacy Policy”) describes
            how the Company Processes Personal Data. Please read this Privacy
            Policy carefully before commencing the use of the Platform. <br />{' '}
            If you have any questions relating to Processing of your Personal
            Data or pertaining to this Privacy Policy, please, contact us at{' '}
            <a href="mailto:support@lympo.io">support@lympo.io</a>.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Definitions
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            <b>Company</b> – Latgala OÜ, registered address at Tornimäe tn 5,
            Tallinn, Estonia, is the sole owner of “IT sprendimai sekmei”, a
            company based in Lithuania. Throughout this Privacy Policy,
            &quot;we&quot; or &quot;us&quot; refers to Latgala OÜ and its
            subsidiaries.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Data Subject</b> – any natural person who uses or has used the
            Platform. In this Privacy Policy may also be referred to as “you”.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Data Controller</b> – anyone who alone or jointly with others
            determines the purposes and means of the Processing of Personal
            Data. For the Processing described in this Privacy Policy the
            Company is the Data Controller.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Data Processor</b> – a person or an entity who Processes Personal
            Data on behalf of the Data Controller.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>GDPR</b> – Regulation (EU) 2016/679 (General Data Protection
            Regulation).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Personal Data</b> – any information relating to an identified or
            identifiable living natural person.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Platform</b> – a platform, programming interface (API),
            applications, materials, any products, services, solutions and other
            items provided by or on behalf of the Company and available through
            website{' '}
            <a
              href="https://digisport.xyz/rose"
              target={'_blank'}
              rel="noreferrer"
            >
              https://digisport.xyz/Rose
            </a>{' '}
            and any affiliated mobile websites and mobile applications, owned
            and/or operated by the Company.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Processing</b> – any operation or set of operations performed
            with regard to Personal Data, whether or not performed by automated
            means, for example collection, recording, organisation, storage,
            adaptation, alteration, retrieval, gathering, use, combination,
            erasure or destruction.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Recipient</b> – a natural or legal person, public authority or
            another body, to whom Personal Data may be lawfully disclosed by the
            Company.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Terms</b> –{' '}
            <a
              href="https://digisport.xyz/rose"
              target={'_blank'}
              rel="noreferrer"
            >
              https://digisport.xyz/Rose
            </a>{' '}
            Terms and Conditions available through website{' '}
            <a
              href="https://digisport.xyz/rose"
              target={'_blank'}
              rel="noreferrer"
            >
              https://digisport.xyz/Rose
            </a>{' '}
            .
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            Other terms used in this Privacy Policy have the meaning described
            in the Terms and GDPR.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            What types of Personal Data we have and how we get it?
          </Typography>
          <Typography variant={'body1'} component={'div'} sx={sxClasses.bodyM2}>
            We currently Process the following information:
            <br />
            <br />
            <ul>
              <li>
                {' '}
                Name and last name, contact number, email address, nationality,
                sport that you participate in, your Twitter page, Youtube
                channel, Instagram profile, Facebook page address (applicable
                only when you provide your information to us via the Platform in
                order to become a Digisport athlete and/or become one and we
                sign an agreement with you);
              </li>
              <li>
                {' '}
                Communication data, related to communication between you and the
                Company via email or by other means;
              </li>
              <li>
                {' '}
                Data that you provide to us via our social network pages (e.g.
                Facebook, Twitter, Linkedin, etc.).
              </li>
              <li>
                {' '}
                IP address (we may also derive your approximate location from
                your IP address)
              </li>
              <li>
                {' '}
                Blockchain wallet address, including related data used to detect
                cryptocurrency and NFT holdings
              </li>
            </ul>
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Legal grounds and purposes of Processing Personal Data
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            We only collect and process Personal Data about you where we have
            legal grounds. Legal grounds include consent (where you have given
            consent), contract (where processing is necessary for the
            performance of the Terms or contract signed with you), legal
            obligations applicable to us and our legitimate interests.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Performance of the Terms or agreement signed with you
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            The main purpose of Processing the Personal Data is to perform the
            Terms or an agreement signed with you. Example of purpose for such
            Processing is management of our legal (contractual) relationships
            with you.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Compliance with legal obligations
          </Typography>
          <Typography variant={'body1'} component={'div'} sx={sxClasses.bodyM2}>
            The Company needs to comply with legal obligations. Therefore, we
            are required to Process Personal Data in accordance with applicable
            laws. Examples of purposes for such Processing are:
            <br />
            <ul>
              <li>
                to comply with rules and regulations relating to accounting,
                legal investigations, etc.;
              </li>
              <li> to fulfil the obligations of applicable litigation laws.</li>
            </ul>
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Legitimate interests
          </Typography>
          <Typography variant={'body1'} component={'div'} sx={sxClasses.bodyM2}>
            The Company Processes Personal Data for our legitimate interests.
            This Processing is necessary for the purposes of a legitimate
            interest pursued by the Company, which we have considered
            outweighing the Data Subject’s interest of protection of the
            Personal Data. Examples of purposes for such Processing are:
            <br />
            <ul>
              <li>
                {' '}
                to develop, examine and improve the Platform and/or the Data
                Subject’s user experience by performing analyses, statistics,
                etc.;
              </li>
              <li>
                {' '}
                to prevent or investigate possible fraud or other violations of
                the Terms and/or attempts to harm other Platform users;
              </li>
              <li> to reply to the Data Subject’s requests;</li>
              <li> to establish and defend legal claims.</li>
            </ul>
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Consent
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            Some of the data may be Processed based on the Data Subject’s
            consent, e.g. when you subscribe to our newsletter. Where we rely on
            your consent to process Personal Data, you have the right to
            withdraw or decline your consent at any time by contacting us at{' '}
            <a href="mailto:support@lympo.io">support@lympo.io</a>.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Whom we disclose the Personal Data?
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            The Company may share some Personal Data with Recipients, such as
            competent authorities. The Company does not disclose more Personal
            Data than necessary for the purpose of disclosure. Recipients may
            Process the Personal Data acting as Data Processors and/or as Data
            Controllers. When Recipient is Processing Personal Data on its own
            behalf as a Data Controller, the Recipient is responsible for
            providing information on such Processing of Personal Data to the
            Data Subject.
          </Typography>
          <Typography variant={'body1'} component={'div'} sx={sxClasses.bodyM2}>
            The Company may disclose Personal Data to Recipients, such as:
            <ul>
              <li>
                {' '}
                Data Processors invoked by the Company for development and/or
                maintenance of the Platform or our services;
              </li>
              <li> competent authorities, such as law enforcement agencies;</li>
              <li>
                {' '}
                financial and legal consultants, auditors, other service
                providers and affiliates of the Company, to whom disclosing of
                the Personal Data is necessary and lawful.
              </li>
            </ul>
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            How and how long do we store the Personal Data?
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            As a general rule Data Subject’s Personal Data are Processed within
            the EU/EEA. The transfer and Processing of Personal Data outside of
            the EU/EEA could only take place if there is a legal basis and
            appropriate safeguards are taken.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            We keep Personal Data for a limited time period and in any event no
            longer than necessary for the purposes for which the Personal Data
            were collected. The retention periods are defined by the laws or
            legitimate interests of the Company. After the retention period
            comes to an end the Company will then delete the Personal Data.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Use of Cookies
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            We use cookies as described in the table below.
          </Typography>
        </div>
        <div className={classes.containerInner}>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Performance cookies
          </Typography>
        </div>
        <TableContainer className={classes.tableContainer}>
          <Table sx={{ minWidth: 800 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Cookie key</Typography>
                </TableCell>
                <TableCell align="left">Domain</TableCell>
                <TableCell align="left">Path</TableCell>
                <TableCell align="left">Cookie type</TableCell>
                <TableCell align="left">Expiration</TableCell>
                <TableCell align="left" sx={{ maxWidth: 200 }}>
                  Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  _ga
                </TableCell>
                <TableCell align="left">
                  Google LLC <br /> .digisport.xyz/Rose
                </TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">First-party</TableCell>
                <TableCell align="left">1 year 1 month</TableCell>
                <TableCell align="left" sx={{ maxWidth: 200 }}>
                  This cookie name is associated with Google Universal Analytics
                  - which is a significant update to Google&apos;s more commonly
                  used analytics service. This cookie is used to distinguish
                  unique users by assigning a randomly generated number as a
                  client identifier. It is included in each page request in a
                  site and used to calculate visitor, session and campaign data
                  for the sites analytics reports.
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  _ga_98BV24J4FY
                </TableCell>
                <TableCell align="left">.digisport.xyz/Rose</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">First-party</TableCell>
                <TableCell align="left">1 year 1 month</TableCell>
                <TableCell align="left" sx={{ maxWidth: 200 }}>
                  This cookie is set by Google Analytics. It stores and update a
                  unique value for each page visited and is used to count and
                  track pageviews.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.containerInner}>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Targeting cookies
          </Typography>
        </div>
        <TableContainer className={classes.tableContainer}>
          <Table sx={{ minWidth: 800 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Cookie key</Typography>
                </TableCell>
                <TableCell align="left">Domain</TableCell>
                <TableCell align="left">Expiration</TableCell>
                <TableCell align="left" sx={{ maxWidth: 200 }}>
                  Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  YSC
                </TableCell>
                <TableCell align="left">
                  <>
                    <a
                      href="https://policies.google.com/privacy"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      <b>Google LLC</b>
                    </a>
                    <br />
                    .youtube.com
                  </>
                </TableCell>
                <TableCell align="left">Session</TableCell>
                <TableCell align="left" sx={{ maxWidth: 200 }}>
                  This cookie is set by YouTube to track views of embedded
                  videos.
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  VISITOR_INFO1_LIVE
                </TableCell>
                <TableCell align="left">
                  <>
                    <a
                      href="https://policies.google.com/privacy"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      <b>Google LLC</b>
                    </a>
                    <br />
                    .youtube.com
                  </>
                </TableCell>
                <TableCell align="left">6 months</TableCell>
                <TableCell align="left" sx={{ maxWidth: 200 }}>
                  This cookie is set by Youtube to keep track of user
                  preferences for Youtube videos embedded in sites;it can also
                  determine whether the website visitor is using the new or old
                  version of the Youtube interface.
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  _fbp
                </TableCell>
                <TableCell align="left">
                  <>
                    <a
                      href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      <b>Meta Platform Inc.</b>
                    </a>
                    <br />
                    .digisport.xyz
                  </>
                </TableCell>
                <TableCell align="left">3 months</TableCell>
                <TableCell align="left" sx={{ maxWidth: 200 }}>
                  Used by Meta to deliver a series of advertisement products
                  such as real time bidding from third party advertisers
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.containerInner}>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Swipelux cookies
          </Typography>
        </div>
        <TableContainer className={classes.tableContainer}>
          <Table sx={{ minWidth: 800 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Cookie key</Typography>
                </TableCell>
                <TableCell align="left">Domain</TableCell>
                <TableCell align="left">Path</TableCell>
                <TableCell align="left">Expiration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  _ga_YSRDBV7BWL
                </TableCell>
                <TableCell align="left">.swipelux.com</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">1 year 1 month</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  analytics_session_id.last_access
                </TableCell>
                <TableCell align="left">.swipelux.com</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">1 year</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  analytics_session_id
                </TableCell>
                <TableCell align="left">.swipelux.com</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">1 year</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  intercom-session-no0vwrwo
                </TableCell>
                <TableCell align="left">.swipelux.com</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">1 week</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  intercom-id-no0vwrwo
                </TableCell>
                <TableCell align="left">.swipelux.com</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">1 year</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  intercom-device-id-no0vwrwo
                </TableCell>
                <TableCell align="left">.swipelux.com</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">1 year</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  ajs_anonymous_id
                </TableCell>
                <TableCell align="left">.swipelux.com</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">1 year</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  _ga
                </TableCell>
                <TableCell align="left">.swipelux.com</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">1 year 1 month</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  ajs_user_id
                </TableCell>
                <TableCell align="left">.swipelux.com</TableCell>
                <TableCell align="left">/</TableCell>
                <TableCell align="left">1 year</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.containerInner}>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            You can configure your browser to decline some or all cookies or to
            ask for your permission before accepting them. Please note that by
            deleting cookies or disabling future cookies you may be unable to
            access certain areas or features of our website. For information on
            how to adjust or change your browser settings, visit{' '}
            <a href="www.aboutcookies.org" target={'_blank'}>
              www.aboutcookies.org
            </a>{' '}
            or{' '}
            <a href="www.allaboutcookies.org" target={'_blank'}>
              www.allaboutcookies.org
            </a>{' '}
            .
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            For the information on, policy and control options for the
            third-party cookies, please read cookie policies of such third
            parties.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Use of Google Analytics
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            Google Analytics is integrated on our website{' '}
            <a
              href="https://digisport.xyz/Rose"
              target={'_blank'}
              rel="noreferrer"
            >
              https://digisport.xyz/Rose
            </a>
            .
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            Google Analytics is a website analysis service. Website analysis
            refers to the collection, recording and analysis of data regarding
            the behaviour of visitors to the website. You can find more
            information about Google Analytics’ use of Personal Data here:{' '}
            <a
              href="https://policies.google.com/privacy?hl=en"
              target={'_blank'}
              rel="noreferrer"
            >
              https://policies.google.com/privacy?hl=en
            </a>
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Use of Venly
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            Our website{' '}
            <a
              href="https://digisport.xyz/rose"
              target={'_blank'}
              rel="noreferrer"
            >
              https://digisport.xyz/Rose
            </a>{' '}
            integrates the Venly solution, which allows users to sign up and log
            in with social accounts and create WEB3 wallets for use on the NFT
            platform. Venly is a third-party service provider, and as such, may
            collect and process Personal Data of our users in accordance with
            their own privacy policy. By using the Venly solution on our
            website, you acknowledge and agree that your Personal Data may be
            shared with Venly and processed in accordance with their privacy
            policy, which can be found here:{' '}
            <a
              href="https://www.venly.io/legal/privacy-policy"
              target={'_blank'}
              rel="noreferrer"
            >
              https://www.venly.io/legal/privacy-policy
            </a>{' '}
            . The Company assumes no responsibility or liability for the
            collection, use, or management of Personal Data by Venly.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Use of Swipelux
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            Our website{' '}
            <a
              href="https://digisport.xyz/rose"
              target={'_blank'}
              rel="noreferrer"
            >
              https://digisport.xyz/Rose
            </a>{' '}
            integrates the Swipelux payment solution, which enables users to buy
            cryptocurrency on the NFT platform using credit or debit card. As a
            data controller, Latgala OÜ may access some of your personal data,
            such as email address, phone number, wallet address, and order ID,
            through a provided dashboard. The personal data that is accessed by
            Latgala OÜ is used solely for the purpose of processing the
            transaction and providing customer support as necessary. By using
            the Swipelux payment solution on our website, you acknowledge and
            agree that your personal data may be shared with Swipelux and
            processed in accordance with their privacy policy, which can be
            found here:{' '}
            <a
              href="https://swipelux.com/privacy_policy"
              target={'_blank'}
              rel="noreferrer"
            >
              https://swipelux.com/privacy_policy
            </a>{' '}
            . The Company assumes no responsibility or liability for the
            collection, use, or management of personal data by Swipelux.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Security
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            We seek to use reasonable organizational, technical and
            administrative measures to protect Personal Information within our
            organization. Unfortunately, no data transmission or storage system
            can be guaranteed to be 100% secure. If you have reason to believe
            that your interaction with us is no longer secure, please
            immediately notify us in accordance with the section below.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Data Subject’s rights and how to contact?
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            Under GDPR, you have the following rights:
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Your right of access</b> – you have the right to ask us for
            copies of your personal information.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Your right to rectification</b> – you have the right to ask us to
            rectify information you think is inaccurate. You also have the right
            to ask us to complete information you think is incomplete.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Your right to erasure</b> – you have the right to ask us to erase
            your personal information in certain circumstances.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Your right to restriction of Processing</b> – you have the right
            to ask us to restrict the Processing of your information in certain
            circumstances.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Your right to object to Processing</b> – you have the right to
            object to the Processing of your information in certain
            circumstances.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            <b>Your right to data portability</b> – you have the right to ask
            that we transfer the information you gave us to another
            organisation, or to you, in certain circumstances.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            If you make a request, we have one month to respond to you.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            Please contact us at{' '}
            <a href="mailto:support@lympo.io">support@lympo.io</a> if you wish
            to make a request.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            You can also complain to the Estonian Data Protection Inspectorate
            (website address:{' '}
            <a href="https://www.aki.ee/en" target={'_blank'} rel="noreferrer">
              https://www.aki.ee/en
            </a>{' '}
            ) if you are unhappy with how we Processed your Personal Data.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            The Privacy Policy can be amended from time to time due to
            legislative changes, changes in the Platform, activities of the
            Company and other factors. We will inform you about the amendments
            by publishing the amended text of the Privacy Policy on the website{' '}
            <a
              href="https://digisport.xyz/rose"
              target={'_blank'}
              rel="noreferrer"
            >
              https://digisport.xyz/Rose
            </a>{' '}
            . We advise you to regularly review the relevant and applicable
            Privacy Policy.
          </Typography>
        </div>
      </main>
    </Container>
  )
}
