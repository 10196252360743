import { MainLandingHeading } from './MainLandingHeading'
import { MainLandingHiW } from './MainLandingHiW'
import { MainLandingPartners } from './MainLandingPartners'
import { MainLandingSocial } from './MainLandingSocial'
import { MainLandingFAQ } from './MainLandingFAQ'
import { MainLandingMerch } from './MainLandingMerch'
import { MainLandingBuildWithPacks } from './MainLandingBuildWithPacks'
import { MainLandingLayoutTop } from './MainLandingLayoutTop'
import { MainLandingLayoutBottom } from './MainLandingLayoutBottom'
import { MainLandingLayout } from './MainLandingLayout'
import { MainLandingDoubleSliderHeroes } from './MainLandingDoubleSliderHeroes'
import { RoseSliderHeroes } from '../components/RoseSliderHeroes'

export const MainLanding = () => {
  return (
    <MainLandingLayout>
      <MainLandingLayoutTop>
        <MainLandingHeading />
      </MainLandingLayoutTop>
      <MainLandingHiW />
      <MainLandingDoubleSliderHeroes />
      <MainLandingBuildWithPacks />
      <MainLandingLayoutBottom>
        <MainLandingMerch />
        <RoseSliderHeroes />
        <MainLandingFAQ />
        <MainLandingSocial />
        <MainLandingPartners />
      </MainLandingLayoutBottom>
    </MainLandingLayout>
  )
}
