import { RoseWhatInsidePacks } from '../components/RoseWhatInsidePacks'
import { RoseCollectionInfo } from './RoseCollectionInfo'
import {
  RoseCollectionHeading,
  RoseCollectionLayout,
  RoseCollectionTabs,
} from '../components/RoseCollection'

export const RoseCollectionMerch = () => {
  return (
    <RoseCollectionLayout currentTab={RoseCollectionTabs.Merch}>
      <RoseCollectionHeading />
      <RoseCollectionInfo activeTab={RoseCollectionTabs.Merch} />
      <RoseWhatInsidePacks />
    </RoseCollectionLayout>
  )
}
