import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'

const useStyles = makeStyles({ name: 'RoseCollectionHeading' })((theme) => ({
  root: {
    padding: theme.spacing(25, 0, 17),
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(11, 0, 7),
    },
  },
  container: {
    textAlign: 'center',
  },
  subtitle: {
    color: theme.palette.secondary.light,
    fontFamily: fontSpaceMono,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    fontSize: 104,
    lineHeight: '125px',
  },
  text: {
    maxWidth: 848,
    fontWeight: 700,
    lineHeight: '32px',
    margin: `${theme.spacing(3)} auto 0`,

    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(1)} auto 0`,
    },
  },
}))

export const RoseCollectionHeading = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth={'lg'}>
        <div className={classes.container}>
          <Typography variant={'h3'} className={classes.subtitle}>
            Welcome to your
          </Typography>
          <Typography variant={'h1'} className={classes.title}>
            Digital sticker collection
          </Typography>
          <Typography variant={'h6'} className={classes.text}>
            Explore your digital sticker collection. Take a look at your
            collector’s journey so far and calculate the next steps towards
            building the fighter hidden within you.
          </Typography>
        </div>
      </Container>
    </div>
  )
}
