import { RoseCollectionDetailsLayout } from '../components/RoseCollectionDetailsLayout'
import { RoseMerchDetailsHeading } from './RoseMerchDetailsHeading'
import { useParams } from 'react-router-dom'

export const RoseMerchDetails = () => {
  const { id: paramId = '' } = useParams()

  return (
    <RoseCollectionDetailsLayout isMerchPage>
      <RoseMerchDetailsHeading paramId={paramId} />
    </RoseCollectionDetailsLayout>
  )
}
