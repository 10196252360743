import { makeStyles } from 'styles/makeStyles'
import { RoseInventoryHeading } from '../RoseInventoryHeading'
import { LINKS_ANCHOR } from 'constants/linksAnchor'
import { itemMerchImages, itemStickersNameById } from 'constants/stickers'
import { InventoryItem } from 'components/InventoryItems'
import Skeleton from '@mui/material/Skeleton'
import { fontSpaceMono } from 'styles/fonts'
import { TShirtIcon } from 'assets/icons'
import Typography from '@mui/material/Typography'
import { IRoseItem } from 'models/ICollectionData'
import { MenuInventory } from '../constants'
import { FC, memo } from 'react'
import { RoseInventoryLayout } from '../RoseInventoryLayout'
import { arePropsEqual } from 'utils/arePropsEqual'

const useStyles = makeStyles({ name: 'RoseInventoryItemsComponent' })(
  (theme) => ({
    root: {
      width: '100%',
    },
    content: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
      margin: theme.spacing(3, 0, 10),

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    emptyBlock: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(2),
      margin: theme.spacing(3, 0, 10),
      borderRadius: theme.spacing(3),
      border: `1px solid rgba(255, 255, 255, 0.4)`,
      borderBottom: 'none',
      padding: theme.spacing(5, 2, 0),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2, 0),
      },

      svg: {
        minWidth: 120,
        width: 120,
      },
    },
    emptyBlockTitle: {
      fontFamily: fontSpaceMono,
      lineHeight: '32px',
      textAlign: 'center',
      width: '100%',
      fontWeight: 700,
    },
  })
)

interface Props {
  roseItems: IRoseItem[]
  isInitialLoading: boolean
  activeMenuItem: MenuInventory
}

const RoseInventoryItems: FC<Props> = ({
  roseItems,
  isInitialLoading,
  activeMenuItem,
}) => {
  const { classes, cx } = useStyles()

  return (
    <RoseInventoryLayout activeMenuItem={activeMenuItem}>
      <div className={cx(classes.root)} id={LINKS_ANCHOR.inventoryItems.id}>
        <RoseInventoryHeading
          title={'Items'}
          description={
            'Show off your Thug Rose spirit with limited-edition hoodies, t-shirts, and caps representing strength, perseverance, dedication, and the Champion spirit.'
          }
        />
        <div className={classes.content}>
          {isInitialLoading ? (
            <Skeleton
              sx={{
                transform: 'inherit',
                width: '100%',
                height: 300,
                borderRadius: 4,
                mt: 3,
                mb: 10,
              }}
            />
          ) : (
            <>
              {!roseItems.length ? (
                <div className={classes.emptyBlock}>
                  <TShirtIcon />
                  <Typography
                    variant={'h5'}
                    className={classes.emptyBlockTitle}
                  >
                    You don’t own any merch items
                  </Typography>
                </div>
              ) : (
                <>
                  {roseItems.map((item) => (
                    <InventoryItem
                      key={item.id}
                      title={itemStickersNameById[item.typeId]}
                      imgUrl={itemMerchImages[item.typeId]}
                      id={item.id}
                      status={item.status}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </RoseInventoryLayout>
  )
}

export const RoseInventoryItemsComponent = memo(
  RoseInventoryItems,
  arePropsEqual
)
