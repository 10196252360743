import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { fontSpaceMono } from 'styles/fonts'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'
import { selectUserAddress } from 'store/reducers/user/selectors'
import { useAppSelector } from 'store/hooks'

const useStyles = makeStyles({ name: 'LicenceAgreementContent' })((theme) => ({
  main: {
    width: '100%',
    margin: `${theme.spacing(16)} auto 0`,
    textAlign: 'left',

    a: {
      textDecoration: 'underline',
    },

    ul: {
      li: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },

    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(8)} auto 0`,
    },
  },
  containerInner: {
    maxWidth: 847,
    margin: '0 auto',
  },
  title: {
    fontSize: 64,
    lineHeight: '80px',
    fontWeight: 800,
    textAlign: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      lineHeight: '48px',
    },
  },
  titleYellow: {
    fontFamily: fontSpaceMono,
    fontSize: 40,
    lineHeight: '64px',
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.secondary.light,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  titleDate: {
    textAlign: 'center',
    width: '100%',
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  subTitle: {
    lineHeight: '32px',
    fontWeight: 700,
  },
  listLowerAlpha: {
    listStyle: 'lower-alpha',

    li: {
      marginBottom: theme.spacing(2),
    },
  },
}))

const sxClasses = {
  bodyM2: {
    mb: 2,
  },
  bodyM3: {
    mb: 3,
  },
  bodyM4: {
    mb: 4,
  },
}

export const LicenceAgreementContent = () => {
  const { classes } = useStyles()

  const userAddress = useAppSelector(selectUserAddress)

  return (
    <Container maxWidth={'lg'}>
      <main className={classes.main}>
        <div className={classes.containerInner}>
          <Typography className={classes.title}>Licence Agreement</Typography>
          <Typography className={classes.titleYellow}>Personal Use</Typography>
          <Typography
            variant={'caption'}
            component={'p'}
            className={classes.titleDate}
          >
            Last updated: 26-05-2023
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            This licence agreement (this “<b>Agreement</b>”) sets out the terms
            relating to the grant of a licence in certain intellectual property
            in digisport.xyz/Rose, which is a collection of digital collectable
            tokens using the ERC-1155 token standard, which is more commonly
            known as non-fungible tokens (“<b>NFTs</b>”). Digital collectibles
            have been minted via a smart contract deployed on the Polygon
            blockchain at addresses: {ROSE_COLLECTION_CONTRACT_ADDRESS} (the “
            <b>Smart Contract</b>”). Digital collectibles contain metadata which
            are associated with digital art created by the Creator (as defined
            below) (the “<b>Artwork</b>”). For the purposes of this Agreement,
            the “<b>Licensed NFT Artwork</b>” refers to the Artwork (in its
            entirety) specifically, directly and identifiably associated with
            the Digital collectible owned by you in totality and not any
            individual component of the Artwork.
          </Typography>
          <Typography variant={'body1'} component={'div'} sx={sxClasses.bodyM2}>
            This Agreement, which contains legally binding provisions, is
            initially entered into between:
            <br />
            <br />
            <ul>
              <li>
                LATGALA OÜ, a company incorporated under the laws of Lithuania
                (“<b>Creator</b>”); and
              </li>
              <li>
                the individual or entity who is the recorded owner of any
                <b>Digital collectable</b> (item stickers (NFTs), Fighter avatar
                stickers (NFTs), Mystery boxes (NFTs), Fighter avatars (NFTs),
                item certificate (NFTs)) and who has expressly accepted the
                terms of this Agreement by inserting their electronic signature
                below (“<b>you</b>”).
              </li>
            </ul>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            Purchasing a non-fungible token does not automatically grant you any
            intellectual property rights or licences to any artwork depicted by
            the non-fungible token. Accordingly, you agree to enter into this
            Agreement in order to be granted the right to use the Licensed NFT
            Artwork. Individuals and entities who subsequently become owners of
            Digital collectibles using a secondary marketplace that supports
            creator fees may enter into this Agreement by inserting their
            electronic signature below, subject to the verification of ownership
            of one or more Digital collectibles through a process specified by
            the Creator in its sole discretion.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            Whereas:{' '}
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            A. The Smart Contract maintains a record of which digital wallet is
            the owner of a specific Digital collectable. Accordingly, when you
            purchase a Digital collectible on a secondary marketplace or receive
            a Digital collectible by way of a transfer, your ownership of that
            specific Digital collectible is recorded by the Smart Contract.
            Holding a Digital collectible grants you the non-exclusive right to
            hold, sell, transfer and carry out blockchain transactions involving
            that Digital collectible (“Your Digital collectible”).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            B. The Creator retains absolute ownership of all intellectual
            property rights, title and interest in the Artwork of the Digital
            collectibles, including but not limited to copyrights, trade marks,
            goodwill and other intellectual property rights.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            C. You are granted a licence on the terms set out in this Agreement
            to use the Licensed NFT Artwork for as long as you own the relevant
            Digital collectible. As between you and the Creator, you own all
            rights, title and interest in and to any modifications, revisions,
            adaptations or transformations based upon Your Digital collectible
            during the Term (as defined below) (“Derivative Works”), provided
            that (i) the Creator retains the ownership of all intellectual
            property rights in the Licensed NFT Artwork; or (ii) your use of any
            Derivative Works during and after the Term is subject to the terms
            of the Licence (as defined below).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            D. Holding Your Digital collectible may provide you with future
            utility, provided that your enjoyment of any potential future
            utility will be subject to the terms of this Agreement.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            E. You and the Creator agree as follows:
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            1. Licence.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            1.1 Subject to the terms of this Agreement and provided that you
            have acquired Your Digital collectible lawfully, the Creator hereby
            grants to you during the Term (as defined below) a non-exclusive,
            non-transferrable, sublicensable, universe-wide, royalty-free
            licence to use the Licensed NFT Artwork in any and all formats and
            media, by any and all technologies and means of delivery, for
            personal recreational, non-commercial use, including displaying the
            Licensed NFT Artwork as a profile picture, publicly displaying the
            Licensed NFT Artwork for personal enjoyment, whether in a physical
            or digital museum or other venue, and preparation of the Derivative
            Works (the “Licence”). The Licence does not permit you to
            commercialize or make commercial uses of any of the intellectual
            property associated with Your Digital collectible, including the
            Licensed NFT Artwork.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            1.2 Except as expressly provided herein, ownership of a Digital
            collectible and the Licence are not separable in any way. You may
            not engage in any transaction or activity that purports to decouple
            the Licence from Your Digital collectible.
          </Typography>
          <Typography variant={'body1'} component={'div'} sx={sxClasses.bodyM2}>
            1.3 The Licence is subject to, and conditional upon your continued
            compliance with, the following restrictions and qualifications:
            <ol className={classes.listLowerAlpha}>
              <li>
                The Licence extends only to the Licensed NFT Artwork. Therefore,
                while the Licence allows you to create and exploit the
                Derivative Works, the Licence does not grant you rights in any
                individual element (such as the features, attributes,
                properties, or traits) of Your Digital collectible or the
                Licensed NFT Artwork or a licence to exploit any individual
                element separate and apart from Your Digital collectible (such
                as the features, attributes, properties, traits and other
                elements of other Digital collectibles).
              </li>
              <li>
                The Licence does not grant you any rights in or to any trade
                names, logos, brands or trade marks of the Creator or any of its
                affiliates (“Creator Brand IP Rights”), all of which are
                expressly reserved to the Creator and its respective affiliates.
                This means that to the extent that the Licensed NFT Artwork
                contains any Creator Brand IP Rights, you will need to blur or
                edit out such Creator IP Rights prior to any use of the Licensed
                NFT Artwork unless you receive additional written permission
                from the Creator. You hereby agree that any Creator Brand IP
                Rights you purport to acquire, together with any associated
                goodwill, shall automatically, immediately, and at your expense
                be assigned to the Creator or an affiliate designated by the
                Creator.
              </li>
              <li>
                The Licence does not grant you any rights to use the business
                name of “digisport.xyz“ or any other business name of the
                Creator and its affiliates, all of which are exclusively
                reserved to the Creator and its affiliates. The Licence does not
                grant you any right to represent yourself as being associated
                with the Creator or any of its affiliates in any capacity.
                Specifically, nothing contained in this Agreement will be
                construed as creating any agency, partnership, joint venture, or
                other form of joint enterprise, employment, or fiduciary
                relationship between you and the Creator, and neither you nor
                the Creator will have authority to contract for or bind the
                other in any manner whatsoever.
              </li>
              <li>
                You may not use the Licensed NFT Artwork (i) on any physical or
                digital tobacco, pharmaceutical or regulated product; (ii) on
                any physical or digital weapons or other items which may
                represent or are associated with war (iii) in a manner that
                expresses hate or prejudice or encourages violence towards a
                person or group based on membership in a protected class, such
                as race, religion, gender, orientation, or disability; or (iv)
                any manner which would bring the Creator into disrepute.
              </li>
              <li>
                You may not use the Licensed NFT Artwork in a manner that
                violates any applicable law.
              </li>
              <li>
                While your right to use the Licensed NFT Artwork under the
                Licence is royalty-free, you agree to pay a creator fee
                (otherwise known as a resale royalty) when you buy or sell the
                Digital collectibles. The Creator applies various amounts as the
                percentage of creator fees. These creator fees are deducted each
                time a Digital collectible is sold on a secondary marketplace
                that supports such creator fees. You undertake not to transfer
                your ownership in any of Your Digital collectibles in any manner
                which would purposefully result in you failing to pay the
                creator fees in full. For the avoidance of doubt, you are not
                prohibited from gifting Your Digital collectibles to another
                person or making a transfer that is not for the purpose of
                circumventing the payment of creator fees.
              </li>
              <li>
                As a continuing condition of receiving the rights under this
                Licence, you agree to the collection and payment of creator fees
                to the Creator when you buy and sell the NFT. You agree that you
                will not use Your Digital collectibles and the Licensed NFT
                Artwork in any way that circumvents the payment of creator fees
                in the sale of Your Digital collectibles. You agree that you
                will not offer to sell or sell Your Digital collectibles on any
                website, app, intermediary, marketplace, platform, or exchange
                (collectively, “marketplace”) that does not collect or enforce
                creator fees or that makes creator fees optional or adopts a
                so-called “zero royalty” approach. You agree that offering to
                sell or selling Your Digital collectibles on a marketplace that
                does not collect or enforce creator fees or makes creator fees
                optional, or conducting a sale by any other manner (such as
                peer-to-peer transactions) that circumvents the payment of
                creator fees, is not authorized by this Licence and constitutes
                a breach of this Licence. Additionally, if you sell Your Digital
                collectibles on a marketplace that does not collect the creator
                fees, you agree to pay the amount of creator fees to the
                Creator.
              </li>
              <li>
                Your obligation to pay creator fees in the sale of Your NFTs
                shall expire when the copyright term to the Artwork expires.
              </li>
              <li>
                The Licence does not permit you to (i) mint another non-fungible
                token using the Licensed NFT Artwork; (ii) use the Licensed NFT
                Artwork in a manner which competes with the Creator; or (iii) in
                audiovisual media.
              </li>
              <li>
                All rights not expressly granted in this Agreement are reserved
                by the Creator.
              </li>
            </ol>
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            2. Licence.
          </Typography>
          <Typography variant={'body1'} component={'div'} sx={sxClasses.bodyM2}>
            2.1 The Creator acknowledges that not every owner of a Digital
            collectible will be aware of the need to enter into a licence
            agreement with the Creator upon the acquisition of a Digital
            collectible. Accordingly, by entering into this Agreement, the
            Creator hereby releases you from all liability and obligations and
            irrevocably waives its right to make a claim against you in respect
            of any infringement of Creator Brand IP Rights in respect of the
            Licensed NFT Artwork, subject to the following conditions:
            <ol className={classes.listLowerAlpha}>
              <li>
                your use of the Licensed NFT Artwork must not have breached any
                term of this Agreement. In other words, the Creator will treat
                you as if you entered into this Agreement upon you legally
                acquiring Your Digital collectible; and
              </li>
              <li>
                the Creator’s release and waiver shall only apply in respect of
                the period commencing from the date you acquire Your Digital
                collectible lawfully and ending immediately upon your transfer
                of Your Digital collectible to another person.
              </li>
            </ol>
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            2.1 The Creator agrees to carry out all such acts to give effect to
            the release and waiver set out in this Clause 2. Specifically, if
            the release set out in Clause 2 is insufficient under any laws
            applicable to you, the Creator shall execute in your favour a
            separate document giving effect to the release and waiver.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            3. Our commitments to each other.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            3.1 Each party represents and warrants to the other that it has the
            full right, power, and authority to enter into, perform, and grant
            the rights and licences it grants and is required to grant under
            this Agreement.
          </Typography>
          <Typography variant={'body1'} component={'div'} sx={sxClasses.bodyM2}>
            3.2 You hereby represent and warrant to the Creator that:
            <ol className={classes.listLowerAlpha}>
              <li>you lawfully acquired Your Digital collectible;</li>
              <li>
                {' '}
                you paid the creator fees when acquiring Your Digital
                collectible;
              </li>
              <li>
                {' '}
                you are the sole and absolute owner of the private keys to the
                wallet address set forth in your signature block below and, to
                the extent that you co-own such wallet address with any other
                person(s), that you have the authority to enter into this
                Agreement on the behalf of such other person(s);
              </li>
              <li>
                {' '}
                you will not use the Licensed NFT Artwork in any manner that is
                defamatory, obscene, or otherwise unlawful;
              </li>
              <li>
                {' '}
                you will not use the Licensed NFT Artwork in any manner that is
                in contravention of any applicable law, this Agreement or any
                right of the Creator or its affiliates, suggests sponsorship of
                or by, or association with, any third party;
              </li>
              <li>
                {' '}
                you will not use the Licensed NFT Artwork in any manner that is
                detrimental to the Creator or its affiliates;
              </li>
              <li>
                {' '}
                you will not carry out any act to sell or otherwise dispose of
                your ownership of Your Digital collectible in a manner which
                circumvents the requirement to pay the creator fees; and
              </li>
              <li>
                {' '}
                you are not, and will not, knowingly execute a transaction
                involving a Digital collectible or the Licensed NFT Artwork with
                any person who is (i) located in a country that is subject to a
                U.S. Government embargo, or that has been designated by the U.S.
                Government as a terrorist-supporting country; or (ii) listed on
                any list of prohibited or restricted parties, whether on a list
                maintained by the Office of Foreign Assets Control of the U.S.
                Department of the Treasury, the U.S. Department of State, the
                United Nations Security Council, the European Union, any
                European Union member state, His Majesty’s Treasury of the
                United Kingdom or other relevant sanctions authority.
              </li>
            </ol>
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            4. Indemnification, Disclaimers and Limitations of Liability.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            4.1 You will have an obligation to cover all of the Creator’s costs
            arising from any material breach of this Agreement by you.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            4.2 As a separate obligation to that in Clause 4.1, you will defend,
            indemnify, and hold the Creator, its licensors, affiliates,
            representatives, and service providers, and each of them, and all of
            their respective officers, directors, employees, and agents (the
            &quot;Indemnified Parties”) harmless from and against any and all
            claims, damages, losses, investigations, liabilities, judgments,
            fines, penalties, settlements, interest, and other similar results
            or occurrences (including attorneys’ fees) that directly or
            indirectly arise from, or are related to or in connection with, any
            claim, suit, action, demand, or proceeding or other similar
            occurrence, process, or activity that is initiated, made, brought,
            or financed by a third party (including any person who accesses or
            transacts using any Digital collectibles or artwork associated with
            the Digital collectible, whether or not such person personally
            purchased a Digital collectible) against the Indemnified Parties, or
            on account of the investigation, defense, or settlement thereof,
            arising out of related to, or in connection with: (a) your access to
            or use of any NFT marketplace or third-party services or products;
            (b) your breach or alleged breach of this Agreement; (c) your
            exercise or attempted exercise of the Licence; or (d) your actual or
            alleged violation of applicable law. Counsel to be used in the
            defense of such claim must be approved by the Creator in writing
            prior to retention of such counsel and, upon our request, you will
            allow us to participate in the defense of any such claims. You will
            not enter into any settlement or compromise of any claim or
            litigation or that includes an admission of liability without the
            Creator’s prior written consent.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            4.3 Each Digital collectible is an intangible digital asset that
            exists only by virtue of the ownership record maintained on the
            Polygon blockchain. Any transfer of ownership that might occur in
            any unique digital asset occurs on the decentralized ledger within
            the Polygon blockchain, which the Creator does not control.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            4.4 The Creator will not be responsible or liable to owner for any
            loss in connection with any Digital collectible or the Licensed NFT
            Artwork and takes no responsibility for, and will not be liable to
            owner for, any use of or inability to use any Digital collectible or
            the Licensed NFT Artwork for any reason.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            4.5 There are numerous risks associated with blockchain technology
            and the Digital collectibles. You should familiarize yourself with
            these risks and agree to assume these risks by owning a Digital
            collectible.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            4.6 To the maximum extent permitted by law, no Indemnified Party
            will be liable for any incidental, special, exemplary, or
            consequential damages, or damages for lost profits, lost revenues,
            lost savings, lost business opportunity, loss of data or goodwill,
            service interruption, computer damage, or system failure, or the
            cost of substitute services of any kind arising out of or in
            connection with these terms or from the use of or inability to use
            or interact with any Digital collectible or the Licensed NFT
            Artwork, whether based on warranty, contract, tort (including
            negligence), product liability, or any other legal theory, and
            whether or not the Creator or its service providers have been
            informed of the possibility of such damage, even if a limited remedy
            set forth herein is found to have failed of its essential purpose.
            Notwithstanding the foregoing, in no event will any Indemnified
            Party’s cumulative liability hereunder from all causes of action and
            all theories of liability exceed the price paid for Your Digital
            collectibles.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            5. Term and Termination.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            5.1 This Agreement is effective commencing from the date that you
            enter into this Agreement and shall automatically expire immediately
            after your disposal (including by way of sale or transfer) of Your
            Digital collectible (as recorded by the Smart Contract) (“Term”).
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            5.2 Upon the termination or expiration of this Agreement: (a) if you
            have complied with the conditions of the Licence, including the
            obligation to the payment of creator royalties, the subsequent
            acquirer of Your Digital collectible shall receive the same rights
            under this Agreement to the extent permitted under applicable law;
            (b) if you have not complied with the conditions of the Licence, all
            rights licensed under this Agreement will revert to the Creator and
            you will be prohibited from any further use of the Licensed NFT
            Artwork; (c) you may retain any tangible property using the Licensed
            NFT Artwork for personal use only; and (d) all sublicences that have
            been granted by you shall automatically and immediately terminate.
            If, during the Term, you create and make available to the public a
            Derivative Work, you may continue to use and exploit that Derivative
            Work in accordance with this Agreement after the Term; provided
            however that: (i) you will be responsible for any obligations or
            liabilities arising from your continued use of the Derivative Work
            after the Term; and (ii) this privilege does not allow you to use
            the Licensed NFT Artwork to create any new works or materials after
            the Term.
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            6. Non-Compliance with this Agreement.
          </Typography>
          <Typography variant={'body1'} component={'div'} sx={sxClasses.bodyM2}>
            6.1 If you materially breach any term of this Agreement, or if the
            Creator discovers that you acquired Your Digital collectible(s)
            unlawfully or you acquired Your Digital collectible(s) and failed to
            pay creator fees, the Creator may seek appropriate measures and
            remedies, including but not limited to any one or more of the
            following:
            <ol className={classes.listLowerAlpha}>
              <li>
                terminating this Agreement with immediate effect, provided that
                the Creator may allow you to cure such breach within a specified
                timeframe;
              </li>
              <li>
                restricting you from enjoying or participating in any future
                benefit or gathering of the community for of Your Digital
                collectibles (including, without limitation, airdrops and events
                conducted for the community), although, for the avoidance of
                doubt, the Creator has no obligation to provide any future
                benefit or gathering to the community or you;
              </li>
              <li>
                requiring a marketplace to remove or disable access to the
                infringing uses of the Licensed NFT Artwork displayed on a
                marketplace, platform or other Internet service provider by way
                of a Digital Millennium Copyright Act notice or takedown notice,
                in accordance with applicable copyright or other laws.
              </li>
            </ol>
          </Typography>
          <Typography
            variant={'h5'}
            className={classes.subTitle}
            sx={sxClasses.bodyM2}
          >
            7. Miscellaneous.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            7.1 Recognised Blockchain. The Licence applies only to the Digital
            collectibles on the blockchain that the Creator, in its sole
            discretion, may designate, which designation shall apply
            retroactively. Thus, for example, if a fork or other event purports
            to result in duplicate Digital collectibles, only the Digital
            collectibles recorded on the blockchain designated by the Creator
            will be eligible to receive the benefit of the Licence. Any licence
            purportedly granted hereunder to the owner of a non-fungible token
            recorded on a blockchain not designated by the Creator is void ab
            initio.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            7.2 Assignment. This Agreement is personal to you. Unless otherwise
            provided in this Agreement, you may not assign or otherwise transfer
            any of its rights, delegate, or otherwise transfer any of its
            obligations or performance, under this Agreement. Any purported
            assignment, delegation, or transfer in violation of this Clause is
            void. The Creator may freely assign or otherwise transfer all or any
            of its rights, or delegate or otherwise transfer all or any of its
            obligations or performance, under this Agreement. This Agreement is
            binding on and inures to the benefit of the parties hereto and their
            respective permitted successors and assigns.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            7.3 Waiver. No waiver by any party of any of the provisions hereof
            will be effective unless explicitly set forth in writing and signed
            by the party so waiving. No waiver by any party will operate or be
            construed as a waiver of any failure, breach, or default not
            expressly identified by such written waiver, whether of a similar or
            different character, and whether occurring before or after that
            waiver. No failure to exercise, or delay in exercising, any right,
            remedy, power, or privilege arising from this Agreement will operate
            or be construed as a waiver thereof; nor will any single or partial
            exercise of any right, remedy, power, or privilege hereunder
            preclude any other or further exercise thereof or the exercise of
            any other right, remedy, power, or privilege.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            7.4 Governing Law. This Agreement shall be governed by and construed
            in accordance with the laws of Lithuania, without regard to its
            principles on the conflicts of laws.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            7.5 Each party to this Agreement hereby irrevocably and
            unconditionally submits, for itself and its property, to the
            nonexclusive jurisdiction of any Lithuanian court and any appellate
            court from any thereof, in any action or proceeding arising out of
            or relating to this Agreement, or for recognition or enforcement of
            any judgement, and each of the parties hereto hereby irrevocably and
            unconditionally agrees that all claims in respect of any such action
            or proceeding may be heard and determined in such New York State or,
            to the extent permitted by law, in such federal court.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            7.6 Entire Agreement, Severance. This Agreement, including and
            together with any related attachments, is the sole and entire
            agreement of the parties with respect to the subject matter herein,
            and supersedes all prior and contemporaneous understandings,
            agreements, representations, and warranties, whether written or
            oral, regarding such subject matter. If any term or provision of
            this Agreement is invalid, illegal, or unenforceable in any
            jurisdiction, such invalidity, illegality, or unenforceability will
            not affect any other term or provision of this Agreement or
            invalidate or render unenforceable such term or provision in any
            other jurisdiction.
          </Typography>
          <Typography variant={'body1'} sx={sxClasses.bodyM2}>
            {' '}
            7.7 Amendment. Any amendments of this Agreement can be made by the
            Creator and will only be effective upon the approval of the Creator.
          </Typography>
          {!!userAddress && (
            <Typography variant={'body1'}>
              By inserting an electronic signature below, you, being the sole
              and absolute owner of the private keys to the wallet address{' '}
              <b>{userAddress}</b>, hereby agree to the terms of this Agreement.
            </Typography>
          )}
        </div>
      </main>
    </Container>
  )
}
