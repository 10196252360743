import { useEffect } from 'react'
import { localStorageKeys } from 'constants/localStorageKeys'
import { web3Modal } from 'services/providers'

export const useRefreshPageByEth = () => {
  useEffect(() => {
    setTimeout(() => {
      if (
        localStorage.getItem(localStorageKeys.isLoadedMM) !== 'true' &&
        web3Modal?.cachedProvider === 'injected'
      ) {
        localStorage.setItem(localStorageKeys.isAnimationDisabled, 'true')
        window.location.reload()
      }
    }, 2500)
  }, [])
}
