import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { cacheKeys } from 'constants/cacheKeys'
import { httpGeneralApi } from 'services/api/http/general'
import { apiUrls } from 'constants/apiUrls'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectUserAddress } from 'store/reducers/user/selectors'
import { setProfileIsExists } from 'store/reducers/user/actions'

export const useQuery_GetIsUserInfoExists_UserInfo = (
  options?: UseQueryOptions<boolean>
) => {
  const dispatch = useAppDispatch()
  const userAddress = useAppSelector(selectUserAddress)

  return useQuery<boolean>(
    [cacheKeys.userInfo.isUserInfoExists, userAddress],
    async () => {
      const { isExists } = await httpGeneralApi.getDataByUrl<{
        isExists: boolean
      }>(`${apiUrls.userInfo}/user_info?user_address=${userAddress}`)

      dispatch(setProfileIsExists(isExists))

      return isExists
    },
    {
      enabled: !!userAddress,
      ...options,
    }
  )
}
