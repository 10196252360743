import { makeStyles } from 'styles/makeStyles'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { FC, useMemo } from 'react'
import { RoseNftTiny } from '@pages/Rose/components/RoseNfts'
import { Link } from 'react-router-dom'
import { RoseCollectionById } from 'constants/stickers'
import { itemStickersPathId } from '@pages/Rose/RoseMerchDetails/RoseMerchDetailsHeading/constants'

const useStyles = makeStyles({ name: 'RoseMerchStepItem' })((theme) => ({
  root: {
    position: 'relative',
    borderRadius: theme.spacing(3.75),
    boxSizing: 'border-box',

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  container: {
    width: 414,
    minWidth: 414,
    borderRadius: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center center',
    transition: 'all .3s ease-in-out',
    boxSizing: 'border-box',
    willChange: 'all',

    [theme.breakpoints.down('sm')]: {
      width: 288,
      minWidth: 288,
      height: 'auto',
      backgroundSize: '288px 288px',
      backgroundPosition: 'top center',
      marginBottom: theme.spacing(3),
    },

    '&::after': {
      content: "''",
      left: 0,
      position: 'absolute',
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 2,
      width: '100%',
      height: '100%',
      opacity: 0,
      transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      willChange: 'opacity',
    },

    '&:hover::after': {
      opacity: 1,
      cursor: 'pointer',
    },
  },
  content: {
    width: '100%',
    position: 'relative',
    zIndex: 3,
    height: '100%',
    borderRadius: theme.spacing(3),
    overflow: 'hidden',

    '&::before': {
      content: "''",
      left: 0,
      position: 'absolute',
      bottom: 0,
      background: 'linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
      zIndex: 2,
      width: '100%',
      height: 180,
    },

    img: {
      width: '100%',
      height: 'auto',
    },
  },
  title: {
    fontWeight: 700,
    color: theme.palette.secondary.light,
  },
  text: {
    fontWeight: 400,
    fontFamily: fontSpaceMono,
  },
  stickersContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
    margin: theme.spacing(-3, 0, 2),
    zIndex: 3,
  },
  contentBottom: {
    width: '100%',
    position: 'relative',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(0, 2, 1.5),

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  borderYellow: {
    border: `4px solid ${theme.palette.secondary.light}`,
  },
  labelYellow: {
    position: 'absolute',
    top: -4,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: theme.spacing(0.5, 1),
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    background: theme.palette.secondary.light,
    zIndex: 5,
    maxWidth: 210,
    width: '100%',
    textAlign: 'center',
  },
  labelYellowText: {
    color: theme.palette.grey['A100'],
    fontFamily: fontSpaceMono,
  },
  borderGreen: {
    border: `4px solid ${theme.palette.success.main}`,
  },
  labelGreen: {
    background: theme.palette.success.main,
    maxWidth: 155,
  },
  labelGreenText: {
    color: theme.palette.common.white,
  },
}))

interface Props {
  className?: string
  itemStickerId: RoseCollectionById
  merchImgUrl: string
  nftImgUrl: string
  selectedItems: Record<number, number>
  balanceById: Record<number, number>
  href: string
  title: string
  isValidChain: boolean
}

export const RoseMerchStepItem: FC<Props> = ({
  className,
  itemStickerId,
  merchImgUrl,
  nftImgUrl,
  selectedItems,
  balanceById,
  href,
  title,
  isValidChain,
}) => {
  const { classes, cx } = useStyles()

  const stickersList = Array(5).fill(nftImgUrl)

  const balanceLength = Array(balanceById[itemStickerId]).length
  const selectedLength = Array(selectedItems[itemStickerId]).length

  const isCompleted = selectedLength === 5

  const isReadyToSelect = useMemo(() => {
    return balanceById[itemStickerId] > 0
  }, [balanceById, itemStickerId])

  return (
    <Link to={href} className={cx(className && className)}>
      <div
        className={cx(
          classes.root,
          isReadyToSelect && !isCompleted && classes.borderYellow,
          isCompleted && classes.borderGreen
        )}
      >
        {isReadyToSelect && !isCompleted && (
          <div className={cx(classes.labelYellow)}>
            <Typography variant="subtitle2" className={classes.labelYellowText}>
              New stickers available to add
            </Typography>
          </div>
        )}
        {isCompleted && (
          <div className={cx(classes.labelYellow, classes.labelGreen)}>
            <Typography
              variant="subtitle2"
              className={cx(classes.labelYellowText, classes.labelGreenText)}
            >
              Collection completed!
            </Typography>
          </div>
        )}
        <div className={cx(classes.container)}>
          <div className={cx(classes.content)}>
            <img src={merchImgUrl} alt={'merch'} />
          </div>
          <div className={cx(classes.stickersContainer)}>
            {stickersList.map((item, idx) => {
              return (
                <RoseNftTiny
                  key={idx}
                  isActive={idx < balanceLength && isValidChain}
                  isChecked={
                    !!selectedItems[itemStickerId] &&
                    idx < selectedLength &&
                    isValidChain
                  }
                  isSmall
                  imageUrl={item}
                />
              )
            })}
          </div>
          <div className={classes.contentBottom}>
            <div>
              <Typography variant="h4" fontWeight={800}>
                {title}
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2" className={classes.text}>
                Stickers you own: {balanceById[itemStickerId] || 0}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
