import { fontNeuePlak, fontSpaceMono } from './fonts'
import { Components, Theme } from '@mui/material/styles'

export const getOverridesComponent = (baseTheme: Theme): Components => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: 16,
          lineHeight: 1.5,
        },
        body: {
          scrollBehavior: 'smooth',
          fontFamily: fontNeuePlak,
          boxSizing: 'border-box',
          height: '100%',
          margin: 0,
          padding: 0,
        },
        a: {
          color: 'currentColor',
          textDecoration: 'none',
        },
        p: {
          color: 'currentColor',
          margin: 0,
          padding: 0,
          fontSize: 16,
          lineHeight: '24px',
          fontWeight: 400,
        },
        span: {
          color: 'currentColor',
          margin: 0,
          padding: 0,
        },
        '::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '::-webkit-scrollbar-thumb': {
          background: `${baseTheme.palette.primary.dark}`,
          borderRadius: '8px',
        },
        '.web3modal-modal-lightbox': {
          zIndex: `${baseTheme.zIndex.modal} !important`,
        },
        ul: {
          padding: baseTheme.spacing(0, 0, 0, 2),
          margin: 0,

          li: {
            fontSize: 14,
            lineHeight: '24px',
            fontWeight: 400,

            '&::marker': {
              color: baseTheme.palette.common.white,
            },

            '&:not(:last-of-type)': {
              marginBottom: baseTheme.spacing(1),
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          color: baseTheme.palette.text.primary,
          fontSize: 14,
          lineHeight: '24px',
          borderRadius: 24,
          boxShadow: 'none',
          fontFamily: fontSpaceMono,
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: baseTheme.spacing(1),

          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeMedium: {
          borderRadius: 16,
        },
        sizeLarge: {
          borderRadius: 24,
        },
        outlined: {
          border: '1px solid rgba(255, 255, 255, 0.4)',

          '&:hover': {
            border: `1px solid ${baseTheme.palette.warning.light}`,
            backgroundColor: 'rgba(253, 220, 124, 0.1)',
          },

          '&:active': {
            borderWidth: 1,
            backgroundColor: baseTheme.palette.grey[500],
          },

          '&:disabled': {
            background: baseTheme.palette.grey[400],
            borderColor: baseTheme.palette.grey[400],
            color: baseTheme.palette.grey[500],
          },
        },
        outlinedSizeMedium: {
          padding: baseTheme.spacing(0.875, 1),
        },
        outlinedSizeLarge: {
          fontSize: 18,
          lineHeight: '24px',
          padding: baseTheme.spacing(2.5, 3),

          [baseTheme.breakpoints.down('sm')]: {
            fontSize: 14,
          },
        },
        containedSizeMedium: {
          padding: baseTheme.spacing(1, 2),
        },
        containedSizeLarge: {
          fontSize: 18,
          lineHeight: '24px',
          padding: baseTheme.spacing(2.5, 3),
        },
        containedPrimary: {
          position: 'relative',
          zIndex: 1,
          color: baseTheme.palette.grey['A100'],
          backgroundImage: 'linear-gradient(180deg, #EAC351 0%, #D9B240 100%)',

          '&::before': {
            content: "''",
            left: 0,
            bottom: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            backgroundImage:
              'linear-gradient(180deg, #FFDF80 0%, #D9B240 100%)',
            zIndex: -1,
            transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            opacity: 0,
            borderRadius: 'inherit',
            willChange: 'opacity',
          },

          '&:hover::before': {
            opacity: 1,
          },

          '&:disabled': {
            background: baseTheme.palette.grey[400],
            color: baseTheme.palette.grey[500],
          },
        },
        containedSecondary: {
          backgroundColor: baseTheme.palette.common.white,
          color: baseTheme.palette.common.black,

          '&:hover': {
            backgroundColor: baseTheme.palette.grey[100],
          },

          '&:active': {
            backgroundColor: baseTheme.palette.grey[100],
          },

          '&:disabled': {
            background: baseTheme.palette.grey[400],
            color: baseTheme.palette.grey[500],
          },
        },
        containedSuccess: {
          backgroundColor: baseTheme.palette.success.main,
          color: baseTheme.palette.common.white,

          '&:hover': {
            backgroundColor: baseTheme.palette.success.dark,
          },

          '&:active': {
            backgroundColor: baseTheme.palette.success.dark,
          },

          '&:disabled': {
            background: baseTheme.palette.grey[400],
            color: baseTheme.palette.grey[500],
          },
        },
        outlinedSecondary: {
          border: `1px solid ${baseTheme.palette.warning.light}`,
        },
        text: {
          '&:hover': {
            textDecoration: 'underline',
            color: baseTheme.palette.text.secondary,
          },

          '&:disabled': {
            textDecoration: 'none',
            color: baseTheme.palette.grey[500],
          },

          '&:active': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: baseTheme.palette.background.default,
          backgroundImage: 'none',
          borderRadius: 8,
          boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.45)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: '24px',

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.text.primary,
          },
        },
        notchedOutline: {
          borderColor: baseTheme.palette.grey[800],
          borderWidth: 1,
          borderRadius: 8,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: '24px',
          color: baseTheme.palette.common.white,
          borderColor: baseTheme.palette.common.white,

          '&.Mui-focused': {
            color: baseTheme.palette.text.primary,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: '16px',
          fontWeight: 700,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: baseTheme.spacing(3, 3, 2),
          textAlign: 'center',
          position: 'relative',
          fontSize: 20,
          lineHeight: '28px',

          [baseTheme.breakpoints.down('md')]: {
            padding: baseTheme.spacing(3),
            fontSize: 24,
            lineHeight: 1.5,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: baseTheme.spacing(0, 3, 3),

          [baseTheme.breakpoints.down('md')]: {
            padding: baseTheme.spacing(0, 3, 3),
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: baseTheme.palette.background.default,
          boxShadow: 'none',
          borderRadius: 8,
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          padding: baseTheme.spacing(0.5, 2),
        },
        icon: {
          fill: baseTheme.palette.grey[700],
          width: 20,
          height: 23,
          right: 10,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: baseTheme.palette.text.primary,
          },
          '&.Mui-colorPrimary': {
            color: baseTheme.palette.grey[400],
          },
          '&.Mui-disabled': {
            color: baseTheme.palette.grey[700],
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: baseTheme.palette.text.secondary,

          '&.Mui-disabled': {
            color: baseTheme.palette.grey[700],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: '-1px',

          '& .MuiTabs-flexContainer': {
            gap: baseTheme.spacing(10),

            [baseTheme.breakpoints.down('md')]: {
              gap: baseTheme.spacing(5),
            },

            [baseTheme.breakpoints.down('sm')]: {
              gap: baseTheme.spacing(2),
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.grey[200],
          textTransform: 'none',
          fontSize: 14,
          lineHeight: '24px',
          fontWeight: 700,
          paddingLeft: 0,
          paddingRight: 0,

          '&.Mui-selected': {
            color: baseTheme.palette.text.primary,
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          backgroundColor: baseTheme.palette.grey[800],
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: baseTheme.palette.text.primary,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          background: 'transparent',

          '&.Mui-expanded': {
            margin: 0,
            padding: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
        content: {
          margin: 0,

          '&.Mui-expanded': {
            margin: 0,
            padding: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  }
}
