import { makeStyles } from 'styles/makeStyles'
import { FC, useEffect } from 'react'
import Container from '@mui/material/Container'
import { Box, Button, Typography } from '@mui/material'
import { useIsMounted } from 'hooks/useIsMounted'
import { useSpring, animated } from 'react-spring'
import { RoseHeaderPacks } from '@pages/Rose/components/RoseHeader/RoseHeaderPacks'
import { linearGradients } from 'styles/other'
import { Link, PathMatch } from 'react-router-dom'
import { ROUTER_PATHS } from 'routes/routerPaths'
import {
  AppsIcon,
  ArrowRightSmallLineIcon,
  HomeIcon,
  OpenSeaIcon,
  ShelvesIcon,
  UserIcon,
} from 'assets/icons'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import { RoseHeaderUserInfo } from '@pages/Rose/components/RoseHeader/RoseHeaderUserInfo'
import { publicImages } from 'utils/getPublicImages'
import { openSeaUrls } from 'constants/staticUrls'
import { scrollToTop } from 'utils/scrollToTop'

const useStyles = makeStyles({ name: 'RoseHeaderMenu' })((theme) => ({
  root: {
    position: 'fixed',
    height: '100vh',
    zIndex: theme.zIndex.appBar - 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingTop: theme.spacing(11),
    top: 0,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(5),
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      background: `
        url('${publicImages.getBackground('rose/mobile-menu-bg.webp')}') 
        top center 
        no-repeat
      `,
      backgroundColor: theme.palette.background.default,
    },
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    minHeight: 0,
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(5, 0),
  },
  packContainer: {
    width: '100%',
    display: 'flex',
    paddingBottom: theme.spacing(3),
  },
  dividerHorizontal: {
    width: '100%',
    height: 1,
    background: linearGradients.backgroundLinesHorizontal,
  },
  linkContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 0),
    maxWidth: 288,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },

    span: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  linkInner: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  linkBigText: {
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    gap: '0 !important',
  },
  linkImgContainer: {
    minWidth: 48,
    width: 48,

    img: {
      width: '100%',
      height: 'auto',
    },
  },
  btn: {
    maxWidth: 288,
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
}))

const sxClasses = {
  container: {
    display: 'flex',
    height: '100%',
  },
}

interface Props {
  isOpenMenu: boolean
  isValidChain: boolean
  isMdDownMediaQuery: boolean
  matchRoseInventory: boolean
  matchRoseCollectionFighters: PathMatch<string> | null
  matchRoseCollectionId: PathMatch<string> | null
  matchRoseMerchCollectionId: PathMatch<string> | null
  matchRoseCollectionMerch: PathMatch<string> | null
}

const RoseHeaderMenu: FC<Props> = ({
  isOpenMenu = false,
  isValidChain,
  isMdDownMediaQuery,
  matchRoseInventory,
  matchRoseCollectionFighters,
  matchRoseCollectionId,
  matchRoseMerchCollectionId,
  matchRoseCollectionMerch,
}) => {
  const { classes } = useStyles()

  const [isMounted] = useIsMounted()

  useEffect(() => {
    if (isOpenMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    document.body.style.overflow = 'auto'
  }, [isOpenMenu, isMdDownMediaQuery])

  useEffect(() => {
    if (isOpenMenu && isMdDownMediaQuery) {
      disableBodyScroll(document?.body)
    } else {
      enableBodyScroll(document?.body)
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpenMenu])

  const menuAnimationStyle = useSpring({
    opacity: isOpenMenu ? 1 : 0,
    transform: isOpenMenu ? `translateY(0)` : `translateY(-150%)`,
  })

  if (!isMounted || !isOpenMenu) {
    return null
  }

  return (
    <animated.div style={menuAnimationStyle} className={classes.root}>
      <Container maxWidth={'lg'} sx={sxClasses.container}>
        <div className={classes.container}>
          {isValidChain && (
            <>
              <div className={classes.packContainer}>
                <RoseHeaderPacks />
              </div>
              <Button
                variant={'outlined'}
                className={classes.btn}
                href={openSeaUrls.roseCollection}
                target={'_blank'}
              >
                <span>View on OpenSea</span>
                <OpenSeaIcon />
              </Button>
              <div className={classes.linkContainer}>
                {isValidChain && (
                  <>
                    <Link to={ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS}>
                      <Box
                        className={classes.link}
                        onClick={() => scrollToTop()}
                      >
                        <div className={classes.linkInner}>
                          <div className={classes.linkImgContainer}>
                            <img
                              src={publicImages.getIllustration(
                                'rose/fighter-nfts-collection-tab.png'
                              )}
                              alt={'Fighter NFTs'}
                            />
                          </div>
                          <span className={classes.linkBigText}>
                            <Typography variant={'body1'} fontWeight={800}>
                              Fighter NFTs
                            </Typography>
                            <Typography
                              variant={'subtitle2'}
                              color={'text.secondary'}
                              fontWeight={400}
                            >
                              Your inner ultimate fighter is waiting to be
                              released! Collect Avatar stickers and merge them
                              to build your avatar!
                            </Typography>
                          </span>
                        </div>
                        <ArrowRightSmallLineIcon />
                      </Box>
                    </Link>
                    <div className={classes.dividerHorizontal} />
                  </>
                )}
                {isValidChain && (
                  <>
                    <Link to={ROUTER_PATHS.ROSE_NFT_COLLECTION_MERCH}>
                      <Box
                        className={classes.link}
                        onClick={() => scrollToTop()}
                      >
                        <div className={classes.linkInner}>
                          <div className={classes.linkImgContainer}>
                            <img
                              src={publicImages.getIllustration(
                                'rose/merch-collection-tab.webp'
                              )}
                              alt={'Merch'}
                            />
                          </div>
                          <span className={classes.linkBigText}>
                            <Typography variant={'body1'} fontWeight={800}>
                              Merch
                            </Typography>
                            <Typography
                              variant={'subtitle2'}
                              color={'text.secondary'}
                              fontWeight={400}
                            >
                              Show off your Thug Rose spirit with
                              limited-edition merchandise! Collect digital item
                              stickers and redeem them for real-life prizes and
                              interactions.
                            </Typography>
                          </span>
                        </div>
                        <ArrowRightSmallLineIcon />
                      </Box>
                    </Link>
                    <div className={classes.dividerHorizontal} />
                  </>
                )}
                {!matchRoseInventory && isValidChain && (
                  <>
                    <Link to={ROUTER_PATHS.ROSE_INVENTORY_NFT_STICKERS}>
                      <div className={classes.link}>
                        <span>
                          <ShelvesIcon />
                          <Typography variant={'caption'}>Inventory</Typography>
                        </span>
                        <ArrowRightSmallLineIcon />
                      </div>
                    </Link>
                    <div className={classes.dividerHorizontal} />
                  </>
                )}
                {isValidChain && (
                  <>
                    <Link to={ROUTER_PATHS.ROSE_PROFILE}>
                      <div className={classes.link}>
                        <span>
                          <UserIcon />
                          <Typography variant={'caption'}>Profile</Typography>
                        </span>
                        <ArrowRightSmallLineIcon />
                      </div>
                    </Link>
                    <div className={classes.dividerHorizontal} />
                  </>
                )}
                {(matchRoseCollectionFighters ||
                  matchRoseCollectionMerch ||
                  matchRoseCollectionId ||
                  matchRoseMerchCollectionId) &&
                  isValidChain && (
                    <>
                      <Link to={ROUTER_PATHS.ROSE}>
                        <div className={classes.link}>
                          <span>
                            <HomeIcon />
                            <Typography variant={'caption'}>Home</Typography>
                          </span>
                          <ArrowRightSmallLineIcon />
                        </div>
                      </Link>
                      <div className={classes.dividerHorizontal} />
                    </>
                  )}
                {isValidChain &&
                  !matchRoseCollectionFighters &&
                  !matchRoseCollectionMerch &&
                  !matchRoseCollectionId &&
                  !matchRoseMerchCollectionId && (
                    <>
                      <Link to={ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS}>
                        <div className={classes.link}>
                          <span>
                            <AppsIcon />
                            <Typography variant={'caption'}>
                              Collection
                            </Typography>
                          </span>
                          <ArrowRightSmallLineIcon />
                        </div>
                      </Link>
                      <div className={classes.dividerHorizontal} />
                    </>
                  )}
              </div>
            </>
          )}
          <RoseHeaderUserInfo />
        </div>
      </Container>
    </animated.div>
  )
}

export default RoseHeaderMenu
