import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { fontSpaceMono } from 'styles/fonts'
import { MAIN_LANDING_HIW_LISTS } from './constants'
import { MainLandingHiWItem } from './MainLandingHiWItem'
import { LINKS_ANCHOR } from 'constants/linksAnchor'

const useStyles = makeStyles({ name: 'MainLandingHiW' })((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: `
        url('${process.env.PUBLIC_URL}/images/backgrounds/main-landing/hiw-blur-bg.webp') 
        top center 
        no-repeat
      `,
    backgroundSize: 'contain',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    marginBottom: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  subtitle1: {
    fontFamily: fontSpaceMono,
    textTransform: 'uppercase',
    color: theme.palette.success.main,
    lineHeight: '40px',
  },
  text: {
    maxWidth: 524,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(15),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
}))

export const MainLandingHiW = () => {
  const { classes, cx } = useStyles()

  return (
    <>
      <div className={cx(classes.root)} id={LINKS_ANCHOR.roseHIW.id}>
        <Container maxWidth={'lg'}>
          <div className={classes.textContainer}>
            <div>
              <Typography variant={'subtitle1'} className={classes.subtitle1}>
                The Mechanics:
              </Typography>
              <Typography variant={'h2'}>How it Works?</Typography>
            </div>
            <Typography variant={'subtitle1'} className={classes.text}>
              The mechanics for your ultimate collector&apos;s journey for the
              Legendary Fighter title! Take on the challenge and become a
              winner.
            </Typography>
          </div>
        </Container>
        <div className={classes.content}>
          {MAIN_LANDING_HIW_LISTS.map(
            ({ id, title, subtitle, description, imageUrl }) => (
              <MainLandingHiWItem
                key={id}
                id={id}
                title={title}
                subtitle={subtitle}
                description={description}
                imgUrl={imageUrl}
              />
            )
          )}
        </div>
      </div>
    </>
  )
}
