import React, { ComponentProps, FC, useState } from 'react'
import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import {
  WarningFillIcon,
  CloseIcon,
  CheckboxCircleFillIcon,
} from 'assets/icons'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

type Props = ComponentProps<typeof Alert> & {
  title?: string
  withCloseBtn?: boolean
  onCloseCallback?: () => void
}

const useStyles = makeStyles({ name: 'AppAlertCircle' })((theme) => ({
  root: {
    borderRadius: `${theme.spacing(2)} !important`,
    padding: `${theme.spacing(2)}, ${theme.spacing(3)} !important`,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    textAlign: 'left',

    '& .MuiAlert-message': {
      width: '100%',
    },

    svg: {
      width: 16,
      height: 16,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  standardSuccess: {
    backgroundColor: `${theme.palette.success.main} !important`,

    '& .MuiAlert-icon': {
      color: `${theme.palette.text.primary} !important`,
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  standardError: {
    backgroundColor: `${theme.palette.error.light} !important`,

    '& .MuiAlert-icon': {
      color: `${theme.palette.text.primary} !important`,
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  standardWarning: {
    backgroundColor: `${theme.palette.warning.main} !important`,
    color: `${theme.palette.grey[800]} !important`,

    '& .MuiAlert-icon': {
      color: `${theme.palette.grey[800]} !important`,
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  standardInfo: {
    backgroundColor: `${theme.palette.info.main} !important`,

    '& .MuiAlert-icon': {
      color: `${theme.palette.common.white} !important`,
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    width: '100%',
    fontSize: 12,

    a: {
      textDecoration: 'underline',
    },
  },
  description: {
    a: {
      textDecoration: 'underline',
    },
  },
  iconBtn: {
    minWidth: 16,
    width: 16,
    height: 16,
    color: theme.palette.text.primary,

    svg: {
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },
}))

export const AppAlertCircle: FC<Props> = ({
  children,
  classes,
  title,
  withCloseBtn,
  onCloseCallback,
  ...rest
}) => {
  const { classes: styles } = useStyles()

  const [shouldShow, setShouldShow] = useState(true)

  const onClose = () => {
    onCloseCallback && onCloseCallback()
    setShouldShow(false)
  }

  if (!shouldShow) {
    return null
  }

  return (
    <Alert
      classes={{
        standardSuccess: styles.standardSuccess,
        standardError: styles.standardError,
        standardWarning: styles.standardWarning,
        standardInfo: styles.standardInfo,
        root: styles.root,
        ...classes,
      }}
      iconMapping={{
        success: <CheckboxCircleFillIcon />,
        warning: <WarningFillIcon />,
        error: <WarningFillIcon />,
        info: <WarningFillIcon />,
      }}
      {...rest}
    >
      <Box className={styles.content}>
        {!!title && (
          <Typography variant={'subtitle2'} className={styles.title}>
            <span dangerouslySetInnerHTML={{ __html: title }} />{' '}
            {withCloseBtn && (
              <IconButton onClick={onClose} className={styles.iconBtn}>
                <CloseIcon />
              </IconButton>
            )}
          </Typography>
        )}
        {!!children && (
          <Typography
            variant={'body1'}
            className={styles.description}
            sx={{ fontSize: 12, lineHeight: '16px' }}
          >
            {children}
          </Typography>
        )}
      </Box>
    </Alert>
  )
}
