import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export const selectUserState = (state: RootState) => state.user

export const selectUserAddress = createSelector(
  selectUserState,
  (userState) => userState.address
)

export const selectUserChainId = createSelector(
  selectUserState,
  (userState) => userState.chainId
)

export const selectIsLoadingUserAuth = createSelector(
  selectUserState,
  (userState) => userState.isLoading
)

export const isLoggedInSelector = createSelector(
  selectUserAddress,
  selectUserChainId,
  (address, chainId) => !!address && !!chainId
)

export const selectIsValidChain = createSelector(
  selectUserState,
  (userState) => userState.isValidChain
)

export const selectProfileIsExists = createSelector(
  selectUserState,
  (userState) => userState.profile.isExists
)

export const selectProfileIsLoading = createSelector(
  selectUserState,
  (userState) => userState.profile.isLoading
)

export const selectProfileUserInfo = createSelector(
  selectUserState,
  (userState) => userState.profile.userInfo
)
