import * as React from 'react'
import { ComponentProps, FC } from 'react'
import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import MuiTooltip from '@mui/material/Tooltip'

type Props = {
  title?: string
  description?: any[]
  isSmall?: boolean
} & ComponentProps<typeof MuiTooltip>

const useStyles = makeStyles({ name: 'Tooltip' })((theme) => ({
  title: {
    padding: theme.spacing(1),
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
    paddingBottom: 0,
  },
  description: {
    padding: theme.spacing(1),
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    whiteSpace: 'pre-line',

    a: {
      textDecoration: 'underline',
    },
  },
}))

export const Tooltip: FC<Props> = ({
  title,
  description,
  isSmall,
  ...rest
}) => {
  const { classes } = useStyles()

  const content = (
    <>
      {!!title && (
        <Typography variant={'subtitle2'} className={classes.title}>
          {title}
        </Typography>
      )}
      {description?.map((copy: string | undefined, idx) => {
        if (typeof copy === 'string') {
          return (
            <Typography
              variant={'body2'}
              key={copy}
              className={classes.description}
              gutterBottom
              dangerouslySetInnerHTML={{ __html: copy }}
            />
          )
        }

        return <div key={idx}>{copy}</div>
      })}
    </>
  )

  return (
    <MuiTooltip
      sx={{ maxWidth: isSmall ? 300 : 700 }}
      title={content}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'grey[800]',
            '& .MuiTooltip-arrow': {
              color: 'grey[800]',
            },
            maxWidth: isSmall ? 300 : 500,
            borderRadius: 2,
            border: isSmall ? 'none' : `1px solid rgba(255, 255, 255, 0.4)`,
            borderBottom: 'none',
          },
        },
      }}
      {...rest}
    />
  )
}
