import { makeStyles } from 'styles/makeStyles'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowRightFillYellowIcon from 'assets/icons/arrow-right-fill-yellow.svg'

const useStyles = makeStyles({ name: 'RoseFighterStepItem' })((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
  },
  item: {
    fontWeight: 400,
    fontFamily: fontSpaceMono,
    position: 'relative',

    '&:not(:last-of-type):hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },

    '&:not(:last-of-type)::before': {
      content: "''",
      position: 'absolute',
      right: -24,
      top: 0,
      background: `url(${ArrowRightFillYellowIcon}) no-repeat center center`,
      width: 16,
      height: 16,
    },

    '&:last-of-type': {
      fontWeight: 700,
    },
  },
}))

interface Props {
  className?: string
  items: Array<
    | string
    | {
        label: string
        href: string
      }
  >
}

export const RoseBreadcrumbs: FC<Props> = ({ className, items = [] }) => {
  const { classes, cx } = useStyles()

  const navigate = useNavigate()

  return (
    <div className={cx(classes.root, className && className)}>
      {items.map((item) => {
        if (typeof item === 'string') {
          return (
            <Typography
              variant={'subtitle2'}
              key={item}
              className={classes.item}
            >
              {item}
            </Typography>
          )
        }

        return (
          <Typography
            key={item.label}
            variant={'subtitle2'}
            className={classes.item}
            onClick={() => navigate(item.href)}
          >
            {item.label}
          </Typography>
        )
      })}
    </div>
  )
}
