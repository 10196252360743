import { RootState } from '../../store'

export const appSelector = (state: RootState) => state.app

export const isLoadingSelector = (state: RootState) =>
  appSelector(state).isLoading

export const reFetchedFlagSelector = (state: RootState) =>
  appSelector(state).reFetchFlags

export const modalsSelector = (state: RootState) => appSelector(state).modals

export const whiteListSelector = (state: RootState) =>
  appSelector(state).whiteList

export const balancesSelector = (state: RootState) =>
  appSelector(state).balances

export const termsAndConditionsSelector = (state: RootState) =>
  appSelector(state).termsAndConditions
