import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { contractErc1155Api } from 'services/api/contract/erc1155'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  selectIsValidChain,
  selectUserAddress,
  selectUserChainId,
} from 'store/reducers/user/selectors'
import { enqueueSnack } from 'store/reducers/snackbar/actions'

export const useQuery_BalanceOfBatch_Erc1155 = (
  cacheKeys: string,
  ids: string[] | number[] = [],
  nftContractAddress: string | undefined,
  reFetchFlag: boolean,
  options?: UseQueryOptions<number[]>
) => {
  const dispatch = useAppDispatch()

  const userAddress = useAppSelector(selectUserAddress)
  const isValidChain = useAppSelector(selectIsValidChain)

  return useQuery<number[]>(
    [cacheKeys, userAddress, nftContractAddress, isValidChain, reFetchFlag],
    async () =>
      await contractErc1155Api.getErc1155BalanceBatch(
        ids,
        nftContractAddress as string,
        userAddress as string
      ),
    {
      enabled:
        !!ids.length && !!nftContractAddress && isValidChain && !!userAddress,
      onError: () => {
        dispatch(
          enqueueSnack({
            message: `Error Balance, please try later.`,
            variant: 'error',
          })
        )
      },
      ...options,
    }
  )
}

export const useQuery_IsApproved_Erc1155 = (
  cacheKey: string,
  nftContractAddress: string | undefined,
  toAddress: string | undefined,
  options?: UseQueryOptions<boolean>
) => {
  const dispatch = useAppDispatch()

  const userAddress = useAppSelector(selectUserAddress)
  const userChainId = useAppSelector(selectUserChainId)
  const isValidChain = useAppSelector(selectIsValidChain)

  return useQuery<boolean>(
    [
      cacheKey,
      userAddress,
      userChainId,
      nftContractAddress,
      isValidChain,
      toAddress,
    ],
    async () =>
      await contractErc1155Api.isApprovedErc1155(
        userAddress as string,
        nftContractAddress as string,
        toAddress as string
      ),
    {
      enabled: !!toAddress && !!nftContractAddress && isValidChain,
      onError: () => {
        dispatch(
          enqueueSnack({
            message: `Error Approved, please try later.`,
            variant: 'error',
          })
        )
      },
      ...options,
    }
  )
}
