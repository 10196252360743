import { makeStyles } from 'styles/makeStyles'
import { FC, useState } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { AddCircleFillIcon } from 'assets/icons'
import { linearGradients } from 'styles/other'
import { useAppDispatch } from 'store/hooks'
import { setModal } from 'store/reducers/app/actions'
import { ModalName } from 'constants/modals'
import OpenMysteryBoxModal from 'components/Modals/OpenMysteryBoxModal/OpenMysteryBoxModalContainer'

const useStyles = makeStyles({ name: 'MysteryPacksSmallItem' })((theme) => ({
  root: {
    borderRadius: theme.spacing(2),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.1)',
    border: `1px solid rgba(255, 255, 255, 0.4)`,
    borderBottom: 'none',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      maxWidth: 140,
      width: '100%',
    },
  },
  content: {
    background: 'rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(0.25, 0),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(2),
    minWidth: 104,

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },

    img: {
      width: 24,
      height: 24,
    },
  },
  boxText: {
    fontSize: 8,
    lineHeight: '12px',
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  dividerVertical: {
    width: 1,
    height: 36,
    background: linearGradients.backgroundLinesVertical,
  },
  btn: {
    opacity: 0.8,
    padding: theme.spacing(1),
    minWidth: 0,

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
}))

interface Props {
  className?: string
  name: string
  imageUrl: string
  own: number
  openPackBtnClick?: () => void
}

export const MysteryPacksSmallItem: FC<Props> = ({
  className,
  name,
  imageUrl,
  own,
  openPackBtnClick,
}) => {
  const { classes, cx } = useStyles()

  return (
    <>
      <div className={cx(classes.root, className)}>
        <div className={classes.content}>
          <div className={classes.box}>
            <img src={imageUrl} alt={name} />
            <Typography variant={'body1'} className={classes.boxText}>
              {name}
            </Typography>
          </div>
          <div className={classes.dividerVertical} />
          <div className={classes.box}>
            <Typography variant={'body1'} fontWeight={700}>
              {own}
            </Typography>
          </div>
        </div>
        <Button
          variant={'text'}
          onClick={() => {
            openPackBtnClick && openPackBtnClick()
          }}
          className={classes.btn}
          disabled={own === 0}
        >
          Open
        </Button>
      </div>
    </>
  )
}
