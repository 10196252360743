import React from 'react'

export class SocialsLinks {
  constructor(
    public icon: React.SVGProps<SVGSVGElement>,
    public path: string = '',
    public text: string = '',
    public target: string = ''
  ) {
    this.icon = icon
    this.path = path
    this.text = text
    this.target = '_blank'
  }
}
