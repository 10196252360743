export const cacheKeys = {
  balance: 'balance',
  balanceBN: 'balanceBN',
  userBalancesBN: 'userBalancesBN',
  getTrackedActionsList: 'getTrackedActionsList',
  sellBoxes: {
    getBoxData: 'sellBoxes_GetBoxData',
    swipeluxInventory: 'sellBoxes_SwipeluxInventory',
  },
  erc20: {
    approve: 'erc20_approve',
  },
  erc1155: {
    balanceOfBatch: 'erc1155_balanceOfBatch',
  },
  whiteList: 'whiteList',
  isAcceptedTermsAndConditions: 'isAcceptedTermsAndConditions',
  collectionList: 'collectionList',
  countries: 'countries',
  roseItems: 'roseItems',
  userInfo: {
    isUserInfoExists: 'userInfo_isUserInfoExists',
    getUserInfo: 'userInfo_GetUserInfo',
  },
}
