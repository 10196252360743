import { makeStyles } from 'styles/makeStyles'
import { RoseInventoryHeading } from '../RoseInventoryHeading'
import { publicImages } from 'utils/getPublicImages'
import { InventoryNftBreeding } from 'components/InventoryItems'
import { LINKS_ANCHOR } from 'constants/linksAnchor'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import NftBreedingModal from 'components/Modals/NftBreedingModal'
import { AthleteRarityId } from 'constants/rarity'
import Skeleton from '@mui/material/Skeleton'
import { MenuInventory } from '../constants'
import { ICollectionAvatarResponse } from 'models/ICollectionData'
import { RoseInventoryLayout } from '../RoseInventoryLayout'
import { arePropsEqual } from 'utils/arePropsEqual'

const useStyles = makeStyles({ name: 'RoseInventoryNftFusionComponent' })(
  (theme) => ({
    root: {
      width: '100%',
    },
    content: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
      margin: theme.spacing(3, 0, 10),

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
  })
)

interface Props {
  collection: ICollectionAvatarResponse | undefined
  activeMenuItem: MenuInventory
  isInitialLoading: boolean
  isValidChain: boolean
}

const RoseInventoryNftFusion: FC<Props> = ({
  collection,
  activeMenuItem,
  isInitialLoading,
  isValidChain,
}) => {
  const { classes, cx } = useStyles()

  const [titleModal, setTitleModal] = useState<AthleteRarityId | null>(null)

  const currentNftList = useMemo(() => {
    if (isInitialLoading) return []
    if (titleModal === AthleteRarityId.Beginner) return collection?.beginnerNft
    if (titleModal === AthleteRarityId.Intermediate)
      return collection?.intermediateNft
    if (titleModal === AthleteRarityId.Advanced) return collection?.advancedNft
    if (titleModal === AthleteRarityId.Elite) return collection?.eliteNft
    return []
  }, [collection, titleModal, isInitialLoading])

  useEffect(() => {
    if (!isValidChain) {
      setTitleModal(null)
    }
  }, [isValidChain])

  return (
    <>
      <RoseInventoryLayout activeMenuItem={activeMenuItem}>
        <div
          className={cx(classes.root)}
          id={LINKS_ANCHOR.inventoryNftFusion.id}
        >
          <RoseInventoryHeading
            title={'NFT Fusion'}
            description={
              'Fusing lower-level fighters will give you the chance to enter the ranks of higher-level fighters, and reach the ultimate Legendary tier! Will you rise to the challenge?'
            }
          />
          <div className={classes.content}>
            {isInitialLoading ? (
              <Skeleton
                sx={{
                  transform: 'inherit',
                  width: '100%',
                  height: 300,
                  borderRadius: 4,
                  mt: 3,
                  mb: 10,
                }}
              />
            ) : (
              <>
                <InventoryNftBreeding
                  imageUrl={publicImages.getIllustration(
                    `rose/fighters/fighter-blur-advanced.webp`
                  )}
                  title={'For Intermediate NFT'}
                  description={
                    'Intermediate Fighter can  be achieved through fusing - combining Beginner Fighters. You need to fuse 4 Beginner NFTs to create one Intermediate fighter'
                  }
                  fromTitle={'Beginner'}
                  toTitle={'Intermediate'}
                  requiredForBreeding={4}
                  availableForBreeding={collection?.beginnerNft.length || 0}
                  disabled={
                    isInitialLoading ||
                    !collection?.beginnerNft ||
                    (typeof collection?.beginnerNft.length === 'number' &&
                      collection?.beginnerNft.length < 4) ||
                    !isValidChain
                  }
                  onBreedNftBtnClick={() =>
                    setTitleModal(AthleteRarityId.Beginner)
                  }
                />
                <InventoryNftBreeding
                  imageUrl={publicImages.getIllustration(
                    `rose/fighters/fighter-blur-elite.webp`
                  )}
                  title={'For Advanced NFT'}
                  description={
                    'Advanced Fighter can be achieved through fusing - combining Intermediate Fighters. You need to fuse 4 Intermediate NFTs to create one Advanced NFT.'
                  }
                  fromTitle={'Intermediate'}
                  toTitle={'Advanced'}
                  requiredForBreeding={4}
                  availableForBreeding={collection?.intermediateNft.length || 0}
                  disabled={
                    isInitialLoading ||
                    (typeof collection?.intermediateNft.length === 'number' &&
                      collection?.intermediateNft.length < 4) ||
                    !isValidChain
                  }
                  onBreedNftBtnClick={() =>
                    setTitleModal(AthleteRarityId.Intermediate)
                  }
                />

                <InventoryNftBreeding
                  imageUrl={publicImages.getIllustration(
                    `rose/fighters/fighter-blur-intermediate.webp`
                  )}
                  title={'For Elite NFT'}
                  description={
                    'Elite Fighter can be achieved through fusing - combining Advanced Fighters. You need to fuse 4 Advanced NFTs to create one Elite NFT.'
                  }
                  fromTitle={'Advanced'}
                  toTitle={'Elite'}
                  requiredForBreeding={4}
                  availableForBreeding={collection?.advancedNft.length || 0}
                  disabled={
                    isInitialLoading ||
                    (typeof collection?.advancedNft.length === 'number' &&
                      collection?.advancedNft.length < 4) ||
                    !isValidChain
                  }
                  onBreedNftBtnClick={() =>
                    setTitleModal(AthleteRarityId.Advanced)
                  }
                />

                <InventoryNftBreeding
                  imageUrl={publicImages.getIllustration(
                    `rose/fighters/fighter-blur-legendary.webp`
                  )}
                  title={'For Legendary NFT'}
                  description={
                    'Legendary Fighter can only be achieved through fusing - combining lower-level Fighters. You need to fuse 4 Elite NFTs to reach Legendary tier. '
                  }
                  fromTitle={'Elite'}
                  toTitle={'Legendary'}
                  requiredForBreeding={4}
                  availableForBreeding={collection?.eliteNft.length || 0}
                  disabled={
                    isInitialLoading ||
                    (typeof collection?.eliteNft.length === 'number' &&
                      collection?.eliteNft.length < 4) ||
                    !isValidChain
                  }
                  onBreedNftBtnClick={() =>
                    setTitleModal(AthleteRarityId.Elite)
                  }
                />
              </>
            )}
          </div>
        </div>
      </RoseInventoryLayout>
      <NftBreedingModal
        nftList={currentNftList || []}
        isOpen={!!titleModal}
        onClose={() => setTitleModal(null)}
        rarityId={titleModal || AthleteRarityId.Advanced}
      />
    </>
  )
}

export const RoseInventoryNftFusionComponent = memo(
  RoseInventoryNftFusion,
  arePropsEqual
)
