import { Currency } from './currency'

export const SELL_BOXES_CONTRACT_ADDRESS =
  '0x0D317CEB10559e1E8576dE29CB7C5c892988407F'

export const ROSE_COLLECTION_CONTRACT_ADDRESS =
  '0x1f094e0BD8dB610FC7B711f310E203627d6978F2'

export const ROUTER_CONTRACT_ADDRESS =
  '0x9b908954a285b466825232F61E50E0b987F5DE56'

export const CURRENCY_ADDRESSES: Record<string, string> = {
  [Currency.Sport]: '0xcE6eaA8A872C7882Bf6A3DFB10bAE343C4999e8E',
  [Currency.WETH]: '0xf1181efc9eB93d15A908aD5DA077a48A6E97554b',
  [Currency.USDT]: '0xb3457705A8eF5ab71fbE66c6d2cB146799AccD0a',
  [Currency.Sand]: '0x733896b31637a1040B789cA68C4f7b3D897563ed',
  [Currency.ApeCoin]: '0xdD473FEe9ff79400Bb5dea401a4F65032d11784E',
}

export const CURRENCY_DECIMALS: Record<string, number> = {
  [Currency.Sport]: 18,
  [Currency.WETH]: 18,
  [Currency.USDT]: 6,
  [Currency.Sand]: 18,
  [Currency.ApeCoin]: 18,
}

export const CURRENCY_DECIMALS_BY_ADDRESS: Record<string, number> = {
  [CURRENCY_ADDRESSES[Currency.Sport]]: CURRENCY_DECIMALS[Currency.Sport],
  [CURRENCY_ADDRESSES[Currency.WETH]]: CURRENCY_DECIMALS[Currency.WETH],
  [CURRENCY_ADDRESSES[Currency.USDT]]: CURRENCY_DECIMALS[Currency.USDT],
  [CURRENCY_ADDRESSES[Currency.Sand]]: CURRENCY_DECIMALS[Currency.Sand],
  [CURRENCY_ADDRESSES[Currency.ApeCoin]]: CURRENCY_DECIMALS[Currency.ApeCoin],
}

export const currencyNamesByAddress: Record<string, string> = {
  [CURRENCY_ADDRESSES[Currency.Sport]]: 'Sport',
  [CURRENCY_ADDRESSES[Currency.WETH]]: 'WETH',
  [CURRENCY_ADDRESSES[Currency.Sand]]: 'Sand',
  [CURRENCY_ADDRESSES[Currency.ApeCoin]]: 'ApeCoin',
  [CURRENCY_ADDRESSES[Currency.USDT]]: 'USDT',
}
