import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'
import * as yup from 'yup'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { ArrowRightLineIcon } from 'assets/icons'
import { phoneRegExp } from 'constants/regExp'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import { useQuery_GetCountries_General } from 'services/useApi/general/useQuery'
import FormHelperText from '@mui/material/FormHelperText'
import { IUserInfo } from 'models/IUserInfo'

const useStyles = makeStyles({ name: 'RoseProfileEditForm' })((theme) => ({
  root: {
    maxWidth: 632,
    width: '100%',
    marginBottom: theme.spacing(24),
  },
  block: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.secondary.light,
    marginBottom: theme.spacing(3),
  },
  field: {
    width: '100%',
    marginBottom: theme.spacing(2),

    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 16,
      border: '1px solid rgba(255, 255, 255, 1)',
    },

    input: {
      background: 'rgba(0, 0, 0, 1)',
      borderRadius: 16,
      color: theme.palette.text.primary,

      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: theme.palette.grey[200],
      },
    },
  },
  autocompleteContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  autocomplete: {
    '& > div': {
      background: 'rgba(0, 0, 0, 1)',
      borderRadius: 16,
      color: theme.palette.text.primary,

      svg: {
        fill: theme.palette.text.primary,
      },
    },
  },
  autocompleteField: {
    marginBottom: 0,
  },
  btn: {
    maxWidth: 200,
    width: '100%',
    justifyContent: 'space-between',

    svg: {
      width: 24,
      height: 24,
      minWidth: 24,
    },
  },
  row: {
    display: 'flex',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 0,
    },
  },
  field50p: {
    width: '100%',
    maxWidth: '50%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  field70p: {
    width: '100%',
    maxWidth: '70%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  field30p: {
    width: '100%',
    maxWidth: '30%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  helperText: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  onSubmitRequest: (data: IUserInfo) => void
}

export const RoseProfileEditForm: FC<Props> = ({ onSubmitRequest }) => {
  const { classes, cx } = useStyles()

  const { data: countries = [] } = useQuery_GetCountries_General()

  const validationSchema = yup.object({
    firstAndLastName: yup
      .string()
      .min(2)
      .max(50)
      .required('First and last name is required'),
    email: yup
      .string()
      .email('Enter a valid email address')
      .required('Email address is required'),
    phone: yup
      .string()
      .max(50)
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone number is required'),
    country: yup.object().required('Country is required'),
    city: yup.string().min(2).max(50).required('City is required'),
    postCode: yup.number().required('Post code is required'),
    address: yup.string().min(2).max(50).required('Address is required'),
  })

  const formik = useFormik({
    initialValues: {
      firstAndLastName: '',
      email: '',
      phone: '',
      country: null,
      city: '',
      postCode: '',
      address: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({
      email,
      firstAndLastName,
      phone,
      country,
      city,
      postCode,
      address,
    }) => {
      // @ts-ignore
      const countryName = country?.name?.common as string

      await onSubmitRequest({
        email,
        name: firstAndLastName,
        phone,
        country: countryName,
        city,
        postCode,
        address,
      })

      resetForm()
    },
  })

  const {
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    resetForm,
    setFieldValue,
    isSubmitting,
  } = formik

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div className={classes.block}>
          <Typography className={classes.title} variant="h4">
            Contact information
          </Typography>
          <TextField
            id="firstAndLastName"
            name="firstAndLastName"
            label="* First and last name"
            value={values.firstAndLastName}
            onChange={handleChange}
            error={touched.firstAndLastName && Boolean(errors.firstAndLastName)}
            helperText={touched.firstAndLastName && errors.firstAndLastName}
            autoComplete="off"
            className={classes.field}
          />
          <div className={classes.row}>
            <TextField
              id="email"
              name="email"
              label="* Email Address"
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              autoComplete="off"
              className={cx(classes.field, classes.field50p)}
            />
            <TextField
              id="phone"
              name="phone"
              label="* Phone number"
              value={values.phone}
              onChange={handleChange}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
              autoComplete="off"
              type={'tel'}
              className={cx(classes.field, classes.field50p)}
            />
          </div>
        </div>
        <div className={classes.block}>
          <Typography className={classes.title} variant="h4">
            Delivery information
          </Typography>
          <div className={classes.autocompleteContainer}>
            <Autocomplete
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option?.name?.common}
              onChange={(event, value) => {
                setFieldValue('country', value)
              }}
              className={classes.autocomplete}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={option?.flags?.svg}
                    alt={option?.flags?.alt}
                  />
                  {option?.name?.common}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="* Country"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  className={cx(classes.field, classes.autocompleteField)}
                  id="country"
                  name="country"
                  error={touched.country && Boolean(errors.country)}
                />
              )}
            />
            {touched.country && errors.country && (
              <FormHelperText error className={classes.helperText}>
                {errors.country}
              </FormHelperText>
            )}
          </div>
          <div className={classes.row}>
            <TextField
              id="city"
              name="city"
              label="* City"
              value={values.city}
              onChange={handleChange}
              error={touched.city && Boolean(errors.city)}
              helperText={touched.city && errors.city}
              autoComplete="off"
              className={cx(classes.field, classes.field70p)}
            />
            <TextField
              id="postCode"
              name="postCode"
              label="* Post code"
              value={values.postCode}
              onChange={handleChange}
              error={touched.postCode && Boolean(errors.postCode)}
              helperText={touched.postCode && errors.postCode}
              autoComplete="off"
              className={cx(classes.field, classes.field30p)}
            />
          </div>
          <TextField
            id="address"
            name="address"
            label="* Address"
            value={values.address}
            onChange={handleChange}
            error={touched.address && Boolean(errors.address)}
            helperText={touched.address && errors.address}
            autoComplete="off"
            className={cx(classes.field)}
          />
        </div>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          size={'large'}
          className={classes.btn}
          disabled={isSubmitting}
        >
          <span>Save</span>
          <ArrowRightLineIcon />
        </Button>
      </form>
    </div>
  )
}
