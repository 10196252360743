import { IMainLandingHiwItem } from './types'

const getHiWImage = (image: string) =>
  `${process.env.PUBLIC_URL}/images/illustrations/main-landing/hiw/${image}`

export const MAIN_LANDING_HIW_LISTS: IMainLandingHiwItem[] = [
  {
    id: 1,
    title: 'Mystery boxes!',
    subtitle: 'Four Rarities to Suit Your Needs',
    description:
      'Your chance to own something truly unique! Our limited-edition mystery boxes will be filled with digital avatar stickers and item pieces, as well as opportunities to win exclusive merchandise. Four different types of boxes available - Bronze, Silver, Gold, and Diamond - each box containing its own set of pieces of varying rarity.',
    imageUrl: getHiWImage('mystery-boxes.webp'),
  },
  {
    id: 2,
    title: 'Build Your Own Fighter',
    subtitle: 'Train Hard and Become the Legendary Fighter',
    description:
      "Take on the collector's challenge by acquiring mystery boxes and unlocking them to find digital stickers inside. <b>Collect</b> enough stickers to unlock and <b>upgrade</b> your <b>Fighter Avatars</b> and become the ultimate <b>Legendary Fighter</b>. Each rarity avatar embodies your inner fighter spirit, reflecting your determination to never give up and keep striving for your goals!",
    imageUrl: getHiWImage('stickers.webp'),
  },
  {
    id: 3,
    title: 'Exclusive Prizes',
    subtitle: 'Earn & Win Limited-Edition Items and Interactions',
    description:
      'Collect digital stickers of your favorite items and redeem them for <b>real-life prizes</b> and <b>interactions with Rose</b> herself. Show off your Thug Rose spirit with limited-edition hoodies, t-shirts, and caps representing strength, perseverance, dedication, and the Champion spirit. Additional to collectible items, each mystery box gives you an opportunity to win an exclusive prize!',
    imageUrl: getHiWImage('prizes.webp'),
  },
  // {
  //   id: 4,
  //   title: 'Legendary Raffle',
  //   subtitle: 'One-of-a-Kind Raffle for Legendary Fighters',
  //   description:
  //     "Get ready to unleash your inner fighter and reach the pinnacle of success - Legendary status! All you need to do is collect digital stickers and build your ultimate <b>fighter</b>. But that's not all - once you achieve <b>Legendary status</b>, you'll get a chance to participate in the coveted Legendary Raffle! This means you could be the lucky winner of <b>exclusive items signed</b> by none other than Rose Namajunas herself.",
  //   imageUrl: getHiWImage('gloves.webp'),
  // },
]
