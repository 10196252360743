import { Navigate, useParams } from 'react-router-dom'
import { ROUTER_PATHS } from 'routes/routerPaths'
import { FC } from 'react'
import { ItemStickersName } from 'constants/stickers'

interface Props {
  children: JSX.Element
}

const isTypeOfMerchValid = (rarityId: string): boolean => {
  const validRarities = [
    ItemStickersName.Hoodie,
    ItemStickersName.Cap,
    ItemStickersName['T-Shirt'],
  ]

  return validRarities.some(
    (rarity) => rarity.toLowerCase() === rarityId.toLowerCase()
  )
}

export const ProtectedRoseMerchDetailsRoute: FC<Props> = ({ children }) => {
  const { id = '' } = useParams()

  if (isTypeOfMerchValid(id)) {
    return children
  }

  return <Navigate to={ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS} />
}
