import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  EnqueueSnackArgType,
  ISnackbarState,
  ISnackbarTimersInfo,
} from './types'
import _uniqueId from 'lodash/uniqueId'

export const initialState: ISnackbarState = {
  message: '',
  variant: 'default',
  autoHideDuration: 2000,
  id: '',
  startTimerSnackbarKey: '',
  timersInfo: [],
  closeTimerSnackbarKey: '',
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    enqueueSnack(state, action: PayloadAction<EnqueueSnackArgType>) {
      state.message = action.payload.message
      state.variant = action.payload.variant
      state.autoHideDuration =
        action.payload?.autoHideDuration || state.autoHideDuration
      state.id = action.payload?.id || _uniqueId('snackbar-')
    },
    setStartTimerSnackbarKey(state, action: PayloadAction<string>) {
      state.startTimerSnackbarKey = action.payload
    },
    setCloseTimerSnackbarKey(state, action: PayloadAction<string>) {
      state.closeTimerSnackbarKey = action.payload
    },
    addTimersInfo(state, action: PayloadAction<ISnackbarTimersInfo>) {
      state.timersInfo = [...state.timersInfo, action.payload]
    },
    deleteTimersInfo(state, action: PayloadAction<string | number>) {
      state.timersInfo = state.timersInfo.filter(
        (item) => item.snackbarKey !== action.payload
      )
    },
  },
})

export default snackbarSlice.reducer
