import { IUserState } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserInfo } from 'models/IUserInfo'

export const initialState: IUserState = {
  address: null,
  chainId: null,
  isLoading: true,
  isValidChain: false,
  profile: {
    isExists: false,
    isLoading: true,
    userInfo: {
      name: '',
      email: '',
      phone: '',
      country: '',
      city: '',
      postCode: '',
      address: '',
    },
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAddress(state, action: PayloadAction<string | null>) {
      state.address = action.payload?.toLowerCase() || null
    },
    setUserChainId(state, action: PayloadAction<number | null>) {
      state.chainId = action.payload
    },
    setIsLoadingUserAuth(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    clearUserData(state) {
      state.address = null
      state.chainId = null
    },
    setIsValidChain(state, action: PayloadAction<boolean>) {
      state.isValidChain = action.payload
    },
    setProfileIsExists(state, action: PayloadAction<boolean>) {
      state.profile.isExists = action.payload
      state.profile.isLoading = false
    },
    setProfileUserInfo(state, action: PayloadAction<IUserInfo>) {
      state.profile.userInfo = action.payload
    },
  },
})

export default userSlice.reducer
