import { forwardRef, ReactNode, useEffect, useState } from 'react'
import { SnackbarContent, useSnackbar } from 'notistack'
import { CloseIcon, TimerIcon } from 'assets/icons'
import { format, formatDistanceStrict } from 'date-fns'
import { makeStyles } from 'styles/makeStyles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles({ name: 'Snackbar' })((theme) => ({
  root: {
    background: `rgba(13,13,13,0.8)`,
    padding: theme.spacing(1),
    borderRadius: 16,
    color: theme.palette.text.primary,
  },
  content: {
    border: `none`,
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
  },
  extra: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    paddingTop: theme.spacing(0.1),
    transform: 'translateX(-3px)',
    color: theme.palette.common.white,

    '& svg': {
      transform: 'translateY(-1px)',
      marginRight: theme.spacing(1),
    },
  },
  close: {
    marginLeft: theme.spacing(2),
    minWidth: 16,
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
  timerIcon: {
    minWidth: 16,
    width: 16,
    height: 16,
    margin: theme.spacing(0, 1),
  },
}))

type Props = {
  id: string | number
  message: string | React.ReactNode
  variant?: 'started' | 'success'
  startTime?: Date
  time?: Date
  icon?: ReactNode
  trackTime?: boolean
}

export const Snackbar = forwardRef<HTMLDivElement, Props>(
  ({ message, id, trackTime, startTime, time }, ref) => {
    const { classes } = useStyles()
    const { closeSnackbar } = useSnackbar()
    const [currentTime, setCurrentTime] = useState(new Date())

    useEffect(() => {
      let interval: any
      if (trackTime) {
        interval = setInterval(() => {
          setCurrentTime(new Date())
        }, 1000)
      }

      return () => {
        clearInterval(interval)
      }
    }, [trackTime])

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Box className={classes.content}>
          <Box px={1}>
            <Typography variant={'subtitle2'}>{message}</Typography>
            <Typography variant={'body1'} className={classes.extra}>
              {time && (
                <>
                  <TimerIcon className={classes.timerIcon} />{' '}
                  {format(time, 'HH:mm')}
                </>
              )}
              {startTime && time && (
                <> - {formatDistanceStrict(time, startTime)}</>
              )}
              {trackTime && time && (
                <> - {formatDistanceStrict(currentTime, time)}</>
              )}
            </Typography>
          </Box>
          <CloseIcon
            className={classes.close}
            onClick={() => closeSnackbar(id)}
          />
        </Box>
      </SnackbarContent>
    )
  }
)

Snackbar.displayName = 'Snackbar'
