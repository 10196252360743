export const apiUrls = {
  mailerApiUrl:
    process.env.MAILER_API_URL ||
    'https://vywp1l5s3b.execute-api.eu-central-1.amazonaws.com/default/mailer-digiSport-api',
  actionsApiUrl:
    process.env.ACTIONS_API_URL || 'https://dev.actions.api-lympo.io/v1/api',
  mysteryBoxWhitelistApi:
    process.env.MYSTERY_BOX_WHITELIST_API ||
    'https://ohwibavf5l.execute-api.eu-central-1.amazonaws.com/default/rose-mystery-box-whitelist',
  termsAndConditionsApiUrl:
    process.env.TERMS_AND_CONDITIONS_API_URL ||
    'https://terms.api-lympo.io/v1/api',
  thegraph:
    process.env.THEGRAPH_NFT_ATHLETES_API_URL ||
    'https://api.thegraph.com/subgraphs/name/romang11',
  swipeluxApi:
    process.env.SWIPELUX_API_URL || 'https://swipelux.api-lympo.io/v1/api',
  restcountriesApi: 'https://restcountries.com/v3.1',
  userInfo:
    process.env.USER_INFO_API_URL || 'https://user-info.digisport.xyz/v1/api',
}
