import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import {
  AddCircleFillIcon,
  CheckboxGreenIcon,
  RemoveCircleIcon,
} from 'assets/icons'
import Button from '@mui/material/Button'
import { useAppSelector } from 'store/hooks'
import { selectIsValidChain } from 'store/reducers/user/selectors'

const useStyles = makeStyles({ name: 'RoseNftTinyAction' })((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  rootSmall: {
    padding: theme.spacing(1),
  },
  icon: {
    minWidth: 24,
    width: 24,
    height: 24,
    position: 'absolute',
    left: -2,
    top: -2,
  },
  imageContainer: {
    minWidth: 128,
    width: 128,
    height: 128,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    position: 'relative',

    img: {
      width: '100%',
      height: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: 97,
      width: 97,
      height: 97,
    },
  },
  imageContainerSmall: {
    minWidth: 97,
    width: 97,
    height: 97,
  },
  layout: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.6)',
  },
  borderGray: {
    border: `2px solid ${theme.palette.grey[300]}`,
  },
  btn: {
    gap: theme.spacing(0.5),
    textTransform: 'none',
    padding: theme.spacing(1),
    width: '100%',
    borderRadius: theme.spacing(1),
    fontSize: 14,
    lineHeight: '16px',

    svg: {
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },
  borderYellow: {
    border: `2px solid ${theme.palette.secondary.light}`,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    background: theme.palette.secondary.light,
    position: 'absolute',
    border: `2px solid ${theme.palette.common.white}`,
    top: 4,
    left: 4,
    zIndex: 1,
  },
}))

interface Props {
  className?: string
  isActive: boolean
  isChecked?: boolean
  imageUrl: string
  isSmall?: boolean
  onAddBtnClick: () => void
  onRemoveBtnClick: () => void
  isDisabled?: boolean
}

export const RoseNftTinyAction: FC<Props> = ({
  className,
  isActive,
  isChecked,
  imageUrl,
  isSmall,
  onAddBtnClick,
  onRemoveBtnClick,
  isDisabled,
}) => {
  const { classes, cx } = useStyles()

  const isValidChain = useAppSelector(selectIsValidChain)

  return (
    <div
      className={cx(
        classes.root,
        isSmall && classes.rootSmall,
        className && className
      )}
    >
      <div
        className={cx(
          classes.imageContainer,
          !isChecked && classes.borderGray,
          !isChecked && isActive && classes.borderYellow,
          isSmall && classes.imageContainerSmall
        )}
      >
        {!isChecked && isActive && <div className={classes.dot} />}
        {isChecked && <CheckboxGreenIcon className={cx(classes.icon)} />}
        <img src={imageUrl} alt={'rose nft'} />
        {!isChecked && <div className={classes.layout} />}
      </div>
      {isChecked ? (
        <Button
          variant="outlined"
          onClick={onRemoveBtnClick}
          className={classes.btn}
          disabled={isDisabled || !isValidChain}
        >
          <RemoveCircleIcon />
          <span>Remove</span>
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={onAddBtnClick}
          className={classes.btn}
          color={'success'}
          disabled={isDisabled || !isValidChain}
        >
          <AddCircleFillIcon />
          <span>Add</span>
        </Button>
      )}
    </div>
  )
}
