import { useParams } from 'react-router-dom'
import { RoseCollectionDetailsHeadingComponent } from './RoseCollectionDetailsHeadingComponent'
import { localStorageKeys } from 'constants/localStorageKeys'
import { capitalizeFirstLetter } from 'utils/formatValue'
import { useAppSelector } from 'store/hooks'
import { reFetchedFlagSelector } from 'store/reducers/app/selectors'
import {
  athleteStickersByGroupRarity,
  RoseCollectionById,
} from 'constants/stickers'
import {
  useQuery_BalanceOfBatch_Erc1155,
  useQuery_IsApproved_Erc1155,
} from 'services/useApi/erc1155/useQuery'
import { getCacheKey } from 'utils/getCacheKey'
import { cacheKeys } from 'constants/cacheKeys'
import {
  ROSE_COLLECTION_CONTRACT_ADDRESS,
  ROUTER_CONTRACT_ADDRESS,
} from 'constants/addresses'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { NftGroupRarity } from 'models/INfts'
import { useMutation_Approve_Erc1155 } from 'services/useApi/erc1155/useMutation'
import { queryClient } from 'services/providers'
import { useEffect, useMemo, useState } from 'react'
import { useLocalStorageKeys } from 'hooks/useLocalStorageKeys'
import { useIsValidChainId } from 'hooks/useIsValidChainId'
import { validChainId } from 'constants/validChainId'

export const RoseCollectionDetailsHeadingContainer = () => {
  const { id: paramId = '' } = useParams()

  const reFetchedFlag = useAppSelector(reFetchedFlagSelector)

  const [selectedItems, setSelectedItems] = useState<Record<number, number>>({})
  const [isOpenClaimModal, setIsOpenClaimModal] = useState<boolean>(false)

  const [keyLS] = useLocalStorageKeys(localStorageKeys.athleteStickersSelected)
  const [isValidChainId] = useIsValidChainId(validChainId)

  const updateSelectedItems = () => {
    const items = localStorage.getItem(keyLS)

    if (items) {
      setSelectedItems(JSON.parse(items))
    } else {
      setSelectedItems({})
    }
  }

  useEffect(() => {
    updateSelectedItems()
  }, [keyLS])

  const ids = Object.values(RoseCollectionById).filter(
    (item) => typeof item === 'number'
  ) as number[]

  const { data = [] } = useQuery_BalanceOfBatch_Erc1155(
    getCacheKey(
      cacheKeys.erc1155.balanceOfBatch,
      'RoseCollectionDetailsHeadingComponent'
    ),
    ids,
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    reFetchedFlag[ReFetchFlag.ClaimAthleteNft]
  )

  const balanceById = useMemo(() => {
    return data.reduce((acc, item, index) => {
      acc[ids[index]] = item
      return acc
    }, {} as Record<number, number>)
  }, [data])

  const isCompleted = useMemo(() => {
    const ids =
      athleteStickersByGroupRarity[
        capitalizeFirstLetter(paramId) as NftGroupRarity
      ] || []

    return ids.every((id) => selectedItems[id] > 0)
  }, [selectedItems, paramId])

  const ownedGeneral = useMemo(() => {
    const ids =
      athleteStickersByGroupRarity[
        capitalizeFirstLetter(paramId) as NftGroupRarity
      ] || []

    return ids.reduce((acc, id) => {
      return acc + (balanceById[id] || 0)
    }, 0)
  }, [balanceById, paramId])

  const isPrevCompleted = useMemo(() => {
    const ids =
      athleteStickersByGroupRarity[
        capitalizeFirstLetter(paramId) as NftGroupRarity
      ] || []

    const completedIds = ids.filter((id) => selectedItems[id] > 0)

    return completedIds.length === ids.length - 1
  }, [selectedItems, paramId])

  const { data: isApproved = false } = useQuery_IsApproved_Erc1155(
    getCacheKey('IsApproved_Erc1155', 'RoseCollectionDetailsHeadingComponent'),
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    ROUTER_CONTRACT_ADDRESS
  )

  const { mutateAsync: approveMutateAsync, isLoading: approveLoading } =
    useMutation_Approve_Erc1155(async () => {
      await queryClient.invalidateQueries([
        getCacheKey(
          'IsApproved_Erc1155',
          'RoseCollectionDetailsHeadingComponent'
        ),
      ])
    })

  const onApprove = async () => {
    await approveMutateAsync({
      nftContractAddress: ROSE_COLLECTION_CONTRACT_ADDRESS,
      toAddress: ROUTER_CONTRACT_ADDRESS,
    })
  }

  const onAddToLocalStorage = (id: number) => {
    const items = localStorage.getItem(keyLS)

    if (items) {
      const parsedItems = JSON.parse(items)
      const newItems = {
        ...parsedItems,
        [id]: 1,
      }
      localStorage.setItem(keyLS, JSON.stringify(newItems))
    } else {
      localStorage.setItem(keyLS, JSON.stringify({ [id]: 1 }))
    }

    updateSelectedItems()
  }

  const onRemoveFromLocalStorage = (id: number) => {
    const items = localStorage.getItem(keyLS)

    if (items) {
      const parsedItems = JSON.parse(items)
      const newItems = {
        ...parsedItems,
      }
      delete newItems[id]
      localStorage.setItem(keyLS, JSON.stringify(newItems))

      updateSelectedItems()
    }
  }

  return (
    <RoseCollectionDetailsHeadingComponent
      paramId={paramId}
      isOpenClaimModal={isOpenClaimModal}
      setIsOpenClaimModal={setIsOpenClaimModal}
      onAddToLocalStorage={onAddToLocalStorage}
      onRemoveFromLocalStorage={onRemoveFromLocalStorage}
      balanceById={balanceById}
      isCompleted={isCompleted}
      isPrevCompleted={isPrevCompleted}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      isApproved={isApproved}
      approveLoading={approveLoading}
      onApprove={onApprove}
      isValidChainId={isValidChainId}
      ownedGeneral={ownedGeneral}
    />
  )
}
