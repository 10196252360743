import { makeStyles } from 'styles/makeStyles'
import { RoseInventoryHeading } from '../RoseInventoryHeading'
import {
  InventoryFighterAvatarSticker,
  InventoryItemSticker,
} from 'components/InventoryItems'
import { RoseInventoryHeadingLine } from '../RoseInventoryHeadingLine'
import { LINKS_ANCHOR } from 'constants/linksAnchor'
import {
  athleteAdvancedStickerList,
  athleteBeginnerStickerList,
  athleteEliteStickerList,
  athleteIntermediateStickerList,
  itemStickerList,
} from 'constants/stickers'
import { urlGenerator } from 'services/urlGenerator'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'
import { FC, memo } from 'react'
import { RoseInventoryLayout } from '../RoseInventoryLayout'
import { MenuInventory } from '../constants'
import { arePropsEqual } from 'utils/arePropsEqual'

const useStyles = makeStyles({ name: 'RoseInventoryNftStickersComponent' })(
  (theme) => ({
    root: {
      width: '100%',
    },
    content: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
      margin: theme.spacing(3, 0, 10),

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    title: {
      margin: theme.spacing(3, 0, 2),
    },
    list: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(2),
      flexWrap: 'wrap',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    contentFighterAvatar: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      margin: theme.spacing(0, 0, 10),

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    contentMissingStickers: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      margin: theme.spacing(6, 0, 10),
      gap: theme.spacing(8),
      gapRow: theme.spacing(5),

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        gap: theme.spacing(2),
        gapRow: theme.spacing(2),
      },
    },
  })
)

interface Props {
  balanceById: Record<number, number>
  activeMenuItem: MenuInventory
}

const RoseInventoryNftStickers: FC<Props> = ({
  balanceById,
  activeMenuItem,
}) => {
  const { classes, cx } = useStyles()

  return (
    <RoseInventoryLayout activeMenuItem={activeMenuItem}>
      <div
        className={cx(classes.root)}
        id={LINKS_ANCHOR.inventoryNftStickers.id}
      >
        <RoseInventoryHeading
          title={'Item stickers'}
          description={
            'Show off your Thug Rose spirit with limited-edition merchandise! Collect digital stickers and redeem them for real-life prizes and interactions.'
          }
        />
        <div className={classes.content}>
          {itemStickerList.map((item) => (
            <InventoryItemSticker
              key={item.id}
              name={item.name}
              imgUrl={item.image}
              own={balanceById[item.id] || 0}
              openSeaUrl={urlGenerator.createOpenSeaLink(
                ROSE_COLLECTION_CONTRACT_ADDRESS,
                item.id
              )}
            />
          ))}
        </div>
        <RoseInventoryHeading
          title={'Fighter avatar stickers'}
          description={
            'Your inner ultimate fighter is waiting to be released! Collect digital stickers and merge them to build your avatar!'
          }
        />
        <div className={classes.contentFighterAvatar}>
          <RoseInventoryHeadingLine
            title={'Beginner'}
            className={classes.title}
          />
          <div className={classes.list}>
            {athleteBeginnerStickerList.map((item) => (
              <InventoryFighterAvatarSticker
                key={item.id}
                imgUrl={item.image}
                name={item.rarity}
                title={item.name}
                own={balanceById[item.id] || 0}
                openSeaUrl={urlGenerator.createOpenSeaLink(
                  ROSE_COLLECTION_CONTRACT_ADDRESS,
                  item.id
                )}
              />
            ))}
          </div>
          <RoseInventoryHeadingLine
            title={'Intermediate'}
            className={classes.title}
          />
          <div className={classes.list}>
            {athleteIntermediateStickerList.map((item) => (
              <InventoryFighterAvatarSticker
                key={item.id}
                imgUrl={item.image}
                name={item.rarity}
                title={item.name}
                own={balanceById[item.id] || 0}
                openSeaUrl={urlGenerator.createOpenSeaLink(
                  ROSE_COLLECTION_CONTRACT_ADDRESS,
                  item.id
                )}
              />
            ))}
          </div>
          <RoseInventoryHeadingLine
            title={'Advanced'}
            className={classes.title}
          />
          <div className={classes.list}>
            {athleteAdvancedStickerList.map((item) => (
              <InventoryFighterAvatarSticker
                key={item.id}
                imgUrl={item.image}
                name={item.rarity}
                title={item.name}
                own={balanceById[item.id] || 0}
                openSeaUrl={urlGenerator.createOpenSeaLink(
                  ROSE_COLLECTION_CONTRACT_ADDRESS,
                  item.id
                )}
              />
            ))}
          </div>
          <RoseInventoryHeadingLine title={'Elite'} className={classes.title} />
          <div className={classes.list}>
            {athleteEliteStickerList.map((item) => (
              <InventoryFighterAvatarSticker
                key={item.id}
                imgUrl={item.image}
                name={item.rarity}
                title={item.name}
                own={balanceById[item.id] || 0}
                openSeaUrl={urlGenerator.createOpenSeaLink(
                  ROSE_COLLECTION_CONTRACT_ADDRESS,
                  item.id
                )}
              />
            ))}
          </div>
        </div>
      </div>
    </RoseInventoryLayout>
  )
}

export const RoseInventoryNftStickersComponent = memo(
  RoseInventoryNftStickers,
  arePropsEqual
)
