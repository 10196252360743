import { staticUrls } from 'constants/staticUrls'

export const ROSE_PROFILE_CONTENT = {
  title: 'User Profile',
  description:
    'Please fill in the required data for the item to be shipped. Please be aware that depending on your location, the shipping time may vary from 10-42 days. <br /> Please note that, in accordance to our T&C, we do not ship to Russia and Belarus. ',
  bottomDescription: `
    If you need to make some changes to your details please contact <a target="_blank" href="${staticUrls.supportRequest}">our support</a>
  `,
}
