import { ethersProvider } from '../../providers'
import { ContractTransaction } from '@ethersproject/contracts'
import { DigiSportRouter__factory } from 'models/contracts/ethers/factories/DigiSportRouter__factory'

export const contractOpenBoxApi = {
  getProvider: () => ethersProvider.getProvider(),

  async openBox(
    boxId: number,
    amount: number,
    routerContractAddress: string
  ): Promise<ContractTransaction> {
    const digiSportRouterInstance = DigiSportRouter__factory.connect(
      routerContractAddress,
      this.getProvider()!.getSigner()
    )

    const gasLimit = await digiSportRouterInstance.estimateGas.openBox(
      boxId,
      amount
    )

    return digiSportRouterInstance.openBox(boxId, amount, {
      gasLimit: gasLimit.add(100000 * amount),
    })
  },

  async swapAthletesStickers(
    athleteId: number,
    routerContractAddress: string
  ): Promise<ContractTransaction> {
    const digiSportRouterInstance = DigiSportRouter__factory.connect(
      routerContractAddress,
      this.getProvider()!.getSigner()
    )

    return digiSportRouterInstance.swapAthletesStickers(athleteId)
  },

  async swapItemStickers(
    itemId: number,
    routerContractAddress: string
  ): Promise<ContractTransaction> {
    const digiSportRouterInstance = DigiSportRouter__factory.connect(
      routerContractAddress,
      this.getProvider()!.getSigner()
    )

    return digiSportRouterInstance.swapItemStickers(itemId)
  },

  async breeding(
    itemIds: Array<string | number>,
    routerContractAddress: string
  ): Promise<ContractTransaction> {
    const digiSportRouterInstance = DigiSportRouter__factory.connect(
      routerContractAddress,
      this.getProvider()!.getSigner()
    )

    return digiSportRouterInstance.breeding(itemIds)
  },
}
