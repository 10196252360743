import { makeStyles } from 'styles/makeStyles'
import { Typography } from '@mui/material'
import { OpenSeaIcon } from 'assets/icons'
import { FC } from 'react'
import Button from '@mui/material/Button'

const useStyles = makeStyles({ name: 'RoseInventoryHeading' })((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1),
    },
  },
  content: {
    maxWidth: 523,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.secondary.light,
  },
  description: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },
  btn: {
    textTransform: 'none',

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
}))

interface Props {
  className?: string
  title: string
  description: string
  openSeaUrl?: string
}

export const RoseInventoryHeading: FC<Props> = ({
  className,
  title,
  description,
  openSeaUrl,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={classes.content}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.description}>
          {description}
        </Typography>
      </div>
      {openSeaUrl && (
        <Button
          variant={'outlined'}
          color={'secondary'}
          className={classes.btn}
          target={'_blank'}
          href={openSeaUrl}
        >
          <span>View on opensea</span>
          <OpenSeaIcon />
        </Button>
      )}
    </div>
  )
}
