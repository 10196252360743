import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { ArrowRightLineIcon, OpenSeaIcon } from 'assets/icons'
import Button from '@mui/material/Button'
import { RoseSliderHeroes } from '../RoseSliderHeroes'
import { publicImages } from 'utils/getPublicImages'
import { useAppDispatch } from 'store/hooks'
import { setModal } from 'store/reducers/app/actions'
import { ModalName } from 'constants/modals'
import { linearGradients } from 'styles/other'
import { openSeaUrls } from 'constants/staticUrls'

const useStyles = makeStyles({ name: 'RoseWhatInsidePacks' })((theme) => ({
  root: {
    padding: theme.spacing(8, 0, 7),
    position: 'relative',
    zIndex: 2,
    overflow: 'hidden',
    marginTop: theme.spacing(9),

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      top: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0, 15),
      marginTop: theme.spacing(2),
    },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(10),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  subtitle1: {
    fontFamily: fontSpaceMono,
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    lineHeight: '40px',
  },
  text: {
    maxWidth: 632,
    margin: theme.spacing(2, 0, 5),

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    maxWidth: 358,
    width: '100%',
    margin: theme.spacing(0, 0, 3),

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
  boxes: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    maxWidth: 526,

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  box: {
    minWidth: 254,
    width: 254,
    borderRadius: theme.spacing(2),
    background: 'rgba(255, 255, 255, 0.2)',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      minWidth: 140,
      width: 140,
    },

    img: {
      minWidth: 160,
      width: 160,

      [theme.breakpoints.down('sm')]: {
        minWidth: 88,
        width: 88,
      },
    },
  },
}))

export const RoseWhatInsidePacks = () => {
  const { classes } = useStyles()

  const dispatch = useAppDispatch()

  const handleShowDropRatesClick = () => {
    dispatch(setModal(ModalName.BoxDropRates, true))
  }

  return (
    <div className={classes.root}>
      <Container maxWidth={'lg'}>
        <div className={classes.textContainer}>
          <div>
            <Typography variant={'subtitle1'} className={classes.subtitle1}>
              What’s inside
            </Typography>
            <Typography variant={'h2'}>Mystery Boxes</Typography>
            <Typography variant={'subtitle1'} className={classes.text}>
              Discover the secrets of the fighter spirit by obtaining Mystery
              Boxes and unlocking their contents! Gather stickers and unleash
              your true Champion potential! Every box will grant you a minimum
              of 2 fighter guaranteed stickers along with bonus stickers and the
              chance of obtaining a unique item sticker.
            </Typography>
            <Button
              variant={'contained'}
              size={'large'}
              className={classes.btn}
              href={openSeaUrls.roseCollection}
              target={'_blank'}
            >
              <span>View on OpenSea</span>
              <OpenSeaIcon />
            </Button>
            <Button
              variant={'outlined'}
              size={'large'}
              className={classes.btn}
              onClick={handleShowDropRatesClick}
            >
              <span>Show drop rates</span>
              <ArrowRightLineIcon />
            </Button>
          </div>
          <div className={classes.boxes}>
            <div className={classes.box}>
              <img
                src={publicImages.getIllustration(
                  'main-landing/packs/box-bronze.webp'
                )}
                alt={'bronze'}
              />
              <Typography variant={'h4'} fontWeight={700}>
                Bronze
              </Typography>
            </div>
            <div className={classes.box}>
              <img
                src={publicImages.getIllustration(
                  'main-landing/packs/box-silver.webp'
                )}
                alt={'silver'}
              />
              <Typography variant={'h4'} fontWeight={700}>
                Silver
              </Typography>
            </div>
            <div className={classes.box}>
              <img
                src={publicImages.getIllustration(
                  'main-landing/packs/box-gold.webp'
                )}
                alt={'gold'}
              />
              <Typography variant={'h4'} fontWeight={700}>
                Gold
              </Typography>
            </div>
            <div className={classes.box}>
              <img
                src={publicImages.getIllustration(
                  'main-landing/packs/box-diamond.webp'
                )}
                alt={'diamond'}
              />
              <Typography variant={'h4'} fontWeight={700}>
                Diamond
              </Typography>
            </div>
          </div>
        </div>
      </Container>
      <RoseSliderHeroes autoplay />
    </div>
  )
}
