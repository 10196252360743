import { FC, memo } from 'react'
import { makeStyles } from 'styles/makeStyles'
import Box from '@mui/material/Box'
import { CURRENCY_ADDRESSES } from 'constants/addresses'
import { Currency } from 'constants/currency'
import {
  ApeCoinCurrency,
  EthereumCurrency,
  SandCurrency,
  SportCurrency,
  UsdtCurrency,
} from 'assets/currency'

const iconsCurrency: { [x: string]: React.SVGProps<SVGSVGElement> } = {
  [CURRENCY_ADDRESSES[Currency.Sport]]: <SportCurrency />,
  [CURRENCY_ADDRESSES[Currency.WETH]]: <EthereumCurrency />,
  [CURRENCY_ADDRESSES[Currency.Sand]]: <SandCurrency />,
  [CURRENCY_ADDRESSES[Currency.ApeCoin]]: <ApeCoinCurrency />,
  [CURRENCY_ADDRESSES[Currency.USDT]]: <UsdtCurrency />,
}

const useStyles = makeStyles({ name: 'IconCurrencyByAddress' })((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.25),

    svg: {
      height: 16,
      minWidth: 16,
      width: 16,
    },
  },
}))

interface Props {
  className?: string
  address: string
}

const IconCurrencyByAddressComponent: FC<Props> = ({ className, address }) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.root, className && className)}>
      <>{iconsCurrency[address]}</>
    </Box>
  )
}

export const IconCurrencyByAddress = memo(IconCurrencyByAddressComponent)
