export const fontPoppins = [
  '"Poppins"',
  '"Helvetica"',
  'Arial',
  'sans-serif',
].join(',')

export const fontSpaceMono = [
  '"Space Mono"',
  '"Helvetica"',
  'Arial',
  'monospace',
].join(',')

export const fontNeuePlak = ['"Neue Plak"', '"Helvetica"', 'Arial'].join(',')
