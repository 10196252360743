import { makeStyles } from 'styles/makeStyles'
import { NftGroupRarity } from 'models/INfts'
import { publicImages } from 'utils/getPublicImages'
import { FC } from 'react'
import { Typography } from '@mui/material'
import { ArrowRightLineIcon, WarningFillIcon } from 'assets/icons'
import { linearGradients } from 'styles/other'
import { fontSpaceMono } from 'styles/fonts'

const getBgImage = (groupRarity: NftGroupRarity) => {
  return publicImages.getIllustration(
    `rose/fighters/fighter-blur-${groupRarity.toLowerCase()}.webp`
  )
}

const useStyles = makeStyles({ name: 'RoseFighterStepBreeding' })((theme) => ({
  root: {
    position: 'relative',
  },
  container: {
    width: 593,
    minWidth: 593,
    height: 593,
    borderRadius: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center center',

    [theme.breakpoints.down('sm')]: {
      width: 288,
      minWidth: 288,
      height: 'auto',
      backgroundSize: '288px 288px',
      backgroundPosition: 'top center',
      marginBottom: theme.spacing(3),
    },

    '&::after': {
      content: "''",
      left: 0,
      position: 'absolute',
      top: 0,
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: 1,
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(3),
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        height: 288,
      },
    },
  },
  bgBeginner: {
    backgroundImage: `url(${getBgImage(NftGroupRarity.Beginner)})`,
  },
  bgIntermediate: {
    backgroundImage: `url(${getBgImage(NftGroupRarity.Intermediate)})`,
  },
  bgAdvanced: {
    backgroundImage: `url(${getBgImage(NftGroupRarity.Advanced)})`,
  },
  bgElite: {
    backgroundImage: `url(${getBgImage(NftGroupRarity.Elite)})`,
  },
  bgLegendary: {
    backgroundImage: `url(${getBgImage(NftGroupRarity.Legendary)})`,
  },
  content: {
    width: '100%',
    position: 'relative',
    zIndex: 3,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      minHeight: 288,
      display: 'flex',
      alignItems: 'center',
    },
  },
  contentLegendary: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(5, 3),
    position: 'relative',
    zIndex: 3,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    lineHeight: '48px',
    fontWeight: 700,
  },
  description: {
    fontWeight: 700,
    margin: theme.spacing(1, 0, 4),
  },
  contentBlock: {
    display: 'flex',
    gap: theme.spacing(1),
    height: '100%',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentInnerBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 2, 2),
    borderRadius: theme.spacing(2),
    background: 'rgba(38, 38, 38, 0.9)',
    backdropFilter: 'blur(12px)',
    width: '100%',
    height: '100%',
    border: `1px solid rgba(255, 255, 255, 0.2)`,
    borderBottom: 'none',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  contentArrow: {
    minWidth: 48,
    height: 48,
    width: 48,

    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
      minWidth: 24,
      height: 24,
      width: 24,
    },
  },
  contentTitle: {
    fontWeight: 800,
    position: 'relative',
    paddingBottom: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,
    },
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(3),
    height: '100%',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      gap: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
  },
  subtitle: {
    fontFamily: fontSpaceMono,
  },
  contentInfoBigNumber: {
    fontSize: 64,
    lineHeight: '80px',
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    gap: theme.spacing(1),
    color: theme.palette.warning.main,

    svg: {
      minWidth: 16,
      width: 16,
      height: 16,
    },
  },
  colorOrange: {
    color: theme.palette.warning.main,
  },
}))

interface Props {
  className?: string
  groupRarity: NftGroupRarity
  requiredForBreeding: number
  availableForBreeding: number
  disabled?: boolean
}

export const RoseFighterStepBreeding: FC<Props> = ({
  className,
  groupRarity,
  requiredForBreeding = 4,
  availableForBreeding = 0,
  disabled,
}) => {
  const { classes, cx } = useStyles()

  const bgClass = {
    [NftGroupRarity.Beginner]: classes.bgBeginner,
    [NftGroupRarity.Intermediate]: classes.bgIntermediate,
    [NftGroupRarity.Advanced]: classes.bgAdvanced,
    [NftGroupRarity.Elite]: classes.bgElite,
    [NftGroupRarity.Legendary]: classes.bgLegendary,
  }

  const content = {
    [NftGroupRarity.Beginner]: <></>,
    [NftGroupRarity.Intermediate]: <></>,
    [NftGroupRarity.Advanced]: <></>,
    [NftGroupRarity.Elite]: <></>,
    [NftGroupRarity.Legendary]: (
      <div className={classes.contentLegendary}>
        <Typography variant="h4" className={classes.title}>
          Unlock the Ultimate Fighter
        </Typography>
        <Typography variant="body2" className={classes.description}>
          Legendary Fighter can be achieved through fusing - combining Elite
          Fighters. You need to fuse 4 Elite NFTs to create one ultimate
          Legendary NFT. Choose how many fighters you want to interchange for an
          Advanced Fighter.
        </Typography>
        <div className={classes.contentBlock}>
          <div className={classes.contentInnerBlock}>
            <Typography variant="h4" className={classes.contentTitle}>
              Elite
            </Typography>
            <div className={classes.contentInfo}>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Required for fusion
                </Typography>
                <Typography variant="h3" fontWeight={700}>
                  {requiredForBreeding}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Available for fusion
                </Typography>
                <Typography variant="h3" fontWeight={700}>
                  {availableForBreeding}
                </Typography>
              </div>
            </div>
          </div>
          <ArrowRightLineIcon className={classes.contentArrow} />
          <div className={classes.contentInnerBlock}>
            <Typography variant="h4" className={classes.contentTitle}>
              Legendary
            </Typography>
            <div className={classes.contentInfo}>
              <div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Available to fuse
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight={700}
                  className={cx(
                    classes.contentInfoBigNumber,
                    disabled && classes.colorOrange
                  )}
                >
                  {Math.floor(availableForBreeding / requiredForBreeding)}
                </Typography>
              </div>
              {disabled && (
                <div className={classes.warning}>
                  <WarningFillIcon />
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Not enough NFTs for fusion
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    ),
  }

  return (
    <div className={cx(classes.root, className && className)}>
      <div className={cx(classes.container, bgClass[groupRarity])}>
        <div className={cx(classes.content)}>{content[groupRarity]}</div>
      </div>
    </div>
  )
}
