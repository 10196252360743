import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { DiscordIcon, InstagramIcon, TwitterIcon } from 'assets/socials'
import { FadeInComponent } from 'components/Animation/FadeInComponent'
import { fontSpaceMono } from 'styles/fonts'
import { socialUrls } from 'constants/staticUrls'

const useStyles = makeStyles({ name: 'MainLandingSocial' })((theme) => ({
  root: {
    padding: theme.spacing(17, 0),
    background: `
        url('${process.env.PUBLIC_URL}/images/backgrounds/main-landing/social-bg.webp') 
        top center 
        no-repeat
      `,
    backgroundSize: 'cover',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 0),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      alignItems: 'flex-start',
    },
  },
  subtitle1: {
    fontFamily: fontSpaceMono,
    textTransform: 'uppercase',
    color: theme.palette.secondary.light,
    lineHeight: '40px',
  },
  description: {
    maxWidth: 632,
    fontWeight: 700,
    lineHeight: '32px',
    marginBottom: theme.spacing(8),

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(11),
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(2),
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  btn: {
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    maxWidth: 306,
    width: '100%',

    svg: {
      minWidth: 24,
      width: 24,
    },

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(3),
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

export const MainLandingSocial = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <FadeInComponent variant={'up'}>
        <>
          <Container maxWidth={'lg'}>
            <div className={classes.content}>
              <Typography variant={'subtitle1'} className={classes.subtitle1}>
                Join Our
              </Typography>
              <Typography variant={'h2'}>Community</Typography>
              <Typography variant={'h6'} className={classes.description}>
                Step up to the challenge and become the ultimate collector! Join
                us for a shot at rare signed merch and personalized experiences.
              </Typography>
            </div>
            <div className={classes.btnGroup}>
              <Button
                variant={'outlined'}
                className={classes.btn}
                size={'large'}
                target={'_blank'}
                href={socialUrls.digi.twitter}
              >
                <span>Digisport twitter</span>
                <TwitterIcon />
              </Button>
              <Button
                variant={'outlined'}
                className={classes.btn}
                size={'large'}
                target={'_blank'}
                href={socialUrls.digi.discord}
              >
                <span>Lympo discord</span>
                <DiscordIcon />
              </Button>
              <Button
                variant={'outlined'}
                className={classes.btn}
                size={'large'}
                target={'_blank'}
                href={socialUrls.rose.instagram}
              >
                <span>Rose instagram</span>
                <InstagramIcon />
              </Button>
            </div>
          </Container>
        </>
      </FadeInComponent>
    </div>
  )
}
