import { makeStyles } from 'styles/makeStyles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { IncrementDecrementCounter, TransitionDialog } from 'components/common'
import IconButton from '@mui/material/IconButton'
import { CloseIcon } from 'assets/icons'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { FC } from 'react'
import { fontSpaceMono } from 'styles/fonts'

const useStyles = makeStyles({ name: 'OpenMysteryBoxCalcModal' })((theme) => ({
  closeIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.text.secondary,

    svg: {
      width: 24,
      height: 24,
    },
  },
  content: {
    padding: theme.spacing(2, 5),
    display: 'flex',
    width: '100%',
    gap: theme.spacing(2),
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  imageContainer: {
    minWidth: 200,
    width: 200,
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      minWidth: 120,
      width: 120,
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  textContent: {
    display: 'flex',
    gap: theme.spacing(5),
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      gap: theme.spacing(1),
    },
  },
  title: {
    lineHeight: '56px',
    textAlign: 'center',
    width: '100%',
    fontWeight: 800,
  },
  actions: {
    padding: theme.spacing(2),
    borderTop: `1px solid rgba(255, 255, 255, .4)`,
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  btn: {
    minWidth: 224,
    width: 224,

    [theme.breakpoints.down('sm')]: {
      minWidth: 120,
      width: 120,
    },
  },
  label: {
    fontFamily: fontSpaceMono,
    marginBottom: theme.spacing(1),
  },
  counter: {
    marginBottom: theme.spacing(2),
  },
}))

interface Props {
  isOpen: boolean
  onClose: () => void
  amount: number
  setAmount: (amount: number) => void
  own: number
  boxImageUrl: string
  onConfirm: () => void
  name: string
  isApproved: boolean
  isLoading: boolean
  onApproveBtnClick: () => void
}

const OpenMysteryBoxCalcModal: FC<Props> = ({
  isOpen,
  onClose,
  amount,
  setAmount,
  own,
  boxImageUrl,
  onConfirm,
  name,
  isApproved,
  isLoading,
  onApproveBtnClick,
}) => {
  const { classes } = useStyles()

  return (
    <Dialog
      open={isOpen}
      PaperProps={{ sx: { maxWidth: 632, width: '100%' } }}
      aria-labelledby="dialog-OpenMysteryBoxCalcModal-labelledby"
      aria-describedby="dialog-OpenMysteryBoxCalcModal-describedby"
      TransitionComponent={TransitionDialog}
    >
      <DialogTitle id="dialog-OpenMysteryBoxCalcModal-title">
        <span></span>
        {!isLoading && (
          <IconButton
            aria-label="close"
            className={classes.closeIcon}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        className={classes.content}
        id="dialog-OpenMysteryBoxCalcModal-content"
      >
        <Typography variant="h3" className={classes.title}>
          Open <span>{name}</span> Mystery box
        </Typography>
        <div className={classes.textContent}>
          <div className={classes.imageContainer}>
            <img src={boxImageUrl} alt="box" className={classes.image} />
          </div>
          <div>
            <Typography variant="subtitle2" className={classes.label}>
              Boxes to open
            </Typography>
            <IncrementDecrementCounter
              count={amount}
              maxCount={own}
              onIncrementButtonClick={() => setAmount(amount + 1)}
              onDecrementButtonClick={() => {
                if (amount === 1) return
                setAmount(amount - 1)
              }}
              className={classes.counter}
            />
            <Typography variant="subtitle2" className={classes.label}>
              Boxes you own:
            </Typography>
            <Typography variant="h4" fontWeight={800}>
              {own}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          className={classes.btn}
          onClick={onClose}
          disabled={isLoading}
        >
          Back
        </Button>
        {isApproved ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={onConfirm}
            disabled={isLoading}
          >
            Confirm
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={onApproveBtnClick}
            disabled={isLoading}
          >
            Approve
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default OpenMysteryBoxCalcModal
