import * as React from 'react'
import { makeStyles } from 'styles/makeStyles'
import Typography from '@mui/material/Typography'
import { TooltipRenderProps } from 'react-joyride'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { CheckboxLineIcon, ArrowRightLineIcon } from 'assets/icons'

const useStyles = makeStyles({ name: 'TooltipJoyRide' })((theme) => ({
  root: {
    background:
      'linear-gradient(90deg, rgba(54,43,131,1) 0%, rgba(0,0,0,1) 100%)',
    padding: theme.spacing(3, 3, 2),
    borderRadius: theme.spacing(2),
    maxWidth: 304,
  },
  title: {
    fontWeight: 700,
  },
  description: {
    fontSize: 12,
    fontWeight: 400,

    a: {
      textDecoration: 'underline',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  btn: {
    gap: theme.spacing(1),
    fontSize: 12,
    lineHeight: '16px',

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },
  },
  iconRotate: {
    transform: 'rotate(-45deg)',
  },
}))

export const TooltipJoyRide = (data: TooltipRenderProps) => {
  const { classes } = useStyles()

  const {
    backProps,
    continuous,
    index,
    primaryProps,
    closeProps,
    step,
    tooltipProps,
    isLastStep,
    skipProps,
  } = data

  const { hideBackButton } = step

  return (
    <Box {...tooltipProps} className={classes.root}>
      {step.title && (
        <Typography variant={'body1'} className={classes.title}>
          {step?.title}
        </Typography>
      )}
      {!!step?.content && (
        <Typography
          variant={'subtitle2'}
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: step.content as string }}
        />
      )}
      <Box className={classes.content}>
        {index > 0 && !hideBackButton && (
          <Button
            variant={'text'}
            onClick={backProps.onClick}
            className={classes.btn}
          >
            {backProps.title}
          </Button>
        )}
        {continuous && (
          <Button
            onClick={primaryProps.onClick}
            variant={'text'}
            className={classes.btn}
          >
            {isLastStep ? (
              <>
                {/*<CheckboxLineIcon />*/}
                <span>{primaryProps.title}</span>
              </>
            ) : (
              <>
                <span>{primaryProps.title}</span>
                <ArrowRightLineIcon />
              </>
            )}
          </Button>
        )}
        {!continuous && (
          <Button {...closeProps} className={classes.btn}>
            <>
              <CheckboxLineIcon />
              <span>{closeProps.title}</span>
            </>
          </Button>
        )}
        {!isLastStep && !!skipProps && (
          <Button
            variant={'text'}
            onClick={skipProps.onClick}
            className={classes.btn}
          >
            <span>{skipProps.title}</span>
            <ArrowRightLineIcon className={classes.iconRotate} />
          </Button>
        )}
      </Box>
    </Box>
  )
}
