import { makeStyles } from 'styles/makeStyles'
import { Typography } from '@mui/material'
import { fontSpaceMono } from 'styles/fonts'
import { NftGroupRarity } from 'models/INfts'
import { publicImages } from 'utils/getPublicImages'
import { FC, useMemo } from 'react'
import { RoseNftTiny } from '@pages/Rose/components/RoseNfts'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import {
  athleteStickersByGroupRarity,
  RoseCollectionById,
  athleteStickersImages,
} from 'constants/stickers'

const useStyles = makeStyles({ name: 'RoseFighterStepItem' })((theme) => ({
  root: {
    position: 'relative',
  },
  container: {
    width: 416,
    minWidth: 416,
    height: 416,
    borderRadius: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center center',
    transition: 'all .3s ease-in-out',
    willChange: 'all',

    [theme.breakpoints.down('sm')]: {
      width: 288,
      minWidth: 288,
      height: 'auto',
      backgroundSize: '288px 288px',
      backgroundPosition: 'top center',
      marginBottom: theme.spacing(3),
    },

    '&:hover': {
      backgroundSize: '110%',

      [theme.breakpoints.down('sm')]: {
        backgroundSize: '288px 288px',
      },
    },

    '&::before': {
      content: "''",
      left: 0,
      position: 'absolute',
      bottom: 0,
      background: 'linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
      zIndex: 2,
      width: '100%',
      height: 180,
      transition: 'all 12s ease-in',
      willChange: 'all',
    },

    '&::after': {
      content: "''",
      left: 0,
      position: 'absolute',
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 2,
      width: '100%',
      height: '100%',
      opacity: 0,
      transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      willChange: 'opacity',
    },

    '&:hover::after': {
      opacity: 1,
      cursor: 'pointer',
    },
  },
  filter: {
    left: 0,
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(10px)',
  },
  bgBeginner: {
    backgroundImage: `url(${publicImages.getIllustration(
      'rose/nfts/beginner-nft-1.webp'
    )})`,
  },
  bgIntermediate: {
    backgroundImage: `url(${publicImages.getIllustration(
      'rose/nfts/intermediate-nft-6.webp'
    )})`,
  },
  bgAdvanced: {
    backgroundImage: `url(${publicImages.getIllustration(
      'rose/nfts/advanced-nft-1.webp'
    )})`,
  },
  bgElite: {
    backgroundImage: `url(${publicImages.getIllustration(
      'rose/nfts/elite-nft-2.webp'
    )})`,
  },
  bgLegendary: {
    backgroundImage: `url(${publicImages.getIllustration(
      'rose/nfts/legendary-nft-3.webp'
    )})`,
  },
  content: {
    width: '100%',
    position: 'relative',
    zIndex: 3,
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      minHeight: 288,
      display: 'flex',
      alignItems: 'center',
    },
  },
  contentBeginner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(5),
  },
  contentIntermediate: {
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1.5),
    },
  },
  contentAdvanced: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  contentElite: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 7, 0),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2, 0),
    },
  },
  title: {
    fontWeight: 700,
    color: theme.palette.secondary.light,
    fontFamily: fontSpaceMono,
  },
  text: {
    fontWeight: 400,
    fontFamily: fontSpaceMono,
  },
  contentBottom: {
    width: '100%',
    position: 'relative',
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: theme.spacing(0, 2, 1.5),

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  breedLabel: {
    maxWidth: 368,
    width: '100%',
    border: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(2, 3.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    background: 'rgba(0, 0, 0, 0.6)',
    borderRadius: theme.spacing(1),
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 256,
      padding: theme.spacing(2),
    },
  },
  breedLabelTitle: {
    color: theme.palette.secondary.light,
    fontWeight: 700,
    fontFamily: fontSpaceMono,
  },
  breedLabelText: {
    fontSize: 12,
  },
  borderYellow: {
    border: `4px solid ${theme.palette.secondary.light}`,
  },
  labelYellow: {
    position: 'absolute',
    top: -4,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: theme.spacing(0.5, 1),
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    background: theme.palette.secondary.light,
    zIndex: 3,
    maxWidth: 210,
    width: '100%',
    textAlign: 'center',
  },
  labelYellowText: {
    color: theme.palette.grey['A100'],
    fontFamily: fontSpaceMono,
  },
  borderGreen: {
    border: `4px solid ${theme.palette.success.main}`,
  },
  labelGreen: {
    background: theme.palette.success.main,
    maxWidth: 155,
  },
  labelGreenText: {
    color: theme.palette.common.white,
  },
}))

interface Props {
  className?: string
  groupRarity: NftGroupRarity
  title: string
  href: string
  selectedItems: Record<number, number>
  balanceById: Record<number, number>
}

export const RoseFighterStepItem: FC<Props> = ({
  className,
  groupRarity,
  title,
  href,
  selectedItems,
  balanceById,
}) => {
  const { classes, cx } = useStyles()

  const bgClass = {
    [NftGroupRarity.Beginner]: classes.bgBeginner,
    [NftGroupRarity.Intermediate]: classes.bgIntermediate,
    [NftGroupRarity.Advanced]: classes.bgAdvanced,
    [NftGroupRarity.Elite]: classes.bgElite,
    [NftGroupRarity.Legendary]: classes.bgLegendary,
  }

  const isReadyToSelect = useMemo(() => {
    const ids = athleteStickersByGroupRarity[groupRarity] || []

    return ids.some((id) => balanceById[id] > 0)
  }, [balanceById, groupRarity])

  const isCompleted = useMemo(() => {
    const ids = athleteStickersByGroupRarity[groupRarity] || []

    return ids.every((id) => selectedItems[id] > 0)
  }, [selectedItems, groupRarity])

  const ownedGeneral = useMemo(() => {
    const ids = athleteStickersByGroupRarity[groupRarity] || []

    return ids.reduce((acc, id) => {
      return acc + (balanceById[id] || 0)
    }, 0)
  }, [balanceById, groupRarity])

  const content = {
    [NftGroupRarity.Beginner]: (
      <div className={cx(classes.contentBeginner)}>
        <RoseNftTiny
          isActive={balanceById[RoseCollectionById['Beginner Head']] > 0}
          isChecked={selectedItems[RoseCollectionById['Beginner Head']] > 0}
          imageUrl={athleteStickersImages[RoseCollectionById['Beginner Head']]}
        />
        <RoseNftTiny
          isActive={balanceById[RoseCollectionById['Beginner Torso']] > 0}
          isChecked={selectedItems[RoseCollectionById['Beginner Torso']] > 0}
          imageUrl={athleteStickersImages[RoseCollectionById['Beginner Torso']]}
        />
      </div>
    ),
    [NftGroupRarity.Intermediate]: (
      <div className={cx(classes.contentBeginner, classes.contentIntermediate)}>
        <RoseNftTiny
          isActive={balanceById[RoseCollectionById['Intermediate Head']] > 0}
          isChecked={selectedItems[RoseCollectionById['Intermediate Head']] > 0}
          imageUrl={
            athleteStickersImages[RoseCollectionById['Intermediate Head']]
          }
        />
        <RoseNftTiny
          isActive={balanceById[RoseCollectionById['Intermediate Torso']] > 0}
          isChecked={
            selectedItems[RoseCollectionById['Intermediate Torso']] > 0
          }
          imageUrl={
            athleteStickersImages[RoseCollectionById['Intermediate Torso']]
          }
        />
        <RoseNftTiny
          isActive={balanceById[RoseCollectionById['Intermediate Hands']] > 0}
          isChecked={
            selectedItems[RoseCollectionById['Intermediate Hands']] > 0
          }
          imageUrl={
            athleteStickersImages[RoseCollectionById['Intermediate Hands']]
          }
        />
      </div>
    ),
    [NftGroupRarity.Advanced]: (
      <div className={classes.contentAdvanced}>
        <Box display={'flex'} gap={3}>
          <RoseNftTiny
            isActive={balanceById[RoseCollectionById['Advanced Head']] > 0}
            isChecked={selectedItems[RoseCollectionById['Advanced Head']] > 0}
            imageUrl={
              athleteStickersImages[RoseCollectionById['Advanced Head']]
            }
          />
          <RoseNftTiny
            isActive={balanceById[RoseCollectionById['Advanced Torso']] > 0}
            isChecked={selectedItems[RoseCollectionById['Advanced Torso']] > 0}
            imageUrl={
              athleteStickersImages[RoseCollectionById['Advanced Torso']]
            }
          />
        </Box>
        <Box display={'flex'} gap={3}>
          <RoseNftTiny
            isActive={balanceById[RoseCollectionById['Advanced Hands']] > 0}
            isChecked={selectedItems[RoseCollectionById['Advanced Hands']] > 0}
            imageUrl={
              athleteStickersImages[RoseCollectionById['Advanced Hands']]
            }
          />
          <RoseNftTiny
            isActive={balanceById[RoseCollectionById['Advanced Legs']] > 0}
            isChecked={selectedItems[RoseCollectionById['Advanced Legs']] > 0}
            imageUrl={
              athleteStickersImages[RoseCollectionById['Advanced Legs']]
            }
          />
        </Box>
      </div>
    ),
    [NftGroupRarity.Elite]: (
      <div className={classes.contentElite}>
        <Box
          display={'flex'}
          gap={1}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <RoseNftTiny
            isActive={balanceById[RoseCollectionById['Elite Head']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Head']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Head']]}
          />
          <RoseNftTiny
            isActive={balanceById[RoseCollectionById['Elite Torso']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Torso']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Torso']]}
          />
        </Box>
        <Box
          display={'flex'}
          gap={1}
          justifyContent={'space-between'}
          sx={{
            margin: {
              xs: '-40px 0',
              sm: '-20px 0',
            },
          }}
        >
          <RoseNftTiny
            isActive={balanceById[RoseCollectionById['Elite Belt']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Belt']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Belt']]}
          />
        </Box>
        <Box
          display={'flex'}
          gap={1}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <RoseNftTiny
            isActive={balanceById[RoseCollectionById['Elite Hands']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Hands']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Hands']]}
          />
          <RoseNftTiny
            isActive={balanceById[RoseCollectionById['Elite Legs']] > 0}
            isChecked={selectedItems[RoseCollectionById['Elite Legs']] > 0}
            imageUrl={athleteStickersImages[RoseCollectionById['Elite Legs']]}
          />
        </Box>
      </div>
    ),
    [NftGroupRarity.Legendary]: (
      <div className={classes.contentBeginner}>
        <div className={classes.breedLabel}>
          <Typography variant={'body1'} className={classes.breedLabelTitle}>
            NFT Fusion required
          </Typography>
          <Typography variant={'subtitle2'} className={classes.breedLabelText}>
            Legendary Fighter can only be achieved by fusing 4 Elite fighters
            together
          </Typography>
        </div>
      </div>
    ),
  }

  return (
    <Link to={href} className={cx(className && className)}>
      <div className={cx(classes.root)}>
        <div
          className={cx(
            classes.container,
            groupRarity !== NftGroupRarity.Legendary &&
              isReadyToSelect &&
              !isCompleted &&
              classes.borderYellow,
            groupRarity !== NftGroupRarity.Legendary &&
              isCompleted &&
              classes.borderGreen,
            bgClass[groupRarity]
          )}
        >
          {groupRarity !== NftGroupRarity.Legendary && (
            <>
              {isReadyToSelect && !isCompleted && (
                <div className={cx(classes.labelYellow)}>
                  <Typography
                    variant="subtitle2"
                    className={classes.labelYellowText}
                  >
                    New stickers available to add
                  </Typography>
                </div>
              )}
              {isCompleted && (
                <div className={cx(classes.labelYellow, classes.labelGreen)}>
                  <Typography
                    variant="subtitle2"
                    className={cx(
                      classes.labelYellowText,
                      classes.labelGreenText
                    )}
                  >
                    Collection completed!
                  </Typography>
                </div>
              )}
            </>
          )}

          <div className={cx(classes.filter)} />
          <div className={cx(classes.content)}>{content[groupRarity]}</div>
          <div className={classes.contentBottom}>
            <div>
              <Typography variant="h4" fontWeight={800}>
                {groupRarity}
              </Typography>
              <Typography variant="body1" className={classes.title}>
                {title}
              </Typography>
            </div>
            {groupRarity !== NftGroupRarity.Legendary && (
              <div>
                <Typography variant="subtitle2" className={classes.text}>
                  Stickers you own: {ownedGeneral}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}
