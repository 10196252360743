import { makeStyles } from 'styles/makeStyles'
import { FC } from 'react'
import { CheckboxGreenIcon } from 'assets/icons'

const useStyles = makeStyles({ name: 'RoseNftTiny' })((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    position: 'relative',
  },
  icon: {
    minWidth: 24,
    width: 24,
    height: 24,
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  imageContainer: {
    maxWidth: 80,
    minWidth: 80,
    width: 80,
    height: 80,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    position: 'relative',

    img: {
      width: '100%',
      height: 'auto',
    },
  },
  imageContainerSmall: {
    maxWidth: 56,
    height: 56,
    minWidth: 56,
    width: 56,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 46,
      minWidth: 46,
      width: 46,
      height: 46,
    },
  },
  layout: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.6)',
  },
  borderGray: {
    border: `2px solid ${theme.palette.grey[300]}`,
  },
  borderYellow: {
    border: `2px solid ${theme.palette.secondary.light}`,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    background: theme.palette.secondary.light,
    position: 'absolute',
    border: `2px solid ${theme.palette.common.white}`,
    top: 4,
    left: 4,
    zIndex: 1,
  },
}))

interface Props {
  isActive: boolean
  isChecked?: boolean
  imageUrl: string
  isSmall?: boolean
}

export const RoseNftTiny: FC<Props> = ({
  isActive,
  imageUrl,
  isSmall,
  isChecked,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={classes.root}>
      <CheckboxGreenIcon
        className={cx(classes.icon, isChecked && classes.visible)}
      />
      <div
        className={cx(
          classes.imageContainer,
          !isChecked && classes.borderGray,
          !isChecked && isActive && classes.borderYellow,
          isSmall && classes.imageContainerSmall
        )}
      >
        {!isChecked && isActive && <div className={classes.dot} />}
        <img src={imageUrl} alt={'rose nft'} />
        {!isChecked && <div className={classes.layout} />}
      </div>
    </div>
  )
}
