import { Navigate, useSearchParams } from 'react-router-dom'
import { ROUTER_PATHS } from 'routes/routerPaths'
import { FC } from 'react'
import { useAppSelector } from 'store/hooks'
import {
  isLoggedInSelector,
  selectProfileIsExists,
} from 'store/reducers/user/selectors'
import { URL_SEARCH_PARAM } from 'constants/urlSearchParams'

interface Props {
  children: JSX.Element
}

export const ProtectedRoseProfileEditRoute: FC<Props> = ({ children }) => {
  const isExists = useAppSelector(selectProfileIsExists)
  const isLoggedIn = useAppSelector(isLoggedInSelector)

  const [search] = useSearchParams()

  if (!isExists && isLoggedIn) {
    return children
  }

  if (!!search.get(URL_SEARCH_PARAM.backUrl)) {
    return (
      <Navigate
        to={search.get(URL_SEARCH_PARAM.backUrl) || ROUTER_PATHS.ROSE_PROFILE}
      />
    )
  }

  return <Navigate to={ROUTER_PATHS.ROSE_PROFILE} />
}
