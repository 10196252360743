import { makeStyles } from 'styles/makeStyles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { fontSpaceMono } from 'styles/fonts'
import { linearGradients } from 'styles/other'
import { FC, useState } from 'react'
import { MAIN_LANDING_BUILD_INFO } from './constants'
import { MainLandingBuildInfoBtn } from './MainLandingBuildInfoBtn'
import { LINKS_ANCHOR } from 'constants/linksAnchor'

const useStyles = makeStyles({ name: 'MainLandingBuildInfo' })((theme) => ({
  root: {
    padding: theme.spacing(18, 0),
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: `
        url('${process.env.PUBLIC_URL}/images/backgrounds/main-landing/build-info-bg.webp')
        top center
        no-repeat
      `,
    backgroundSize: 'contain',
    position: 'sticky',
    top: -350,

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(10, 0),
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0, 10),
      position: 'static',
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    marginBottom: theme.spacing(18),

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
  },
  subtitle1: {
    fontFamily: fontSpaceMono,
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    lineHeight: '40px',
  },
  text: {
    maxWidth: 524,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1064,
  },
  infoBlock: {
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  dividerHorizontal: {
    width: '100%',
    height: 1,
    background: linearGradients.backgroundLinesHorizontal,
    position: 'relative',
    zIndex: 10,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  dividerColVertical: {
    position: 'absolute',
    top: '-80px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 1,
    height: 'calc(100% + 160px)',
    background: linearGradients.backgroundLinesVertical,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1,
    marginLeft: theme.spacing(13),

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-80px',
      left: 0,
      width: 1,
      height: 'calc(100% + 160px)',
      background: linearGradients.backgroundLinesVertical,

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-80px',
      right: 0,
      width: 1,
      height: 'calc(100% + 160px)',
      background: linearGradients.backgroundLinesVertical,

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      flexDirection: 'row',
      overflowX: 'auto',
    },
  },
  row: {
    display: 'flex',
    position: 'relative',
    zIndex: 1,

    '&:not(:last-of-type)::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      background: linearGradients.backgroundLinesHorizontal,

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  item: {
    padding: theme.spacing(1),
  },
  descriptionBlock: {
    display: 'flex',
    maxWidth: 524,
    padding: theme.spacing(4, 0, 0, 4),
    flexDirection: 'column',
    alignItems: 'center',

    img: {
      maxWidth: 367,
      width: '100%',
      height: 'auto',
    },

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0, 0, 0),
      alignSelf: 'center',
    },
  },
  description: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    margin: theme.spacing(1.5, 0, 3),
  },
}))

const firstRow = [
  MAIN_LANDING_BUILD_INFO.Beginner,
  MAIN_LANDING_BUILD_INFO.Intermediate,
]
const secondRow = [
  MAIN_LANDING_BUILD_INFO.Advanced,
  MAIN_LANDING_BUILD_INFO.Elite,
]
const thirdRow = [MAIN_LANDING_BUILD_INFO.Legendary]

interface Props {
  className?: string
}

export const MainLandingBuildInfo: FC<Props> = ({ className }) => {
  const { classes, cx } = useStyles()
  const [activeTab, setActiveTab] = useState<string>(
    MAIN_LANDING_BUILD_INFO.Beginner.name
  )

  return (
    <div className={cx(classes.root, className)} id={LINKS_ANCHOR.roseItems.id}>
      <Container maxWidth={'lg'}>
        <div className={classes.textContainer}>
          <div>
            <Typography variant={'subtitle1'} className={classes.subtitle1}>
              The Fighter:
            </Typography>
            <Typography variant={'h2'}>Build Your Own</Typography>
          </div>
          <Typography variant={'subtitle1'} className={classes.text}>
            Unleash your inner fighter with our Collector&apos;s Challenge.
            Unlock mystery boxes to collect rare digital stickers and upgrade
            your Fighter to reach the Legendary title!
          </Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.dividerHorizontal} />
          <div className={classes.infoBlock}>
            <div className={classes.table}>
              <div className={classes.dividerColVertical} />
              <div className={classes.row}>
                {firstRow.map(({ name, btnImageUrl }) => (
                  <div key={name} className={classes.item}>
                    <MainLandingBuildInfoBtn
                      name={name}
                      imageUrl={btnImageUrl}
                      onClick={setActiveTab}
                      isActive={name === activeTab}
                    />
                  </div>
                ))}
              </div>
              <div className={classes.row}>
                {secondRow.map(({ name, btnImageUrl }) => (
                  <div key={name} className={classes.item}>
                    <MainLandingBuildInfoBtn
                      name={name}
                      imageUrl={btnImageUrl}
                      onClick={setActiveTab}
                      isActive={name === activeTab}
                    />
                  </div>
                ))}
              </div>
              <div className={classes.row}>
                {thirdRow.map(({ name, btnImageUrl }) => (
                  <div key={name} className={classes.item}>
                    <MainLandingBuildInfoBtn
                      name={name}
                      imageUrl={btnImageUrl}
                      onClick={setActiveTab}
                      isActive={name === activeTab}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.descriptionBlock}>
              <Typography variant={'h4'} fontWeight={700}>
                {MAIN_LANDING_BUILD_INFO[activeTab].name}
              </Typography>
              <Typography variant={'body2'} className={classes.description}>
                {MAIN_LANDING_BUILD_INFO[activeTab].description}
              </Typography>
              <img src={MAIN_LANDING_BUILD_INFO[activeTab].imageUrl} />
            </div>
          </div>
          <div className={classes.dividerHorizontal} />
        </div>
      </Container>
    </div>
  )
}
