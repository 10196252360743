import { makeStyles } from 'styles/makeStyles'
import { ROSE_MERCH_NFTs_TOP_LIST } from './constants'
import { linearGradients } from 'styles/other'
import { RoseMerchStepItem } from '@pages/Rose/components/RoseMerchItems'
import { localStorageKeys } from 'constants/localStorageKeys'
import { useAppSelector } from 'store/hooks'
import { reFetchedFlagSelector } from 'store/reducers/app/selectors'
import { itemsStickersListById } from 'constants/stickers'
import { useQuery_BalanceOfBatch_Erc1155 } from 'services/useApi/erc1155/useQuery'
import { getCacheKey } from 'utils/getCacheKey'
import { cacheKeys } from 'constants/cacheKeys'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { useEffect, useMemo, useState } from 'react'
import { useLocalStorageKeys } from 'hooks/useLocalStorageKeys'
import { ROUTER_PATHS } from 'routes/routerPaths'
import { scrollToTop } from 'utils/scrollToTop'
import { publicImages } from 'utils/getPublicImages'
import { ArrowRightSmallLineIcon } from 'assets/icons'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  isLoggedInSelector,
  selectIsValidChain,
} from 'store/reducers/user/selectors'
import { TABS_ROSE_COLLECTION_INFO } from '@pages/Rose/components/RoseCollection'

const useStyles = makeStyles({ name: 'RoseCollectionInfoMerch' })((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'center',
  },
  top: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    overflowX: 'auto',
    justifyContent: 'center',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  bottom: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  dividerHorizontal: {
    width: '100%',
    height: 1,
    background: linearGradients.backgroundLinesHorizontal,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  item: {
    position: 'relative',

    '&:not(:last-of-type)::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: -8,
      width: 1,
      height: '100%',
      background: linearGradients.backgroundLinesVertical,
      zIndex: 3,

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        display: 'block',
        bottom: 0,
        top: 'auto',
        right: 0,
        width: '100%',
        height: 1,
        background: linearGradients.backgroundLinesHorizontal,
      },
    },
  },
  tab: {
    display: 'none',

    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginTop: theme.spacing(8),
      border: 'none',
    },
  },
  link: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },

    svg: {
      minWidth: 24,
      width: 24,
      height: 24,
    },

    span: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  linkInner: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  linkBigText: {
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    gap: '0 !important',
  },
  linkImgContainer: {
    minWidth: 48,
    width: 48,

    img: {
      width: '100%',
      height: 'auto',
    },
  },
}))

export const RoseCollectionInfoMerch = () => {
  const { classes } = useStyles()

  const [selectedItems, setSelectedItems] = useState<Record<number, number>>({})

  const isValidChain = useAppSelector(selectIsValidChain)

  const [keyLS] = useLocalStorageKeys(localStorageKeys.itemStickersSelected)

  useEffect(() => {
    const items = localStorage.getItem(keyLS)

    if (items) {
      setSelectedItems(JSON.parse(items))
    } else {
      setSelectedItems({})
    }
  }, [keyLS])

  const reFetchedFlag = useAppSelector(reFetchedFlagSelector)

  const ids = Object.values(itemsStickersListById).filter(
    (item) => typeof item === 'number'
  ) as number[]

  const { data = [] } = useQuery_BalanceOfBatch_Erc1155(
    getCacheKey(cacheKeys.erc1155.balanceOfBatch, 'RoseCollectionMerch'),
    ids,
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    reFetchedFlag[ReFetchFlag.ClaimMerch]
  )

  const balanceById = useMemo(() => {
    return data.reduce((acc, item, index) => {
      acc[ids[index]] = item
      return acc
    }, {} as Record<number, number>)
  }, [data, ids])

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        {ROSE_MERCH_NFTs_TOP_LIST.map(
          ({ type, merchImgUrl, stickerImgUrl, href, title }) => (
            <RoseMerchStepItem
              key={type}
              itemStickerId={type}
              merchImgUrl={merchImgUrl}
              nftImgUrl={stickerImgUrl}
              title={title}
              href={href}
              selectedItems={selectedItems}
              className={classes.item}
              balanceById={balanceById}
              isValidChain={isValidChain}
            />
          )
        )}
      </div>
      <Link to={ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS}>
        <Box className={classes.link} onClick={() => scrollToTop()}>
          <div className={classes.linkInner}>
            <div className={classes.linkImgContainer}>
              <img
                src={publicImages.getIllustration(
                  'rose/fighter-nfts-collection-tab.png'
                )}
                alt={'Fighter NFTs'}
              />
            </div>
            <span className={classes.linkBigText}>
              <Typography variant={'body1'} fontWeight={800}>
                {TABS_ROSE_COLLECTION_INFO[0].title}
              </Typography>
              <Typography
                variant={'subtitle2'}
                color={'text.secondary'}
                fontWeight={400}
              >
                {TABS_ROSE_COLLECTION_INFO[0].description}
              </Typography>
            </span>
          </div>
          <ArrowRightSmallLineIcon />
        </Box>
      </Link>
    </div>
  )
}
