import { useMutation } from '@tanstack/react-query'
import { contractErc1155Api } from 'services/api/contract/erc1155'
import { useAppDispatch } from 'store/hooks'
import {
  enqueueSnack,
  setCloseTimerSnackbarKey,
  setStartTimerSnackbarKey,
} from 'store/reducers/snackbar/actions'
import { getErrorApproveErc1155 } from 'services/mapper/errors'
import { snackbarKeys } from 'constants/snackbarKeys'
import isFunction from 'lodash/isFunction'

export const useMutation_Approve_Erc1155 = (
  onSuccessCb?: () => void,
  onErrorCb?: () => void
) => {
  const dispatch = useAppDispatch()

  return useMutation(
    async (data: {
      nftContractAddress: string | undefined
      toAddress: string | undefined
    }) => {
      const tx = await contractErc1155Api.approveErc1155(
        data.nftContractAddress as string,
        data.toAddress as string
      )

      dispatch(setStartTimerSnackbarKey(snackbarKeys.approveErc1155))

      await tx.wait(3)

      dispatch(setCloseTimerSnackbarKey(snackbarKeys.approveErc1155))

      return
    },
    {
      onSuccess: () => {
        isFunction(onSuccessCb) && onSuccessCb()
        dispatch(
          enqueueSnack({
            message: 'Success!',
            variant: 'success',
          })
        )
      },
      onError: (e: Error) => {
        isFunction(onErrorCb) && onErrorCb()
        dispatch(
          enqueueSnack({
            message: getErrorApproveErc1155(JSON.stringify(e)),
            variant: 'error',
          })
        )
      },
    }
  )
}
