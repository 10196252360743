import { NftGroupRarity } from 'models/INfts'
import { ROUTER_PATHS } from 'routes/routerPaths'

const getHrefForNft = (path: string) => {
  return `${ROUTER_PATHS.ROSE_NFT_COLLECTION_FIGHTERS}/${path.toLowerCase()}`
}

const getHrefForBreeding = (path: string) => {
  return `${ROUTER_PATHS.ROSE_NFT_COLLECTION_FUSION}/${path.toLowerCase()}`
}

export const ROSE_FIGHTER_NFT_BEGINNER = {
  rarity: NftGroupRarity.Beginner,
  title: 'Fighter Avatar NFT',
  total: 999,
  remaining: 999,
  own: 0,
  href: getHrefForNft(NftGroupRarity.Beginner),
}

export const ROSE_FIGHTER_NFT_INTERMEDIATE = {
  rarity: NftGroupRarity.Intermediate,
  title: 'Fighter Avatar NFT',
  total: 999,
  remaining: 999,
  own: 0,
  href: getHrefForNft(NftGroupRarity.Intermediate),
}

export const ROSE_FIGHTER_NFT_ADVANCED = {
  rarity: NftGroupRarity.Advanced,
  title: 'Fighter Avatar NFT',
  total: 999,
  remaining: 999,
  own: 0,
  href: getHrefForNft(NftGroupRarity.Advanced),
}

export const ROSE_FIGHTER_NFT_ELITE = {
  rarity: NftGroupRarity.Elite,
  title: 'Fighter Avatar NFT',
  total: 999,
  remaining: 999,
  own: 0,
  href: getHrefForNft(NftGroupRarity.Elite),
}

export const ROSE_FIGHTER_NFT_LEGENDARY = {
  rarity: NftGroupRarity.Legendary,
  title: 'Fighter Avatar NFT',
  total: 999,
  remaining: 999,
  own: 0,
  href: getHrefForBreeding(NftGroupRarity.Legendary),
}

export const ROSE_FIGHTER_NFTs_TOP_LIST = [
  ROSE_FIGHTER_NFT_BEGINNER,
  ROSE_FIGHTER_NFT_INTERMEDIATE,
  ROSE_FIGHTER_NFT_ADVANCED,
]

export const ROSE_FIGHTER_NFTs_BOTTOM_LIST = [
  ROSE_FIGHTER_NFT_ELITE,
  ROSE_FIGHTER_NFT_LEGENDARY,
]
