import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectUserAddress } from 'store/reducers/user/selectors'
import { httpTermsAndConditionsTrackerApi } from 'services/api/http/termsAndConditionsTracker'
import { setTermsAndConditions } from 'store/reducers/app/actions'
import { cacheKeys } from 'constants/cacheKeys'
import { termsAndConditionsIdx } from 'constants/termsAndConditions'
import { ITermsList } from 'models/ITermsAndConditions'

export const useQuery_GetIsAccepted_TermsAndConditions = (
  options?: UseQueryOptions<ITermsList[]>
) => {
  const dispatch = useAppDispatch()
  const userAddress = useAppSelector(selectUserAddress)

  return useQuery<ITermsList[]>(
    [cacheKeys.isAcceptedTermsAndConditions, userAddress],
    async () => {
      const termsList = await httpTermsAndConditionsTrackerApi.getTermsList()

      const termsAndConditionsIdxNumber = Object.values(
        termsAndConditionsIdx
      ).filter((item) => typeof item === 'number') as number[]

      const isAcceptedList = await Promise.all([
        ...termsList?.map((item) =>
          httpTermsAndConditionsTrackerApi.getIsAcceptedTermsAndConditions(
            userAddress as string,
            item.description
          )
        ),
      ])

      termsAndConditionsIdxNumber.forEach((item) => {
        dispatch(
          setTermsAndConditions(
            item,
            isAcceptedList[item],
            termsList[item].description
          )
        )
      })

      return termsList
    },
    {
      enabled: !!userAddress,
      ...options,
    }
  )
}
