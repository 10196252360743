import { FC, useEffect, useMemo, useState } from 'react'
import { nftBreedingModalContent, StepsNftBreedingModal } from './constants'
import WrapperLargeModal from 'components/Modals/WrapperLargeModal'
import { BoxesLoaderBig } from 'components/Loaders'
import { NftBreedingSelectStep } from 'components/Modals/NftBreedingModal/NftBreedingSelectStep'
import { NftAvatar } from 'models/INfts'
import { AthleteRarityId } from 'constants/rarity'
import { useMutation_Breeding_OpenBox } from 'services/useApi/openBox/useMutation'
import { RoseNftCollectionClaimOpeningStep } from '@pages/Rose/RoseNftCollectionDetails/RoseNftCollectionClaimModal/RoseNftCollectionClaimOpeningStep'
import { RoseNftCollectionClaimSuccessStep } from '@pages/Rose/RoseNftCollectionDetails/RoseNftCollectionClaimModal/RoseNftCollectionClaimSuccessStep'
import { setReFetchFlag } from 'store/reducers/app/actions'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { useAppDispatch } from 'store/hooks'

interface Props {
  isOpen: boolean
  onClose: () => void
  rarityId: AthleteRarityId
  nftList: NftAvatar[]
}

const NftBreedingModalContainer: FC<Props> = ({
  isOpen,
  onClose,
  rarityId,
  nftList = [],
}) => {
  const [step, setStep] = useState<null | StepsNftBreedingModal>(null)
  const [selectedNftList, setSelectedNftList] = useState<
    Array<null | NftAvatar>
  >([null, null, null, null])
  const [successData, setSuccessData] = useState<{
    id: number
    image: string
  }>({
    id: 0,
    image: '',
  })

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isOpen) {
      setStep(null)
      setSelectedNftList([null, null, null, null])
    } else {
      setStep(StepsNftBreedingModal.SELECT_NFT)
    }
  }, [isOpen])

  const handleClose = () => {
    onClose()
    setStep(null)
    dispatch(setReFetchFlag(ReFetchFlag.ClaimAthleteNft))
  }

  const { mutateAsync } = useMutation_Breeding_OpenBox(
    () => {
      setStep(StepsNftBreedingModal.OPENING)
    },
    () => {
      setStep(StepsNftBreedingModal.SELECT_NFT)
    }
  )

  const onAutoFillBtnClick = () => {
    const emptyCount = selectedNftList.filter((item) => item === null).length
    const newSelectedNftList = [...selectedNftList]
    let validNftList = nftList.filter((item) => {
      return !selectedNftList.some((selectedItem) => {
        return selectedItem?.id === item.id
      })
    })

    for (let i = 0; i < emptyCount; i++) {
      const randomIdx = Math.floor(Math.random() * validNftList.length)
      const emptyIdx = newSelectedNftList.findIndex((item) => item === null)
      newSelectedNftList[emptyIdx] = validNftList[randomIdx]
      validNftList = validNftList.filter(
        (item) => item.id !== newSelectedNftList[i]?.id
      )
    }

    setSelectedNftList(newSelectedNftList)
  }

  const onBreedNftBtnClick = async () => {
    setStep(StepsNftBreedingModal.LOADING)
    const { id, image } = await mutateAsync({
      itemIds: selectedNftList.map((item) => item?.id as string),
    })
    setSuccessData({ id, image })
  }

  const onSelectNftBtnClick = (data: NftAvatar) => {
    const idx = selectedNftList.findIndex((item) => item === null)
    const newSelectedNftList = [...selectedNftList]
    newSelectedNftList[idx] = data

    setSelectedNftList(newSelectedNftList)
  }

  const onUnSelectNftBtnClick = (data: NftAvatar) => {
    const idx = selectedNftList.findIndex(
      (item) => !!item && item?.id === data.id
    )
    const newSelectedNftList = [...selectedNftList]
    newSelectedNftList[idx] = null

    setSelectedNftList(newSelectedNftList)
  }

  const isCompleted = useMemo(() => {
    return selectedNftList.every((item) => item !== null)
  }, [selectedNftList])

  return (
    <>
      <WrapperLargeModal isOpen={isOpen} onClose={handleClose}>
        <>
          {step === StepsNftBreedingModal.SELECT_NFT && (
            <NftBreedingSelectStep
              selectedNft={selectedNftList}
              nftList={nftList}
              title={nftBreedingModalContent[rarityId].title}
              isCompleted={isCompleted}
              onAutoFillBtnClick={onAutoFillBtnClick}
              onBreedNftBtnClick={onBreedNftBtnClick}
              onSelectNftBtnClick={onSelectNftBtnClick}
              onUnSelectNftBtnClick={onUnSelectNftBtnClick}
            />
          )}
          {step === StepsNftBreedingModal.LOADING && (
            <BoxesLoaderBig
              subtitle={'blockchain gym is busy...'}
              title={'Fusing your Fighter'}
            />
          )}
          {step === StepsNftBreedingModal.OPENING && (
            <RoseNftCollectionClaimOpeningStep
              imageUrl={nftBreedingModalContent[rarityId].prizeUrlImage}
              onNext={() => setStep(StepsNftBreedingModal.SUCCESS)}
              subtitle={'blockchain gym is busy...'}
              title={'Fusing your Fighter'}
            />
          )}
          {step === StepsNftBreedingModal.SUCCESS && (
            <RoseNftCollectionClaimSuccessStep
              imageUrl={successData.image}
              onClose={handleClose}
              id={successData.id}
              title={nftBreedingModalContent[rarityId].prizeTitle}
            />
          )}
        </>
      </WrapperLargeModal>
    </>
  )
}

export default NftBreedingModalContainer
