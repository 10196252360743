import { fontNeuePlak, fontSpaceMono } from './fonts'
import { Theme } from '@mui/material/styles'

export const getTypography = (baseTheme: Theme): any => {
  return {
    fontFamily: fontNeuePlak,
    h1: {
      fontSize: 120,
      lineHeight: '160px',
      fontWeight: 800,

      [baseTheme.breakpoints.down('md')]: {
        fontSize: 40,
        lineHeight: '46px',
      },
    },
    h2: {
      fontSize: 88,
      lineHeight: '120px',
      fontWeight: 800,

      [baseTheme.breakpoints.down('md')]: {
        fontSize: 32,
        lineHeight: '40px',
      },
    },
    h3: {
      fontSize: 40,
      lineHeight: '64px',
      fontWeight: 700,

      [baseTheme.breakpoints.down('md')]: {
        fontSize: 32,
        lineHeight: '40px',
      },
    },
    h4: {
      fontSize: 32,
      lineHeight: '40px',

      [baseTheme.breakpoints.down('md')]: {
        fontSize: 24,
        lineHeight: '32px',
      },
    },
    h5: {
      fontSize: 24,
      lineHeight: '40px',

      [baseTheme.breakpoints.down('md')]: {
        fontSize: 20,
        lineHeight: '28px',
      },
    },
    h6: {
      fontSize: 20,
      lineHeight: '24px',

      [baseTheme.breakpoints.down('md')]: {
        fontSize: 18,
        lineHeight: 1.5,
      },
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 10,
      lineHeight: '16px',
      fontWeight: 700,
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '24px',
    },
    caption: {
      fontFamily: fontSpaceMono,
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 700,
    },
  }
}
