import { RoseCollectionById } from 'constants/stickers'
import { ROSE_COLLECTION_CONTRACT_ADDRESS } from 'constants/addresses'
import { useQuery_BalanceOfBatch_Erc1155 } from 'services/useApi/erc1155/useQuery'
import { getCacheKey } from 'utils/getCacheKey'
import { cacheKeys } from 'constants/cacheKeys'
import { ReFetchFlag } from 'constants/reFetchFlag'
import { useMemo } from 'react'
import { useAppSelector } from 'store/hooks'
import { reFetchedFlagSelector } from 'store/reducers/app/selectors'
import { RoseInventoryNftStickersComponent } from './RoseInventoryNftStickersComponent'
import { MenuInventory } from '../constants'

export const RoseInventoryNftStickersContainer = () => {
  const ids = Object.values(RoseCollectionById).filter(
    (item) => typeof item === 'number'
  ) as number[]

  const reFetchedFlag = useAppSelector(reFetchedFlagSelector)

  const { data = [] } = useQuery_BalanceOfBatch_Erc1155(
    getCacheKey(cacheKeys.erc1155.balanceOfBatch, 'RoseInventoryNftStickers'),
    ids,
    ROSE_COLLECTION_CONTRACT_ADDRESS,
    reFetchedFlag[ReFetchFlag.ClaimMerch]
  )

  const balanceById = useMemo(() => {
    return data.reduce((acc, item, index) => {
      acc[ids[index]] = item
      return acc
    }, {} as Record<number, number>)
  }, [data])

  return (
    <RoseInventoryNftStickersComponent
      activeMenuItem={MenuInventory.NFTStickers}
      balanceById={balanceById}
    />
  )
}
