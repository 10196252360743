import { makeStyles } from 'styles/makeStyles'
import Slider, { Settings } from 'react-slick'
import { publicImages } from 'utils/getPublicImages'

const useStyles = makeStyles({ name: 'MainLandingDoubleSliderHeroes' })(
  (theme) => ({
    root: {
      overflow: 'hidden',
      padding: theme.spacing(5, 0),
    },
    container: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      willChange: 'transform',
      transform: 'rotate(4deg)',
      margin: theme.spacing(5, 0),
    },
    item: {
      padding: theme.spacing(0.25, 0.5),
      overflow: 'hidden',
      borderRadius: theme.spacing(2),

      '&:focus': {
        outline: 'none',
      },

      img: {
        width: '100%',
        height: 'auto',
      },
    },
    divider: {
      width: '100%',
      height: 8,
    },
  })
)

const settingsTop: Settings = {
  dots: true,
  infinite: true,
  slidesToScroll: 1,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 1500,
  pauseOnHover: false,
  slidesToShow: 9,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
}

const settingsBottom: Settings = {
  ...settingsTop,
  rtl: true,
}

export const MainLandingDoubleSliderHeroes = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Slider {...settingsTop}>
          <div className={classes.item} style={{ minWidth: '64px' }}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/beginner-nft-1.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/advanced-nft-1.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/elite-nft-1.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/legendary-nft-1.webp'
              )}`}
              alt={'nft'}
            />
          </div>

          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/beginner-nft-2.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/advanced-nft-2.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/elite-nft-2.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/legendary-nft-2.webp'
              )}`}
              alt={'nft'}
            />
          </div>

          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/beginner-nft-4.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/advanced-nft-3.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/elite-nft-4.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/legendary-nft-3.webp'
              )}`}
              alt={'nft'}
            />
          </div>
        </Slider>
        <Slider {...settingsBottom}>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/beginner-nft-3.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/advanced-nft-4.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/elite-nft-3.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/legendary-nft-4.webp'
              )}`}
              alt={'nft'}
            />
          </div>

          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/beginner-nft-5.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/advanced-nft-5.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/elite-nft-5.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/legendary-nft-5.webp'
              )}`}
              alt={'nft'}
            />
          </div>

          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/beginner-nft-6.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/advanced-nft-6.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/elite-nft-6.webp'
              )}`}
              alt={'nft'}
            />
          </div>
          <div className={classes.item}>
            <img
              src={`${publicImages.getIllustration(
                'rose/nfts/legendary-nft-6.webp'
              )}`}
              alt={'nft'}
            />
          </div>
        </Slider>
      </div>
    </div>
  )
}
